import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import lodash from 'lodash'
import { errorMessage } from "../../../module/error-methods";
import "../../../assets/scss/custom/pages/datatables.scss";
import Drive from "../../../assets/images/driveview.png";
import Excel from "../../../assets/images/excel.png";
import MountComponent from "../../../module/mount-components";
import {getChannelPartnersAPI} from "../../../api/channel-partner.api"
import getNetworkLocationsListAPI from "../../../api/getNetworkLocationsListAPI";
import getDivisionsAPI from "../../../api/getDivisionsAPI";
import { getSalesHierarchiesAPI } from "../../../api/sales-hierarchy.api";

export default React.memo(({ tab, setHide}) => {
  const [divisionId, setDivisionId] = useState(null);
  const [designationId, setDesignationId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });
  
  const [chanelPartner, setChanelPartner] = useState([]);
  const [divisionOptions,setDivisionOptions] = useState([]);

  /** @handlers --------------------------------------------------------------------------------------------------------------------- */

  const handleSelectDivision = useCallback((option) => { setDivisionId(option.value) });
  const handleSelectDesignation = useCallback((option) => { setDesignationId(option.value) });
  const handleSelectLocation = useCallback((option) => { setLocationId(option.value) });

/** ------------------------------------------------------------------------------------------------------------------------------- */

  const getNetworkChannelPartner = async () => {
    try {

      setLoading(true);
      const queryParams = { divisionId,locationId, salesHierarchyId: designationId };
      const { data } = await getChannelPartnersAPI(queryParams);

      if(lodash.isArray(data)) { setChanelPartner([...data]) }

    } catch (error) { } finally { setLoading(false); }
};

  useEffect(() => { 
    if (tab === "CHANNEL_PARTNER_TAB") { getNetworkChannelPartner(); }
    return () => null;
  }, [tab,divisionId, designationId, locationId]);

  const getDivisionsDetails = async () => {
      await getDivisionsAPI().then(({ data }) => {
        if(lodash.isArray(data)) { 
            const options = data.map(({ name, id }) => ({ label: name, value: id }));
            return setDivisionOptions([ ...options ])
        }
      }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }) });
  }

  const getSaleDesignation = async () => {
    try {
      if(lodash.isNil(divisionId)) { return true; }
      const { data : salesHierarchies } = await getSalesHierarchiesAPI({ divisionId });
      const salesHierarchyOptions = lodash.map(salesHierarchies,(data) => ({ 
          value: data.id,
          label: data.name,
      }));
      return setDesignationOptions(lodash.cloneDeep(salesHierarchyOptions))
    } catch (error) { setDesignationOptions([]) }
  };

  const getNetworkLocations = async () => {
    try {
      if(lodash.isNil(divisionId)) { return true; }
      const { data : networkLocations } = await getNetworkLocationsListAPI({ divisionId });
      const networkLocationOptions = lodash.map(networkLocations,(data) => ({ 
          value: data.id,
          label: data.location,
      }));
      return setLocationOptions(lodash.cloneDeep(networkLocationOptions));
  } catch (error) { setLocationOptions([]); }
  }

  useEffect(() => { if (tab === "CHANNEL_PARTNER_TAB") { getDivisionsDetails(); } }, [tab]);
  useEffect(() => { if (tab === "CHANNEL_PARTNER_TAB") { getSaleDesignation(); } }, [tab,divisionId]);
  useEffect(() => { if (tab === "CHANNEL_PARTNER_TAB") { getNetworkLocations();} }, [tab,divisionId]);

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "srn",
        sort: "asc",
        width: 150,
      },
      {
        label: "Channel Partner Name",
        field: "partnerName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU/Division",
        field: "sbu",
        sort: "asc",
        width: 100,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 100,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      ...chanelPartner.map((data, i) => ({
        srn: ++i,
        partnerName: `${data.firstName} ${data.lastName}`,
        mobile: data.mobileNo,
        email: data.email,
        sbu: data.divisions.name,
        designation: data.distributionHierarchy.name,
        location: data.networkLocation.location,
      })),
    ],
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data.rows);
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(dataBlob, "channelPartners.xlsx");
  };

  return (
    <div className="location">
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>
      <div className="viewSalesButtons row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="addNewBtn">
            <button
              style={{
                padding: "8px",
                borderRadius: "7px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                border: "none",
                margin: "0 5px",
                background: "#fff",
              }}
              onClick={() => setHide("mapView")}
            >
              <img
                src={Drive}
                alt=""
                style={{
                  height: "1.5em",
                  objectFit: "contain",
                  width: "100%",
                  background: "#fff",
                }}
              />
            </button>
            <button
              onClick={exportToExcel}
              style={{
                border: "none",
                margin: "0 5px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                padding: "8px",
                background: "#fff",
                borderRadius: "7px",
              }}
            >
              <img
                src={Excel}
                alt=""
                style={{
                  height: "1.5em",
                  objectFit: "contain",
                  width: "100%",
                  background: "#fff",
                }}
              />
            </button>
          </div>
        </div>
      </div>

      <div className="viewSalesDropdowns">
        <Row>
          <Col lg={4} md={4}>
            <div className="mb-2">
              <Select
                onChange={handleSelectDivision}
                options={divisionOptions}
                classNamePrefix="select2-selectionsssss"
                className="mainSelect"
                placeholder="Select SBU"
              />
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="mb-2">
              <Select
                value={
                  divisionId
                    ? designationOptions.find(
                        (option) => option.value === divisionId.value
                      )
                    : null
                }
                onChange={handleSelectDesignation}
                options={designationOptions}
                classNamePrefix="select2-selectionsssss"
                className="mainSelect"
                placeholder="Select Heirarchy"
                isDisabled={!divisionId}
              >
                {designationOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="mb-2">
              <Select
                value={
                  divisionId
                    ? locationOptions.find(
                        (option) => option.value === locationId
                      )
                    : null
                }
                onChange={handleSelectLocation}
                options={locationOptions}
                classNamePrefix="select2-selectionsssss"
                className="mainSelect"
                placeholder="Select Location"
                isDisabled={!divisionId}
              >
                {locationOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
      </div>

      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={data} footer={false}/>
      </div>
    </div>
  );
});

