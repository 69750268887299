import React from "react";
import { Link } from "react-router-dom";

import image from "../../../../assets/images/add.png";
import MountComponent from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";

export default React.memo(({ userPermissions }) => (
    <div className=""><div className="text-center">
        <img src={image} />
        <div className="text-center mt-2 mb-3">
            <MountComponent condition={userPermissions.SALESMEN_CREATE}>
                <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN_CREATE} className="btn btn-primary btn-group mt-4">Add New</Link>
            </MountComponent>
        </div>
        <div>
            <h6><strong className="text-secondary">You don't have any salesman.</strong></h6>
            <h6><strong className="text-secondary">Add salesman to your network</strong></h6>
        </div>
    </div></div>
));
