import React, {useEffect, useState} from "react";
import {
    Col,
    Row,
    Form,
    Label,
    Card,
    Modal,
    CardBody,
    Container,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import getCampaignViewAPI from "../../../../api/getCampaignViewAPI";
import RightSideProfile from "../../../Dashboard/right-side-profile";
import PreviewImg from "../../../../assets/images/previewImg.png";

export default(() => {

    const navigate = useNavigate();
    const params = useParams();
    const campaignId = params.id;
    const [campaignData, setCampaignData] = useState([])


    const campaignView = async (campaignId) => {
        try {
          const { data } = await getCampaignViewAPI(campaignId);
          if (data) {
            const campaign = data.data;
            setCampaignData(campaign);
          }
        } catch (error) {
          console.log("Error", error);
        }
        // finally{
        //   setLoading(false)
        // }
      };
    
      useEffect(() => {
        // setLoading(true)
        campaignView(campaignId);
      }, []);

    return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid>
            <Row>
                {/* <div className="submitForm"> */}

                <Col lg={9}>
                    <Card>
                        <CardBody className="cardbody">
                        <Row>
                            <Col lg={12} md={12}>
                            <div className="mt-4">
                                <Form>
                                <h4 className="mt-3 fw-bold mb-3 d-flex justify-content-center">Campaign review</h4>
                                <div className="mb-0">
                                    <Label
                                    className="form-label"
                                    htmlFor="formrow-firstname-input"
                                    >
                                    From
                                    </Label>
                                    <p>{campaignData.sourceEmail}</p>
                                </div>

                                <div className="mb-0 mt-2 ">
                                    <Label
                                    className="form-label"
                                    htmlFor="formrow-firstname-input"
                                    >
                                    Subject
                                    </Label>
                                    <p>{campaignData.subject}</p>
                                </div>

                                <Card>
                                    <CardBody className="cardbody">
                                    <img src={PreviewImg}/>
                                    </CardBody>
                                </Card>
                                </Form>
                            </div>
                            </Col>

                            <Col
                            lg={12}
                            className="d-flex"
                            style={{ justifyContent: "center" }}
                            >
                            <div className="saveBtn">
                                <button onClick={() => navigate("/network-engage/campaign/create")} className="btn btn-group">Go Back</button>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={3}>
                    {/* <RightSideProfile /> */}
                </Col>
            </Row>
            </Container>
        </div>
    </React.Fragment>
    );
});

