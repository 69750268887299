import { useEffect, useState } from "react";
import lodash from "lodash";

import getOrganizationModules from "../api/organizationModuleApi"

export const useApiData = (params) => {
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const httpRequest = async () => {
        try {
            const { data: response } = await getOrganizationModules(params);
            const levels = lodash.isArray(response.data) ? response.data: [];
            return setArray(lodash.map(levels, 'moduleLevel'));
        } catch (error) { setArray([]); setError(error.message) } finally { setLoading(false); }
    };

    useEffect(() => { httpRequest(); return () => null }, []);

    return { array, error, loading };
};

export const useGuideHook = (storedModules, totalModules) => {
    const [state, updateState] = useState({ render: false, count: 0  })
    
    useEffect(() => {
        const count = calculateLevelsCount(storedModules, totalModules);
        updateState({ count: count, render: (count > 0 && count < 100)  })
    }, [storedModules]);

    return { count: state.count, render: state.render };
}

export const getColor = (state, timeline = {}) => {
    switch (true) {
        case lodash.includes(state, timeline.current): return '#25D366';
        case lodash.includes(state, timeline.previous): return '#37AADE';
        default: return '#adb5bd';
    }
};

export const calculateLevelsCount = (activeModuleState, activeModule, maxPercentage = 100) => {
    const multiplyNumber = maxPercentage / lodash.size(lodash.keys(activeModule));
    const levelsCount = lodash.size(activeModuleState) * multiplyNumber;
    return Math.ceil(levelsCount / 5) * 5;
};
