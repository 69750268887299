import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { MountCondition } from './mount-components'

const TimelineSection = styled.div`
  text-align: center;
  background-color: transparent;
`;

const TimelineTitle = styled.p`
  word-wrap: break-word;
  background-color: transparent;
  color: black;
  font-weight: bold;
  margin-top: 0.5rem;
  text-transform: capitalize;
`;

const TimelineSteps = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const TimelineStep = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
  margin-top: 10px;
  max-width: 100px;
  min-width: 100px;
  background-color: transparent;

  @media (min-width: 768px) {
    &:not(:last-child)::after {
      content: "";
      display: block;
      border-top: 0.25rem dotted ${(props) => props.color}; /* Green shade */
      width: 100%;
      position: absolute;
      top: 1rem;
      left: 4.5rem;
    }
  }

  @media (min-width: 480px) {
    &:not(:last-child)::after {
      content: "";
      display: block;
      border-top: 0.25rem dotted ${(props) => props.color}; /* Green shade */
      width: 100%;
      position: absolute;
      top: 1rem;
      left: 4.5rem;
      display: none;
    }
  }
  @media (min-width: 768px) {
    &:not(:last-child)::after {
      content: "";
      display: block;
      border-top: 0.25rem dotted ${(props) => props.color}; /* Green shade */
      width: 100%;
      position: absolute;
      top: 1rem;
      left: 4.5rem;
    }
  }
`;

const InnerCircle = styled(Link)`
  border: 0.15rem solid ${(props) => props.borderColor};
  border-radius: 1rem;
  height: 2rem;
  width: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
`;

const InnerCurrentCircle = styled.div`
width: 5px;
height: 5px;
background-color: ${(props) => props.color};
border-radius: 50%;
position:absolute;
margin-top: -50px;
display:none;
`

const CheckText = styled.div`
  margin: 0;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const TimelineHeading = styled.h3`
    text-align: center;
    font-weight: 600;
    margin-bottom: 1em;
    font-size: 20px;
    background-color:transparent
    color:black;
    margin-top:1rem;
`;

export const TimelineContent = React.memo(
  ({ color, heading, index, navigate = "", active }) => (
    <TimelineStep key={1} className="setppers" color={color.dashed}>
      <TimelineSection>
        <MountCondition condition={active}>
          <MountCondition.True>
            <InnerCircle backgroundColor={color.highlight} borderColor={color.accent} to={navigate}>
              <InnerCurrentCircle color={color.dashed}></InnerCurrentCircle>
              <CheckText color={"#fff"}>{index}</CheckText>
            </InnerCircle>
          </MountCondition.True>
          <MountCondition.False>
            <InnerCircle backgroundColor={color.accent} borderColor={color.highlight} to={navigate}>
              <CheckText color={color.main}>{index}</CheckText>
            </InnerCircle>
          </MountCondition.False>
        </MountCondition>
        <TimelineTitle>{heading}</TimelineTitle>
      </TimelineSection>
    </TimelineStep>
  )
);

export default React.memo(({ children }) => (
  <TimelineSteps className="main-stepper"> {children} </TimelineSteps>
));
