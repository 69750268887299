import React, { useState, useEffect } from 'react';
import { Navigate, isRouteErrorResponse, useRouteError } from "react-router-dom";
import styled from "styled-components";

const ErrorContainer = styled.div``;

const ErrorWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh; /* Use viewport height to center vertically */
margin: 0;`

const ErrorHeading = styled.h3`
    text-align: center;
    color:black;
`
const ErrorMessage = styled.h5`
    text-align: center;
    color:black;
`

export default React.memo(() => {
    const error = useRouteError();
    const response = { status: '500 Server Error', message: error.message ?? 'Something went wrong' }

    if (isRouteErrorResponse(error) && error.status) { return (<Navigate to='/login' />) }

    return (
        <ErrorWrapper>
            <ErrorContainer>
                <ErrorHeading>{ response.status }</ErrorHeading>
                <ErrorMessage>{ response.message }</ErrorMessage>
            </ErrorContainer>
        </ErrorWrapper>
    );
});



export const ErrorMessageComponent = () => {
  const messages = [
    ['Whoops.', 'Oops.', 'Excuse me.', 'Oh Dear.', 'Well poo.', 'Hm...', 'This is awkward.', 'Well gosh!'],
    ['It appears', 'Looks like', 'Unfortunately,', 'It just so happens', 'Sadly,', 'Seemingly from nowhere'],
    ['there was an error.', 'we goofed up.', 'a bad thing happened.', 'the server crashed.', 'a bug appeared.', 'someone did a naughty.', 'pixies got into the server!', 'the server threw a tantrum.', 'the website had a bad day.', 'our code pooped out.'],
    ['Sorry.', 'Apologies.', 'Our bad.', 'Sad day.', 'We are quite contrite.', 'Beg pardon.']
  ];

  const [messageElements, setMessageElements] = useState(Array(messages.length).fill(''));
  const [lastMessageType, setLastMessageType] = useState(-1);

  const widthElementRef = React.createRef();
  const messageTimer = 4000;

  useEffect(() => {
    setupMessages();
    const intervalId = setInterval(() => {
      swapMessage();
    }, messageTimer);

    return () => clearInterval(intervalId);
  }, []);

  const setupMessages = () => {
    const newMessageElements = messages.map((message, index) => getNewMessage(index));
    setMessageElements(newMessageElements);
  };

  const calculateWidth = (element, message) => {
    widthElementRef.current.innerText = message;
    const newWidth = widthElementRef.current.getBoundingClientRect().width;
    element.style.width = `${newWidth}px`;
  };

  const swapMessage = () => {
    const toSwapIndex = getNewSwapIndex();
    const newMessage = getNewMessage(toSwapIndex);

    setMessageElements((prevMessages) => {
      const newMessages = [...prevMessages];
      newMessages[toSwapIndex] = newMessage;
      return newMessages;
    });

    const element = document.querySelector(`#js-${toSwapIndex}`);
    element.style.lineHeight = '0';

    setTimeout(() => {
      checkWidthSet(toSwapIndex, newMessage);
      calculateWidth(element, newMessage);
    }, 200);

    setTimeout(() => {
      element.style.lineHeight = '1.2';
    }, 400);
  };

  const checkWidthSet = (index, message) => {
    const element = document.querySelector(`#js-${index}`);
    if (!element.style.width) {
      element.style.width = `${element.clientWidth}px`;
    }
  };

  const getNewSwapIndex = () => {
    let newMessageIndex = Math.floor(Math.random() * messages.length);
    while (lastMessageType === newMessageIndex) {
      newMessageIndex = Math.floor(Math.random() * messages.length);
    }
    setLastMessageType(newMessageIndex);
    return newMessageIndex;
  };

  const getNewMessage = (toSwapIndex) => {
    const messagesArray = messages[toSwapIndex];
    const previousMessage = messageElements[toSwapIndex] || '';
    let newMessageIndex = Math.floor(Math.random() * messagesArray.length);
    let newMessage = messagesArray[newMessageIndex];
    while (newMessage === previousMessage) {
      newMessageIndex = Math.floor(Math.random() * messagesArray.length);
      newMessage = messagesArray[newMessageIndex];
    }
    return newMessage;
  };

  return (
    <section className="centered">
      <h1>500 Server Error</h1>
      <div className="container">
        {messageElements.map((message, index) => (
          <span className="message" key={index} id={`js-${index}`}>
            {message}
          </span>
        ))}
        <div>
          <span className="hidden" ref={widthElementRef} id="js-hidden">
            Message Here
          </span>
        </div>
      </div>
    </section>
  );
};

 