import React from "react";
import { Modal, Label, Form, Input, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

import { MDBDataTable } from "mdbreact";

import MountComponent from "../../../../module/mount-components";
import ROUTES from "../../../../routes/routes";

import DeleteModal from "../../../../module/delete-modal.component";

export default React.memo((props) => {
    const { locations, locationState } = props;
    const { deleteModal, toggleDeleteModal, handleDeleteCallback, handleConfirmDeleteCallback } = props; /** delete modal ref props */
    const { updateModal, toggleUpdateModal, handleUpdateCallback } = props; /** update modal ref props */
    const { toggleImportModal } = props; /** import modal ref props */
    const { userPermissions } = props;
    const { divisionOptions, stateOptions, cityOptions, pinOptions, regionOptions} = props /** dependencies array's */
    const { updateLocationFormik } = props; /** update formik dependency */

    const data = {
        columns: [
          {
            label: "Sr.No.",
            field: "sr",
            sort: "asc",
            width: 150,
          },
          {
            label: "SBU",
            field: "sbu",
            sort: "asc",
            width: 150,
          },
          {
            label: "Location Name",
            field: "location",
            sort: "asc",
            width: 270,
          },
          {
            label: "Region",
            field: "region",
            sort: "asc",
            width: 200,
          },
          {
            label: "State",
            field: "state",
            sort: "asc",
            width: 100,
          },
          {
            label: "City",
            field: "city",
            sort: "asc",
            width: 150,
          },
          {
            label: "Pincode",
            field: "pincode",
            sort: "asc",
            width: 100,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 100,
          },
        ],
        rows: [...locations.map((details, index) => ({
            sr: ++index,
            sbu: details?.divisions?.name,
            location: `${details.location} (${details.id})`,
            region: details?.regions?.name,
            state: details.state,
            city: details.city,
            pincode: details.pincode,
            action: (
                <div className="editDataShow functionalBtn">
                    <MountComponent condition={userPermissions.LOCATION_UPDATE}>
                        <button className="btn btn-group border" onClick={() => { handleUpdateCallback(details) }}>
                            <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                        </button>
                    </MountComponent>
                    <MountComponent condition={userPermissions.LOCATION_DELETE}>
                        <button className="btn btn-group border ms-1" onClick={() => { handleDeleteCallback(details) }}>
                            <i className="fa fa-trash" aria-hidden="true" style={{ color: "red" }}></i>
                        </button>
                    </MountComponent>
                </div>
            ),
          }))],
    };

    return (
        <div className="userTable"><div>
              
            <MountComponent condition={userPermissions.LOCATION_CREATE}>
                <div className="bg-transparent text-right btn-css">
                    <button onClick={() => { toggleImportModal(true); }} className="btn btn-warning me-3">
                        Bulk Upload
                    </button>
                    <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION_CREATE} className="btn btn-primary ">Add New</Link>
                </div>
            </MountComponent>
            <div className="UserDataTable"><div className="col-12"><div className="userProfileTable">
                <div className="dataTable-hide-extra-props mt-3">
                    <MDBDataTable responsive bordered data={data} footer={false} />
                </div>
                <div className="resumeBtn mt-4 text-center">
                    <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} className="btn btn-group me-3">Previous</Link>
                    <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN} className="btn btn-group">Next</Link>
                </div>
            </div></div></div>

            <Modal isOpen={deleteModal} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${locationState.location} ?`}
                ></DeleteModal>
            </Modal>

            <Modal isOpen={updateModal} backdrop={"static"} scrollable={false} id="staticBackdrop"  size="xl">
                <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Edit Location</h5>
                      <button className="btn-close" type="button" onClick={() => { toggleUpdateModal(false); }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal row" onSubmit={updateLocationFormik.handleSubmit} >
                          <div className="col-6"><div className="mt-4"><div className="mb-0">
                              <Label className="form-label" htmlFor="divisionId-input">Division Name</Label>
                              <Select className="" name="divisionId"
                                  onChange={({ value }) => { updateLocationFormik.setFieldValue("divisionId", value); }}
                                  value={divisionOptions.find(({ value }) => value === updateLocationFormik.values.divisionId) ?? null }
                                  options={divisionOptions}
                                  invalid={updateLocationFormik.touched.divisionId && updateLocationFormik.errors.divisionId ? true : false}
                              ></Select>
                              <MountComponent condition={updateLocationFormik.touched.divisionId && updateLocationFormik.errors.divisionId}>
                                  <FormFeedback type="invalid">{updateLocationFormik.errors.divisionId}</FormFeedback>
                              </MountComponent>
                          </div></div></div>

                          <div className="col-6"><div className="mt-4"><div className="mb-0">
                                <Label className="form-label" htmlFor="location-input">Location</Label>
                                <Input
                                      type="text"
                                      placeholder="Enter Location"
                                      name="location"
                                      className="form-control"
                                      id="location-input"
                                      onChange={updateLocationFormik.handleChange}
                                      onBlur={updateLocationFormik.handleBlur}
                                      value={updateLocationFormik.values.location}
                                      invalid={updateLocationFormik.touched.location && updateLocationFormik.errors.location ? true : false}
                                />
                                <MountComponent condition={updateLocationFormik.touched.location && updateLocationFormik.errors.location}>
                                    <FormFeedback type="invalid">{updateLocationFormik.errors.location}</FormFeedback>
                                </MountComponent>
                          </div></div></div>

                          <div className="col-6"><div className="mt-4"><div className="mb-0">
                              <Label className="form-label" htmlFor="region-input">Region</Label>
                              <Select className="" name="regionId"
                                  onChange={({ value }) => { updateLocationFormik.setFieldValue("regionId", value); }}
                                  value={regionOptions.find(({ value }) => value === updateLocationFormik.values.regionId) ?? null }
                                  options={regionOptions}
                                  invalid={updateLocationFormik.touched.regionId && updateLocationFormik.errors.regionId ? true : false}
                              ></Select>
                              <MountComponent condition={updateLocationFormik.touched.regionId && updateLocationFormik.errors.regionId}>
                                  <FormFeedback type="invalid">{updateLocationFormik.errors.regionId}</FormFeedback>
                              </MountComponent>
                          </div></div></div>
                          
                          <div className="col-6"><div className="mt-4"><div className="mb-0">
                              <Label className="form-label" htmlFor="state-input">State</Label>
                              <Select className="" name="state"
                                  onChange={({ value }) => { updateLocationFormik.setFieldValue("state", value); }}
                                  value={stateOptions.find(({ value }) => value === updateLocationFormik.values.state) ?? null }
                                  options={stateOptions}
                                  invalid={updateLocationFormik.touched.state && updateLocationFormik.errors.state ? true : false}
                              ></Select>
                              <MountComponent condition={updateLocationFormik.touched.state && updateLocationFormik.errors.state}>
                                  <FormFeedback type="invalid">{updateLocationFormik.errors.state}</FormFeedback>
                              </MountComponent>
                          </div></div></div>

                          <div className="col-6"><div className="mt-4"><div className="mb-0">
                              <Label className="form-label" htmlFor="city-input">City</Label>
                              <Select className="" name="city"
                                  onChange={({ value }) => { updateLocationFormik.setFieldValue("city", value); }}
                                  value={cityOptions.find(({ value }) => value === updateLocationFormik.values.city) ?? null }
                                  options={cityOptions}
                                  invalid={updateLocationFormik.touched.city && updateLocationFormik.errors.city ? true : false}
                              ></Select>
                              <MountComponent condition={updateLocationFormik.touched.city && updateLocationFormik.errors.city}>
                                  <FormFeedback type="invalid">{updateLocationFormik.errors.city}</FormFeedback>
                              </MountComponent>
                          </div></div></div>

                          <div className="col-6"><div className="mt-4"><div className="mb-0">
                              <Label className="form-label" htmlFor="pincode-input">Pin Code</Label>
                              <Select className="" name="pincode"
                                  onChange={({ value }) => { updateLocationFormik.setFieldValue("pincode", value); }}
                                  value={pinOptions.find(({ value }) => value === updateLocationFormik.values.pincode) ?? null }
                                  options={pinOptions}
                                  invalid={updateLocationFormik.touched.pincode && updateLocationFormik.errors.pincode ? true : false}
                              ></Select>
                              <MountComponent condition={updateLocationFormik.touched.pincode && updateLocationFormik.errors.pincode}>
                                  <FormFeedback type="invalid">{updateLocationFormik.errors.pincode}</FormFeedback>
                              </MountComponent>
                          </div></div></div>

                          <div className="col-12 mt-5 text-center">
                                <button type="button" className="btn btn-primary" onClick={() => { toggleUpdateModal(false); }}>Cancel</button>
                                <button type="submit" className="btn btn-primary  mx-3" >Update</button>
                          </div>
                    </Form>
                </div>
                 
            </Modal>
        </div></div>
    )
});