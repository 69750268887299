import config,{server} from '../config/config'

export const userDetailsAPI = async (data,params) => {

    return await server({
        method  : 'patch',
        url     : `users/${params.id}`,
        data    : data,
        headers : {
            'content-type': 'multipart/form-data',
        }
    })
}

export default userDetailsAPI