import React from "react";
import Source from "./source";

const Brochure = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = " Organization Users | Giffy Network";

    return (
    <React.Fragment>
      <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12"><div className="profile">
                <Source></Source>
            </div></div>
      </div></div></div>
    </React.Fragment>
); };

export default Brochure;
