import React, { useEffect, useState } from "react";
import lodash from "lodash";

import { Alert } from "reactstrap";
import getAudienceListAPI from "../../../../api/getAudienceListAPI.js";
import AudienceTable, { SetupAudience } from "./source.jsx";
import { MountCondition } from '../../../../module/mount-components.jsx'
import MountComponent from "../../../../module/mount-components";
 
export default () => {
    
    const [audienceData, setAudienceData] = useState([]);
    const [loading,setLoading] = useState(true);
    const notificationInitialState = { visible: false, message: '', color: '' }
    const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

    const audienceDetails = async () => {
        try {
            const { data : response } = await getAudienceListAPI();
            setAudienceData(lodash.isArray(response.data) ? response.data : []);
        } catch (error) { toggleNotificationState({ visible: true, color: "success", message: "Something went wrong" })
        } finally { setLoading(false) }
    };

    useEffect(() => { audienceDetails(); }, []);

    return (
        <React.Fragment>
            <MountCondition condition={loading}>
                <MountCondition.True>
                    <div className="fullscreen-loader"><div className="loader-container">
                        <div className="loader"></div>
                    </div></div>
                </MountCondition.True>
            </MountCondition>

            <MountComponent condition={notificationState.visible}>
                <Alert className="pt-2 pb-2" color={notificationState.color}>
                { notificationState.message }
                </Alert>
            </MountComponent>

            <div className="page-content"><div className="container-fluid"><div className="row">
                <div className="col-lg-12"><div className="zone"><div className="profile">
                    <MountCondition condition={lodash.isEmpty(audienceData)}>
                        <MountCondition.True>
                            <SetupAudience></SetupAudience>
                        </MountCondition.True>
                        <MountCondition.False>
                            <AudienceTable audienceData={audienceData}></AudienceTable>
                        </MountCondition.False>
                    </MountCondition>
                </div></div></div>
                {/* <div className="col-lg-3"><RightSideProfile /></div> */}
            </div></div></div>
        </React.Fragment>
)};
