import React from "react";
import Select from "react-select";

import { MDBDataTable } from "mdbreact";
import { Alert, Modal } from "reactstrap";
import { Link } from "react-router-dom";

import ROUTES from "../../../../routes/routes";
import DeleteModal from "../../../../module/delete-modal.component";

export default React.memo((props) => {
  const { filters,updateFilters } = props;
  const { statusOptions, moduleState, dataTable, notificationState } = props;
  const { deleteModal, toggleDeleteModal, handleConfirmDeleteCallback } = props;

  return (
    <React.Fragment>
      <div className="productlist-header d-flex justify-content-between mb-3">
        <div className="">
            <Select 
                classNamePrefix="select2-selection" 
                placeholder={`Filter`} 
                options={statusOptions} 
                onChange={({ value }) => { updateFilters({ ...filters, status: value }) }}
              />
        </div>
        <div className="productlist-buttons d-flex bg-transparent">
            <Link to={ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT} className="btn btn-primary me-3">Add New</Link>
            <button className="btn btn-orange fontSetting">Bulk Upload</button>
        </div>
      </div>

      <Alert color={notificationState.color} isOpen={notificationState.visible}>
            {notificationState.message}
      </Alert>

      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={dataTable} footer={false} />
      </div>

      <div className="actions text-center my-4">
        <Link className="btn btn-md btn-primary" to={ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS}>Previous</Link>
      </div>

      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          handleConfirmDeleteCallback={handleConfirmDeleteCallback}
          message={`Are you sure you want to delete ${moduleState.name} ?`}
        ></DeleteModal>
      </Modal>
    </React.Fragment>
  );
});
