import React, { useState } from "react";
import { TabContent, TabPane, NavLink, NavItem, Nav } from "reactstrap";
import classnames  from "classnames";
import MainProfile from "../Profile/main-profile";
import Organized   from "../Organization-Module/organization/view";
import MainNetwork from "../Masters/main-network";
import MyUsers      from "./myUser";
import UserForm    from "../User/user-form";
import Mount       from '../../module/mount-components'

const  Accounts = React.memo(() => {
  const [activeTab, switchTab] = useState("4");
  const [hide, SetHide] = useState(false);
  
  return (
    <div><div className="profile"><div className="account extra">
        <Nav pills style={{ justifyContent: "center" }}>
          <NavItem>
              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "4",})} onClick={() => { switchTab("4");}} >
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                  <span className="d-none d-sm-block">Profile</span>
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "5" })} onClick={() => { switchTab("5"); }}>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                  <span className="d-none d-sm-block">Organization</span>
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "6" })} onClick={() => { switchTab("6"); }}>
                  <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                  <span className="d-none d-sm-block">Masters</span>
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "7" })} onClick={() => { switchTab("7"); }}>
                  <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                  <span className="d-none d-sm-block">Users</span>
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "8" })} onClick={() => { switchTab("8"); }}>
                  <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                  <span className="d-none d-sm-block">Billing</span>
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "9" })} onClick={() => { switchTab("9"); }}>
                  <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                  <span className="d-none d-sm-block">Account Status</span>
              </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted mt-5">
          <TabPane tabId="4">
              <Mount condition={activeTab ==="4"}><MainProfile /></Mount>
          </TabPane>
          <TabPane tabId="5">
              <Mount condition={activeTab ==="5"}><Organized message={true}/></Mount>
          </TabPane>
          <TabPane tabId="6">
            <Mount condition={activeTab ==="6"}><MainNetwork /></Mount>
          </TabPane>
          <TabPane tabId="7">
              <Mount condition={activeTab ==="7"}>{!hide ? <MyUsers SetHide={SetHide} /> : <UserForm SetHide={SetHide} />}</Mount>
          </TabPane>
          <TabPane tabId="8">
              <Mount condition={activeTab ==="8"}><p className="mb-0">Hello</p></Mount>
          </TabPane>
          <TabPane tabId="9">
              <Mount condition={activeTab ==="9"}><p className="mb-0">World</p></Mount>
          </TabPane>
        </TabContent>
    </div></div></div>
  );
})

export default Accounts;
