import { server } from '../config/config'

export default () => server({
    method  : 'get',
    url     : `organization/0/users`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getOrganizationUserAPI = (id) => server({
    method  : 'get',
    url     : `organization/0/users/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const storeOrganizationUserAPI = (payload = {}) => server({
    method  : 'post',
    url     : `organization/0/users`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const updateOrganizationUserAPI = (id,payload = {}) => server({
    method  : 'patch',
    url     : `organization/0/users/${id}`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteOrganizationUser = (id) => server({
    method  : 'delete',
    url     : `organization/0/users/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const checkPermissions = (params = {}) => server({
    method  : 'get',
    params  : { ...params },
    url     : `organization/0/users/0/check-permissions`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
