import { server } from '../config/config'

export const storeGeographyRegionAPI = (payload = {}) => server({
    method  : 'post',
    url     : `geography-regions`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getGeographyRegionsAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `geography-regions`,
    params  : { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getGeographyRegionAPI = (id) => server({
    method  : 'get',
    url     : `geography-regions/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateGeographyRegionAPI = (id, payload = {}) => server({
    method: 'put',
    url: `geography-regions/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteGeographyRegionAPI = (id) => server({
    method: 'delete',
    url: `geography-regions/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
