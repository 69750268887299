import { server } from '../config/config'
 
export const storeTaxAPI = (payload = {}) => server({
    method  : 'post',
    url     : `tax`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getTaxesAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `tax`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getTaxAPI = (id) => server({
    method  : 'get',
    url     : `tax/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateTaxAPI = (id, payload = {}) => server({
    method: 'put',
    url: `tax/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteTaxAPI = (id) => server({
    method: 'delete',
    url: `tax/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
