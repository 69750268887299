import React from "react";
import { Link } from "react-router-dom";

import NetImage from "../../assets/images/loyaltyManager.png";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

import { calculateLevelsCount, useApiData } from "../../module/start-guide-methods";

export default React.memo(() => {
    const { NAME, CHILDREN: { GEOGRAPHY, LOCATION, SALESMEN, CHANNEL_PARTNER, } } = PERMISSIONS.NETWORK_MANAGER;
    const progressSteps = {
        0:  { label: 'Start Loyalty Manager',target: GEOGRAPHY.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.GEOGRAPHY, activeClass: '' },
        20: { label: 'Resume', target: LOCATION.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION, activeClass: '' },
        40: { label: 'Resume', target: SALESMEN.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN, activeClass: '' },
        60: { label: 'Resume', target: CHANNEL_PARTNER.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER, activeClass: '' },
        80: { label: 'Completed', target: NAME, path: ROUTES.NETWORK_MANAGER.PATH, activeClass: '' },
    };

    const { array: apiData }  = useApiData({ module: NAME });
    const currentProgressStep = calculateLevelsCount(apiData, progressSteps);
    const currentStepDetails = progressSteps[currentProgressStep];
    
  return (
    <div className="row">
        <div className="col-md-4">
          <div className="netImage"><img src={NetImage} /></div>
        </div>
        <div className="col-md-8">
            <div className="netContent">
                <h2 className="text-primary">
                    <strong>
                        <span className="pt-2 head-catalog">Build Loyalty with your Channel</span>
                    </strong>
                </h2>
                <ul className="hide-bullet-catalog">
                <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>Channel Loyalty App</strong></li>
                    <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>Create custom incentives and loyalty schemes</strong></li>
                    <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>QR code based quick scan</strong></li>
                    <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>Easy to aggregate Loyalty Points</strong></li>
                    <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>Customised Rewards Catalog</strong></li>
                    <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>Easy redemption through points</strong></li>
                    <li className="mt-1 text-black"><strong><i aria-hidden="true" class="far fa-star mx-2"></i>Incentivise, Retain & Grow your Channel Partners</strong></li>
                </ul>
            </div>
            <Link to={currentStepDetails?.path} className="btn btn-group networkBtn">
                {currentStepDetails?.label}
            </Link>
        </div>
    </div>
  );
});
