import React from "react";
import lodash from "lodash";
import { Link } from "react-router-dom";

import NetImage from "../../assets/images/net.png";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

import { useNetworkHook, useHttpRequest } from "../../module/network-tab-methods";
import MountComponent from "../../module/mount-components";

export default React.memo(() => {
    const { NAME, CHILDREN: { GEOGRAPHY, LOCATION, SALESMEN, CHANNEL_PARTNER, } } = PERMISSIONS.NETWORK_MANAGER;
    const totalModules = {
        [GEOGRAPHY.NAME]: { label: 'Start Network Manage', target: GEOGRAPHY.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.GEOGRAPHY, activeClass: '' },
        [LOCATION.NAME]: { label: 'Resume', target: LOCATION.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION, activeClass: '' },
        [SALESMEN.NAME]: { label: 'Resume', target: SALESMEN.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN, activeClass: '' },
        [CHANNEL_PARTNER.NAME]: { label: 'Resume', target: CHANNEL_PARTNER.NAME, path: ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER, activeClass: '' },
        [NAME]: { label: 'Completed', target: NAME, path: ROUTES.NETWORK_MANAGER.PATH, activeClass: '' },
    };

    const { array: storedModules } = useHttpRequest({ module: NAME });
    const { step } = useNetworkHook(storedModules, (array) => {
        switch (true) {
            case lodash.isEmpty(array): { return totalModules[GEOGRAPHY.NAME]; }
            case !lodash.includes(array, GEOGRAPHY.NAME): { return totalModules[GEOGRAPHY.NAME]; };
            case !lodash.includes(array, LOCATION.NAME): { return totalModules[LOCATION.NAME]; };
            case !lodash.includes(array, SALESMEN.NAME): { return totalModules[SALESMEN.NAME]; };
            case !lodash.includes(array, CHANNEL_PARTNER.NAME): { return totalModules[CHANNEL_PARTNER.NAME]; };
            default: { return totalModules[NAME]; };
        }
    });

    console.clear(); console.log("step", step);

    return (
        <div className="row">
            <div className="col-md-4">
                <div className="netImage"><img src={NetImage} /></div>
            </div>
            <div className="col-md-8">
                <div className="netContent">
                    <h4>Get Visibility of your Channel Network</h4>
                    <ul className="netFirst">
                        <li>Easily Integrate your Sales Teams & Channel Network</li>
                        <li>Get visibility of your Secondary network</li>
                        <li>Locate easily on Google Maps</li>
                        <li>Region wise Channel Insights</li>
                    </ul>
                    <ul className="netSecond">
                        <li>Market Penetration</li>
                        <li>Distribution Reach</li>
                        <li>Unrepresented Markets</li>
                        <li>Retail Coverage</li>
                        <li>Product Penetration and many more..</li>
                    </ul>
                </div>
                <MountComponent condition={(step.target !== NAME)}>
                    <Link to={step?.path} className="btn btn-primary networkBtn">
                        {step?.label}
                    </Link>
                </MountComponent>
            </div>
        </div>
    );
});
