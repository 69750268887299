import React from "react";

import { Modal, Label, Form, Input, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

import imageGoodDrive from "../../../../assets/images/driveview.png";
import imageExcel from "../../../../assets/images/excel.png";

import { MDBDataTable } from "mdbreact";

import {
  themeReactSelect,
  styleReactSelect,
} from "../../../../module/select-methods.js";
import DeleteModal from "../../../../module/delete-modal.component";
import MountComponent from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";

export default React.memo((props) => {
  const { salesmenData, salesmenDetails } = props;

  const {
    deleteModal,
    toggleDeleteModal,
    handleDeleteCallback,
    handleConfirmDeleteCallback,
  } = props; /** delete modal ref props */
  const { updateModal, toggleUpdateModal, handleUpdateCallback } =
    props; /** update modal ref props */

  const { userPermissions, toggleImportModal } = props;
  const { handleGoogleMap, handleXLSXExport } = props;

  const { divisionOptions, designationOptions, locationOptions } =
    props; /** dependencies array's */
  const { filters, handleFilter } = props; /** dependencies array's */

  const { updateSalesmanFormik } = props; /** update formik dependency */

  const data = {
    columns: [
      {
        label: "Sr. No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "First Name",
        field: "firstName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Mobile",
        field: "mobileNo",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },
      {
        label: "SBU",
        field: "sbu",
        sort: "asc",
        width: 100,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: [
      ...salesmenData.map((details, index) => ({
        sr: ++index,
        firstName: details.firstName,
        lastName: details.lastName,
        mobileNo: details.mobileNo,
        email: details.email,
        sbu: details?.divisions?.name,
        designation: details?.salesHierarchy?.name,
        location: details?.networkLocation?.location,
        action: (
          <div className="editDataShow functionalBtn">
            <MountComponent condition={userPermissions.SALESMEN_UPDATE}>
              <button
                className="btn btn-group border"
                onClick={() => {
                  handleUpdateCallback(details);
                }}
              >
                <i
                  class="fas fa-pencil-alt text-primary"
                  aria-hidden="true"
                ></i>
              </button>
            </MountComponent>
            <MountComponent condition={userPermissions.SALESMEN_DELETE}>
              <button
                className="btn btn-group border ms-1"
                onClick={() => {
                  handleDeleteCallback(details);
                }}
              >
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  style={{ color: "red" }}
                ></i>
              </button>
            </MountComponent>
          </div>
        ),
      })),
    ],
  };

  return (
    <div className="userTable">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <MountComponent condition={userPermissions.SALESMEN_CREATE}>
            <div className="bg-transparent text-right btn-css">
              <button
                onClick={() => {
                  toggleImportModal(true);
                }}
                className="btn btn-warning me-3"
              >
                Bulk Upload
              </button>
              <Link
                to={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN_CREATE}
                className="btn btn-primary"
              >
                Add New
              </Link>
            </div>
          </MountComponent>
          <div className="mb-3 mt-3">
            <div className="" style={{ textAlign: "right" }}>
              <button
                onClick={handleGoogleMap}
                style={{
                  border: "none",
                  margin: "0 5px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  background: "#fff",
                  padding: "8px",
                  borderRadius: "7px",
                }}
              >
                <img
                  src={imageGoodDrive}
                  alt=""
                  style={{
                    height: "1.5em",
                    objectFit: "contain",
                    width: "100%",
                    background: "#fff",
                  }}
                />
              </button>
              <button
                onClick={handleXLSXExport}
                style={{
                  borderRadius: "7px",
                  padding: "8px",
                  background: "#fff",
                  border: "none",
                  margin: "0 5px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                }}
              >
                <img
                  src={imageExcel}
                  alt=""
                  style={{
                    height: "1.5em",
                    objectFit: "contain",
                    width: "100%",
                    background: "#fff",
                  }}
                />
              </button>
            </div>
          </div>
        </div>

        <div className="viewSalesDropdowns">
          <div className="row">
            <div className="col-md-4 col-lg-4">
              <div className="mb-2">
                <Select
                  name="divisionId"
                  onChange={handleFilter}
                  options={divisionOptions}
                  value={divisionOptions.find(({ value }) => value === filters.divisionId) ?? null}
                  className=""
                  placeholder="Select SBU"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="mb-2">
                <Select
                  name="designationId"
                  onChange={handleFilter}
                  options={designationOptions}
                  value={designationOptions.find(({ value }) => value === filters.designationId) ?? null}
                  className=""
                  placeholder="Select Hierarchy"
                />
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-2">
                <Select
                  name="locationId"
                  onChange={handleFilter}
                  options={locationOptions}
                  value={locationOptions.find(({ value }) => value === filters.locationId) ?? null}
                  className=""
                  placeholder="Select Location"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="UserDataTable">
          <div className="col-12">
            <div className="userProfileTable">
              <div className="dataTable-hide-extra-props mt-3">
                <MDBDataTable responsive bordered data={data} footer={false} />
              </div>
              <div className="resumeBtn mt-4 text-center">
                <Link
                  to={ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION}
                  className="btn btn-group me-3"
                >
                  Previous
                </Link>
                <Link
                  to={ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER}
                  className="btn btn-group"
                >
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={deleteModal}
          backdrop={"static"}
          scrollable={true}
          id="staticBackdrop"
          centered
        >
          <DeleteModal
            toggleDeleteModal={toggleDeleteModal}
            handleConfirmDeleteCallback={handleConfirmDeleteCallback}
            message={`Are you sure you want to delete ${salesmenDetails.firstName} ?`}
          ></DeleteModal>
        </Modal>

        <Modal
          isOpen={updateModal}
          backdrop={"static"}
          scrollable={false}
          id="staticBackdrop"
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Edit Salesman
            </h5>
            <button
              className="btn-close"
              type="button"
              onClick={() => {
                toggleUpdateModal(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Form
              className="form-horizontal row"
              onSubmit={updateSalesmanFormik.handleSubmit}
            >
              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label className="form-label">First Name</Label>
                    <Input
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      className="form-control"
                      id="formrow-firstname-input"
                      onChange={updateSalesmanFormik.handleChange}
                      onBlur={updateSalesmanFormik.handleBlur}
                      value={updateSalesmanFormik.values.firstName || ""}
                      invalid={
                        updateSalesmanFormik.touched.firstName &&
                          updateSalesmanFormik.errors.firstName
                          ? true
                          : false
                      }
                    />
                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.firstName &&
                        updateSalesmanFormik.errors.firstName
                      }
                    >
                      <FormFeedback type="invalid">
                        {updateSalesmanFormik.errors.firstName}
                      </FormFeedback>
                    </MountComponent>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-lastName-input"
                    >
                      Last Name
                    </Label>
                    <Input
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      className="form-control"
                      id="formrow-lastName-input"
                      onChange={updateSalesmanFormik.handleChange}
                      onBlur={updateSalesmanFormik.handleBlur}
                      value={updateSalesmanFormik.values.lastName || ""}
                      invalid={
                        updateSalesmanFormik.touched.lastName &&
                          updateSalesmanFormik.errors.lastName
                          ? true
                          : false
                      }
                    />
                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.lastName &&
                        updateSalesmanFormik.errors.lastName
                      }
                    >
                      <FormFeedback type="invalid">
                        {updateSalesmanFormik.errors.lastName}
                      </FormFeedback>
                    </MountComponent>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-mobileNo-input"
                    >
                      Mobile
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter Mobile Number"
                      name="mobileNo"
                      className="form-control"
                      id="formrow-mobileNo-input"
                      onChange={updateSalesmanFormik.handleChange}
                      onBlur={updateSalesmanFormik.handleBlur}
                      value={updateSalesmanFormik.values.mobileNo || ""}
                      invalid={
                        updateSalesmanFormik.touched.mobileNo &&
                          updateSalesmanFormik.errors.mobileNo
                          ? true
                          : false
                      }
                    />
                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.mobileNo &&
                        updateSalesmanFormik.errors.mobileNo
                      }
                    >
                      <FormFeedback type="invalid">
                        {updateSalesmanFormik.errors.mobileNo}
                      </FormFeedback>
                    </MountComponent>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label className="form-label" htmlFor="formrow-email-input">
                      Email
                    </Label>
                    <Input
                      type="email"
                      placeholder="Enter Email Address"
                      name="email"
                      className="form-control"
                      id="formrow-email-input"
                      onChange={updateSalesmanFormik.handleChange}
                      onBlur={updateSalesmanFormik.handleBlur}
                      value={updateSalesmanFormik.values.email || ""}
                      invalid={
                        updateSalesmanFormik.touched.email &&
                          updateSalesmanFormik.errors.email
                          ? true
                          : false
                      }
                    />

                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.email &&
                        updateSalesmanFormik.errors.email
                      }
                    >
                      <FormFeedback type="invalid">
                        {updateSalesmanFormik.errors.email}
                      </FormFeedback>
                    </MountComponent>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label className="form-label">Division Name</Label>
                    <Select
                      className=""
                      name="divisionId"
                      inputId="aria-example-input"
                      isSearchable
                      onBlur={updateSalesmanFormik.handleBlur}
                      options={divisionOptions}
                      onChange={({ value }) => {
                        updateSalesmanFormik.setFieldValue("divisionId", value);
                      }}
                      value={divisionOptions.find(
                        ({ value }) =>
                          value === updateSalesmanFormik.values.divisionId
                      )}
                      invalid={
                        updateSalesmanFormik.touched.divisionId &&
                          updateSalesmanFormik.errors.divisionId
                          ? true
                          : false
                      }
                      theme={(theme) =>
                        themeReactSelect(
                          theme,
                          updateSalesmanFormik.errors.divisionId
                        )
                      }
                      styles={{
                        control: (baseStyles, state) =>
                          styleReactSelect(
                            baseStyles,
                            state,
                            updateSalesmanFormik.errors.divisionId
                          ),
                      }}
                    ></Select>
                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.divisionId &&
                        updateSalesmanFormik.errors.divisionId
                      }
                    >
                      <small className="text-danger">
                        {updateSalesmanFormik.errors.divisionId}
                      </small>
                    </MountComponent>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label className="form-label">Designation</Label>
                    <Select
                      className=""
                      name="designation"
                      isSearchable
                      options={designationOptions}
                      onBlur={updateSalesmanFormik.handleBlur}
                      onChange={({ value }) => {
                        updateSalesmanFormik.setFieldValue(
                          "designation",
                          value
                        );
                      }}
                      value={designationOptions.find(
                        ({ value }) =>
                          value === updateSalesmanFormik.values.designation
                      )}
                      invalid={
                        updateSalesmanFormik.touched.designation &&
                          updateSalesmanFormik.errors.designation
                          ? true
                          : false
                      }
                      theme={(theme) =>
                        themeReactSelect(
                          theme,
                          updateSalesmanFormik.errors.designation
                        )
                      }
                      styles={{
                        control: (baseStyles, state) =>
                          styleReactSelect(
                            baseStyles,
                            state,
                            updateSalesmanFormik.errors.designation
                          ),
                      }}
                    ></Select>
                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.designation &&
                        updateSalesmanFormik.errors.designation
                      }
                    >
                      <small className="text-danger">
                        {updateSalesmanFormik.errors.designation}
                      </small>
                    </MountComponent>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="mt-4">
                  <div className="mb-0">
                    <Label className="form-label">Location</Label>
                    <Select
                      className=""
                      name="location"
                      isSearchable
                      options={locationOptions}
                      onBlur={updateSalesmanFormik.handleBlur}
                      onChange={({ value }) => {
                        updateSalesmanFormik.setFieldValue("location", value);
                      }}
                      value={locationOptions.find(
                        ({ value }) =>
                          value === updateSalesmanFormik.values.location
                      )}
                      invalid={
                        updateSalesmanFormik.touched.location &&
                          updateSalesmanFormik.errors.location
                          ? true
                          : false
                      }
                      theme={(theme) =>
                        themeReactSelect(
                          theme,
                          updateSalesmanFormik.errors.location
                        )
                      }
                      styles={{
                        control: (baseStyles, state) =>
                          styleReactSelect(
                            baseStyles,
                            state,
                            updateSalesmanFormik.errors.location
                          ),
                      }}
                    ></Select>
                    <MountComponent
                      condition={
                        updateSalesmanFormik.touched.location &&
                        updateSalesmanFormik.errors.location
                      }
                    >
                      <small className="text-danger">
                        {updateSalesmanFormik.errors.location}
                      </small>
                    </MountComponent>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    toggleUpdateModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary  mx-3">
                  Update
                </button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
});
