import { server } from '../config/config';

export const getNetworkSalesmenDataAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `network-salesmen`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getNetworkSalesmenAPI = (id) => server({
    method  : 'get',
    url     : `network-salesmen/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const storeNetworkSalesmenAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-salesmen`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getNetworkSalesmenImportInfoAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-salesmen/info`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
export const uploadMultipleNetworkSalesmenAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-salesmen/multiple`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const updateNetworkSalesmenAPI = (id,payload = {}) => server({
    method  : 'put',
    url     : `network-salesmen/${id}`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteNetworkSalesmenAPI = (id) => server({
    method  : 'delete',
    url     : `network-salesmen/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
