import React, { useState, useEffect } from "react";
import Editable from "react-bootstrap-editable";
import getDivisionsAPI from "../../api/getDivisionsAPI";
import getDivisionsSaveAPI from "../../api/getDivisionSaveAPI";
import deleteDivisionAPI from "../../api/deleteDivisionAPI";
import updateDivisionsAPI from "../../api/updateDivisionAPI";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import MainNetwork from "./main-network";

import {
  Col,
  Row,
  Alert,
  Modal,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import MountComponents from "../../module/mount-components";
function Division({ setIsListEmpty,permissions } ) {
  
  const [divisionId, setDivisionId] = useState(null);
  const [divisionName, setDivisionName] = useState("");
  const [value, setValue] = useState(divisionName ? divisionName : "");
  const [divisions, setDivisions] = useState([]);

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
  const organizationId = authUserOrg?.data?.data?.id;
  const [visible, setVisible] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const [modal_delete, setmodal_delete] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [error, setError] = useState();
  const [divisionAPICalls, setDivisionAPICalls] = useState(0);

  const divisionList = async () => {
    try {
      const response = await getDivisionsAPI();
      if (response) {
        if(response?.data?.length===0){
          setIsListEmpty(true);
        }
        setDivisions(response.data);
      }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  // useEffect(() => {
  //   divisionList();
  // }, []);
  useEffect(() => {
    if (divisionAPICalls <= 1) {
      console.log("divisionList");
      divisionList();
      // Increment the API call count
      setDivisionAPICalls((prevCount) => prevCount + 1);
    }
  }, [divisionAPICalls]);

  const handleSubmit = async (newValue) => {
    console.log("handleSubmit called")
    try {
      const payload = {
        userId: authUser.userId,
        name: newValue,
        organizationId: organizationId,
      };
      const { data } = await getDivisionsSaveAPI(payload);
      if(data){
        setIsListEmpty(false)
      }
      console.log("Data div", data)
      setResetKey((prevKey) => prevKey + 1);
      
      toast.success("Division created successfully !");
      setVisible(true);
      divisionList(); 
      console.log("divisionList len", divisions.length)
      const isEmpty = divisions.length === 0;
        // Call the callback function to inform the parent component
      // onApiSuccess(isEmpty);
      setValue("");
    } catch (error) {
      if (error.response.status === 409) {
        toast.error("Division/SBU Already Exist");
      }
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      
      //
      // toast.error(error.response.data.error);
    }
    return (
      <React.Fragment>
        <MainNetwork divisionData={divisions.length} />
        {/* Rest of the JSX */}
      </React.Fragment>
    );
  };

  const handleEditClick = (divisionId, divisionName) => {

    setDivisionId(divisionId);
    setDivisionName(divisionName);
    setValue(divisionName);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_delete() {
    setmodal_delete(!modal_delete);
    removeBodyCss();
  }
  function tog_edit() {
    setmodal_edit(!modal_edit);
    removeBodyCss();
  }

  const handleSubmitRemove = async (divisionId) => {
    try {
      const { data } = await deleteDivisionAPI(divisionId);
      setDivisionId(null);
      setmodal_delete(false);
      toast.success("Division/SBU Deleted Successfully");
      divisionList();
    } catch (error) {
      console.log(error);
      setDivisionId(null);
      setmodal_delete(false);
      toast.error(error.response.data.error);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: divisionName,
      userId: authUser.userId,
      organizationId: organizationId,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Division Name"),
    }),
    onSubmit: async (values) => {
      console.log('value div', values)
      try {
        const { data } = await updateDivisionsAPI(values, {
          id: divisionId,
        });
        if(data?.data){
          setIsListEmpty(false);
        }
        setDivisionId(null);
        setDivisionName("");
        setmodal_edit(false);
        toast.success("Division/SBU Updated Successfully");
        divisionList();
        
      } catch (error) {
        console.log("Error sbu", error)
        if(error.response.data.error == "Division name must contain only alphabets and spaces."){
          setmodal_edit(false);
          toast.error("Division name must contain only alphabets and spaces.")
        }
        if(error.response.status == 409){
          setDivisionName(values.name)
          setmodal_edit(false);
          toast.error("Division name already exists")
        }
        // toast.error("")
      }
    },
  });

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU Name",
        field: "sbuName",
        sort: "asc",
        width: 270,
      },
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: [
      ...divisions.map((DivisionName, i) => ({
        sr: ++i,
        sbuName: DivisionName.name,
        id: DivisionName.id,
        action: (
          <div className="editDataShow functionalBtn">
            <MountComponents condition={true}>
                <button className="btn btn-group border" onClick={() => { tog_edit(); handleEditClick(DivisionName.id, DivisionName.name);}}>
                    <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
            </MountComponents>
            <MountComponents condition={true}>
                <button className="btn btn-group border mx-2" onClick={() => { tog_delete(); handleEditClick(DivisionName.id, DivisionName.name); }} data-toggle="modal">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </MountComponents>
          </div>
        ),
      })),
    ],
  };
  return (
    <div className="division">
      <div id="liveAlertPlaceholder">
        <div>
          {/* <Alert
            color="success"
            isOpen={visible}
            toggle={() => setVisible(false)}
          >
            Division Added Successfully !
          </Alert> */}
        </div>
      </div>
      <div className="justify-content-center row"><div className="col-12">
          <MountComponents condition={true}>
              <Editable
                key={resetKey}
                alwaysEditing={true}
                disabled={false}
                editText={value}
                isValueClickable={true}
                mode="inline"
                placement="top"
                placeholder="Name of your Geography Hierarchy"
                showText
                className="mainText"
                type="textfield"
                value={value}
                onSubmit={handleSubmit}
                onChange={(newValue) => setValue(newValue)}
              />
          </MountComponents>
          <div className="dataTable-hide-extra-props mt-3">
            <MDBDataTable responsive bordered data={data} footer={false} />
          </div>
      </div></div>

      {/* Modal for Delete Division */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        backdrop={"static"}
        scrollable={true}
        id="staticBackdrop"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Delete
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_delete(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete {validation.values.name}?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancelModal btn"
            onClick={() => {
              setmodal_delete(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="nextMasterModal btn"
            onClick={() => handleSubmitRemove(divisionId)}
          >
            Delete
          </button>
        </div>
      </Modal>

      {/* Modal for Edit Division */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          tog_edit();
        }}
        backdrop={"static"}
        scrollable={true}
        id="staticBackdrop"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Edit Division/SBU
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_edit(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Division/SBU name</Label>
                  <Input
                    name="name"
                    placeholder="Division name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="cancelModal btn"
              onClick={() => {
                setmodal_edit(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="nextMasterModal btn">
              Update
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default React.memo(Division);
