import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Alert, Col, Row, Form, Label, Input, FormFeedback } from "reactstrap";
import Avatar from "react-avatar";

import config from "../../../../../src/config/config";

import getBusinessRulesAPI from "../../../../api/getBusinessRulesAPI";
import userDetailsAPI from "../../../../api/userDetailsAPI";
import getUsersDetailsAPI from "../../../../api/getUsersDetailsAPI";
import { storeOrganizationModule } from "../../../../api/organizationModuleApi.js";

import user from "../../../../assets/images/profile-pic.png";

import MountComponent from "../../../../module/mount-components.jsx";
import { errorMessage } from "../../../../module/error-methods.js"

import PERMISSIONS from "../../../../routes/permissions.js";
import ROUTES from "../../../../routes/routes.js";

export default React.memo(() => {
  const { NAME, CHILDREN: { PROFILE } } = PERMISSIONS.QUICK_START_GUIDE;

  const [imagePreview, setImagePreview] = useState(user);
  const [optionList, setOptionList] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false });

  const authUser = JSON.parse(localStorage.getItem("authUser"));

  const name = RegExp(/^[A-Za-z ]+$/);

  const fetchData = async () => {
    try {
      const response = await getBusinessRulesAPI();
      setOptionList(response.data?.data);
    } catch (error) { }
  };

  const handleImageChange = (event) => {
    const selectedFile = event.currentTarget.files[0];

    if (selectedFile) {
      validation.setFieldValue("profilePicture", selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(event.currentTarget.files[0]);

      validation.setFieldValue("profilePicture", selectedFile);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organizationName: userDetails.organizationName,
      organizationBusinessRole: userDetails.userRole,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
    },

    validationSchema: Yup.object({
      profilePicture: Yup.mixed().test(
        "fileSize",
        "The selected image exceeds the file size limit of 7MB.",
        (value) => !value || value || value.size <= 7000000
      ),

      organizationName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Organisation Name"),

      organizationBusinessRole: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Business Role "),

      firstName: Yup.string()
        .matches(name, "Only characters are allowed")
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter First Name"),

      lastName: Yup.string()
        .matches(name, "Only characters are allowed")
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Last Name"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const { redirect, ...payload } = values; 
        let message = 'Success! Profile updated successfully';

        await userDetailsAPI(payload, { id: userDetails.id });
        await storeOrganizationModule({ module: NAME, moduleLevel: PROFILE.NAME });
        await getUsersDetails();

        if (redirect === true) {
            validation.setFieldValue("redirect", false);
            message = 'Success! Profile updated successfully. Redirect in 3 sec.';
            setTimeout(() => { navigate(ROUTES.ORGANIZATION_DETAILS.PATH); }, 2500);
        }

        toggleNotificationState({ visible: true, color: 'success', message: message });
        resetForm();
      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); }
    },
  });



  const getUsersDetails = async () => {
    try {
      setLoading(true);

      const { data: response } = await getUsersDetailsAPI(authUser.data.id);
      const userDetails = response.data;

      if (userDetails.profilePicture) {
        setImagePreview(`${config.api.base_url}public/documents/${userDetails.profilePicture}`);
      }

      return setUserDetails(userDetails);
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
    } finally { setLoading(false); }
  };

  useEffect(() => { fetchData(); getUsersDetails(); return () => null; }, []);
  useEffect(() => {
    if (notificationState.visible) {
      setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
    }
  }, [notificationState.visible]);

  return (
    <div className="">
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible} >
        {notificationState.message}
      </Alert>

      <Form className="form-horizontal" onSubmit={validation.handleSubmit}>
        <Row>
          <Col lg={3} md={3}>
            <div className="profileImageSection">
              <h5>Your Profile</h5>
              <div className="profileImage">
                <i
                  onClick={() => {
                    const inputElement = document.getElementById("file-input");
                    if (inputElement) {
                      inputElement.click();
                    }
                  }}
                  class="fas fa-pen custom-pen-design"
                ></i>
                <input
                  type="file"
                  name="profilePicture"
                  title="Select a file"
                  accept="image/png"
                  onChange={handleImageChange}
                  style={{
                    position: "absolute",
                    backgroundColor: "none",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                ></input>

                {imagePreview && (
                  <div>
                    <Avatar src={imagePreview} size="150" opacity="0" round />
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col lg={9} md={9}>
            <div className="submitForm">
              <Col lg={12}>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Organisation Name
                        </Label>
                        <Input
                          type="text"
                          placeholder="Sample Organisation Name"
                          name="organizationName"
                          readOnly
                          className="form-control"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          defaultValue={authUser.data.organizationName}
                          value={validation.values.organizationName}
                          invalid={
                            validation.touched.organizationName &&
                              validation.errors.organizationName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.organizationName &&
                          validation.errors.organizationName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.organizationName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Business Role
                        </Label>
                        <select
                          className="form-control"
                          name="organizationBusinessRole"
                          onChange={validation.handleChange}
                          value={optionList.find(
                            ({ value }) => value === validation.values.organizationBusinessRole
                          )}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.organizationBusinessRole &&
                              validation.errors.organizationBusinessRole
                              ? true
                              : false
                          }
                          placeholder="Select Role"
                        >
                          {optionList.map((item) => (
                            <option key={item._id} value={item.roleName}>
                              {item.roleName}
                            </option>
                          ))}
                        </select>
                        {validation.touched.organizationBusinessRole &&
                          validation.errors.organizationBusinessRole ? (
                          <FormFeedback type="invalid">
                            {validation.errors.organizationBusinessRole}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          First Name
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder="Sample First Name"
                          className="form-control"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          defaultValue={authUser.firstName}
                          value={validation.values.firstName}
                          invalid={
                            validation.touched.firstName &&
                              validation.errors.firstName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstName &&
                          validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Sample Last Name"
                          className="form-control"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          defaultValue={authUser.lastName}
                          value={validation.values.lastName}
                          invalid={
                            validation.touched.lastName &&
                              validation.errors.lastName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastName &&
                          validation.errors.lastName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Email
                        </Label>
                        <Input
                          type="text"
                          name="email"
                          readOnly
                          className="form-account"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          defaultValue={authUser?.email}
                          value={authUser?.data?.email}
                          invalid={
                            validation.touched.email &&
                              validation.errors.email
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">

                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Phone Number
                        </Label>
                        <Input
                          type="text"
                          name="phoneNumber"
                          readOnly
                          className="form-account"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          defaultValue={authUser?.phoneNumber}
                          value={authUser?.data?.phoneNumber}
                          invalid={
                            validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </Col>
          <Col lg={12}>
            <div className="saveBtn mt-4">
              <button type="submit" className="btn btn-group">
                Save
              </button>
              <button className="btn btn-group" type="submit" onClick={() => validation.setFieldValue("redirect", true)}>
                Save & Next
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
