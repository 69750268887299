import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Modal,
  Col,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { MountCondition } from "../../../../module/mount-components";
import getTemplateListAPI from "../../../../api/getTemplateListAPI";
import deleteTemplateAPI from "../../../../api/deleteTemplateAPI";
import Plus from "../../../../assets/images/plusIcon.png";
import Preview from "../../../../assets/images/previewImg.png";
import * as htmlToImage from 'html-to-image';
import "../../../NetworkEngage/style.css"
import ROUTES from '../../../../routes/routes';
import MountComponent from "../../../../module/mount-components";

export default React.memo(() => {
  const navigate = useNavigate();
  const [isFits, setIsFits] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const notificationInitialState = { visible: false, message: '', color: '' }
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const templateList = async () => {
    try {
      const data = await getTemplateListAPI();
      const templates = data.data.data;
      setTemplateData(templates);
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };


  useEffect(() => {
    setLoading(true);
    templateList();
  }, []);

  const canvas = document.getElementById('template');

  // Use html-to-image to convert the canvas to an image
  htmlToImage.toPng(canvas)
    .then(function (dataUrl) {
      const img = new Image();

      img.src = dataUrl;

      document.body.appendChild(img);
    })
    .catch(function (error) {
      console.error('Error converting canvas to image:', error);
    });

  const handleEditClick = (templateId, templateName) => {
    setTemplateId(templateId);
    setTemplateName(templateName);
  };

  function tog_delete() {
    setModalDelete(!modalDelete);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleTemplateRemove = async (templateId) => {
    try {
      const { data } = await deleteTemplateAPI(templateId);
      toggleNotificationState({ visible: true, color: "success", message: "Template deleted successfully!" })
      templateList();
      templateId(null);
      setModalDelete(false);
    } catch (error) {
      console.log(error);
      setTemplateId(null);
      setModalDelete(false);
      toggleNotificationState({ visible: true, color: "success", message: error.response.data.error })
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
    return () => null;
  }, [notificationState.visible]);

  return (
    <React.Fragment>
      <MountCondition condition={loading}>
        <MountCondition.True>
          <div className="fullscreen-loader">
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          </div>
        </MountCondition.True>
      </MountCondition>
      <MountComponent condition={notificationState.visible}>
        <Alert className="pt-2 pb-2" color={notificationState.color}>
          { notificationState.message }
        </Alert>
      </MountComponent>
      {isFits ? (
        <Lightbox
          mainSrc={Preview}
          enableZoom={false}
          onCloseRequest={() => {
            setIsFits(!isFits);
          }}
        />
      ) : null}

      <div className="createTemp">
        <div className="row">
          <div className="col-md-3">
            <div className="network-engage">
              <div className="tempCard">
                <div className="innertempcard" onClick={() => navigate(ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE_CREATE)}>
                  <button
                    className="btn btn-group"
                  >
                    <img src={Plus} />
                  </button>
                </div>
                <div className="network-engages">
                  <div className="tempText">
                    <p>Blank</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {templateData.map((item) => (
            <div className="col-md-3">
              <div className="network-engage">
                <div className="previewImg">
                  <ul className="list-inline font-size-20 contact-links mb-0">
                    <li className="list-inline-item dropdown">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-muted font-size-18 px-2"
                          caret
                        >
                          <i className="uil uil-ellipsis-v"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                            onClick={() => navigate(String(ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE_CLONE).replace(':id', item.id))}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            href="#"
                            onClick={() => navigate(ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE_CLONE)}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                  <Link className="image-popup-vertical-fit" to="#">
                    <img
                      onClick={() => {
                        setIsFits(true);
                      }}
                      className="img-fluid"
                      alt="Minible"
                      src={Preview}
                    />
                  </Link>
                  <div className="tempText">
                    <p title={item.name.length > 15 ? item.name : null}>
                      {item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name}
                    </p>
                    <div className="iconContainer">
                      <button
                        className="btn btn-group"
                        onClick={() => {
                          tog_delete();
                          handleEditClick(item.id, item.name);
                        }}
                        data-toggle="modal"
                      >
                        <i
                          class="fas fa-trash-alt"
                          style={{ color: "red" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>

      <Col lg={12} className="d-flex" style={{ justifyContent: "center" }}>
        <div className="saveBtn">
          <Link to={ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE} className="btn btn-group mt-4" >Next</Link>
        </div>
      </Col>

      {/* Modal for Delete Division */}
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          tog_delete();
        }}
        backdrop={"static"}
        scrollable={true}
        id="staticBackdrop"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Delete
          </h5>
          <button
            type="button"
            className="btn-close"
            // onClick={() => {
            //   setmodal_delete(false);
            // }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete {templateName}?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancelModal btn"
            onClick={() => {
              setModalDelete(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="nextMasterModal btn"
            onClick={() => handleTemplateRemove(templateId)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
});
