import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import store from "store";
import classnames from "classnames";

import { Link } from "react-router-dom";
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import doubtImage from "../../../../assets/images/icon/doubts-button.png";
import { MountCondition } from '../../../../module/mount-components';

import PERMISSIONS from '../../../../routes/permissions.js';
import ROUTES from '../../../../routes/routes.js';

import { checkPermissions } from "../../../../api/organization-users.api";

import Brands from "./brands.component.jsx";
import Categories from "./categories.component";
import SubCategories from "./sub-categories.component";
import Types from "./types.component"
import Units from "./units.component"
import Variants from "./variants.component"
import SubVariants from "./sub-variants.component";
import Taxes from "./taxes.component";
import Measurement from "./measurement.component.jsx";
import Currency from "./currency.component.jsx";

export default React.memo(() => {
    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [tab, toggleTab] = useState('BRANDS_TAB');
    const tabsStructure = {
        'BRANDS_TAB': { next: 'CATEGORIES_TAB', previous: null },
        'CATEGORIES_TAB': { next: 'SUB_CATEGORIES_TAB', previous: 'BRANDS_TAB' },
        'SUB_CATEGORIES_TAB': { next: 'TYPES_TAB', previous: 'CATEGORIES_TAB' },
        'TYPES_TAB': { next: 'UNITS_TAB', previous: 'SUB_CATEGORIES_TAB' },
        'UNITS_TAB': { next: 'VARIANTS_TAB', previous: 'TYPES_TAB' },
        'VARIANTS_TAB': { next: 'SUB_VARIANTS_TAB', previous: 'UNITS_TAB' },
        'SUB_VARIANTS_TAB': { next: 'TAXES_TAB', previous: 'VARIANTS_TAB' },
        'TAXES_TAB': { next: 'CURRENCY_TAB', previous: 'SUB_VARIANTS_TAB' },
        'CURRENCY_TAB': { next: 'MEASUREMENT_TAB', previous: 'TAXES_TAB' },
        'MEASUREMENT_TAB': { next: null, previous: 'CURRENCY_TAB' },
    }

    const [userPermissions, setUserPermissions] = useState({
        PRODUCT_MASTERS_CREATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        PRODUCT_MASTERS_VIEW: userDetails.userRole === "ORGANIZATION" ? true : false,
        PRODUCT_MASTERS_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        PRODUCT_MASTERS_DELETE: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    /** @apis ----------------------------------------------------------------------------------------------------------------------- */

    const getPermissions = async () => {
        const modulePermissions = PERMISSIONS.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    /** @handlers ------------------------------------------------------------------------------------------------------------------- */

    const switchTab = useCallback((tabId) => { return toggleTab(tabId) });
    const nextTabCallback = useCallback(() => {
        return toggleTab(tabsStructure[tab].next)
    });

    const previousTabCallback = useCallback(() => {
        return toggleTab(tabsStructure[tab].previous)
    });

    /** @useEffects ----------------------------------------------------------------------------------------------------------------- */
    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, [tab]);
    /** ----------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <div className="master"><div className="card-body"><div className="vertical-wizard wizard clearfix vertical">
                <div className="steps clearfix">
                    <ul>
                        <NavItem className={classnames({ current: tab === "BRANDS_TAB" })}>
                            <NavLink className={classnames({ active: tab === "BRANDS_TAB" }, "mainInfo")} onClick={() => { switchTab("BRANDS_TAB"); }}>
                                <div className="TabsName cursor-pointer">Brands{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Brands</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "CATEGORIES_TAB" })}>
                            <NavLink className={classnames({ active: tab === "CATEGORIES_TAB" }, "mainInfo")} onClick={() => { switchTab("CATEGORIES_TAB"); }}>
                                <div className="TabsName cursor-pointer">Categories{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Categories</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "SUB_CATEGORIES_TAB" })}>
                            <NavLink className={classnames({ active: tab === "SUB_CATEGORIES_TAB" }, "mainInfo")} onClick={() => { switchTab("SUB_CATEGORIES_TAB"); }}>
                                <div className="TabsName cursor-pointer">Sub Categories{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Sub Categories</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "TYPES_TAB" })}>
                            <NavLink className={classnames({ active: tab === "TYPES_TAB" }, "mainInfo")} onClick={() => { switchTab("TYPES_TAB"); }}>
                                <div className="TabsName cursor-pointer">Types{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Types</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "UNITS_TAB" })}>
                            <NavLink className={classnames({ active: tab === "UNITS_TAB" }, "mainInfo")} onClick={() => { switchTab("UNITS_TAB"); }}>
                                <div className="TabsName cursor-pointer">Units{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Units</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "VARIANTS_TAB" })}>
                            <NavLink className={classnames({ active: tab === "VARIANTS_TAB" }, "mainInfo")} onClick={() => { switchTab("VARIANTS_TAB"); }}>
                                <div className="TabsName cursor-pointer">Variants{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Variants</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "SUB_VARIANTS_TAB" })}>
                            <NavLink className={classnames({ active: tab === "SUB_VARIANTS_TAB" }, "mainInfo")} onClick={() => { switchTab("SUB_VARIANTS_TAB"); }}>
                                <div className="TabsName cursor-pointer">Sub Variants{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Sub Variants</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "TAXES_TAB" })}>
                            <NavLink className={classnames({ active: tab === "TAXES_TAB" }, "mainInfo")} onClick={() => { switchTab("TAXES_TAB"); }}>
                                <div className="TabsName cursor-pointer">Taxes{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Taxes</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "CURRENCY_TAB" })}>
                            <NavLink className={classnames({ active: tab === "CURRENCY_TAB" }, "mainInfo")} onClick={() => { switchTab("CURRENCY_TAB"); }}>
                                <div className="TabsName cursor-pointer">Currencies{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Currencies</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: tab === "MEASUREMENT_TAB" })}>
                            <NavLink className={classnames({ active: tab === "MEASUREMENT_TAB" }, "mainInfo")} onClick={() => { switchTab("MEASUREMENT_TAB"); }}>
                                <div className="TabsName cursor-pointer">Measurements{" "}
                                    <span className="numb">
                                        <img src={doubtImage} />
                                        <div className="infoooo"><span>Measurements</span></div>
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                    </ul>
                </div>

                <div className="content clearfix">
                    <TabContent activeTab={tab}>
                        <TabPane tabId={"BRANDS_TAB"}>
                            <Brands userPermissions={userPermissions} tab={tab}></Brands>
                        </TabPane>
                        <TabPane tabId={"CATEGORIES_TAB"}>
                            <Categories userPermissions={userPermissions} tab={tab}></Categories>
                        </TabPane>
                        <TabPane tabId={"SUB_CATEGORIES_TAB"}>
                            <SubCategories userPermissions={userPermissions} tab={tab}></SubCategories>
                        </TabPane>
                        <TabPane tabId={"TYPES_TAB"}>
                            <Types userPermissions={userPermissions} tab={tab}></Types>
                        </TabPane>
                        <TabPane tabId={"UNITS_TAB"}>
                            <Units userPermissions={userPermissions} tab={tab}></Units>
                        </TabPane>
                        <TabPane tabId={"VARIANTS_TAB"}>
                            <Variants userPermissions={userPermissions} tab={tab}></Variants>
                        </TabPane>
                        <TabPane tabId={"SUB_VARIANTS_TAB"}>
                            <SubVariants userPermissions={userPermissions} tab={tab}></SubVariants>
                        </TabPane>
                        <TabPane tabId={"TAXES_TAB"}>
                            <Taxes userPermissions={userPermissions} tab={tab}></Taxes>
                        </TabPane>
                        <TabPane tabId={"CURRENCY_TAB"}>
                            <Currency userPermissions={userPermissions} tab={tab}></Currency>
                        </TabPane>
                        <TabPane tabId={"MEASUREMENT_TAB"}>
                            <Measurement userPermissions={userPermissions} tab={tab}></Measurement>
                        </TabPane>
                    </TabContent>
                </div>

                <div className="actions clearfix text-center my-4">
                    <MountCondition condition={lodash.isNull(tabsStructure[tab].previous)}>
                        <MountCondition.True>
                            <Link to={ROUTES.CATALOG_MANAGER.PATH} className="nextMaster btn btn-group me-2">Previous</Link>
                        </MountCondition.True>
                        <MountCondition.False>
                            <button className="btn btn-primary me-2" onClick={previousTabCallback}>Previous</button>
                        </MountCondition.False>
                    </MountCondition>
                    <MountCondition condition={lodash.isNull(tabsStructure[tab].next)}>
                        <MountCondition.True>
                            <Link to={ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT} className="nextMaster btn btn-group">Next</Link>
                        </MountCondition.True>
                        <MountCondition.False>
                            <button className="btn btn-primary" onClick={nextTabCallback}>Next</button>
                        </MountCondition.False>
                    </MountCondition>
                </div>
            </div></div></div>
        </React.Fragment>
    );
});
