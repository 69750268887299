import React from "react";
import { Navigate } from "react-router-dom";

import { useAuthentication } from "../../../../routes/middleware/auth.middleware.js";
import ROUTES from "../../../../routes/routes.js";

import Source from "./source.jsx";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Login | Giffy Network";

    if (useAuthentication()) {
        return (<Navigate to={{ pathname: ROUTES.HOME.PATH }} />);
    }

    return (<React.Fragment><Source /></React.Fragment>)
}
