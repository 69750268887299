import React from "react";
import Countdown from 'react-countdown';

export const CountdownComponent = React.memo(({ countdownState, handleCountdownComplete }) => {
    const countdownRenderer = ({ seconds, completed }) => {
        if (completed) return (<span></span>);
        else return (<span className="resend-span-body cursor-default">Resend OTP in {seconds} seconds</span>);
    };
    return (
        <React.Fragment>
            <Countdown key={countdownState.key} date={countdownState.time} onComplete={handleCountdownComplete} renderer={countdownRenderer} />
        </React.Fragment>
    );
});
