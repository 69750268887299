import { server } from '../config/config'

export const storeGeographyHierarchyAPI = (payload = {}) => server({
    method  : 'post',
    url     : `geography-levels`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getGeographyHierarchiesAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `geography-levels`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getGeographyHierarchyAPI = (id) => server({
    method  : 'get',
    url     : `geography-levels/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateGeographyHierarchyAPI = (id, payload = {}) => server({
    method: 'put',
    url: `geography-levels/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteGeographyHierarchyAPI = (id) => server({
    method: 'delete',
    url: `geography-levels/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});