import config,{server} from '../config/config'

export const getTaxesAPI = async () => {
    
    return await server({
        method  : 'get',
        url     : `tax`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getTaxesAPI