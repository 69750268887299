import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

export default React.memo(() => {
    const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";
    const geoURL2 = "https://gist.githubusercontent.com/karmadude/4527959/raw/59492893c2f13ffb909ba48253fa7fa30a640c56/in-map-major-cities.json"
    return (
        <div className="row">
            <div className="col-md-6 bg-success">
                <ComposableMap>
                    <Geographies geography={geoURL2}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography key={geo.rsmKey} geography={geo} style={{
                                    default: {
                                        fill: "#DDD",
                                    },
                                    hover: {
                                        fill: "#KKK",
                                    },
                                    pressed: {
                                        fill: "#E42",
                                    },
                                }} />
                            ))
                        }
                    </Geographies>

                </ComposableMap>
            </div>
            <div className="col-md-6 bg-warning">
                <ComposableMap>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography key={geo.rsmKey} geography={geo} style={{
                                    default: {
                                        fill: "#EEE",
                                    },
                                    hover: {
                                        fill: "#F93",
                                    },
                                    pressed: {
                                        fill: "#E68",
                                    },
                                }} />
                            ))
                        }
                    </Geographies>

                </ComposableMap>
            </div>
        </div>)
})