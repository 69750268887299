import React from "react";

import MountComponent from '../../../../module/mount-components';
import RightSideProfile from "../../../Dashboard/right-side-profile";

import Source from "./source";
import TimelineGuide from "../../timeline-guide";

import PERMISSIONS from "../../../../routes/permissions";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Location | Giffy Network";

    const showGirl = false;
    const { CHILDREN: { LOCATION } } = PERMISSIONS.NETWORK_MANAGER;

    return (
      <React.Fragment>
        <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12 col-md-12"><div className="profile">
                <div className="actives">
                    <TimelineGuide heading="Setup Your Location" activePage={LOCATION}></TimelineGuide>
                </div>
                <Source></Source>
            </div></div>
            <div className="col-lg-3 col-md-12">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
        </div></div></div>
      </React.Fragment>
  ); };
