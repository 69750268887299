import React, { useState, useEffect } from "react";
import { Col, Table, Modal } from "reactstrap";
import getOrganizationsUsersListAPI from "../../api/getOrganizationsUsersListAPI";
import deleteUsersAPI from "../../api/deleteUsersAPI";
import { ToastContainer, toast } from "react-toastify";

function Myuser({ SetHide }) {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [users,setUsers] = useState([]);
  const [modal_delete, setmodal_delete] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedUserFirstName, setSelectedUserFirstName] = useState("");

  const userList = async() => {
    try{  
        const response = await getOrganizationsUsersListAPI();
        if(response){
          setUsers(response.data);
        }
        
      }
    catch(error){
        console.log("Erorr reg", error)
    }
  }; 
  useEffect(() => {
    userList();
  },[]) 

  function tog_delete() {
    setmodal_delete(!modal_delete);
    removeBodyCss();
    // console.log("first")
  }

  const handleEditClick = (organizationId, userId, firstName) => {
   
    setOrgId(organizationId);
    setUserId(userId);
    setSelectedUserFirstName(firstName);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleSubmitRemove = async (orgId, userId) => {
  
    try {
      const { data } = await deleteUsersAPI(orgId, userId);
      setOrgId(null);
      setmodal_delete(false);
      toast.success("User Deleted Successfully");
      userList();
    } catch (error) {
      console.log("Errorm", error);

    }
  };


  return (
    <div className="userTable">
      <div className="resumeBtn text-end">
        <button
          onClick={() => {
            SetHide(true);
          }}
          className="btn btn-group mx-3"
        >
          Add New
        </button>
      </div>
      <div className="UserDataTable">
        <Col lg={12}>
          <div className="userProfileTable">
            <div className="table-responsive">
              <Table className="table table-bordered mb-0 table">
                <thead>
                  <tr>
                    <th>Fisrt Name</th>
                    <th>Last Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Organization</th>
                    <th>Business Role</th>
                    <th>Role</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                {users.map((data) => ( 
                    <tr>
                      <td>{data.user.firstName}</td>
                      <td>{data.user.lastName}</td>
                      <td>{data.user.phoneNumber}</td>
                      <td>{data.user.email}</td>
                      <td>{data.user.organizationName}</td>
                      <td>{data.user.organizationBusinessRole}</td>
                      <td>{data.lastName}</td>
                      <td>{data.userRole}</td>
                      <td>
                        <button 
                          className="btn btn-group border"
                          onClick={() => {
                            tog_delete();
                            handleEditClick(data.organizationId, data.id, data.user.firstName);
                          }}>
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            // onClick={() => handleSubmitRemove(data.organizationId, data.userId)}
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                    ))}   
                </tbody>
              </Table>
            </div>
            <div className="resumeBtn mt-4 text-center">
              <button className="btn btn-group">Next</button>
            </div>
          </div>
        </Col>

        <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        backdrop={"static"}
        scrollable={true}
        id="staticBackdrop"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Delete
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_delete(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete {selectedUserFirstName}?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancelModal btn"
            onClick={() => {
              setmodal_delete(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="nextMasterModal btn"
            onClick={() => handleSubmitRemove(orgId, userId)}
          >
            Delete
          </button>
        </div>
      </Modal>

      </div>
    </div>
  );
}

export default Myuser;
