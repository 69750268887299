import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Col, Row, Form, Label, Input, Table, FormFeedback } from "reactstrap";
import StepperHead from "../../components/Common/StepperHead";
import organizationUserSaveAPI from "../../api/organizationUserSaveAPI";
import getBusinessRulesAPI from "../../api/getBusinessRulesAPI";
import getUsersDetailsAPI from "../../api/getUsersDetailsAPI";
import updateUserGuideAPI from "../../api/updateUserGuideAPI";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import { storeOrganizationModule } from "../../api/organizationModuleApi";

function UserForm({ SetHide }) {
  const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
  const organizationId = authUserOrg.data.data.id;
  const userCount = localStorage.getItem("maincounter");
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const [dashboardCount, setDashboardCount] = useState('');

  var space = RegExp(/^\s+$/);

  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [count, setCount] = useState(0);

  const fetchData = async () => {
    try {
      const response = await getBusinessRulesAPI();
      setOptionList(response.data?.data);
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const userDetails = async () => {
    try {
      const { data } = await getUsersDetailsAPI(userData.data.id);
      if (data) {
        setDashboardCount(data.data.dashboardStartGuide)
      }

      await storeOrganizationModule({ module : 'DASHBOARD_GUIDE', moduleLevel : 'ADMIN_USERS' });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  document.title = " Organization Users | Giffy Network";

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organizationBusinessRole: optionList.length > 0 ? optionList[0].roleName : "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      // organizationName: authUserOrg.data.data.companyName
    },
    validationSchema: Yup.object({
      organizationBusinessRole: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Business Role "),
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter First Name"),
      lastName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Last Name"),
      email: Yup.string().email().required("Please Enter Your Email"),
      phoneNumber: Yup.string()
        .matches(phoneRegex, "Invalid phone number")
        .required("Phone is required"),
      // organizationName:Yup.string().matches(/^[a-zA-Z\s]+$/, 'Only characters and spaces are allowed').required('Name is required')
    }),
    onSubmit: async (values) => {
      try {
        const { data } = await organizationUserSaveAPI(values, organizationId);
        // console.log("Data user", data)
        if (data.status === true && dashboardCount < 75) {
          const guideResponse = await updateUserGuideAPI('dashboardStartGuide', userData.data.id);

          if(guideResponse.data.status === true) {
            const newCount = 75;
            localStorage.setItem("dashboardStartGuide", newCount.toString());
          }

            // if(details.gstCertificate){
          }
        SetHide(true);
        toast.success("User added successfully")
        if(userCount < 75){
          const newCount = 75;
          localStorage.setItem("maincounter", newCount.toString());
          setCount(newCount);
      }
      } catch (error) {
        toast.error(error.response.data.error);
        //
        // toast.error(error.response.data.error);
      }
    },
  });

  return (
    <div className="userProfile">
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="">
          <Row>
            <Col lg={6} md={6}>
              <div className="mt-4">
                <Form>
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                      First Name
                    </Label>
                    <Input
                      className="form-control  formControlCustom"
                      name="firstName"
                      type="text"
                      placeholder="Enter First Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                      invalid={
                        validation.touched.firstName &&
                        validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="mt-4">
                <Form>
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                      Last Name
                    </Label>
                    <Input
                      className="form-control  formControlCustom"
                      name="lastName"
                      type="text"
                      placeholder="Enter Last Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        validation.touched.lastName &&
                        validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="mt-4">
                <Form>
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                      Mobile
                    </Label>
                    <Input
                      id="Number"
                      name="phoneNumber"
                      className="form-control formControlCustom"
                      placeholder="+91 "
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phoneNumber || ""}
                      invalid={
                        validation.touched.phoneNumber &&
                        validation.errors.phoneNumber
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phoneNumber &&
                    validation.errors.phoneNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phoneNumber}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="mt-4">
                <Form>
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                      Email
                    </Label>
                    <Input
                      id="email"
                      name="email"
                      className="form-control  formControlCustom"
                      placeholder="Enter your Email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Form>
              </div>
            </Col>
            {/* <Col lg={6}>
            <div className="mt-4">
              <Form>
                <div className="mb-0">
                  <Label
                    className="form-label"
                    htmlFor="formrow-firstname-input"
                  >
                    Organization
                  </Label>
                  <span className="form-control">{authUserOrg.data.data.companyName}</span>
                  <Input
                    type="text"
                    name="organizationName"
                    placeholder="Enter Organization Name"
                    className="form-control"
                    id="formrow-firstname-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.organizationName || ""}
                    // invalid={
                    //   validation.touched.organizationName && validation.errors.organizationName ? true : false
                    // }
                  />
                </div>
              </Form>
            </div>
          </Col> */}
            <Col lg={6} md={6}>
              <div className="mt-4">
                <Form>
                  <div className="mb-0">
                    <Label
                      className="form-label"
                      htmlFor="formrow-firstname-input"
                    >
                      Business Role
                    </Label>
                    <select
                      className="form-control formControlCustom"
                      onChange={validation.handleChange}
                      name="organizationBusinessRole"
                      value={validation.values.organizationBusinessRole || ""}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.organizationBusinessRole &&
                        validation.errors.organizationBusinessRole
                          ? true
                          : false
                      }
                      placeholder="Select Role"
                    >
                      {/* <option value='1'>Owner</option>
                      <option value='2'>Managing Director</option>
                      <option value='3'>Sales Head</option>
                      <option value='4'>Operations Manager</option>
                      <option value='5'>Marketing Head</option>
                      <option value='6'>IT Head</option>
                      <option value='7'>Finance Head</option> */}
                      {optionList.map((item) => (
                        <option key={item._id} value={item.roleName}>
                          {item.roleName}
                        </option>
                        // console.log("ITEMMM", item.roleName)
                      ))}
                    </select>
                  </div>
                </Form>
                {validation.touched.organizationBusinessRole &&
                validation.errors.businessRole ? (
                  <FormFeedback type="invalid">
                    {validation.errors.organizationBusinessRole}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            
            <div className="resumeBtn mt-4 text-center">
              <button type="submit" className="btn btn-group">
                Submit
              </button>
              <button  
                className="btn btn-group mx-2" 
                onClick={() => {
                  SetHide(true);
                }}
                >
                Cancel
              </button>
            </div>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default UserForm;
