import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";

import Accordion from "react-bootstrap/Accordion";
import ReactPaginate from "react-paginate";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import MountComponent from "../../../../module/mount-components";
import ROUTES from "../../../../routes/routes";
import config from "../../../../config/config";

import getProductListAPI from "../../../../api/getProductListAPI";
import getCategoriesAPI from "../../../../api/getCategoriesAPI";
import getSubVariantsAPI from "../../../../api/getSubVariantsAPI";
import getVariantsAPI from "../../../../api/getVariantsAPI";
import getSubCategoriesAPI from "../../../../api/getSubCategoriesAPI";

export default React.memo(() => {
  const [nouisliderKey, setNouisliderKey] = useState(0);
  const [mobileSidebar, toggleMobileSidebar] = useState(false);

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    subCategories: [],
    subVariants: [],
    searchProduct: "",
    min: 0,
    max: 0,
  });

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [variants, setVariants] = useState([]);
  const [subVariants, setSubVariants] = useState([]);
  const [noUiSliderAmount, setNoUiSliderAmount] = useState({ min: 0, max: 1 });
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 0 });

  const searchParams = useSearchParams()[0];

  const getProducts = async () => {
    try {
        setLoading(true);

        const params = new URLSearchParams(searchParams);
        const queryParams = lodash.reduce(params,(result, value, key) => {
            return { ...result, [key]: value };
          },{ page: pagination.currentPage, ...filters }
        );

        const { data: response } = await getProductListAPI(queryParams);

        const min = parseInt(response.meta.minRegularPrice ?? 0);
        let max = parseInt(response.meta.maxRegularPrice ?? 0);
        max = max === min ? max + 1 : max;

        setProducts(lodash.isArray(response.data) ? response.data : []);
        setPagination({ ...response.meta });
        setNoUiSliderAmount({ min, max });

      } catch (error) {
        toast.error("there is technical error is", error);
      } finally { setLoading(false); }
  };

  const getCategories = async () => {
      await getCategoriesAPI().then(({ data: categories }) => {
          return setCategories(lodash.isArray(categories) ? categories : []);
      }).catch(() => {});
  };

  const getSubCategories = async () => {
      await getSubCategoriesAPI().then(({ data: response }) => {
          return setSubCategories(lodash.isArray(response.data) ? response.data : []);
      }).catch(() => {});
  };

  const getVariants = async () => {
      await getVariantsAPI().then(({ data: response }) => {
          return setVariants(lodash.isArray(response) ? response : []);
      }).catch(() => {});
  };

  const getSubVariants = async () => {
      await getSubVariantsAPI().then(({ data: response }) => { 
          return setSubVariants(lodash.isArray(response) ? response : []);
      }).catch(() => {});
  };

  useEffect(() => { getProducts(); return () => null; }, [
    pagination.currentPage,
    filters.subCategories,
    filters.subVariants,
    filters.searchProduct,
    filters.min,
    filters.max,
  ]);

  useEffect(() => { 
      getCategories(); /** get categories options */
      getSubCategories(); /** get sub categories options */
      getVariants(); /** get variants options */
      getSubVariants(); /** get sub variants options */
  }, []);
  
  const handlePageChange = useCallback(({ selected: page }) => {
      return setPagination((state) => ({ ...state, currentPage: page + 1 }));
  },[pagination]);

  const handleSubcategoryChange = useCallback((subcategoryId) => {
      setFilters((prevFilters) => {
        const updatedSubCategories = lodash.xor(prevFilters.subCategories, [subcategoryId]);
        return { ...prevFilters, subCategories: updatedSubCategories };
      });
  },[]);

  const handleSubVariantsChange = useCallback((subVariantId) => {
      return setFilters((prevFilters) => ({
        ...prevFilters,subVariants: lodash.xor(prevFilters.subVariants, [subVariantId]),
      }));
  },[filters]);

  const handleSearch = useCallback(({ target }) => {
      const inputValue = target.value.toString();
      if (inputValue.length < 3 && inputValue.length !== 0) return;
      setFilters({ ...filters, searchProduct: inputValue });
  },[filters]);

  const resetFilters = useCallback(() => {
    setNouisliderKey((prevKey) => prevKey + 1);
    setFilters({
        subCategories: [],
        subVariants: [],
        searchProduct: "",
        min: 0,
        max: 0,
    });
  });

  const handlePriceSlider = useCallback(([min, max]) => {
      setFilters(prevFilters => ({ ...prevFilters,min,max }));
  },[setFilters]);

  
  return (
    <React.Fragment>
      <MountComponent condition={loading}>
          <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
          </div></div></div>
      </MountComponent>

      <div className="catalog-header-box">
          <h5 style={{ fontWeight: "bold" }}>Product/Catalog</h5>
          <button onClick={() => { toggleMobileSidebar(true); }} className="btn btn-group mobFilters" >
            Filters
          </button>
      </div>

      <MountComponent condition={mobileSidebar}>
          <div className="card mobFilter">
            <div className="card-header bg-transparent border-bottom d-flex ">
              <h5 className="mb-0">Filters</h5>
              <button onClick={() => { toggleMobileSidebar(false); }} className="btn btn-group">
                Close
              </button>
            </div>
            <div className="p-2">
              <h3 className="font-size-14 mx-2">
                <b>Categories</b>
              </h3>

              {categories.map((category, index) => (
                <div className="custom-accordion" key={`category-${index}`}>
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{category.name}</Accordion.Header>
                      <Accordion.Body>
                        <div className="scrollable-list">
                          <ul>
                            {subCategories
                              .filter(
                                (subCategory) =>
                                  subCategory.categoryId === category.id
                              )
                              .map((subCategory, subCategoryIndex) => (
                                <li key={`sub-category-${subCategoryIndex}`}>
                                  <Input
                                    type="checkbox"
                                    value={subCategory.id}
                                    className="form-check-input"
                                    id={subCategory.id}
                                    onChange={() =>
                                      handleSubcategoryChange(subCategory.id)
                                    }
                                    checked={filters.subCategories.includes(
                                      subCategory.id
                                    )}
                                  />
                                  <Label className="ms-2">
                                    {subCategory.name}
                                  </Label>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
            </div>

            <div className="p-2">
              <h3 className="font-size-14 mx-2">
                <b>Variants</b>
              </h3>
              <div style={{ maxHeight: '10px', overflowY: 'scroll' }}>
                {variants.map((variant, index) => (
                  <div className="custom-accordion" key={`variant-${index}`}>
                    <Accordion>
                      <Accordion.Item eventKey={`variant-${index}`}>
                        <Accordion.Header>{variant.name}</Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {subVariants
                              .filter(
                                (subVariant) =>
                                  subVariant.variantsId === variant.id
                              )
                              .map((subVariant, subVariantIndex) => (
                                <li key={`sub-category-${subVariantIndex}`}>
                                  <Input
                                    type="checkbox"
                                    value={subVariant.id}
                                    className="form-check-input"
                                    id={subVariant.id}
                                    onChange={() =>
                                      handleSubVariantsChange(subVariant.id)
                                    }
                                    checked={filters.subVariants.includes(
                                      subVariant.id
                                    )}
                                  />
                                  <Label className="ms-2">
                                    {subVariant.name}
                                  </Label>
                                </li>
                              ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
          </div>
      </MountComponent>

      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-3 p-0"><div className="card webFilter" style={{ height: "auto" }}>
            <div className="card-header bg-transparent border-bottom d-flex justify-content-between align-content-center">
              <h5 className="mb-0">Filters</h5>
              <button className="btn btn-sm btn-primary" onClick={resetFilters}>
                Reset Filters
              </button>
            </div>
            <div className="p-2">
              <h3 className="font-size-14 mx-2">
                <b>Categories</b>
              </h3>
              {categories.map((category, index) => (
                <div className="custom-accordion" key={`category-${index}`}>
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{category.name}</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {subCategories
                            .filter(
                              (subCategory) =>
                                subCategory.categoryId === category.id
                            )
                            .map((subCategory, subCategoryIndex) => (
                              <li key={`sub-category-${subCategoryIndex}`}>
                                <Input
                                  type="checkbox"
                                  value={subCategory.id}
                                  className="form-check-input"
                                  id={subCategory.id}
                                  onChange={() =>
                                    handleSubcategoryChange(subCategory.id)
                                  }
                                  checked={filters.subCategories.includes(
                                    subCategory.id
                                  )}
                                />
                                <Label className="ms-2">
                                  {subCategory.name}
                                </Label>
                              </li>
                            ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
            </div>

            <div className="p-3 border-top">
              <div>
                <h5 className="font-size-14 mb-5">Price</h5>
                <Nouislider
                  key={nouisliderKey}
                  range={{
                    min: noUiSliderAmount.min,
                    max: noUiSliderAmount.max,
                  }}
                  tooltips={true}
                  start={[0, noUiSliderAmount.max]}
                  className="mt-5"
                  connect={true}
                  onEnd={handlePriceSlider}
                  onStart={handlePriceSlider}
                />
              </div>
            </div>

            <div className="p-2">
              <h3 className="font-size-14 mx-2">
                <b>Variants</b>
              </h3>
              {variants.map((variant, index) => (
                <div className="custom-accordion" key={`variant-${index}`}>
                  <Accordion>
                    <Accordion.Item eventKey={`variant-${index}`}>
                      <Accordion.Header>{variant.name}</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {subVariants
                            .filter(
                              (subVariant) =>
                                subVariant.variantsId === variant.id
                            )
                            .map((subVariant, subVariantIndex) => (
                              <li key={`sub-category-${subVariantIndex}`}>
                                <Input
                                  type="checkbox"
                                  value={subVariant.id}
                                  className="form-check-input"
                                  id={subVariant.id}
                                  onChange={() =>
                                    handleSubVariantsChange(subVariant.id)
                                  }
                                  checked={filters.subVariants.includes(
                                    subVariant.id
                                  )}
                                />
                                <Label className="ms-2">
                                  {subVariant.name}
                                </Label>
                              </li>
                            ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
            </div>
        </div></div>

        <div className="col-xl-9 col-lg-8 col-md-9">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-inline float-md-end">
                    <div className="search-box">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="orm-control bg-light border-light rounded"
                          placeholder="Search..."
                          onChange={handleSearch}
                        />
                        <i className="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {products.length !== 0 ? (products.filter(product => product.status === 'Publish').map((data, index) => (
                  <div className="col-xl-4 col-md-4 col-md-4 col-sm-6 col-xs-12" key={index}>
                    <Link
                      to={String(
                        ROUTES.CATALOG_MANAGER.CHILDREN.CATALOG_DETAILS
                      ).replace(":id", data.id)}
                      className="text-dark"
                    >
                      <div className="product-box">
                        <div className="product-img pt-2 px-2">
                          <img
                            src={`${config.api.base_url}public/products/${data.image}`}
                            alt="source"
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                        <div className="text-center product-content">
                          <h5>{data.title}</h5>
                          <p className="text-muted font-size-13">
                            {data.categories.name}&nbsp;,&nbsp;
                            {data.subCategories.name}
                          </p>
                          <h6 className="mb-0">
                            <span className="text-muted me-2">
                              <del>Rs {data.mrp}</del>
                            </span>
                            <b>Rs {data.regularPrice}</b>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))) : <h4 className="text-xl-center" style={{ marginTop: "60px" }}>No Product Available in Selected Criteria</h4>}

                <div className="col-12 bg-transparent mt-5">
                  <div className="float-sm-end">
                    <nav className="pagination pagination-rounded mb-sm-0">
                      <ReactPaginate
                        previousLabel={">"}
                        nextLabel={"<"}
                        pageCount={pagination.totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={6}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        renderOnZeroPageCount={null}
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
