import React, { useState, useEffect } from "react";
import lodash from "lodash";

import Select from "react-select";
import { Col, Row, Form, Label, Input, FormFeedback, Alert } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import MountComponent from "../../../../module/mount-components";
import { errorMessage, toastErrorMessage } from "../../../../module/error-methods";
import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods.js";

import { storeOrganizationModule } from "../../../../api/organizationModuleApi";
import { storeChannelPartnerAPI } from "../../../../api/channel-partner.api.js";

import { getDivisionsAPI } from "../../../../api/division.api.js";
import { getNetworkLocationsAPI } from "../../../../api/network-location.api.js";
import { getDistributionHierarchiesAPI } from "../../../../api/distribution-hierarchy.api";

import ROUTES from "../../../../routes/routes.js";
import PERMISSIONS from "../../../../routes/permissions";

export default React.memo(() => {
  const { NAME, CHILDREN: { CHANNEL_PARTNER } } = PERMISSIONS.NETWORK_MANAGER;

  const [divisionOptions, setDivisionOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

  const navigate = useNavigate();

  const channelPartnerFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: null,
      lastName: null,
      mobileNo: null,
      email: null,
      location: null,
      designation: null,
      divisionId: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter firstName"),
      lastName: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter lastName"),
      mobileNo: Yup
        .string()
        .required('Mobile number is required.')
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits.')
        .test('positive',
          'Mobile number must be positive.',
          (val) => parseInt(val) > 0
        ),
      email: Yup.string().email().required("Please Enter email "),
      divisionId: Yup.string().required("Please select division"),
      designation: Yup.string().required("Please select designation"),
      location: Yup.string().required("Please select location"),
    }),

    onSubmit: async (payload) => {
      try {

        await storeChannelPartnerAPI(payload);
        await storeOrganizationModule({ module: NAME, moduleLevel: CHANNEL_PARTNER.NAME });

        toggleNotificationState({ visible: true, color: 'success', message: "Channel partner added Successfully" });
        setTimeout(() => {
          navigate(ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER);
        }, 3000);

      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); }
    },
  });

  /** @apis ----------------------------------------------------------------------------------------------------------------------- */

  const getDivisionOptions = async () => {
    await getDivisionsAPI().then(({ data }) => {
      if (lodash.isArray(data)) {
        return setDivisionOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
      }
    }).catch((error) => toastErrorMessage(error));
  };

  const getDesignationOptions = async () => {
    const divisionId = channelPartnerFormik.values.divisionId
    if (!divisionId) { return true; }

    await getDistributionHierarchiesAPI({ divisionId }).then(({ data }) => {
      if (lodash.isArray(data)) {
        channelPartnerFormik.setFieldValue('designation', null);
        return setDesignationOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
      }
    }).catch((error) => toastErrorMessage(error));
  };

  const getLocationOptions = async () => {
    const divisionId = channelPartnerFormik.values.divisionId
    if (!divisionId) { return true; }
    await getNetworkLocationsAPI({ divisionId }).then(({ data }) => {
      if (lodash.isArray(data)) {
        channelPartnerFormik.setFieldValue('location', null);
        return setLocationOptions([...data.map(({ location, id }) => ({ label: location, value: id }))]);
      }

    }).catch((error) => toastErrorMessage(error));
  };

  /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { getDivisionOptions(); }, []);
  useEffect(() => { getDesignationOptions(); }, [channelPartnerFormik.values.divisionId]);
  useEffect(() => { getLocationOptions(); }, [channelPartnerFormik.values.divisionId]);

  useEffect(() => {
    if (notificationState.visible) {
      setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000);
    }
  }, [notificationState.visible]);

  /** ---------------------------------------------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible} toggle={() => toggleNotificationState({ visible: false, color: '', message: '' })} >
        {notificationState.message}
      </Alert>

      <Form className="form-horizontal" onSubmit={channelPartnerFormik.handleSubmit}>
        <div className="submitForm"><div className="row">
          <Col lg={6} md={6}>
            <div className="mt-4">
              <div className="mb-0">
                <Label
                  className="form-label"
                  htmlFor="formrow-firstname-input"
                >
                  First Name
                </Label>
                <Input
                  type="text"
                  placeholder="Enter First Name"
                  name="firstName"
                  className="form-control"
                  id="formrow-firstname-input"
                  onChange={channelPartnerFormik.handleChange}
                  onBlur={channelPartnerFormik.handleBlur}
                  value={channelPartnerFormik.values.firstName || ""}
                  invalid={
                    channelPartnerFormik.touched.firstName &&
                      channelPartnerFormik.errors.firstName
                      ? true
                      : false
                  }
                />
              </div>
              {channelPartnerFormik.touched.firstName &&
                channelPartnerFormik.errors.firstName ? (
                <FormFeedback type="invalid">
                  {channelPartnerFormik.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={6} md={6}>
            <div className="mt-4">
              <div className="mb-0">
                <Label
                  className="form-label"
                  htmlFor="formrow-firstname-input"
                >
                  Last Name
                </Label>
                <Input
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  className="form-control"
                  id="formrow-firstname-input"
                  onChange={channelPartnerFormik.handleChange}
                  onBlur={channelPartnerFormik.handleBlur}
                  value={channelPartnerFormik.values.lastName || ""}
                  invalid={
                    channelPartnerFormik.touched.lastName &&
                      channelPartnerFormik.errors.lastName
                      ? true
                      : false
                  }
                />
              </div>
              {channelPartnerFormik.touched.lastName &&
                channelPartnerFormik.errors.lastName ? (
                <FormFeedback type="invalid">
                  {channelPartnerFormik.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={6} md={6}>
            <div className="mt-4">
              <div className="mb-0">
                <Label
                  className="form-label"
                  htmlFor="formrow-firstname-input"
                >
                  Mobile
                </Label>
                <Input
                  type="number"
                  placeholder="Enter Mobile Number"
                  name="mobileNo"
                  className="form-control"
                  id="formrow-firstname-input"
                  onChange={channelPartnerFormik.handleChange}
                  onBlur={channelPartnerFormik.handleBlur}
                  value={channelPartnerFormik.values.mobileNo || ""}
                  invalid={
                    channelPartnerFormik.touched.mobileNo &&
                      channelPartnerFormik.errors.mobileNo
                      ? true
                      : false
                  }
                />
                {channelPartnerFormik.touched.mobileNo &&
                  channelPartnerFormik.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {channelPartnerFormik.errors.mobileNo}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>

          <Col lg={6} md={6}>
            <div className="mt-4">
              <div className="mb-0">
                <Label
                  className="form-label"
                  htmlFor="formrow-firstname-input"
                >
                  Email
                </Label>
                <Input
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  className="form-control"
                  id="formrow-firstname-input"
                  onChange={channelPartnerFormik.handleChange}
                  onBlur={channelPartnerFormik.handleBlur}
                  value={channelPartnerFormik.values.email || ""}
                  invalid={
                    channelPartnerFormik.touched.email && channelPartnerFormik.errors.email
                      ? true
                      : false
                  }
                />
                {channelPartnerFormik.touched.email && channelPartnerFormik.errors.email ? (
                  <FormFeedback type="invalid">
                    {channelPartnerFormik.errors.email}
                  </FormFeedback>
                ) : null}
              </div>

            </div>
          </Col>

          <Col lg={6} md={6}>
            <div className="mt-4"><div className="mb-0">
              <Label className="form-label">Division Name</Label>
              <Select
                className=""
                options={divisionOptions}
                name="divisionId"
                inputId="aria-example-input"
                isSearchable
                onChange={({ value }) => { channelPartnerFormik.setFieldValue("divisionId", value); }}
                value={divisionOptions.find(({ value }) => value === channelPartnerFormik.values.divisionId)}
                onBlur={channelPartnerFormik.handleBlur}
                invalid={channelPartnerFormik.touched.divisionId && channelPartnerFormik.errors.divisionId ? true : false}
                theme={(theme) => themeReactSelect(theme, channelPartnerFormik.touched.divisionId && channelPartnerFormik.errors.divisionId)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, channelPartnerFormik.touched.divisionId && channelPartnerFormik.errors.divisionId) }}
              ></Select>
              <MountComponent condition={channelPartnerFormik.touched.divisionId && channelPartnerFormik.errors.divisionId}>
                <small className="select-error">{channelPartnerFormik.errors.divisionId}</small>
              </MountComponent>
            </div></div>
          </Col>

          <Col lg={6} md={6}>
            <div className="mt-4"><div className="mb-0">
              <Label className="form-label">Designation</Label>
              <Select
                className=""
                name="designation"
                isSearchable
                onBlur={channelPartnerFormik.handleBlur}
                onChange={({ value }) => {
                  channelPartnerFormik.setFieldValue("designation", value);
                }}
                value={designationOptions.find(({ value }) =>
                  value === channelPartnerFormik.values.designation
                ) ?? null}
                options={designationOptions}
                invalid={channelPartnerFormik.touched.designation && channelPartnerFormik.errors.designation ? true : false}
                theme={(theme) => themeReactSelect(theme, channelPartnerFormik.touched.designation && channelPartnerFormik.errors.designation)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, channelPartnerFormik.touched.designation && channelPartnerFormik.errors.designation) }}
              >
              </Select>
              <MountComponent condition={channelPartnerFormik.touched.designation && channelPartnerFormik.errors.designation}>
                <small className="select-error">{channelPartnerFormik.errors.designation}</small>
              </MountComponent>
            </div></div>
          </Col>

          <Col lg={6} md={6}>
            <div className="mt-4"><div className="mb-0">
              <Label className="form-label">Location</Label>
              <Select
                className=""
                name="location"
                isSearchable
                onBlur={channelPartnerFormik.handleBlur}
                onChange={({ value }) => {
                  channelPartnerFormik.setFieldValue("location", value);
                }}
                value={locationOptions.find(
                  ({ value }) => value === channelPartnerFormik.values.location
                ) ?? null}
                options={locationOptions}
                invalid={channelPartnerFormik.touched.location && channelPartnerFormik.errors.location ? true : false}
                theme={(theme) => themeReactSelect(theme, channelPartnerFormik.touched.location && channelPartnerFormik.errors.location)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, channelPartnerFormik.touched.location && channelPartnerFormik.errors.location) }}
              >
              </Select>
              <MountComponent condition={channelPartnerFormik.touched.location && channelPartnerFormik.errors.location}>
                <small className="select-error">{channelPartnerFormik.errors.location}</small>
              </MountComponent>
            </div></div>
          </Col>

          <hr className="mt-3" style={{ color: "#ced4da", border: "1px solid" }} />
          <div className="col-12 d-flex justify-content-center">
            <div className="saveBtn">
              <button className="btn btn-group" type="submit">Save</button>
            </div>
            <div className="saveBtn mx-2">
              <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER} type="button" className="btn btn-group">Cancel</Link>
            </div>
          </div>

        </div></div>
      </Form>
    </React.Fragment>
  );
});
