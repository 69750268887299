import config,{server} from '../config/config'

export const getBrandsAPI = async (queryParams = {}) => {
    
    return await server({
        method  : 'get',
        url     : `brands`,
        params  : { ...queryParams },
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getBrandsAPI