import React, { useEffect, useState } from "react";
import lodash from "lodash";
import parser from 'html-react-parser';

import { MountCondition } from "../../../../module/mount-components";
import getTemplateViewAPI from "../../../../api/getTemplateViewAPI";

export default (props) => {
  const [template,setTemplate] = useState(null);
  const [loading, setLoading]  = useState(false);

  const templateView = async () => {
      try {
          const { data: response } = await getTemplateViewAPI(props.campaignState.templateId);
          const templateContent = lodash.get(response,'data.template',null);
          
          if(templateContent){ setTemplate(templateContent) };
      } catch (error) { } finally { setLoading(false); }
  };

  useEffect(() => { setLoading(true); templateView(); }, [props.screen]);

  return (
    <React.Fragment>
        <MountCondition condition={loading}>
            <MountCondition.True>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountCondition.True>
        </MountCondition>
        <div className="page-content p-0"><div className="container-fluid"><div className="row bg-transparent mt-0 p-0 pb-3">
            <div className="col-12 bg-transparent">
                <h5 className="fw-bold d-flex justify-content-center text-black">Campaign Preview</h5>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                  <p className="text-black mb-2"><strong>From:</strong> <span>{ props.campaignState.sourceEmail }</span></p>
                  <p className="text-black mb-0"><strong>Subject:</strong><span>{ props.campaignState.subject }</span></p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3 d-flex align-items-end flex-column">
                  <div className="d-flex bg-success">
                      {/* <section className=""><strong>Desktop</strong></section> */}
                      {/* <section className=""><strong>Mobile</strong></section> */}
                  </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
                <div className="card shadow"><div className="card-body">
                    { parser(template ?? 'Loading...') }
                </div></div>
                <div className="d-flex align-items-end flex-column">
                  <button className="btn-sm btn-secondary btn" onClick={() => { props.switchScreen('create-campaign') }}>Go Back</button>
                </div>
            </div>
        </div></div></div>
    </React.Fragment>
);};
