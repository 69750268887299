import config,{server} from '../config/config'

export const getSalesDesignationAPI = async (divisionId) => {
    // console.log("params sales des", id)
    return await server({
        method  : 'get',
        url     : `sales-hierarchy/sales-desigantion/${divisionId}`,
        // params    :  { divisionId },
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getSalesDesignationAPI