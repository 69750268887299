import { server } from "../../config/config";
import lodash from 'lodash';
import axios from "axios";
import { toast } from "react-toastify";

export const notification = (toastId, status, message) => {
    toast.update(toastId, { render: message, type: status, isLoading: false, autoClose: 800 });
}

export const apiTree = (payload) => {
    return new Promise((resolve) => {

        if (!payload.divisionId) { return resolve([]) }

        server({
            method: 'get',
            url: `tree`,
            headers: { "Content-type": "application/json; charset=UTF-8" },
            params: { divisionId: payload.divisionId }
        }).then((response) => { resolve(response.data) }).catch(() => { resolve([]) });
    })
}

export const apiStoreNode = (payload) => {
    return new Promise((resolve) => {
        const toastIdentification = toast.loading("Please wait...")

        server({
            method: 'post', data: { ...payload },
            url: `/tree`,
            headers: { "Content-type": "application/json; charset=UTF-8", }
        }).then((response) => {
            notification(toastIdentification, "success", "Level stored Successfully"); resolve({ status: true, data: response.data });
        }).catch((error) => {
            notification(toastIdentification, "error", "Something went wrong"); resolve({ status: false, error: error.message });
        });
    })
}


export const apiDeleteNode = (id) => {
    return new Promise((resolve) => {
        const toastIdentification = toast.loading("Please wait...")

        server({
            method: 'delete', data: {},
            url: `/tree/${id}`,
            headers: { "Content-type": "application/json; charset=UTF-8", }
        }).then((response) => {
            notification(toastIdentification, "success", "Level deleted Successfully"); resolve({ status: true, data: response.data });
        }).catch((error) => {
            notification(toastIdentification, "error", "Something went wrong"); resolve({ status: false, error: error.message })
        });
    })
}

export const apiPositions = (pinCode) => {
    const response = { pinCode: pinCode.value, lat : '24.5854', lng : '73.7125', };
    return response;
}

export const apiPositionsInactive = (pinCode) => new Promise(async (resolve) => {

    try {

        if (lodash.isEmpty(pinCode)) { return resolve({}) };

        const googleKey = "AIzaSyDFbh8qmYfgZR77myC6eowbBEkhiR1YZPA"
        const url = `https://maps.googleapis.com/maps/api/geocode/json`
        const response = await axios.get(url, {
            params: { address: pinCode.value, key: googleKey }
        });

        if (response.data.status === 'OK' && response.data.results.length > 0) {
            const position = response.data.results[0];
            const lat = position.geometry.location.lat;
            const lng = position.geometry.location.lng;

            return resolve({ pinCode: pinCode.value, lat, lng, });

        } else { return resolve({}); }

    } catch (error) { return resolve({}); }
});

export const apiStates = () => {
    return new Promise((resolve) => {
        server({
            method: 'get', params: {},
            url: `/location/states`,
            headers: { "Content-type": "application/json; charset=UTF-8", }
        }).then((response) => { resolve(response.data.data ?? []); }).catch(() => { resolve([]) });
    })
}

export const apiCities = (states) => {
    return new Promise((resolve) => {

        if (lodash.isEmpty(states)) { return resolve([]) }

        server({
            method: 'get', params: { states: lodash.map(states, ({ value }) => value) },
            url: `/location/cities`,
            headers: { "Content-type": "application/json; charset=UTF-8", }
        }).then((response) => { resolve(response.data.data ?? []); }).catch(() => { resolve([]) });
    })
}

export const apiPinCodes = (states, cities) => {
    return new Promise((resolve) => {

        if (lodash.isEmpty(states) || lodash.isEmpty(cities)) { return resolve([]) }

        server({
            method: 'get', url: `/location/pin-codes`,
            headers: { "Content-type": "application/json; charset=UTF-8" },
            params: {
                states: lodash.map(states, ({ value }) => value),
                cities: lodash.map(cities, ({ value }) => value),
            }
        }).then((response) => { resolve(response.data.data ?? []); }).catch(() => { resolve([]) });
    })
}

export const googleMapKey = "AIzaSyDFbh8qmYfgZR77myC6eowbBEkhiR1YZPA"

export const apiDivisions = () => {
    return new Promise((resolve) => {
        server({
            method: 'get', url: `divisions`,
            headers: { "Content-type": "application/json; charset=UTF-8" },
        }).then((response) => { resolve(response.data) }).catch(() => { resolve([]) });
    })
}

export const apiGeographyLevels = (params) => {
    return new Promise((resolve) => {
        if (!params.divisionId) { return resolve([]) }

        server({
            method: 'get', url: `geography-levels`,
            params: { divisionId: params.divisionId },
            headers: { "Content-type": "application/json; charset=UTF-8" },
        }).then((response) => { resolve(response.data) }).catch(() => { resolve([]) });
    })
}
