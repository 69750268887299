import React, { useState, useEffect, useCallback } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import lodash from 'lodash';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import MountComponent from "../../../module/mount-components";
import "../../../assets/scss/custom/pages/datatables.scss";
import { errorMessage } from "../../../module/error-methods";
import Drive from "../../../assets/images/driveview.png";
import Excel from "../../../assets/images/excel.png";
import { getNetworkSalesmenDataAPI } from "../../../api/network-salesman.api";
import { getDivisionsAPI } from "../../../api/division.api";
import { getSalesHierarchiesAPI } from "../../../api/sales-hierarchy.api";
import getNetworkLocationsListAPI from "../../../api/getNetworkLocationsListAPI";

export default React.memo(({ tab, setHide }) => {
 
  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });
  
  const [salesmenData, setSalesmenData] = useState([]);
  const [divisionOptions,setDivisionOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const [divisionId, setDivisionId] = useState(null);
  const [designationId, setDesignationId] = useState(null);
  const [locationId, setLocationId] = useState(null);

  /** @handlers --------------------------------------------------------------------------------------------------------------------- */

    const handleSelectDivision = useCallback((option) => { setDivisionId(option.value) });
    const handleSelectDesignation = useCallback((option) => { setDesignationId(option.value) });
    const handleSelectLocation = useCallback((option) => { setLocationId(option.value) });

  /** ------------------------------------------------------------------------------------------------------------------------------- */


  const divisionList = async () => {
      await getDivisionsAPI().then(({ data }) => {
          if(lodash.isArray(data)) { 
              const options = data.map(({ name, id }) => ({ label: name, value: id }));
              return setDivisionOptions([ ...options ])
          }
      }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }) });
  };

  const getSaleDesignation = async () => {
      try {
        if(lodash.isNil(divisionId)) { return true; }
        const { data : salesHierarchies } = await getSalesHierarchiesAPI({ divisionId });
        const salesHierarchyOptions = lodash.map(salesHierarchies,(data) => ({ 
            value: data.id,
            label: data.name,
        }));
        return setDesignationOptions(lodash.cloneDeep(salesHierarchyOptions))
      } catch (error) { setDesignationOptions([]) }
  };

  const getNetworkLocations = async () => {
    try {
        if(lodash.isNil(divisionId)) { return true; }
        const { data : networkLocations } = await getNetworkLocationsListAPI({ divisionId });
        const networkLocationOptions = lodash.map(networkLocations,(data) => ({ 
            value: data.id,
            label: data.location,
        }));
        return setLocationOptions(lodash.cloneDeep(networkLocationOptions));
    } catch (error) { setLocationOptions([]); }
  };

  const getNetworkSalesmen = async () => {
      try {

        setLoading(true);
        const queryParams = { divisionId,locationId, salesHierarchyId: designationId };
        const { data } = await getNetworkSalesmenDataAPI(queryParams);

        if(lodash.isArray(data)) { setSalesmenData([...data]) }

      } catch (error) { } finally { setLoading(false); }
  };

    useEffect(() => { 
      if (tab === "SALESMAN_TAB") { getNetworkSalesmen(); }
      return () => null;
    }, [tab,divisionId, designationId, locationId]);

    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(data.rows);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "salesman.xlsx");
    };

    useEffect(() => { if (tab === "SALESMAN_TAB") { divisionList(); } }, [tab]);
    useEffect(() => { if (tab === "SALESMAN_TAB") { getSaleDesignation(); } }, [tab,divisionId]);
    useEffect(() => { if (tab === "SALESMAN_TAB") { getNetworkLocations();} }, [tab,divisionId]);
 
    const data = {
      columns: [
        {
          label: "Sr. No.",
          field: "sr",
          sort: "asc",
          width: 150,
        },
        {
          label: "First Name",
          field: "firstName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Last Name",
          field: "lastName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Mobile",
          field: "mobileNo",
          sort: "asc",
          width: 200,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 100,
        },
        {
          label: "SBU",
          field: "sbu",
          sort: "asc",
          width: 100,
        },
        {
          label: "Designation",
          field: "designation",
          sort: "asc",
          width: 150,
        },
        {
          label: "Location",
          field: "location",
          sort: "asc",
          width: 100,
        }
      ],
      rows:
      salesmenData
        .map((data, i) => ({
          sr: ++i,
          firstName: data.firstName,
          lastName: data.lastName,
          mobileNo: data.mobileNo,
          email: data.email,
          sbu: data?.divisions?.name,
          designation: data?.salesHierarchy?.name,
          location: data?.networkLocation?.location,
        })),
    };
 
  return (
    <div className="location">
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>
      <div className="viewSalesButtons row">
        <div className="col-lg-12 col-md-12 col-sm-10"><div className="addNewBtn">
            
            <button onClick={() => setHide("map")} style={{ border: "none",margin: "0 5px",boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",background: "#fff", padding: "8px",borderRadius: "7px",}}>
                <img src={Drive} alt="" style={{ height: "1.5em",objectFit: "contain",width: "100%",background: "#fff", }}/>
            </button>
            <button onClick={exportToExcel} style={{ borderRadius: "7px",padding: "8px",background: "#fff",border: "none",margin: "0 5px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",}}>
              <img src={Excel} alt="" style={{ height: "1.5em", objectFit: "contain", width: "100%", background: "#fff", }} />
            </button>
        </div></div>
      </div>

      <div className="viewSalesDropdowns"><div className="row">
          <div className="col-md-4 col-lg-4"><div className="mb-2">
              <Select onChange={handleSelectDivision} options={divisionOptions} value={divisionOptions.find((option) => option.value === divisionId)} className="mainSelect" placeholder="Select SBU" />
          </div></div>
          <div className="col-md-4 col-lg-4"><div className="mb-2">
              <Select onChange={handleSelectDesignation} options={designationOptions} value={divisionId && designationOptions.find((option) => option.value === divisionId.value)} className="mainSelect" placeholder="Select Hierarchy" />
          </div></div>
          
          <div className="col-md-4 col-lg-4"><div className="mb-2">
              <Select onChange={handleSelectLocation} options={locationOptions} value={divisionId && locationOptions.find((option) => option.value === divisionId.value)} className="mainSelect" placeholder="Select Location"/>
          </div></div>    
      </div></div>

      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={data} footer={false}/>
      </div>

    </div>
  );
})


