import React, { useCallback, useRef, useState } from 'react';
import lodash from "lodash";
import VectorMap, { ControlBar, Layer, Legend, Source, Tooltip } from 'devextreme-react/vector-map';
import * as mapsData from 'devextreme-dist/js/vectormap-data/world.js';

import states from './states.json';

const defaultGeoCoordinates = {
    type: mapsData.world.type,
    bbox: mapsData.world.bbox,
    features: states.features
}

export default React.memo((props) => {
    const vectorMapRef = useRef(null);
    const [state] = useState({ center: [91.873, 22.517], bounds: [-180, 85, 180, -60], zoomFactor: 8 });
    const [geoCoordinates, updateGeoCoordinates] = useState({ ...defaultGeoCoordinates })

    const reset = useCallback(() => {
        vectorMapRef.current.instance.center([91.873, 22.517]);
        vectorMapRef.current.instance.zoomFactor(8);
        updateGeoCoordinates({ ...defaultGeoCoordinates });
    }, [vectorMapRef]);

    const handleTooltip = useCallback((arg) => {
        return { text: arg.attribute('NAME_1') ?? arg.attribute('NAME_1') }
    });

    const searchCoordinates = (array) => {
        if (lodash.size(array) === 1) { return searchCoordinates(array[0]) }
        const data = lodash.get(array, Math.floor(array.length / 2));
        const response = (array) => {
            if (array.length === 2 && lodash.isNumber(array[0])) { return array; }
            return response(array[0])
        }
        return response(data);
    }

    const markerClick = useCallback(({ target, component }) => {
        try {

            if (!lodash.has(target, "attribute")) { return true };

            const targetState = target.attribute("NAME_1");
            const filterFeatures = lodash.filter(states.features, ({ properties: { NAME_1: state } }) => {
                return targetState === state;
            });

            updateGeoCoordinates({
                type: mapsData.world.type,
                bbox: mapsData.world.bbox,
                features: filterFeatures
            });

            if (target?.layer.type === 'area') {
                const coordinates = searchCoordinates(target.coordinates());
                component.center(coordinates).zoomFactor(30);
            }
        } catch (error) { }
    });

    const customizeLayer = (elements) => {
        elements.forEach((element) => {
            const targetState = element.attribute("NAME_1");
            const searchRegion = lodash.find(props.vectorMapProps.props, ({ states }) => states.includes(targetState));
            if (searchRegion) { element.applySettings({ color: searchRegion.color }); }
        });
    };

    const customizeText = (parameter) => {
        const region = lodash.get(props.vectorMapProps, `props[${parameter.index}]`, { label: '' });
        return region.label;
    };
    return (
        <React.Fragment>
            <div className="mapsHeading"><div className="row">
                <div className="col-lg-3 col-xl-3 col-md-4 col-sm-12 col-xs-12"><h4>Channel Partner</h4></div>
                <div className="d-flex justify-content-end col-lg-9 col-xl-9 col-md-8 col-sm-12 col-xs-12">
                    <button className="btn btn-sm btn-primary ps-3 pe-3 me-2" onClick={reset}>
                        Reset Map
                    </button>
                    <button className="btn btn-sm btn-primary ps-3 pe-3 me-0" onClick={() => props.toggleVectorMap(false)}>
                        Back
                    </button>
                </div>
            </div></div>

            <div className="mt-3 mb-3 bg-transparent rounded"><div className="row">
                <div className="col-lg-7 m-auto">
                    <VectorMap
                        ref={vectorMapRef}
                        id="vector-map"
                        onClick={markerClick}
                        bounds={state.bounds}
                        zoomingEnabled={false}
                        zoomFactor={state.zoomFactor}
                        center={state.center}
                    >
                        <ControlBar zoomVisible={false} panVisible={false} />
                        <Layer
                            dataSource={geoCoordinates}
                            customize={customizeLayer}
                            name="areas"
                            colorGroupingField="color"
                            colorGroups={props.vectorMapProps.colorGroups}
                            hoverEnabled={true}
                        ></Layer>
                        <Tooltip enabled={true} customizeTooltip={handleTooltip} />
                        <Legend
                            customizeText={customizeText}
                            markerShape="circle"
                            markerColor="#ff0000"
                        >
                            <Source layer="areas" grouping="color"></Source>
                        </Legend>
                    </VectorMap>
                </div></div></div>
        </React.Fragment>
    )
});
