import React, { useState, memo, useReducer } from "react";
import store from "store";
import lodash from "lodash";
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
import { json, useNavigate } from "react-router-dom";
import { MountCondition } from "../../module/mount-components";
import { checkPermissions } from '../../api/organization-users.api';

import ROUTES from "../routes";

export const tokenVerification = (userToken) => {
    const decodedToken = jwtDecode(userToken);
    const timeStamp = Date.now() / 1000;

    return timeStamp < decodedToken.exp;
}
export const useAuthentication = () => {
    const userSession = lodash.defaultTo(store.get('session'), {});
    const userToken = userSession.token;
    if (lodash.isEmpty(userSession) || lodash.isNil(userToken)) { return false; }

    return tokenVerification(userToken);
}

export const userOrganizationPermission = () => {
    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});
    return userDetails.userRole === "ORGANIZATION";
}

export const usePermissions = (permissionName) => new Promise(async (resolve) => {
    checkPermissions({ searchKeywords: [permissionName] })
        .then(({ data: userPermissions }) => { resolve({ status: true, userPermissions }); })
        .catch(({ message }) => { resolve({ status: false, error: message }); });
});

export const authentication = async (props, route) => {
    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    if (lodash.isEmpty(userSession)) { throw json({}, { status: 401 }); }

    if (lodash.has(route, 'permission') === false) { return json({ haveAccess: true, redirect: ROUTES.ERRORS.UNAUTHORIZED }); }
    if (userDetails.userRole === "ORGANIZATION") { return json({ haveAccess: true, redirect: ROUTES.ERRORS.UNAUTHORIZED }); }

    const permissionName = route?.permission?.NAME;
    const permissionResponse = await checkPermissions({ searchKeywords: [permissionName] });
    const userPermissions = lodash.get(permissionResponse, 'data.data', []);
    const searchPermission = lodash.find(userPermissions, ({ name }) => permissionName === name);

    const haveAccess = searchPermission?.permission || false;

    return json({ haveAccess, redirect: ROUTES.ERRORS.UNAUTHORIZED });
}

export default memo(({ children, permission }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const initialState = { authorized: true };
    const accessReducer = (state, action) => {
        switch (action.type) {
            case 'GRANT_ACCESS': return { authorized: true };
            case 'REVOKE_ACCESS': return { authorized: false };
            default: return { authorized: false };
        }
    };

    const [state, dispatch] = useReducer(accessReducer, initialState);

    const checkPermission = async () => {
        try {

            const storedUser = localStorage.getItem("authUser");
            const userSession = storedUser ? JSON.parse(storedUser) : {};
            const userDetails = lodash.get(userSession, 'data', {});

            if (lodash.isEmpty(userSession)) { return navigate('/login'); }
            if (userDetails.userRole === "ORGANIZATION" || !permission) { return dispatch({ type: 'GRANT_ACCESS' }); }

            const permissionName = permission?.NAME;
            const userPermissions = await checkPermissions({ searchKeywords: [permissionName] });
            const searchPermission = lodash.find(userPermissions.data.data, ({ name }) => permissionName === name) ?? { permission: false };

            if (searchPermission.permission === false) throw new Error("Something");

            if (searchPermission.permission === true) { return dispatch({ type: 'GRANT_ACCESS' }); }

        } catch (error) {
            toast.error("Sorry! You do not have permission to access this page");
            navigate('/dashboard')
        } finally { setLoading(false); }
    }

    // useEffect(() => {
    //     dispatch({ type: 'REVOKE_ACCESS' }); checkPermission(); return () => null;
    // }, [permission]);


    return (
        <React.Fragment>
            <MountCondition condition={loading}>
                <MountCondition.True>
                    <div className="fullscreen-loader"><div className="loader-container">
                        <div className="loader"></div>
                    </div></div>
                </MountCondition.True>
                <MountCondition.False>
                    {state.authorized && children}
                </MountCondition.False>
            </MountCondition>
        </React.Fragment>
    );
});
