import React, { useState, useCallback } from "react";
import classnames from "classnames";

import { TabContent, TabPane, NavLink } from "reactstrap";
import { NavItem, Nav, Card, CardBody } from "reactstrap";

import PERMISSIONS from "../routes/permissions";

const Root = React.memo(({ children, defaultActive }) => {
    
    const [tab, toggleTab] = useState(defaultActive);
    const handleTab = useCallback((tabId) => { toggleTab(tabId); });
    
    const [NETWORK_MANAGER_TAB, NETWORK_ENGAGE_TAB,CATALOG_MANAGER,LOYALTY_MANAGER_TAB] = React.Children.toArray(children);

    return (
        <div className="netTab">
            <Card>
                <CardBody className="cardbody">
                    <Nav pills>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: tab === PERMISSIONS.NETWORK_MANAGER.NAME })}
                                onClick={() => { handleTab(PERMISSIONS.NETWORK_MANAGER.NAME) }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Network Manager</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: tab === PERMISSIONS.NETWORK_ENGAGE.NAME })}
                                onClick={() => { handleTab(PERMISSIONS.NETWORK_ENGAGE.NAME) }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Network Engage</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: tab === PERMISSIONS.CATALOG_MANAGER.NAME })}
                                onClick={() => { handleTab(PERMISSIONS.CATALOG_MANAGER.NAME) }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope"></i>
                                </span>
                                <span className="d-none d-sm-block">Catalog Manager</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: tab === PERMISSIONS.LOYALTY_MANAGER.NAME })}
                                onClick={() => { handleTab(PERMISSIONS.LOYALTY_MANAGER.NAME) }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-star"></i>
                                </span>
                                <span className="d-none d-sm-block">Loyalty Manager</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={tab} className="p-3 text-muted">
                        <TabPane tabId={PERMISSIONS.NETWORK_MANAGER.NAME}>{NETWORK_MANAGER_TAB}</TabPane>
                        <TabPane tabId={PERMISSIONS.NETWORK_ENGAGE.NAME}>{NETWORK_ENGAGE_TAB}</TabPane>
                        <TabPane tabId={PERMISSIONS.CATALOG_MANAGER.NAME}>{CATALOG_MANAGER}</TabPane>
                        <TabPane tabId={PERMISSIONS.LOYALTY_MANAGER.NAME}>{LOYALTY_MANAGER_TAB}</TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </div>
    );
});

Root.NetworkManagerTab = React.memo(({ children }) => children);
Root.NetworkEngageTab  = React.memo(({ children }) => children);
Root.CatalogManagerTab = React.memo(({ children }) => children);
Root.LoyaltyManagerTab = React.memo(({ children }) => children);

export default Root;