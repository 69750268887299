import React, { useState, useEffect } from "react";
import lodash from "lodash";

import getNetworkChannelPartnerAPI from "../../../api/getNetworkChannelPartnerAPI";
import GoogleMap from "../../../module/google-map-components";
import { MountCondition } from "../../../module/mount-components";
import { errorMessage } from "../../../module/error-methods";

export default (props) => {
    document.title = "Google Map | Giffy Admin"

    const [loading, setLoading] = useState(true);
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

    const [googleMapMarks, setGoogleMapMarks] = useState([]);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });

    const getChannelPartners = async () => {
        try {

            const { data } = await getNetworkChannelPartnerAPI();

            const primaryLocation = lodash.get(data, `[0]['networkLocation']`, {});
            const channelPartnersLocation = lodash.map(data, ({ networkLocation: location }, index) => ({
                key: ++index,
                title: location.location,
                position: { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) },
            }));

            setGoogleMapMarks([...channelPartnersLocation])

            const latitude = parseFloat(primaryLocation.latitude);
            const longitude = parseFloat(primaryLocation.longitude);

            if (lodash.isNumber(latitude) && lodash.isNumber(longitude)) {
                return setCenter({ lat: latitude, lng: longitude });
            }

        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) })
        } finally { setLoading(false); }
    };

    useEffect(() => { getChannelPartners(); return () => null; }, []);

    return (
        <React.Fragment>
            <MountCondition condition={loading}>
                <MountCondition.True>
                    <div className="fullscreen-loader"><div className="loader-container">
                        <div className="loader"></div>
                    </div></div>
                </MountCondition.True>
            </MountCondition>
            <div className="mapsHeading"><div className="row">
                <div className="col-lg-3 col-xl-3 col-md-4 col-sm-12 col-xs-12"><h5>Channel Partner</h5></div>
                <div className="d-flex justify-content-end col-lg-9 col-xl-9 col-md-8 col-sm-12 col-xs-12">
                    <button className="btn btn-sm btn-primary ps-3 pe-3" onClick={() => props.setHide(true)}>Back</button>
                </div>
            </div></div>

            <div className="mt-3 mb-3"><div className="row"><div className="col-lg-12">
                <GoogleMap marks={[...googleMapMarks]} center={center}></GoogleMap>
            </div></div></div>
        </React.Fragment>
    )
};
