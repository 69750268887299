import React from "react";

import { LevelContainer, LevelIndex, LevelLink, ProgressCircle } from "../../module/start-guide-components";
import { getColor, useApiData, useGuideHook } from "../../module/start-guide-methods";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";
import MountComponent from "../../module/mount-components";

export default React.memo(() => {
    const { NAME, CHILDREN: { GEOGRAPHY, LOCATION, SALESMEN, CHANNEL_PARTNER } } = PERMISSIONS.NETWORK_MANAGER;
    const totalModules = {
        [GEOGRAPHY.NAME]: { current: GEOGRAPHY.NAME, previous: null },
        [LOCATION.NAME]: { current: LOCATION.NAME, previous: GEOGRAPHY.NAME },
        [SALESMEN.NAME]: { current: SALESMEN.NAME, previous: LOCATION.NAME },
        [CHANNEL_PARTNER.NAME]: { current: CHANNEL_PARTNER.NAME, previous: SALESMEN.NAME },
    };

    const { array: storedModules } = useApiData({ module: NAME });
    const { count, render } = useGuideHook(storedModules, totalModules);

    return (
        <MountComponent condition={render}>
            <div className="guide"><div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="guideContent">
                        <h4>Quick Start Guide</h4>
                    </div>
                    <LevelContainer source={GEOGRAPHY.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[GEOGRAPHY.NAME])}><span>1</span></LevelIndex>
                        <LevelLink to={ROUTES.NETWORK_MANAGER.CHILDREN.GEOGRAPHY}>Geography</LevelLink>
                    </LevelContainer>
                    <LevelContainer source={LOCATION.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[LOCATION.NAME])}>2</LevelIndex>
                        <LevelLink to={ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION}>Location</LevelLink>
                    </LevelContainer>
                    <LevelContainer source={SALESMEN.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[SALESMEN.NAME])}>3</LevelIndex>
                        <LevelLink to={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN}>Salesmen</LevelLink>
                    </LevelContainer>
                    <LevelContainer source={CHANNEL_PARTNER.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[CHANNEL_PARTNER.NAME])}>4</LevelIndex>
                        <LevelLink to={ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER}>Channel Partner</LevelLink>
                    </LevelContainer>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto">
                    <ProgressCircle count={count}></ProgressCircle>
                </div>
            </div></div>
        </MountComponent>
    )
});