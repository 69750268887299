import React from "react";

import MountComponent from '../../../module/mount-components';
import RightSideProfile from "../../Dashboard/right-side-profile";
import Source from "./source";

export default () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  document.title = " Report | Giffy Network";
  const showGirl = false;

    return (
      <React.Fragment>
        <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12 col-md-12"><div className="profile">
                <Source></Source>
            </div></div>
            <div className="col-lg-3 col-md-12">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
        </div></div></div>
      </React.Fragment>
  ); };
