import React from "react";
import Countdown from 'react-countdown';

export const LoginWithContactNumber = React.memo(() => {
    return (<React.Fragment></React.Fragment>)
});

export const LoginWithContactEmail = React.memo(() => {
    return (<React.Fragment></React.Fragment>)
});

export const CountdownComponent = React.memo(({ countdownState, handleCountdownComplete }) => {
    const countdownRenderer = ({ seconds, completed }) => {
        if (completed) return (<span></span>);
        else return(<span>Resend OTP in {seconds}</span>);
    };
    return (
        <p className="btn btn-primary w-100 timer waves-effect waves-light createButton">
            <Countdown key={countdownState.key} date={countdownState.time} onComplete={handleCountdownComplete} renderer={countdownRenderer} />
        </p>
    );
});
