import React from "react";
import lodash from "lodash";

import { MDBDataTable } from "mdbreact";
import { Alert, Label, Modal } from "reactstrap";

import { MountCondition } from "../../../../module/mount-components";
import config from "../../../../config/config";

export default React.memo((props) => {
    const { handleUploadModal, handleUploadSubmit } = props;
    const { importModal, xlsxData, xlsxDataDataTable } = props;
    const { getRootProps, getInputProps } = props;
    const { xlsxNotificationState, toggleXLSXNotificationState } = props;

    const downloadURL = `${config.api.base_url}network-salesmen/5d63eceb14d7/download`;

    return (
        <React.Fragment>

            <Modal size="xl" isOpen={importModal} toggle={() => { handleUploadModal() }}>
                <button className="close" onClick={() => { handleUploadModal() }} ></button>
                <div className="modal-body"><div className="mt-4"><div className="mb-0 text-center">
                    <Alert color={xlsxNotificationState.color} isOpen={xlsxNotificationState.visible} toggle={() => toggleXLSXNotificationState({ ...xlsxNotificationState, visible: false })} >
                        {xlsxNotificationState.message}
                    </Alert>
                    <div className="saveBtn">
                        <a className="btn btn-group mt-0 mb-2" href={downloadURL}>Download CSV</a>
                    </div>
                    <Label className="form-label mt-2 mb-3" htmlFor="formrow-firstname-input">
                        This CSV Contains header values in the first column
                    </Label>

                    <MountCondition condition={lodash.isEmpty(xlsxData)}>
                        <MountCondition.True>
                            <div {...getRootProps()} className="dropzone"><div className="dz-message needsclick">
                                <input {...getInputProps()} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                <div className="">
                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                </div>
                                <div className="d-content">
                                    <h4>Drop file here or click to upload</h4>
                                    <span>XLSX (Max: 5MB)</span>
                                </div>
                            </div></div>
                        </MountCondition.True>
                        <MountCondition.False>
                            <div className="dataTable-hide-extra-props mt-3">
                                <MDBDataTable responsive bordered data={xlsxDataDataTable} searchLabel={false} footer={false} />
                            </div>
                        </MountCondition.False>
                    </MountCondition>
                </div></div></div>

                <div className="modal-footer">
                    <button type="button" className="cancelModal btn" onClick={() => { handleUploadModal() }}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={() => { handleUploadSubmit() }}>Upload</button>
                </div>
            </Modal>
        </React.Fragment>
    )
})