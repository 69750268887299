import React, { useEffect, useState, useRef } from "react"
import * as Yup from "yup";
import lodash from "lodash";
import store from "store";

import { useFormik } from "formik";
import { Link } from "react-router-dom";

import classnames from "classnames";

import ReactSelect from 'react-select';
import { storeOrganizationModule } from "../../../../api/organizationModuleApi";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Alert, Collapse, Nav, NavItem, NavLink, TabContent, TabPane, Form } from "reactstrap";

import Select from "./search-select";

import Question from "../../../../assets/images/icon/question.png";
import Zoom from "../../../../assets/images/zoom.png";
import ZoneTable from "../../../Geography/zoneTable";
import './style.css'

import { apiDivisions, apiGeographyLevels, googleMapKey } from "../../../Tree/api";
import { apiCities, apiPinCodes, apiPositions, apiTree, apiStates, apiStoreNode, apiDeleteNode } from "../../../Tree/api";
import { generateRandomString, hexToRGBA, searchNode, selectOptions, selectPinCodesOptions, treeNode, colors, geographyStructure, excludeStarOptions } from "../../../Tree/helper";
import { checkPermissions } from "../../../../api/organization-users.api";

import { MountCondition } from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";
import PERMISSIONS from "../../../../routes/permissions";

// @util-components ----------------------------------------------------------------------------------------------------

const Mount = React.memo(({ condition, children }) => {
    if (condition === true) { return children }
    return (<React.Fragment></React.Fragment>)
});

const List = ({ children, array }) => {
    return (
        <React.Fragment>
            {lodash.map(array, (data, index) => (<React.Fragment key={index}>{React.cloneElement(children, { ...data })}</React.Fragment>))}
        </React.Fragment>
    );
};

const DynamicAccordion = (props) => {
    return (
        <React.Fragment>
            <List array={props.levels}>
                <AccordionItem toggleLoader={props.toggleLoader} initialCall={props.initialCall} geography={props.geography} geographyId={props.geographyId} deleteNode={props.deleteNode} updateNode={props.updateNode} storeNode={props.storeNode}></AccordionItem>
            </List>
        </React.Fragment>
    )
};

// ----------------------------------------------------------------------------------------------------------------

const LevelForm = (props) => {

    const { NAME, CHILDREN: { GEOGRAPHY } } = PERMISSIONS.NETWORK_MANAGER;

    const getStates = async () => {
        props.toggleLoader(true);
        const response = await apiStates();
        updateStates([...selectOptions(response, 'state')]);
        props.toggleLoader(false);
    };

    const getCities = async ({ states }) => {
        props.toggleLoader(true);
        const response = await apiCities(states);
        updateCities([...selectOptions(response, 'province')]);
        props.toggleLoader(false);
    }

    const getPinCodes = async ({ states, cities }) => {
        props.toggleLoader(true);
        const response = await apiPinCodes(excludeStarOptions(states), excludeStarOptions(cities));
        updatePinCodes([...selectPinCodesOptions(response, 'zipcode')]);
        props.toggleLoader(false);
    }

    const getPositions = async ({ pinCodes }) => {
        if (lodash.size(pinCodes) > 4) { props.toggleLoader(true) }

        const positionPromises = lodash.map(excludeStarOptions(pinCodes), (pinCode) => apiPositions(pinCode));
        const positionResponses = await Promise.all(positionPromises);
        const nonEmptyPositions = lodash.filter(positionResponses, (positionDetails) => !lodash.isEmpty(positionDetails));
        const positionsByPinCode = lodash.keyBy(nonEmptyPositions, 'pinCode');

        validation.setFieldValue('positions', { ...lodash.mapValues(positionsByPinCode, (details) => lodash.omit(details, 'pinCode')) });
        props.toggleLoader(false)
    }

    const defaultData = { states: [...props.states], cities: [...props.cities], pinCodes: [...props.pinCodes], positions: {} }
    const [states, updateStates] = useState(props.initialCall ? [] : props.optionStates)
    const [cities, updateCities] = useState(props.initialCall ? [] : props.optionCities)
    const [pinCodes, updatePinCodes] = useState(props.initialCall ? [] : props.optionPinCodes)
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

    const yupSchema = Yup.object().shape({
        states: Yup.array().of(Yup.object().shape({
            label: Yup.string().required('Please select State'),
            value: Yup.string().required('Please select State'),
        })).min(1, 'Please select at least one state').required('Please select at least one state'),

        cities: Yup.array().of(Yup.object().shape({
            label: Yup.string().required('Please select City'),
            value: Yup.string().required('Please select City'),
        })).min(1, 'Please select at least one city').required('Please select at least one city'),

        pinCodes: Yup.array().of(Yup.object().shape({
            label: Yup.string().required('Please select Pin Code'),
            value: Yup.string().required('Please select Pin Code'),
        })).min(1, 'Please select at least one pin code').required('Please select at least one pin code'),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: defaultData,
        validationSchema: yupSchema,
        onSubmit: async (data) => {

            const payload = { divisionId: props.divisionId, parent: props.parent, id: props.id ?? null }

            payload.states = lodash.map(excludeStarOptions(data.states), ({ id, value: state }) => ({ id, state }));
            payload.cities = lodash.map(excludeStarOptions(data.cities), ({ id, value: city }) => ({ id, city }));
            payload.pinCodes = lodash.map(lodash.filter(excludeStarOptions(data.pinCodes), ({ value }) => {
                return !lodash.isUndefined(data.positions[value])
            }), ({ id, value: pinCode, place }) => {
                return { ...data.positions[pinCode], id, pinCode, place }
            });

            payload.name = props.levelName;
            payload.color = lodash.invert(colors)[props.color];
            payload.geographyLevelId = (({ geographyId, geography }) => {
                if (lodash.isNull(geographyId)) return geographyId;
                return geography[geographyId]['id']
            })(props);

            if (lodash.isEmpty(payload.pinCodes)) { return toggleNotificationState({ visible: true, color: 'danger', message: 'Something went wrong.' }) }

            const response = await apiStoreNode(payload);
            await storeOrganizationModule({ module: NAME, moduleLevel: GEOGRAPHY.NAME });

            if (response.status === true) { return props.updateNode(props.node, data, response.data); }
        }
    });

    useEffect(() => { /** if (props.initialCall) */ if (true) { getStates(); } }, [true]);
    useEffect(() => { /** if (props.initialCall) */ if (true) { getCities(validation.values); } }, [validation.values.states]);
    useEffect(() => { /** if (props.initialCall) */ if (true) { getPinCodes(validation.values); } }, [validation.values.cities]);
    useEffect(() => { if (true) { getPositions(validation.values); } }, [validation.values.pinCodes]);

    return (
        <Form className="form-horizontal" onSubmit={(e) => { e.preventDefault(); return validation.handleSubmit(); }}>
            {/* <pre>{JSON.stringify(validation.errors, null, 2)}</pre>  */}
            <div className="accordion-body"><div className="row">
                <div className="col-md-12"><div className="mb-0">
                    <label className="control-label">Select State</label>
                    <Select hasError={validation.touched.states && validation.errors.states} error={validation.errors.states} value={validation.values.states} options={[...states]} name="states" label="States" updateValue={validation.setFieldValue} />
                </div></div>
                <div className="col-md-12"><div className="mb-0 mt-2">
                    <label className="control-label">Select City/District</label>
                    <Select hasError={validation.touched.cities && validation.errors.cities} error={validation.errors.cities} value={validation.values.cities} options={[...cities]} name="cities" label="Cities" updateValue={validation.setFieldValue} />
                </div></div>
                <div className="col-md-12"><div className="mb-0 mt-2">
                    <label className="control-label">Select Pin Codes</label>
                    <Select hasError={validation.touched.pinCodes && validation.errors.pinCodes} error={validation.errors.pinCodes} value={validation.values.pinCodes} options={[...pinCodes]} name="pinCodes" label="PinCodes" updateValue={validation.setFieldValue} />
                </div></div>
                <div className="col-md-12"><div className="mb-0 mt-3 d-flex justify-content-between">
                    <Mount condition={props.initialCall === false}>
                        <p onClick={() => props.deleteNode(props.id)} type="button" className="fw-bold" style={{ color: 'red' }}>Delete</p>
                    </Mount>
                    <Mount condition={true}>
                        <p onClick={() => validation.handleSubmit()} type="button" className="fw-bold text-primary">Save</p>
                    </Mount>
                </div></div>
            </div></div>
        </Form>
    );
};

const AccordionItem = (props) => {
    const [accordionStatus, toggleAccordion] = useState(false);
    const levelReference = useRef(null);
    const geographyId = props.geographyId ?? 'initial-node'
    const geographyDetails = props.geography[geographyId] ?? {}
    const passGeographyId = props.initialCall === true ? 'initial-node' : geographyDetails.id
    const childGeography = props.geography[passGeographyId] ?? {}
    const defaultValue = props.initialCall === true ? props.name : geographyDetails.levelName

    const manageNode = () => {

        if (lodash.isNull(props.id)) { return alert('Please fill the details!'); /** toast.error('Please fill the details!', { autoClose: 900 }); */ }

        toggleAccordion(false); props.storeNode(props.node, generateRandomString())
    }

    return (
        <div className={`accordion-item border-0 ${true ? 'open' : ''}`}>
            <div className="accordion-header">
                <div className="button-container" style={{ backgroundColor: props.color }} >
                    <div className="accordion-title">{defaultValue}</div>
                    <button className="dynamic-accordion-button" style={{ backgroundColor: props.color }} onClick={() => { toggleAccordion(!accordionStatus) }}></button>
                </div>
                <Mount condition={!lodash.isEmpty(childGeography)}>
                    <div className="button-container">
                        <button className="dynamic-accordion-button" style={{ backgroundColor: props.color }} onClick={() => { manageNode() }}>
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </Mount>
            </div>

            <Mount condition={false}>
                <pre>{JSON.stringify(geographyDetails, null, 2)}</pre>
                <pre>{JSON.stringify(geographyId, null, 2)}</pre>
            </Mount>

            <Mount condition={accordionStatus}>
                <Collapse className="dynamic-accordion-collapse" style={{ backgroundColor: hexToRGBA(props.color, '0.2') }} isOpen={accordionStatus}>
                    <LevelForm {...props} toggleLoader={props.toggleLoader} levelName={defaultValue}></LevelForm>
                </Collapse>
            </Mount>

            <Mount condition={!lodash.isEmpty(props.nodes)}>
                <div className="accordion-content">
                    <DynamicAccordion toggleLoader={props.toggleLoader} initialCall={false} geography={props.geography} geographyId={passGeographyId} storeNode={props.storeNode} updateNode={props.updateNode} deleteNode={props.deleteNode} levels={props.nodes}></DynamicAccordion>
                </div>
            </Mount>
        </div>
    );
};

export default React.memo((props) => {

    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const { CHILDREN: { GEOGRAPHY } } = PERMISSIONS.NETWORK_MANAGER;
    const [userPermissions, setUserPermissions] = useState({
        GEOGRAPHY_MAP: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    const [activeTab, switchTab] = useState("map-tab");
    const [showLoader, toggleLoader] = useState(false);
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

    const [divisions, updateDivisions] = React.useState([]);
    const [divisionId, updateDivisionId] = React.useState(null);

    const [tree, updateTree] = useState({ node: 'root-node', nodes: [] });
    const [markers, updateMarkers] = useState([])
    const center = { lat: 24.5826, lng: 73.7191 };
    const [geography, updateGeography] = useState({});
    const [isGeographyEmpty, updateGeographyStatus] = useState(false);

    /** @apis ------------------------------------------------------------------------------------------------------------------------- */

    const getDivisions = async () => {
        const response = await apiDivisions();
        updateDivisions(lodash.map(response, (division) => {
            return {
                value: division.id, label: division.name,
            }
        }));
    };

    const initialCall = async () => {

        if (lodash.isNull(divisionId)) { return true; }

        const response = await apiTree({ divisionId: divisionId.value });
        const geographyLevels = await apiGeographyLevels({ divisionId: divisionId.value });

        const initialValues = lodash.map(response.data ?? [], (data) => treeNode(data));

        if (lodash.isEmpty(geographyLevels) || lodash.isEmpty(response.data)) {
            updateTree({ node: 'root-node', nodes: [] });
            updateMarkers([]); updateGeography({});

            return updateGeographyStatus(true);
        }

        else { updateGeographyStatus(false); updateTree({ node: 'root-node', nodes: [...initialValues] }); }

        const extractDeepPinCodes = (node) => {
            const pinCodes = lodash.get(node, 'pinCodes', []);
            const nestedPinCodes = lodash.flatMap(node.nodes, extractDeepPinCodes);
            return lodash.concat(pinCodes, nestedPinCodes)
        };

        const deepPinCodes = lodash.concat(...lodash.map(response.data, (node) => extractDeepPinCodes(node)));
        const apiPositions = lodash.map(deepPinCodes, ({ lat, lng, pinCode, id }) => ({
            id: id, position: { lat: parseFloat(lat), lng: parseFloat(lng) }, title: pinCode
        }));

        updateMarkers(apiPositions); updateGeography(geographyStructure(geographyLevels));
    }

    const getPermissions = async () => {
        const modulePermissions = GEOGRAPHY.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);
    useEffect(() => { getDivisions(); }, [true]);
    useEffect(() => { initialCall(); }, [divisionId]);
    useEffect(() => {
        if (notificationState.visible) {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
        }
    }, [notificationState.visible]);

    /** ---------------------------------------------------------------------------------------------------------------------------------- */

    const storeNode = (parent, node) => {
        const treeClone = lodash.cloneDeep(tree);
        const response = searchNode(parent, treeClone);

        if (!lodash.isEmpty(response)) {
            const { id, color, divisionId } = response;
            const { states: optionStates, cities: optionCities, pinCodes: optionPinCodes } = response;

            response.nodes.push(treeNode({
                divisionId, parent: id, name: node, color: color,
                optionStates: optionStates.map((object) => { object.id = null; return object; }),
                optionCities: optionCities.map((object) => { object.id = null; return object; }),
                optionPinCodes: optionPinCodes.map((object) => { object.id = null; return object; })
            }));
        }

        return updateTree(lodash.cloneDeep(treeClone));
    }

    const updateNode = async (parent, data, apiResponse = {}) => { return await initialCall(); }
    const deleteNode = async (id) => {
        if (!lodash.isNull(id)) { await apiDeleteNode(id); }
        return await initialCall();
    }

    return (
        <React.Fragment>
            <Mount condition={showLoader === true}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </Mount>
            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <section className="row">
                
                <MountCondition condition={userPermissions.GEOGRAPHY_MAP}>
                    <MountCondition.True>
                        <div className="col-md-7"><div className="row">
                            <div className="col-md-6"><h5>Region <img src={Question} /></h5></div>
                            <div className="col-md-6">
                                <ReactSelect classNamePrefix="select2-selection" placeholder="Select Division" value={divisionId} options={[...divisions]} onChange={(props) => { updateDivisionId(props); }} /><br />
                            </div>
                            <Mount condition={isGeographyEmpty}>
                                <div className="col-md-12 text-center p-3 mt-4">
                                    <p className="fw-bold fs-5">Geography isn't created at!</p>
                                </div>
                            </Mount>
                            <div className="col-md-12">
                                <DynamicAccordion initialCall={true} geography={geography} geographyId={null} storeNode={storeNode} updateNode={updateNode} deleteNode={deleteNode} levels={tree.nodes} toggleLoader={toggleLoader}></DynamicAccordion>
                            </div>
                        </div></div>

                        {divisionId && <div className="col-md-5"><div className="mapSection">
                            <Nav pills>
                                <NavItem>
                                    <NavLink onClick={() => { switchTab("map-tab"); }} style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "map-tab" })}>
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Maps</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => { switchTab("table-tab"); }} style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "table-tab" })}>
                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                        <span className="d-none d-sm-block">Detail</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId="map-tab">
                                    <div className="maps"><div id="chicago" className="vector-map-height" style={{ height: '450px' }}>
                                        <LoadScript googleMapsApiKey={googleMapKey}>
                                            <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={center} zoom={5}>
                                                <List array={markers}><Marker /></List>
                                            </GoogleMap>
                                        </LoadScript>
                                    </div></div>
                                </TabPane>
                                <TabPane tabId="table-tab">
                                    <div className="formTable">
                                        <ZoneTable />
                                        <button className="btn btn-group zoom" onClick={() => { console.log("table"); }}>
                                            <img src={Zoom} />
                                        </button>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div></div>}
                    </MountCondition.True>
                    <MountCondition.False>
                        <div className="col-md-12 text-center mt-3 mb-5">
                            <h3 className="py-5"><span className="text-secondary">You do not have permission!</span></h3>
                        </div>
                    </MountCondition.False>
                </MountCondition>

                <div className="col-md-12 text-center mt-3">
                    <button className="btn btn-primary me-2" onClick={() => { props.switchScreen('single-level'); }} >Previous</button>
                    <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION} className="btn btn-group btn-primary">Next</Link>
                </div>

            </section>
        </React.Fragment>
    );
});
