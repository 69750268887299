
import axios from 'axios'
const config = {
    api: {
      // base_url: "https://app.giffy.network/api/",
      // base_url: "http://192.168.0.126:4000/development/",
      base_url: "https://app.giffy.network/development/",
      working_directory : 'public/documents'
    },   
  };  

  export const server = axios.create({
    baseURL: config.api.base_url,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

const authUser = JSON.parse(localStorage.getItem("authUser"));

if (authUser) {
  server.defaults.headers.common['Authorization'] = `${authUser.token}`;
}

  export default config;
  