import { server } from '../config/config'

export const storeVariantAPI = (payload = {}) => server({
    method: 'post',
    url: `variants`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const getVariantsAPI = (queryParams = {}) => server({
    method: 'get',
    url: `variants`,
    params: { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getVariantAPI = (id) => server({
    method: 'get',
    url: `variants/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateVariantAPI = (id, payload = {}) => server({
    method: 'put',
    url: `variants/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteVariantAPI = (id) => server({
    method: 'delete',
    url: `variants/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
