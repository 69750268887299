import React, { useCallback, useEffect, useState } from "react";
import store from "store";
import lodash from "lodash";

import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { Alert, Col, Form, Label, Input, FormFeedback } from "reactstrap";
import Dropzone from "react-dropzone";
import Select from "react-select";
import MountComponent, { MountCondition } from "../../../../module/mount-components.jsx";

import organizationDetailsAPI from "../../../../api/organizationDetailsAPI.js";
import getOrganizationDetailsAPI from "../../../../api/getOrganizationDetailsAPI.js";

import { getStatesListAPI, getCitiesListAPI, getPinCodesListAPI } from "../../../../api/location.api.js";
import { storeOrganizationModule } from "../../../../api/organizationModuleApi.js";
import { checkPermissions } from "../../../../api/organization-users.api.js";

import config from "../../../../config/config.js";

import PERMISSIONS from "../../../../routes/permissions.js";
import ROUTES from "../../../../routes/routes.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const cardValidation = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;
const gstValidation =
  /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/;
const name = RegExp(/^[A-Za-z]+$/);

const years = [];
const currentYear = new Date().getFullYear();
for (let year = 1800; year <= currentYear; year++) {
  years.push(year);
}

const industries = [
  "Appliances",
  "Automobile",
  "Beauty",
  "Books",
  "Clothing & Accessories",
  "Electronics",
  "FMCG",
  "Footwear",
  "Furniture",
  "Garden & Outdoors",
  "Grocery & Gourmet Foods",
  "Health & Perosnal Care",
  "Home & Kitchen",
  "Kids",
  "Luggage & bags",
  "Retail",
  "Sports",
  "Fitness & Outdoors",
  "Chemicals",
];
const industriesOptions = industries.map((option, index) => ({
  label: option,
  value: option,
}));

const typeOfBusiness = [
  "Private Limited",
  "Public Limited",
  "Partnership",
  "Corporation",
  "LLP",
  "Other",
];
const typeOfBusinessOptions = typeOfBusiness.map((option, index) => ({
  label: option,
  value: ++index,
}));

const styleReactSelect = (baseStyles, state, error) => ({
  ...baseStyles,
  borderColor: error ? "#f46a6a" : "#ced4da",
  "&:hover": {
    boxShadow: error ? "0 0 0 1px #dc3545" : "0 0 0 1px #ced4da",
    outlineColor: error ? "#dc3545" : "#ced4da",
    borderColor: error ? "#f46a6a" : "#ced4da",
    borderWidth: "1px",
  },
});

const themeReactSelect = (theme, error) => ({
  ...theme,
  boxShadow: "none",
  border: "solid 0.1px",
  outline: "none",
  borderWidth: "0.1px",
  colors: { ...theme.colors, primary: error ? "#dc3545" : "#99a7d9" },
});

const organizationValidationSchema = {
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Please Enter Organization Name"),

  companyWebsite: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .url("Invalid URL format")
    .required("Please Enter Website"),

  street: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please Enter Website"),

  officeApartmentNumber: Yup.string()
    .required("Please enter your apartment number")
    .matches(/^\d{1,4}(?:[-\s]\d{4})?$/, "Apartment Number is invalid")
    .test(
      "length",
      "Apartment Number needs to be more than 1 digit",
      (val) => val?.length >= 1
    )
    .typeError("Please enter a valid Apartment Number"),

  pinCode: Yup.string()
    .required("Please enter your zip")
    .typeError("Please enter a valid zip code"),

  state: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Please select State"),

  city: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Please select City."),

  typeOfBusiness: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Please select Business Type."),

  yearOfIncorporation: Yup.number()
    .required("Year is required")
    .integer("Year must be a whole number")
    .min(1800, "Year must be greater than or equal to 1800")
    .max(2024, "Year cannot exceed the current year"),

  industry: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Please Enter Industry "),

  firstNameOwner: Yup.string()
    .matches(name, "Only characters are allowed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please Enter First Name"),

  lastNameOwner: Yup.string()
    .matches(name, "Only characters are allowed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please Enter Last Name"),

  panNumber: Yup.string()
    .min(10, "Pan Number must be 10 characters long")
    .matches(cardValidation, "Invalid pan card number")
    .test(
      "length",
      "Pan Card Number needs to be exactly 10 digits",
      (val) => val?.length === 10
    ),

  gstInNumber: Yup.string()
    .min(15, "GST number must be 15 characters long")
    .matches(gstValidation, "Invalid GST number")
    .test(
      "length",
      "GST number needs to be exactly 15 digits",
      (val) => val?.length == 15
    )
    .typeError("Please enter a valid GST Number"),

  uploadLogo: Yup.mixed()
    .required("Logo image is required")
    .test("fileType", "Only image files are allowed", (value) => {
      if (lodash.isString(value) && lodash.isEmpty(value) === false) {
        return true;
      }
      return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
    }),

  gstCertificate: Yup.mixed()
    .required("GST Certificate is required")
    .test("fileType", "Only image files are allowed", (value) => {
      if (lodash.isString(value) && lodash.isEmpty(value) === false) {
        return true;
      }
      return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
    }),
};

const organizationInitialValues = {
  companyName: "",
  companyWebsite: "",
  street: "",
  officeApartmentNumber: "",
  pinCode: "",
  state: "",
  city: "",
  typeOfBusiness: null,
  yearOfIncorporation: "",
  industry: "",
  firstNameOwner: "",
  lastNameOwner: "",
  panNumber: "",
  gstInNumber: "",
  gstCertificate: "",
  uploadLogo: null,
  gstCertificate: null,
};

const errorMessage = (error) => {
  if (error.isAxiosError) {
    const apiError = lodash.get(error, "response.data.error");
    return apiError || "Failed to update organization details";
  }
  return "Failed to update organization details";
};

export default React.memo(() => {
  const userSession = store.get('session');
  const userDetails = lodash.get(userSession, 'data', {});

  const [selectedYear, setSelectedYear] = useState(null);
  const [saveClicked, setSaveClicked] = useState(false);

  const currentYear = new Date().getFullYear();
  const minYear = 1800;

  const handleYearChange = (date) => {
    setSelectedYear(date);
    validation.setFieldValue("yearOfIncorporation", date.getFullYear())
  };

  const userData = ((data) => {
    return lodash.isEmpty(data) ? {} : lodash.get(JSON.parse(data), "data");
  })(localStorage.getItem("authUser"));

  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

  const [permissions, setPermissions] = useState({
    ORGANIZATION_DETAILS_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
    ORGANIZATION_DETAILS_VIEW: userDetails.userRole === "ORGANIZATION" ? true : false,
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [pinOptions, setPinOptions] = useState([]);

  const navigate = useNavigate();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...organizationInitialValues },
    validationSchema: Yup.object({ ...organizationValidationSchema }),
    onSubmit: async (data) => {
      try {
        const {
          NAME,
          CHILDREN: { ORGANIZATION_DETAILS },
        } = PERMISSIONS.QUICK_START_GUIDE;

        setLoading(true);

        const gstCertificateCondition = (value, key) =>
          key === "gstCertificate" && lodash.isString(value);
        const uploadLogoCondition = (value, key) =>
          key === "uploadLogo" && lodash.isString(value);

        const result = lodash.omitBy(
          lodash.omitBy(data, gstCertificateCondition),
          uploadLogoCondition
        );
        const payload = lodash.omit(result, [
          "gstCertificatePreview",
          "uploadLogoPreview",
        ]);

        await organizationDetailsAPI(payload, userData.id);
        await storeOrganizationModule({
          module: NAME,
          moduleLevel: ORGANIZATION_DETAILS.NAME,
        });

        toggleNotificationState({ visible: true, color: 'success', message: "Organization Details Updated Successfully." });
        {saveClicked && setTimeout(() => {
          navigate(ROUTES.ORGANIZATION_DETAILS.CHILDREN.NETWORK_DETAILS);
        }, 2500);}

      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setTimeout(() => { setLoading(false); }, 1000); }
    },
  });

  /** @apis ----------------------------------------------------------------------------------------------------------------------- */

  const getPermissions = async () => {
    const updatePermission =
      PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ORGANIZATION_DETAILS.CHILDREN
        .ORGANIZATION_DETAILS_UPDATE;
    const viewPermission =
      PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ORGANIZATION_DETAILS.CHILDREN
        .ORGANIZATION_DETAILS_VIEW;

    await checkPermissions({
      searchKeywords: [updatePermission?.NAME, viewPermission?.NAME],
    })
      .then(({ data: userPermissions }) => {
        const response = lodash.mapValues(
          lodash.mapKeys(userPermissions.data, "name"),
          "permission"
        );
        return setPermissions({ ...permissions, ...response });
      })
      .catch(() => { });
  };

  const getOrganizationDetails = async () => {
    try {
      const { data: response } = await getOrganizationDetailsAPI(userData.id);

      const organizationProperties = lodash.keys(organizationInitialValues);
      const organizationDetails = lodash.pick(response.data, [
        ...organizationProperties,
      ]);

      if (organizationDetails.companyName) { validation.setValues({ ...organizationDetails }); }

    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: "error fetching organization details" });
    } finally { return setLoading(false); }
  };

  const getStateOptions = async () => {
    try {
      const { data: response } = await getStatesListAPI();
      const options = lodash.map(response.data, ({ state }) => ({
        value: state,
        label: state,
      }));

      setStateOptions([...options]);
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: "Oops! error fetching states" });
    } finally { return setLoading(false); }
  };

  const getCityDetails = async () => {
    try {
      const { state } = validation.values;

      if (lodash.isNil(state) || lodash.isEmpty(state)) {
        return setCityOptions([]);
      }

      const { data: response } = await getCitiesListAPI({ states: [state] });
      const options = response.data.map(({ province }) => ({
        value: province,
        label: province,
      }));

      setCityOptions([...options]);
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: "Oops! error fetching cities" });
    } finally { return setLoading(false); }
  };

  const getPinCodeDetails = async () => {
    try {
      const { state, city } = validation.values;

      if (lodash.some([state, city], (value) => lodash.isNil(value) || lodash.isEmpty(value))) {
        return setPinOptions([]);
      }

      const response = await getPinCodesListAPI({ states: [state], cities: [city] });
      const options = response.data.data.map((state) => ({
        value: state.place,
        label: `${state.zipcode} (${state.place})`,
      }));

      setPinOptions(options);
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: "Oops! error fetching pin codes" });
    } finally { return setLoading(false); }
  };

  /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

  useEffect(() => {
    setLoading(true); getOrganizationDetails(); getStateOptions();
  }, []);

  useEffect(() => {
    setLoading(true); getCityDetails();
  }, [validation.values.state]);

  useEffect(() => {
    setLoading(true); getPinCodeDetails(validation.values.state, validation.values.city);
  }, [validation.values.city]);

  useEffect(() => {
    if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); }
  }, []);

  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000); }
  }, [notificationState.visible]);

  /** @handlers ------------------------------------------------------------------------------------------------------------------ */

  const handleImageChange = useCallback(async (images, imageColumn, imagePreviewColumn, source = false) => {
    const file = images[0];
    const reader = await new FileReader();
    const image = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });

    validation.setFieldValue(imageColumn, file);
    validation.setFieldValue(imagePreviewColumn, image);
  });

  /** ---------------------------------------------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>
      <Alert color={notificationState.color} isOpen={notificationState.visible} >
        {notificationState.message}
      </Alert>

      {/* <div className="row">
            <div className="col-md-8">
                <pre> { JSON.stringify(validation.errors,null,2) } </pre><br /><br />
            </div>
            <div className="col-md-4">
                  <pre> { JSON.stringify(validation.values,null,2) } </pre><br /><br />
            </div>
        </div> */}

      <Form className="form-horizontal row" onSubmit={validation.handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="companyName">Company Name</Label>
          <Input
            type="text"
            name="companyName"
            placeholder="Enter Company Full Name"
            className="form-control"
            id="companyName"
            onChange={validation.handleChange}
            value={validation.values.companyName}
            invalid={validation.touched.companyName && validation.errors.companyName ? true : false}
          />
          <MountComponent condition={validation.touched.companyName && validation.errors.companyName}>
            <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
          </MountComponent>
        </div></div></div>

        <Col lg={6} md={6}>
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="companyWebsite">Company Website</Label>
              <Input
                type="text"
                name="companyWebsite"
                placeholder="Enter Company Website"
                className="form-control"
                id="companyWebsite"
                onChange={validation.handleChange}
                value={validation.values.companyWebsite || ""}
                invalid={validation.touched.companyWebsite && validation.errors.companyWebsite ? true : false}
              />
              {validation.touched.companyWebsite &&
                validation.errors.companyWebsite ? (
                <FormFeedback type="invalid">
                  {validation.errors.companyWebsite}
                </FormFeedback>
              ) : null}
            </div>
          </div>
        </Col>

        <Col lg={6} md={6}>
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="street">Street/Area</Label>
              <Input
                type="text"
                name="street"
                placeholder="Enter Street/Area"
                className="form-control"
                id="street"
                onChange={validation.handleChange}
                value={validation.values.street || ""}
                invalid={validation.touched.street && validation.errors.street ? true : false}
              />
              <MountComponent condition={validation.touched.street && validation.errors.street}>
                <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
              </MountComponent>
            </div>
          </div>
        </Col>

        <Col lg={6} md={6}>
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="officeApartmentNumber">Office Apartment Number</Label>
              <Input
                type="number"
                name="officeApartmentNumber"
                placeholder="Enter Apartment Number"
                onChange={validation.handleChange}
                value={validation.values.officeApartmentNumber || ""}
                invalid={validation.touched.officeApartmentNumber && validation.errors.officeApartmentNumber ? true : false}
              />
              <MountComponent condition={validation.touched.officeApartmentNumber && validation.errors.officeApartmentNumber}>
                <FormFeedback type="invalid">{validation.errors.officeApartmentNumber}</FormFeedback>
              </MountComponent>
            </div>
          </div>
        </Col>

        <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="validation.state">State</Label>
          <Select
            options={stateOptions}
            className=""
            name="state"
            isSearchable
            placeholder="Select State"
            onChange={({ value }) => {
              validation.setFieldValue("state", value);
            }}
            value={stateOptions.find(
              ({ value }) => value === validation.values.state
            )}
            theme={(theme) =>
              themeReactSelect(theme, validation.touched.state && validation.errors.state)
            }
            styles={{
              control: (baseStyles, state) =>
                styleReactSelect(
                  baseStyles,
                  state,
                  validation.touched.state && validation.errors.state
                ),
            }}
          ></Select>
          <MountComponent condition={validation.touched.state && validation.errors.state}>
            <small className="select-error">{validation.errors.state}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-md-6 col-lg-6">
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="validation.city">City</Label>
              <Select
                options={cityOptions}
                className=""
                name="city"
                isSearchable
                placeholder="Select City"
                onChange={({ value }) => {
                  validation.setFieldValue("city", value);
                }}
                value={cityOptions.find(
                  ({ value }) => value === validation.values.city
                )}
                theme={(theme) =>
                  themeReactSelect(theme, validation.touched.city && validation.errors.city)
                }
                styles={{
                  control: (baseStyles, state) =>
                    styleReactSelect(baseStyles, state, validation.touched.city && validation.errors.city),
                }}
              ></Select>
              <MountComponent condition={validation.touched.city && validation.errors.city}>
                <small className="select-error">{validation.errors.city}</small>
              </MountComponent>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="formrow-firstname-input">Pincode</Label>
          <Select
            options={pinOptions}
            className=""
            name="pinCode"
            isSearchable
            onChange={({ value }) => {
              validation.setFieldValue("pinCode", value);
            }}
            value={pinOptions.find(
              (option) => option.value === validation.values.pinCode
            )}
            theme={(theme) =>
              themeReactSelect(theme, validation.touched.pinCode && validation.errors.pinCode)
            }
            styles={{
              control: (baseStyles, state) =>
                styleReactSelect(
                  baseStyles,
                  state,
                  validation.touched.pinCode && validation.errors.pinCode
                ),
            }}
          ></Select>
          <MountComponent condition={validation.touched.pinCode && validation.errors.pinCode}>
            <small className="select-error">{validation.errors.pinCode}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="typeOfBusiness">Type Of Business</Label>
          <Select
            options={typeOfBusinessOptions}
            className=""
            name="typeOfBusiness"
            isSearchable
            onChange={({ value }) => {
              validation.setFieldValue("typeOfBusiness", value);
            }}
            value={typeOfBusinessOptions.find(
              (option) => option.value === validation.values.typeOfBusiness
            )}
            theme={(theme) =>
              themeReactSelect(theme, validation.touched.typeOfBusiness && validation.errors.typeOfBusiness)
            }
            styles={{
              control: (baseStyles, state) =>
                styleReactSelect(
                  baseStyles,
                  state,
                  validation.touched.typeOfBusiness && validation.errors.typeOfBusiness
                ),
            }}
          ></Select>
          <MountComponent condition={validation.touched.typeOfBusiness && validation.errors.typeOfBusiness}>
            <small className="select-error">{validation.errors.typeOfBusiness}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
          <div className="mt-4"><div className="mb-0" style={{ display: "flex", flexDirection: "column", width: "100%"}}>
            <Label className="form-label" htmlFor="formrow-firstname-input">Year Of Incorporation</Label>
            <div style={{ width: "100%"}}>
              <DatePicker
              style={{width:"100%"}}
              className="form-control"
                name="yearOfIncorporation"
                selected={selectedYear}
                onChange={(date) => handleYearChange(date)}
                value={validation.values.yearOfIncorporation}
                dateFormat="yyyy"
                showYearPicker
                isClearable
                placeholderText="Select year"
                minDate={new Date(minYear, 0, 1)}
                maxDate={new Date(currentYear, 11, 31)}
              />
            </div>
                    
            <MountComponent condition={validation.touched.yearOfIncorporation && validation.errors.yearOfIncorporation}>
              <FormFeedback type="invalid">{validation.errors.yearOfIncorporation}</FormFeedback>
            </MountComponent>

          </div></div>
        </div>
        </div>
        </div>

        <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="industry">Industry</Label>
          <Select
            options={industriesOptions}
            className=""
            name="industry"
            isSearchable
            onChange={({ value }) => {
              validation.setFieldValue("industry", value);
            }}
            value={industriesOptions.find(
              (option) => option.label === validation.values.industry
            )}
            theme={(theme) =>
              themeReactSelect(theme, validation.touched.industry && validation.errors.industry)
            }
            styles={{
              control: (baseStyles, state) =>
                styleReactSelect(
                  baseStyles,
                  state,
                  validation.touched.industry && validation.errors.industry
                ),
            }}
          ></Select>

          <MountComponent condition={validation.touched.industry && validation.errors.industry}>
            <small className="select-error">{validation.errors.industry}</small>
          </MountComponent>
        </div>
        </div>
        </div>

        <Col lg={6} md={6}>
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="formrow-firstname-input">
                First Name of Owner/CEO
              </Label>
              <Input
                type="text"
                name="firstNameOwner"
                placeholder="Enter First Name of Owner/CEO"
                onChange={validation.handleChange}
                // onBlur={validation.handleBlur}
                value={validation.values.firstNameOwner || ""}
                invalid={
                  validation.touched.firstNameOwner && validation.errors.firstNameOwner
                    ? true
                    : false
                }
              />
              <MountComponent condition={validation.touched.firstNameOwner && validation.errors.firstNameOwner}>
                <FormFeedback type="invalid">{validation.errors.firstNameOwner}</FormFeedback>
              </MountComponent>
            </div>
          </div>
        </Col>

        <Col lg={6} md={6}>
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="formrow-firstname-input">
                Last Name of Owner/CEO
              </Label>
              <Input
                type="text"
                name="lastNameOwner"
                placeholder="Enter Last Name of Owner/CEO"
                onChange={validation.handleChange}
                // onBlur={validation.handleBlur}
                value={validation.values.lastNameOwner || ""}
                invalid={
                  validation.touched.lastNameOwner && validation.errors.lastNameOwner
                    ? true
                    : false
                }
              />
              <MountComponent condition={validation.touched.lastNameOwner && validation.errors.lastNameOwner}>
                <FormFeedback type="invalid">{validation.errors.lastNameOwner}</FormFeedback>
              </MountComponent>
            </div>
          </div>
        </Col>

        <Col lg={6} md={6}>
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="formrow-firstname-input">
                PAN Number
              </Label>
              <Input
                type="text"
                name="panNumber"
                placeholder="Enter PAN Number"
                onChange={validation.handleChange}
                // onBlur={validation.handleBlur}
                value={(validation.values.panNumber || "").toUpperCase()}
                invalid={
                  validation.touched.panNumber && validation.errors.panNumber
                    ? true
                    : false
                }
              />
              <MountComponent condition={validation.touched.panNumber && validation.errors.panNumber}>
                <FormFeedback type="invalid">{validation.errors.panNumber}</FormFeedback>
              </MountComponent>
            </div>
          </div>
        </Col>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4">
            <div className="mb-0">
              <Label className="form-label" htmlFor="formrow-firstname-input">
                GSTIN Number
              </Label>
              <Input
                type="text"
                name="gstInNumber"
                placeholder="Enter GSTIN Number"
                onChange={validation.handleChange}
                value={String(
                  validation.values.gstInNumber ?? ""
                ).toUpperCase()}
                invalid={
                  validation.touched.gstInNumber && validation.errors.gstInNumber
                    ? true
                    : false
                }
              />
              <MountComponent condition={validation.touched.gstInNumber && validation.errors.gstInNumber}>
                <FormFeedback type="invalid">{validation.errors.gstInNumber}</FormFeedback>
              </MountComponent>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4">
            <div className="mb-0">
              <div className="row bg-transparent">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <Label className="form-label" htmlFor="variant-image">
                    Upload GST Certificate of Incorporation
                  </Label>
                  <Dropzone
                    onDrop={(images) => {
                      handleImageChange(
                        images,
                        "gstCertificate",
                        "gstCertificatePreview"
                      );
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div className="dz-message" {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept="image/jpeg, image/png"
                          />
                          <div className="">
                            <i className="display-4 text-muted uil uil-cloud-upload" />
                          </div>
                          <div className="d-content">
                            <h4>Drop file here or click to upload</h4>
                            <span>JPEG,PNG(Max: 2MB)</span>
                            <MountComponent condition={validation.touched.gstCertificate && validation.errors.gstCertificate}>
                              <h4 className="mt-3 mb-3" style={{ color: "#f46a6a" }}>
                                {validation.errors.gstCertificate}
                              </h4>
                            </MountComponent>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <Label className="form-label" htmlFor="variant-image">
                    Preview
                  </Label>
                  <div
                    className="dropzone previewDrop"
                    style={{ border: "none !important" }}
                  >
                    <div
                      className="dz-message"
                      style={{ padding: "0 !important" }}
                    >
                      <MountCondition
                        condition={lodash.isEmpty(
                          validation.values.gstCertificatePreview
                        )}
                      >
                        {validation.values.gstCertificate && <MountCondition.True>
                          <img
                            style={{ height: "150px", objectFit: "cover" }}
                            alt="image"
                            className="card-img-top rounded"
                            src={`${config.api.base_url}public/documents/${validation.values.gstCertificate}`}
                          />
                        </MountCondition.True>}
                        {validation.values.gstCertificate && <MountCondition.False>
                          <img
                            style={{ height: "130px" }}
                            alt="image"
                            className="card-img-top rounded"
                            src={`${validation.values.gstCertificatePreview}`}
                          />
                        </MountCondition.False>}
                      </MountCondition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4">
            <div className="mb-0">
              <div className="row bg-transparent">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <Label className="form-label" htmlFor="variant-image">
                    Upload Logo
                  </Label>
                  <Dropzone
                    onDrop={(images) => {
                      handleImageChange(
                        images,
                        "uploadLogo",
                        "uploadLogoPreview"
                      );
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div className="dz-message" {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept="image/jpeg, image/png"
                          />
                          <div className="">
                            <i className="display-4 text-muted uil uil-cloud-upload" />
                          </div>
                          <div className="d-content">
                            <h4>Drop file here or click to upload</h4>
                            <span>JPEG,PNG(Max: 2MB)</span>
                            <MountComponent condition={validation.touched.uploadLogo && validation.errors.uploadLogo}>
                              <h4 className="mt-3 mb-3" style={{ color: "#f46a6a" }}>
                                {validation.errors.uploadLogo}
                              </h4>
                            </MountComponent>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <Label className="form-label" htmlFor="variant-image">
                    Preview
                  </Label>
                  <div className="dropzone previewDrop">
                    <div className="dz-message">
                      <MountCondition condition={lodash.isEmpty(validation.values.uploadLogoPreview)}>
                        {validation.values.uploadLogo && <MountCondition.True>
                          <img
                            style={{ height: "150px" }}
                            alt="image"
                            className="card-img-top rounded"
                            src={`${config.api.base_url}public/documents/${validation.values.uploadLogo}`}
                          />
                        </MountCondition.True> }
                        {validation.values.uploadLogo && <MountCondition.False>
                          <img
                            style={{ height: "130px" }}
                            alt="image"
                            className="card-img-top rounded"
                            src={`${validation.values.uploadLogoPreview}`}
                          />
                        </MountCondition.False>}
                      </MountCondition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3"><div className="saveBtn">
          <MountCondition condition={permissions.ORGANIZATION_DETAILS_UPDATE}>
            <MountCondition.True>
              <button className="btn btn-group" type="submit" >Save</button>
              <button className="btn btn-group" type="submit" onClick={() => setSaveClicked(true)}>Save & Next</button>
            </MountCondition.True>
          </MountCondition>
        </div></div>
      </Form>
    </React.Fragment>
  );
});
