import React from "react";
import Preview from "./source.jsx";
import RightSideProfile from "../../../Dashboard/right-side-profile.js";

export default () => {
  return (
    <React.Fragment>
    <div className="page-content"><div className="container-fluid"><div className="row">
          <div className="col-lg-12"><div className="profile">
               <Preview/>
          </div></div>
          <div className="col-md-3">
              {/* <RightSideProfile></RightSideProfile> */}
          </div>
  </div></div></div>
  </React.Fragment>
    // <React.Fragment>
    //       <Row>
    //         <Col lg={12}>
    //           <Preview />
    //         </Col>
    //       </Row>
    // </React.Fragment>
  );
};
