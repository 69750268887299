import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import "../../../../assets/scss/custom/pages/datatables.scss";
import { Link } from "react-router-dom";
import { MountCondition } from "../../../../module/mount-components";
import imageSource from "../../../../assets/images/add.png";
import StepperHead from "../../../../components/Common/StepperHead";

const stepper = ["template", "Audience", "Campaign", "General Report"];

export default React.memo(({ campaignData: campaignDetails }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, [campaignDetails]);

  const formatDate = (originalDateString) => {
    const date = new Date(originalDateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Campaign Name",
        field: "campaignName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Sents",
        field: "totalSents",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date of Creation",
        field: "dateOfCreation",
        sort: "asc",
        width: 200,
      },
      {
        label: "Campaign Type",
        field: "campaignType",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "disabled",
      //   width: 100,
      // },
    ],
    rows: [
      ...campaignDetails.map((item, i) => ({
        sr: ++i,
        campaignName: item.name,
        totalSents: item.usersCount,
        campaignType: item.status,
        dateOfCreation: formatDate(item.createdAt),
        // action: (
        //   <div className="functionalBtn editDataShow">
        //     <Link to={`/audience/${item.id}`}>
        //       <button className="btn btn-group border" onClick={() => {}}>
        //         <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        //       </button>
        //     </Link>
        //     <button
        //       className="btn btn-group border"
        //     >
        //       <i className="fa fa-trash" aria-hidden="true"></i>
        //     </button>
        //   </div>
        // ),
      })),
    ],
  };

  return (
    <>
      <React.Fragment>
        <MountCondition condition={loading}>
          <MountCondition.True>
            <div className="fullscreen-loader">
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            </div>
          </MountCondition.True>
        </MountCondition>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={6} md={6}>
                <div className="mt-4">
                    <div className="mb-0">
                      <h5
                        // className="form-label"
                        className="mt-3 fw-bold mb-3"
                        htmlFor="formrow-firstname-input"
                      >
                        Email Campaigns
                      </h5>
                    </div>
                </div>
              </Col>

              <div className="col-md-6 mt-3">
                <div className="locationBtn">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/network-engage/campaign/create");
                    }}
                  >
                    Add New
                  </button>
                </div>
              </div>

              <div className="dataTable mt-4">
                <MDBDataTable responsive bordered data={data} footer={false} />
              </div>

              <Col
                lg={12}
                className="d-flex"
                style={{ justifyContent: "center" }}
              >
                <div className="saveBtn">
                  <button className="btn btn-group">Dashboard</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
});

export const SetupCampaign = React.memo(() => {
  return (
    <React.Fragment>
      <div className="actives">
        <StepperHead data={stepper}></StepperHead>
      </div>
      <div className="addSection">
        <div className="adds">
          <img src={imageSource} />
          <div className="newbtn">
            <Link
              className="btn btn-group"
              to="/network-engage/campaign/create"
            >
              Add New
            </Link>
            <div className="mt-1">
              <p>
                <span>You don't have any Campaign </span>
                <br />
                <span>Add New Audience to your Campaign</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
