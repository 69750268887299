import { server } from '../config/config'

export const storeTypeAPI = (payload = {}) => server({
    method  : 'post',
    url     : `types`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getTypesAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `types`,
    params  : { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getTypeAPI = (id) => server({
    method  : 'get',
    url     : `types/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateTypeAPI = (id, payload = {}) => server({
    method: 'put',
    url: `types/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteTypeAPI = (id) => server({
    method: 'delete',
    url: `types/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
