import { server } from '../config/config'

export const storeOrganizationModule = (payload = {}) => new Promise((resolve) => {
    server({
        method  : 'post',
        url     : `organization-modules`,
        data  : { ...payload },
        headers : { "Content-type": "application/json; charset=UTF-8", }
    }).then(() => resolve()).catch((error) => resolve(error.message));
});
 
export default async (params = {}) => await server({
    method  : 'get',
    url     : `organization-modules`,
    params  : { ...params },
    headers : { "Content-type": "application/json; charset=UTF-8", }
});
