import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import lodash from "lodash";

import { Collapse, Card, CardBody, Form, Input, Modal, Alert } from "reactstrap";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import classnames from "classnames";
import getAudienceList from "../../../../api/getAudienceListAPI";
import saveCampaignAPI from "../../../../api/saveCampaignAPI";
import getTemplateListAPI from "../../../../api/getTemplateListAPI";
import Preview from "../../../../assets/images/previewImg.png";
import { MountCondition } from "../../../../module/mount-components";
import "../../../../assets/scss/custom/pages/datatables.scss";
import MountComponent from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";
import { errorMessage } from "../../../../module/error-methods";

export default (props) => {
  const navigate = useNavigate();

  const [col1, setCol1] = useState(false);
  const [col2, setCol2] = useState(false);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(false);
  const [col10, setCol10] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState([]);
  const [audienceData, setAudienceData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const notificationInitialState = { visible: false, message: '', color: '' }
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
    setModalOpen(true);
  };

  function toggleCol1() {
    setCol1(!col1);
  }

  function toggleCol2() {
    setCol2(!col2);
  }

  function toggleCol8() {
    setCol8(true);
  }

  function toggleCol9() {
    setCol9(!col9);
  }

  function toggleCol10() {
    setCol10(!col10);
  }

  function togDelete() {
    setModalDelete(!modalDelete);
    // removeBodyCss();
  }

  const validateRules = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please Enter Campaign Name"),

    sourceEmail: Yup.string()
      .email("Invalid email")
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Please Enter Email"),

    sourceName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please Enter Source Name"),

    subject: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please Enter subject"),

    status: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please Enter Status"),
  });

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: validateRules,
    initialValues: {
      name: "",
      sourceEmail: "",
      sourceName: "",
      subject: "",
      templateId: "",
      usersCount: "",
      status: "",
      campaignAudience: [],
    },

    onSubmit: async (values) => {
      try {
        const payload = lodash.cloneDeep(values);
        const audienceIds = lodash.map(selectedOptions, ({ value }) => value);

        payload.usersCount = lodash.sumBy(
          lodash.filter(audienceData, ({ id }) => audienceIds.includes(id)),
          "totalUsers"
        );
        payload.campaignAudience = selectedOptions.map((audience) => {
          return { audienceId: audience.value };
        });

        await saveCampaignAPI(payload);
        toggleNotificationState({ visible: true, color: "success", message: "Your campaign is now running" })
        setTimeout(
          () => navigate(ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN),
          1500
        );
      } catch (error) {
        toggleNotificationState({ visible: true, color: "success", message: "Error" })
      } finally {
        setLoading(false);
      }
    },
  });

  const styleReactSelect = (baseStyles, state, error) => {
    return {
      ...baseStyles,
      "&:hover": {
        boxShadow: error ? "0 0 0 1px #dc3545" : "0 0 0 1px #ced4da",
        outlineColor: error ? "#dc3545" : "#ced4da",
        borderColor: error ? "#f46a6a" : "#ced4da",
        borderWidth: "1px",
      },
      borderColor: error ? "#f46a6a" : "#ced4da",
    };
  };

  const themeReactSelect = (theme, error) => {
    return {
      ...theme,
      boxShadow: "none",
      border: "solid 0.1px",
      outline: "none",
      borderWidth: "0.1px",
      colors: { ...theme.colors, primary: error ? "#dc3545" : "#99a7d9" },
    };
  };

  const templateList = async () => {
    try {
      const { data } = await getTemplateListAPI();
      if (data) {
        const template = data.data;
        setTemplateData(template);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const audienceList = async () => {
    try {
      const response = await getAudienceList();

      const audience = lodash.get(response, "data.data", []);
      const options = audience.map((data) => ({
        value: data.id,
        label: data.name,
      }));

      setAudienceData(audience);
      setAudienceOptions(options);
    } catch (error) {
      toggleNotificationState({ visible: true, color: "success", message: "Error" })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    templateList();
  }, []);
  useEffect(() => {
    setLoading(true);
    audienceList();
  }, []);

  const formatDate = (data, label) => {
    const matchingData = data.find((item) => item.name === label);
    if (matchingData) {
      const date = new Date(matchingData.createdAt);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return "";
  };

  const data = {
    columns: [
      {
        label: "SR No",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Audience List Name",
        field: "audienceListName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Users",
        field: "users",
        sort: "asc",
        width: 270,
      },
      {
        label: "Total Users",
        field: "totalUsers",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date Of Creation",
        field: "dateOfCreation",
        sort: "asc",
        width: 270,
      },
    ],
    rows: selectedOptions.map((selectedOption, i) => {
      const matchingData = audienceData.find(
        (data) => data.name === selectedOption.label
      );
      const formattedDate = formatDate(audienceData, selectedOption.label);
      return {
        sr: ++i,
        audienceListName: selectedOption.label
          ? selectedOption.label
          : selectedOption.name,
        users: matchingData ? matchingData?.source : selectedOption.source,
        dateOfCreation: formattedDate ? formattedDate : "",
        totalUsers: matchingData ? matchingData?.totalUsers : "",
      };
    }),
  };

  /** @handlers ----------------------------------------------------------------------------------------------------------------- */

  const handleSelectChange = useCallback((selected) => {
    setSelectedOptions(selected);
  });

  const handleFormSubmit = useCallback(() => {
    try {
      validateRules.validateSync(validation.values);
    } catch (error) {
      console.log(error.message);
      toggleNotificationState({ visible: true, color: "danger", message: "Please provide all the necessary details." })
    } finally {
      validation.handleSubmit();
    }
  }, [validation]);

  const previewHandler = useCallback(async () => {
    try {
      await validateRules.validate(validation.values);

      props.setCampaignState({
        ...validation.values,
        campaignAudience: selectedOptions,
      });
      props.switchScreen("preview");
    } catch (error) {
      toggleNotificationState({ visible: true, color: "danger", message: "Please provide all the necessary details." })
    }
  });

  useEffect(() => {
    validation.setValues({ ...props.campaignState });
    setSelectedOptions([...props.campaignState.campaignAudience]);
  }, [props.screen]);

  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
    return () => null;
  }, [notificationState.visible]);


  /** --------------------------------------------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <MountCondition condition={loading}>
        <MountCondition.True>
          <div className="fullscreen-loader">
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          </div>
        </MountCondition.True>
      </MountCondition>

      <MountComponent condition={notificationState.visible}>
        <Alert className="pt-2 pb-2" color={notificationState.color}>
          { notificationState.message }
        </Alert>
      </MountComponent>

      <Form className="form-horizontal campiagn-section" onSubmit={validation.handleSubmit}>
        <h5 className="mt-3 mb-3 fw-bold d-flex justify-content-center">
          Create a Email Campaign
        </h5>
        <div className="row">
          <div className="col-12">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne"></h2>
                <Collapse
                  id="flush-collapseOne"
                  className="accordion-collapse mt-3"
                  isOpen={col8}
                >
                  <Card>
                    <CardBody className="pt-1 pb-0">
                      <button
                        className={classnames(
                          "accordion-button bg-white border-0 ps-0 h6 text-black",
                          {}
                        )}
                        type="button"
                        onClick={toggleCol8}
                        style={{ cursor: "pointer" }}
                      >
                        <b>Campaign Detail</b>
                      </button>
                      <div className="campaign-accordian">
                        <p>Campaign Name</p>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Enter Campaign Name"
                          className="form-control"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {/* <p>{validation.errors.name}</p> */}
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className="accordion-item">
                <Card>
                  <CardBody className="pt-1 pb-0">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className={classnames(
                          "accordion-button accordion-button bg-white border-0 ps-0 pt-1 pb-1 h6 text-black",
                          {
                            collapsed: !col9,
                          }
                        )}
                        type="button"
                        onClick={toggleCol9}
                        style={{ cursor: "pointer" }}
                      >
                        <b>From</b>
                      </button>
                    </h2>
                    <Collapse
                      id="flush-collapseTwo"
                      className="accordion-collapse mt-3"
                      isOpen={col9}
                    >
                      <p>Email Address</p>
                      <Input
                        type="text"
                        name="sourceEmail"
                        readOnly
                        placeholder="industrials@bata.com"
                        className="form-control"
                        id="formrow-firstname-input"
                        onChange={validation.handleChange}
                        defaultValue="hello@giffy.network"
                        // value={validation.values.sourceEmail || ""}
                        invalid={
                          validation.touched.sourceEmail &&
                          validation.errors.sourceEmail
                            ? true
                            : false
                        }
                      />
                      <p className="mt-4">Name</p>
                      <Input
                        type="text"
                        name="sourceName"
                        readOnly
                        placeholder="Bata Industries"
                        className="form-control"
                        id="formrow-firstname-input"
                        onChange={validation.handleChange}
                        defaultValue="Giffy Network"
                        // value={validation.values.sourceName || ""}
                        invalid={
                          validation.touched.sourceName &&
                          validation.errors.sourceName
                            ? true
                            : false
                        }
                      />
                      <p>{validation.errors.sourceName}</p>
                    </Collapse>
                  </CardBody>
                </Card>
              </div>
              <div className="accordion-item">
                <Card>
                  <CardBody className="pt-1 pb-0">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className={classnames(
                          "accordion-button bg-white border-0 ps-0 pt-1 pb-1 h6 text-black",
                          "fw-medium",
                          { collapsed: !col10 }
                        )}
                        type="button"
                        onClick={toggleCol10}
                        style={{ cursor: "pointer" }}
                      >
                        <b>To</b>
                      </button>
                    </h2>
                    <Collapse
                      id="flush-collapseThree"
                      className="accordion-collapse mt-3 mb-5"
                      isOpen={col10}
                    >
                      <p>Send To</p>
                      <Select
                        className=""
                        name="campaignAudience"
                        isSearchable
                        isMulti
                        options={audienceOptions}
                        onChange={handleSelectChange}
                        value={selectedOptions}
                        styles={{
                          control: (baseStyles, state) =>
                            styleReactSelect(
                              baseStyles,
                              state,
                              validation.errors.campaignAudience
                            ),
                        }}
                        theme={(theme) =>
                          themeReactSelect(
                            theme,
                            validation.errors.campaignAudience
                          )
                        }
                        invalid={
                          validation.touched.campaignAudience &&
                          validation.errors.campaignAudience
                            ? true
                            : false
                        }
                      >
                        {/* <option>Select Regions</option> */}
                      </Select>
                      <p>
                        {validation?.errors?.campaignAudience?.map(
                          (aud) => aud?.audienceId
                        )}
                      </p>
                      <div className="dataTable mt-4">
                        <MDBDataTable
                          responsive
                          bordered
                          data={data}
                          footer={false}
                        />
                      </div>
                    </Collapse>
                  </CardBody>
                </Card>
              </div>
              <div className="accordion-item">
                <Card>
                  <CardBody className="pt-1 pb-0">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className={classnames(
                          "accordion-button bg-white border-0 ps-0 pt-1 pb-1 h6 text-black",
                          "fw-medium",
                          { collapsed: !col1 }
                        )}
                        type="button"
                        onClick={toggleCol1}
                        style={{ cursor: "pointer" }}
                      >
                        <b>Subject</b>
                      </button>
                    </h2>
                    <Collapse
                      id="flush-collapseThree"
                      className="accordion-collapse mt-3 pb-3"
                      isOpen={col1}
                    >
                      <p>Enter Subject</p>
                      <Input
                        type="text"
                        name="subject"
                        placeholder="Enter Subject"
                        className="form-control"
                        id="formrow-firstname-input"
                        onChange={validation.handleChange}
                        value={validation.values.subject || ""}
                        inalid={
                          validation.touched.subject &&
                          validation.errors.subject
                            ? true
                            : false
                        }
                      />
                    </Collapse>
                  </CardBody>
                </Card>
              </div>
              <div className="accordion-item">
                <Card>
                  <CardBody className="pt-1 pb-0">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className={classnames(
                          "accordion-button bg-white border-0 ps-0 pt-1 pb-1 h6 text-black",
                          "fw-medium",
                          { collapsed: !col2 }
                        )}
                        type="button"
                        onClick={toggleCol2}
                        style={{ cursor: "pointer" }}
                      >
                        <b>Design</b>
                      </button>
                    </h2>
                    <Collapse
                      id="flush-collapseThree"
                      className="accordion-collapse mt-3 pb-4"
                      isOpen={col2}
                    >
                      <p>Choose Template</p>
                      <div className="row">
                        {templateData.map((item) => (
                          <div className="col-md-3">
                            <div className="previewImg">
                              <div className="image-container">
                                <img
                                  onClick={() => {
                                    validation.setFieldValue(
                                      "templateId",
                                      item.id
                                    );
                                  }}
                                  className="img-fluid"
                                  alt="Minible"
                                  src={Preview}
                                />
                              </div>
                              {/* <img src={Preview} /> */}
                              <div className="tempText">
                                <p
                                  title={
                                    item.name.length > 12 ? item.name : null
                                  }
                                >
                                  {item.name.length > 12
                                    ? item.name.slice(0, 8) + "..."
                                    : item.name}
                                </p>
                              </div>
                              {validation.values.templateId === item.id && (
                                <div className="tick-mark">
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Collapse>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ color: "#ced4da", border: "1px solid" }} />

        <div className="location">
          <div className="viewSalesButtons row">
            <div className="col-lg-2 col-md-2 col-sm-2">
              <MountCondition condition={validation.values.templateId === ""}>
                <MountCondition.True>
                  <button type="button" disabled className="cancelModal btn">
                    Preview
                  </button>
                </MountCondition.True>
                <MountCondition.False>
                  <div className="saveBtn">
                    <button
                      className="btn btn-group saveBtn"
                      type="button"
                      onClick={previewHandler}
                    >
                      Preview
                    </button>
                  </div>
                </MountCondition.False>
              </MountCondition>
            </div>

            <div className="col-lg-10 col-md-10 col-sm-10 saveBtn">
              <div className="addNewBtn">
                <Link
                  className="btn btn-lightBlue mx-2 fontSetting"
                  to={ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN}
                >
                  Cancel
                </Link>
                <button
                  className="btn btn-lightBlue mx-2 fontSetting"
                  type="button"
                  onClick={handleFormSubmit}
                >
                  Run
                </button>
                <button
                  className="btn btn-orange fontSetting mx-2"
                  type="button"
                  onClick={openDatePicker}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        isOpen={modalOpen}
        toggle={() => {
          togDelete();
        }}
      >
        <div className="modal-body">
          <div className="" style={{ textAlign: "center" }}>
            <h2 className="mt-3">
              <b>
                <span style={{ color: "#000" }}>Coming</span>{" "}
              </b>
              <b>
                <span style={{ color: "#0022a0" }}>Soon</span>
              </b>
              <h5 className="mt-2">
                <span className="text-secondary">Schedule Email Campaign</span>
              </h5>
            </h2>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancelModal btn"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
