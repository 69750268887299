import config,{server} from '../config/config'

export const updateUserGuideAPI = async (guide,id) => {
    return await server({
        method  : 'put',
        url     : `users/${id}`,
        data    : { guideName : guide },
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default updateUserGuideAPI