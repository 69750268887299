import { server } from '../config/config'

export const storeCurrencyAPI = (payload = {}) => server({
    method: 'post',
    url: `currency`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const getCurrenciesAPI = (queryParams = {}) => server({
    method: 'get',
    url: `currency`,
    params: { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getCurrencyAPI = (id) => server({
    method: 'get',
    url: `currency/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateCurrencyAPI = (id, payload = {}) => server({
    method: 'put',
    url: `currency/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteCurrencyAPI = (id) => server({
    method: 'delete',
    url: `currency/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
