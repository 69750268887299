import React from "react"
import TimelineContainer, { TimelineContent, TimelineHeading } from "../../module/timeline-components"
import { getColor, useApiData } from "../../module/timeline-methods";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

export default React.memo(({ heading, activePage }) => {
    const { NAME, CHILDREN: { PRODUCT_MASTERS, PRODUCT } } = PERMISSIONS.CATALOG_MANAGER;
    const { array: state } = useApiData({ module: NAME });

    const structure = {
        [PRODUCT_MASTERS.NAME]: { current: PRODUCT_MASTERS.NAME, previous: null, active: activePage?.NAME === PRODUCT_MASTERS.NAME },
        [PRODUCT.NAME]: { current: PRODUCT.NAME, previous: PRODUCT_MASTERS.NAME, active: activePage?.NAME === PRODUCT.NAME },
        [PRODUCT.CHILDREN.PRODUCT_VIEW.NAME]: { current: PRODUCT.CHILDREN.PRODUCT_VIEW.NAME, previous: PRODUCT.NAME, active: activePage?.NAME === PRODUCT.CHILDREN.PRODUCT_VIEW.NAME },
    }

    return (
        <React.Fragment>
            <TimelineHeading>{heading}</TimelineHeading>
            <TimelineContainer>
                <TimelineContent
                    navigate={ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS}
                    color={getColor(state, structure[PRODUCT_MASTERS.NAME])}
                    index={1}
                    active={structure[PRODUCT_MASTERS.NAME]['active']}
                    heading="Masters">
                </TimelineContent>
                <TimelineContent
                    navigate={ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT}
                    color={getColor(state, structure[PRODUCT.NAME])}
                    index={2}
                    active={structure[PRODUCT.NAME]['active']}
                    heading="Product">
                </TimelineContent>
                <TimelineContent
                    navigate={ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCTS}
                    color={getColor(state, structure[PRODUCT.NAME])}
                    index={3}
                    active={structure[PRODUCT.NAME]['active']}
                    heading="Product List">
                </TimelineContent>
            </TimelineContainer>
        </React.Fragment>
    )
});
