import React, { useState } from "react";

import { Collapse } from "reactstrap";
import SideImage from "../../assets/images/sideImage.png";
import classnames from "classnames";

function RightSideProfile() {
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  function togglecol1() {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  }

  function togglecol2() {
    setcol1(false);
    setcol2(!col2);
    setcol3(false);
  }

  function togglecol3() {
    setcol1(false);
    setcol2(false);
    setcol3(!col3);
  }
  return (
    <div className="sideSection">
      <div className="sideContent">
        <img src={SideImage} />
      </div>
      {/* <div className="accordian">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: !col3,
                })}
                type="button"
                onClick={togglecol3}
                style={{ cursor: "pointer" }}
              >
                Blogs
              </button>
            </h2>
            <Collapse
              id="collapseThree"
              className="accordion-collapse"
              isOpen={col3}
            >
              <div className="accordion-body">
                <ul className="sidefaq">
                  <li>
                    <a
                      target="_blank"
                      href="https://giffy.network/how-digital-is-disrupting-channel-distribution-in-india/"
                    >
                      How Digital is disrupting Channel Distribution in India
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://giffy.network/challenges-of-managing-distribution-channels-in-india/"
                    >
                      Challenges of managing distribution channels in india
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://giffy.network/workplace-banter-cause-problems-in-your-business/"
                    >
                      Why engagement is important with channel partners
                    </a>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: !col2,
                })}
                type="button"
                onClick={togglecol2}
                style={{ cursor: "pointer" }}
              >
                FAQ
              </button>
            </h2>
            <Collapse
              id="collapseTwo"
              className="accordion-collapse"
              isOpen={col2}
            >
              <div className="accordion-body">
                <ul className="sidefaq">
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      What does Giffy really help me do?
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      How do I get Channel visibility?
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      How can I send messages to my Network with Giffy?
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      How much does it cost to use Giffy?
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      Is my company data safe with Giffy?
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      I am not a technology professional, Can I still use Giffy?
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://giffy.network/">
                      Can Giffy really understand my Network requirements?
                    </a>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: !col1,
                })}
                type="button"
                onClick={togglecol1}
                style={{ cursor: "pointer" }}
              >
                Help
              </button>
            </h2>
            <Collapse
              id="collapseOne"
              className="accordion-collapse"
              isOpen={col1}
            >
              <div className="accordion-body">This is the first item</div>
            </Collapse>
          </div>
        </div>
      </div> */}
      <div className="redirect">
        <a target="_blank" href="https://giffy.network/blog/"><p>Blogs</p></a>
        <a target="_blank" href="https://giffy.network/"><p>FAQ</p></a>
        <a target="_blank" href="https://wa.me/919818778986/?text=Hey!"><p>Help</p></a>
      </div>
    </div>
  );
}

export default RightSideProfile;
