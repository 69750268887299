import React from "react";
import store from "store";
import lodash from "lodash";

import { Navigate, useSearchParams } from "react-router-dom";

import ROUTES from "../../../../routes/routes.js";
import Source from "./source.jsx";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Account Verification | Giffy Network";

    const searchParams = useSearchParams()[0];
    const requestEmail = searchParams.get("email");
    const requestPhoneNumber = searchParams.get("phoneNumber");

    if (lodash.isEmpty(store.get('session')) === false) {
        return (<Navigate to={{ pathname: ROUTES.HOME.PATH }} />);
    }

    if (lodash.isNull(requestEmail) && lodash.isNull(requestPhoneNumber)) {
        return (<React.Fragment><h2>Invalid Request...</h2></React.Fragment>)
    }

    return (
        <React.Fragment>
            <Source 
                requestEmail={requestEmail}
                requestPhoneNumber={requestPhoneNumber}
            ></Source>
        </React.Fragment>
    );
}
