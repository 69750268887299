import React, { useState } from "react"

import MountComponent from "../../../../module/mount-components"

import RegistrationForm from "./registration-form";
import VerificationForm from "./registration-verification";
import ThankYou from "./thankyou";

const Index = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Registration | Giffy Network";

    const [screens, switchScreen] = useState({ screen: 'registration-form' });
    const [userDetails, updateUserDetails] = useState({ email: null, phoneNumber: null });

    return (
        <React.Fragment>
            <MountComponent condition={screens.screen === "registration-form"}>
                <RegistrationForm switchScreen={switchScreen} updateUserDetails={updateUserDetails} />
            </MountComponent>
            <MountComponent condition={screens.screen === "registration-verification"}>
                <VerificationForm switchScreen={switchScreen} userDetails={userDetails} />
            </MountComponent>
            <MountComponent condition={screens.screen === "thank-you"}>
                <ThankYou switchScreen={switchScreen} />
            </MountComponent>
        </React.Fragment>
    );
}

export default Index;
