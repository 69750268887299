import React, { useState, useEffect } from "react";
import lodash from "lodash";
import * as Yup from "yup";

import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { Col, Row, Form, Label, Input, FormFeedback, Alert } from "reactstrap";
import Select from "react-select";

import MountComponent from "../../../../module/mount-components.jsx";
import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods.js";
import { errorMessage, toastErrorMessage } from "../../../../module/error-methods.js";

import { getDivisionsAPI } from "../../../../api/division.api.js";
import { getNetworkLocationsAPI } from "../../../../api/network-location.api.js";
import { getSalesHierarchiesAPI } from "../../../../api/sales-hierarchy.api.js";
import { storeNetworkSalesmenAPI } from "../../../../api/network-salesman.api.js";
import { storeOrganizationModule } from "../../../../api/organizationModuleApi.js";

import ROUTES from "../../../../routes/routes.js";
import PERMISSIONS from "../../../../routes/permissions.js";

export default React.memo(() => {
  const { NAME, CHILDREN: { SALESMEN } } = PERMISSIONS.NETWORK_MANAGER;

  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

  const [divisionOptions, setDivisionOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const navigate = useNavigate();

  const salesmanFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: null,
      lastName: null,
      mobileNo: null,
      email: null,
      designation: null,
      location: null,
      divisionId: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter first name."),
      lastName: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter last name."),
      mobileNo: Yup
        .string()
        .required('Mobile number is required.')
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits.'),
      email: Yup.string()
        .email()
        .required("Please Enter email."),
      designation: Yup.string()
        .max(50, "Too Long!")
        .required("Please select designation."),
      location: Yup.string()
        .max(50, "Too Long!")
        .required("Please select location."),
      divisionId: Yup.string()
        .max(50, "Too Long!")
        .required("Please select division "),
    }),

    onSubmit: async (payload) => {
      try {

        await storeNetworkSalesmenAPI(payload);
        await storeOrganizationModule({ module: NAME, moduleLevel: SALESMEN.NAME });

        toggleNotificationState({ visible: true, color: 'success', message: "Salesman added Successfully" });
        setTimeout(() => {
          navigate(ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN);
        }, 3000);

      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); }
    },
  });

  /** @apis ----------------------------------------------------------------------------------------------------------------------- */

  const getDivisionOptions = async () => {
    await getDivisionsAPI().then(({ data }) => {
      if (lodash.isArray(data)) {
        return setDivisionOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
      }
    }).catch((error) => toastErrorMessage(error));
  };

  const getDesignationOptions = async () => {
    const divisionId = salesmanFormik.values.divisionId
    if (!divisionId) { return true; }

    setLoading(true);
    await getSalesHierarchiesAPI({ divisionId }).then(({ data }) => {
      if (lodash.isArray(data)) {
        salesmanFormik.setFieldValue('designation', null);
        return setDesignationOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
      }
    }).catch((error) => toastErrorMessage(error));
    setLoading(false);
  };

  const getLocationOptions = async () => {
    const divisionId = salesmanFormik.values.divisionId
    if (!divisionId) { return true; }

    setLoading(true);
    await getNetworkLocationsAPI({ divisionId }).then(({ data }) => {
      if (lodash.isArray(data)) {
        salesmanFormik.setFieldValue('location', null);
        return setLocationOptions([...data.map(({ location, id }) => ({ label: location, value: id }))]);
      }
    }).catch((error) => toastErrorMessage(error));
    setLoading(false);
  };

  /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { getDivisionOptions(); }, []);
  useEffect(() => { getDesignationOptions(); }, [salesmanFormik.values.divisionId]);
  useEffect(() => { getLocationOptions(); }, [salesmanFormik.values.divisionId]);

  useEffect(() => {
    if (notificationState.visible) {
      setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000);
    }
  }, [notificationState.visible]);

  /** ---------------------------------------------------------------------------------------------------------------------------- */


  return (
    <React.Fragment>
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible} toggle={() => toggleNotificationState({ visible: false, color: '', message: '' })} >
        {notificationState.message}
      </Alert>

      <Form className="form-horizontal row" onSubmit={salesmanFormik.handleSubmit}>

        <Col lg={12}>
          <Row>
            <div className="col-md-6 col-lg-6">
              <div className="mt-4">
                <div className="mb-0">
                  <Label className="form-label">First Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    className="form-control"
                    id=""
                    onChange={salesmanFormik.handleChange}
                    onBlur={salesmanFormik.handleBlur}
                    value={salesmanFormik.values.firstName}
                    invalid={
                      salesmanFormik.touched.firstName &&
                        salesmanFormik.errors.firstName
                        ? true
                        : false
                    }
                  />
                  <MountComponent condition={salesmanFormik.touched.firstName && salesmanFormik.errors.firstName}>
                    <FormFeedback type="invalid">{salesmanFormik.errors.firstName}</FormFeedback>
                  </MountComponent>
                </div></div></div>

            <div className="col-md-6 col-lg-6">
              <div className="mt-4"><div className="mb-0">
                <Label
                  className="form-label"
                  htmlFor="formrow-lastName-input"
                >
                  Last Name
                </Label>
                <Input
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  className="form-control"
                  id="formrow-lastName-input"
                  onChange={salesmanFormik.handleChange}
                  onBlur={salesmanFormik.handleBlur}
                  value={salesmanFormik.values.lastName || ""}
                  invalid={
                    salesmanFormik.touched.lastName &&
                      salesmanFormik.errors.lastName
                      ? true
                      : false
                  }
                />
                <MountComponent condition={salesmanFormik.touched.lastName && salesmanFormik.errors.lastName}>
                  <FormFeedback type="invalid">
                    {salesmanFormik.errors.lastName}
                  </FormFeedback>
                </MountComponent>
              </div>


              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mt-4">
                <div className="mb-0">
                  <Label
                    className="form-label"
                    htmlFor="formrow-mobileNo-input"
                  >
                    Mobile
                  </Label>
                  <Input
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="mobileNo"
                    className="form-control"
                    id="formrow-mobileNo-input"
                    onChange={salesmanFormik.handleChange}
                    onBlur={salesmanFormik.handleBlur}
                    value={salesmanFormik.values.mobileNo || ""}
                    invalid={
                      salesmanFormik.touched.mobileNo &&
                        salesmanFormik.errors.mobileNo
                        ? true
                        : false
                    }
                  />
                  <MountComponent condition={salesmanFormik.touched.mobileNo && salesmanFormik.errors.mobileNo}>
                    <FormFeedback type="invalid">
                      {salesmanFormik.errors.mobileNo}
                    </FormFeedback>
                  </MountComponent>
                </div>

              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mt-4">

                <div className="mb-0">
                  <Label
                    className="form-label"
                    htmlFor="formrow-email-input"
                  >
                    Email
                  </Label>
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    className="form-control"
                    id="formrow-email-input"
                    onChange={salesmanFormik.handleChange}
                    onBlur={salesmanFormik.handleBlur}
                    value={salesmanFormik.values.email || ""}
                    invalid={
                      salesmanFormik.touched.email && salesmanFormik.errors.email
                        ? true
                        : false
                    }
                  />

                  <MountComponent condition={salesmanFormik.touched.email && salesmanFormik.errors.email}>
                    <FormFeedback type="invalid">
                      {salesmanFormik.errors.email}
                    </FormFeedback>
                  </MountComponent>
                </div></div></div>

            <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
              <Label className="form-label">Division Name</Label>
              <Select
                className=""
                name="divisionId"
                inputId="aria-example-input"
                isSearchable
                onBlur={salesmanFormik.handleBlur}
                options={divisionOptions}
                onChange={({ value }) => {
                  salesmanFormik.setFieldValue("divisionId", value);
                }}
                value={divisionOptions.find(
                  ({ value }) => value === salesmanFormik.values.divisionId
                ) ?? null}
                invalid={salesmanFormik.touched.divisionId && salesmanFormik.errors.divisionId ? true : false}
                theme={(theme) => themeReactSelect(theme, salesmanFormik.touched.divisionId && salesmanFormik.errors.divisionId)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, salesmanFormik.touched.divisionId && salesmanFormik.errors.divisionId) }}
              >
              </Select>
              <MountComponent condition={salesmanFormik.touched.divisionId && salesmanFormik.errors.divisionId}>
                <small className="select-error">{salesmanFormik.errors.divisionId}</small>
              </MountComponent>
            </div></div></div>

            <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
              <Label className="form-label" >Designation</Label>
              <Select
                className=""
                name="designation"
                isSearchable
                options={designationOptions}
                onBlur={salesmanFormik.handleBlur}
                onChange={({ value }) => {
                  salesmanFormik.setFieldValue("designation", value);
                }}
                value={designationOptions.find(
                  ({ value }) => value === salesmanFormik.values.designation
                ) ?? null}
                invalid={salesmanFormik.touched.designation && salesmanFormik.errors.designation ? true : false}
                theme={(theme) => themeReactSelect(theme, salesmanFormik.touched.designation && salesmanFormik.errors.designation)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, salesmanFormik.touched.designation && salesmanFormik.errors.designation) }}
              >

              </Select>
              <MountComponent condition={salesmanFormik.touched.designation && salesmanFormik.errors.designation}>
                <small className="select-error">{salesmanFormik.errors.designation}</small>
              </MountComponent>
            </div></div>
            </div>

            <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
              <Label className="form-label">Location</Label>
              <Select
                className=""
                name="location"
                isSearchable
                options={locationOptions}
                onBlur={salesmanFormik.handleBlur}
                onChange={({ value }) => {
                  salesmanFormik.setFieldValue("location", value);
                }}
                value={locationOptions.find(
                  ({ value }) => value === salesmanFormik.values.location
                ) ?? null}
                invalid={
                  salesmanFormik.touched.location && salesmanFormik.errors.location ? true : false
                }
                theme={(theme) => themeReactSelect(theme, salesmanFormik.touched.location && salesmanFormik.errors.location)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, salesmanFormik.touched.location && salesmanFormik.errors.location) }}
              >
              </Select>
              <MountComponent condition={salesmanFormik.touched.location && salesmanFormik.errors.location}>
                <small className="select-error">{salesmanFormik.errors.location}</small>
              </MountComponent>
            </div></div></div>
          </Row>
        </Col>
        <hr className="mt-3" style={{ color: "#ced4da", border: "1px solid" }} />
        <div className="col-12 d-flex justify-content-center">
          <div className="saveBtn">
            <button className="btn btn-group" type="submit">Save</button>
          </div>
          <div className="saveBtn mx-2">
            <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN} type="button" className="btn btn-group">Cancel</Link>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
});
