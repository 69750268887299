import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import getNetworkLocationsListAPI from "../../../api/getNetworkLocationsListAPI";

export default React.memo(({ tab }) => {

  const [locations, setLocations] = useState([]);

  const locationList = async () => {
    try {
      const response = await getNetworkLocationsListAPI();
      if (response) {
        setLocations(response.data);
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    if (tab === "LOCATION_TAB") {locationList();}
  }, [tab]);

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU",
        field: "sbu",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location Name",
        field: "location",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region",
        field: "region",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pincode",
        field: "pincode",
        sort: "asc",
        width: 100,
      }
    ],
    rows: [
      ...locations.map((data, i) => ({
        sr: ++i,
        sbu: data.divisions.name,
        location: `${data.location} (${data.id})`,
        region: data.regions.name,
        state: data.state,
        city: data.city,
        pincode: data.pincode,
      })),
    ],
  };

  return (
    <>
    <div className="location">
      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={data} footer={false}/>
      </div>

    </div>
    </>
  );
})

