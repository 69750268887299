import React from "react";

import MountComponent from '../../../../module/mount-components';
import RightSideProfile from "../../../Dashboard/right-side-profile";
import TimelineGuide from "../../timeline-guide";
import Source from "./source";
import PERMISSIONS from "../../../../routes/permissions";

export default () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  document.title = " Masters | Giffy Network";
  const showGirl = false;
  const { CHILDREN: { NETWORK_DETAILS } } = PERMISSIONS.QUICK_START_GUIDE;

    return (
      <React.Fragment>
        <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12 col-md-12"><div className="profile">
                <div className="actives">
                  <TimelineGuide heading="Setup Your Masters" activePage={NETWORK_DETAILS}></TimelineGuide>
                </div>
                <Source></Source>
            </div></div>
            <div className="col-lg-3 col-md-12">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
        </div></div></div>
      </React.Fragment>
  ); };
