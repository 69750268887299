import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import lodash from "lodash";

import getOrganizationModules from "../../api/organizationModuleApi"

const errorMessage = (error) => {
    const errorMessage = "Something went wrong";
    const apiError = error.isAxiosError ? lodash.get(error, 'response.data.error') : errorMessage;
    return process.env.NODE_ENV === "development" ? apiError : errorMessage;
}

export const useApiData = (params) => {
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {

            const { data: response } = await getOrganizationModules(params);

            if (lodash.isArray(response.data) === false) throw new Error('invalid data');

            const moduleLevels = lodash.map(response.data, 'moduleLevel');

            setArray(moduleLevels);

        } catch (error) { toast.error(errorMessage(error)); } finally { setLoading(false); }
    };

    useEffect(() => { fetchData(); return () => { }; }, []);

    return { array, loading };
};

export const useGuideHook = (state, structure) => {
    const [render, setRender] = useState(false);
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        const getCount = calculateLevelsCount(state, structure);
        setRender(getCount < 100); /** either true or false */
        setCount(getCount);
    }, [state]);

    return { count, render };
}

export const getColor = (state, timeline = {}) => {
    switch (true) {
        case lodash.includes(state, timeline.current): return '#25D366';
        case lodash.includes(state, timeline.previous): return '#37AADE';
        default: return '#adb5bd';
    }
};

export const calculateLevelsCount = (activeModuleState, activeModule, maxPercentage = 100) => {
    const multiplyNumber = maxPercentage / lodash.size(lodash.keys(activeModule));
    const levelsCount = lodash.size(activeModuleState) * multiplyNumber;
    console.log("levelsCount nnn", multiplyNumber)
    return Math.ceil(levelsCount / 5) * 5;
};
