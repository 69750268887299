import React from "react";
import Bin from "../assets/images/bin.png"

export default React.memo((props) => {
    const { handleConfirmDeleteCallback, toggleDeleteModal } = props;
    const { message } = props;

    return (
        <React.Fragment>
            <div className="modal-header" style={{ border: "none", padding: "0 !important" }}>
                <button className="btn-close" type="button" onClick={() => { toggleDeleteModal(false); }} aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <p className="text-center" style={{ fontSize: "22px" }}>{message}</p>
                <div className="bin">
                    <img src={Bin} />
                </div>
            </div>
            <div className="modal-footer justify-content-center" style={{ border: "none" }}>
                <button type="button" className="nextMasterModal btn delete-btn" onClick={() => { handleConfirmDeleteCallback(); }} >Delete</button>
                <button type="button" className="cancelModal btn cancel-btn" onClick={() => { toggleDeleteModal(false); }}>Cancel</button>
            </div>
        </React.Fragment>
    );
});
