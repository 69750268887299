import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import getRegionAPI from "../../../api/getRegionAPI";

export default React.memo(({ tab }) => {
  const [Regions, setRegions] = useState([]);

  const regionsList = async () => {
    try {
      const response = await getRegionAPI();
      if (response) {
        setRegions(response.data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (tab === "GEOGRAPHY_TAB") {regionsList();} ;
  }, [tab]);

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU Name",
        field: "sbu",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region Name",
        field: "name",
        sort: "asc",
        width: 270,
      }
    ],
    rows: Regions.map((data,i) => ({
        sr: ++i,
        sbu: data.division ? data.division.name : '',
        name: `${data.name} (${data.id})`,
      })),
  };

  return (
    <div>
      
      <div className="geo">
        <div className="row">
          <div className="col-md-12  bg-transparent">
            <div className="geoForm">
            
              <div className="division">
                <div className="dataTable-hide-extra-props mt-3">
                  <MDBDataTable responsive bordered data={data} footer={false}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})

