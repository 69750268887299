import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, Routes } from "react-router-dom";
import withRouter from "../Common/withRouter";
import classname from "classnames";
import dashIcon from "../../assets/images/dashIcon.png";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import ROUTES from "../../routes/routes";

const Navbar = (props) => {
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [contact, setcontact] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.isopenMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul
                className="navbar-nav"
                onClick={() => {
                  props.updateOpenMenu(false);
                }}
              >
                <li className="nav-item">
                  <Link className="nav-link" to={ROUTES.HOME.PATH}>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9V18C18 19.1046 17.1046 20 16 20H6C4.89543 20 4 19.1046 4 18V9M20 11L11 2L2 11"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {props.t("Dashboard")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to={ROUTES.NETWORK_MANAGER.PATH}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 16C2 13.7909 3.79086 12 6 12H14C16.2091 12 18 13.7909 18 16V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V16Z"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinejoin="round"
                      />
                      <circle
                        cx="10"
                        cy="5"
                        r="3"
                        stroke="#292929"
                        strokeWidth="2.5"
                      />
                    </svg>
                    {props.t("Network Manager")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link
                      to={ROUTES.NETWORK_MANAGER.CHILDREN.REPORTS}
                      className="dropdown-item"
                    >
                      {props.t("Reports")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <p className="nav-link dropdown-toggle arrow-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 4V18C3 19.1046 3.89543 20 5 20H17H19C20.1046 20 21 19.1046 21 18V8H17"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 4H17V18C17 19.1046 17.8954 20 19 20V20"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 8L7 8"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 12L9 12"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <Link to={ROUTES.NETWORK_ENGAGE.PATH}>
                      {props.t("Network Engage")}{" "}
                      <span className="arrow-down"></span>
                    </Link>
                  </p>
                </li>
                <li className="nav-item dropdown">
                  <p className="nav-link dropdown-toggle arrow-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="17"
                        cy="7"
                        r="3"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <circle
                        cx="7"
                        cy="17"
                        r="3"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 14H20V19C20 19.5523 19.5523 20 19 20H15C14.4477 20 14 19.5523 14 19V14Z"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4 4H10V9C10 9.55228 9.55228 10 9 10H5C4.44772 10 4 9.55228 4 9V4Z"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <Link to={ROUTES.CATALOG_MANAGER.PATH}>
                      {props.t("Catalog Manager")}{" "}
                    </Link>
                    <span className="arrow-down"></span>
                  </p>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <div className="dropdown">
                      <Link
                        to={ROUTES.CATALOG_MANAGER.CHILDREN.CATALOGS}
                        className="dropdown-item"
                      >
                        {props.t("Product Catalog")}
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <p
                    // to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setcomponent(!component);
                    // }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 11V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V11"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 4.5C6 3.11929 7.11929 2 8.5 2V2C10.433 2 12 3.567 12 5.5V7H8.5C7.11929 7 6 5.88071 6 4.5V4.5Z"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 4.5C18 3.11929 16.8807 2 15.5 2V2C13.567 2 12 3.567 12 5.5V7H15.5C16.8807 7 18 5.88071 18 4.5V4.5Z"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 7H21V11H3V7Z"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 11V21"
                        stroke="#292929"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <Link to="/welcome">{props.t("Loyalty Manager")} </Link>
                    <div className="arrow-down"></div>
                  </p>
                  {/* <div className={classname("dropdown-menu", { show: app })}>
                    <div className="dropdown">
                      <Link to="/basic-elements" className="dropdown-item">
                        {props.t("Product Master")}
                      </Link>
                      <Link to="/form-layouts" className="dropdown-item">
                        {props.t("Add Product")}
                      </Link>
                      <Link to="/form-validation" className="dropdown-item">
                        {props.t("Catalog")}
                      </Link>
                      <Link to="/form-validation" className="dropdown-item">
                        {props.t("Media")}
                      </Link>
                      <Link to="/form-validation" className="dropdown-item">
                        {props.t("Businness Data")}
                      </Link>
                    </div>
                  </div> */}
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to={ROUTES.CATALOG_MANAGER.CHILDREN.CATALOGS} >
                    <b><i className="dripicons-cart me-2"></i></b>
                    {props.t("Product Catalog")}
                  </Link>
                </li> */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
