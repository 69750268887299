import config,{server} from '../config/config'

export const organizationDetailsAPI = async (data, userId) => {
    
    return await server({
        method  : 'patch',
        url     : `organization/${userId}`,
        data    : data,
        headers : {
            'content-type': 'multipart/form-data',
        }
    })
}

export default organizationDetailsAPI