import React, { useState, useEffect } from "react";
import config from "../../config/config";
import { Col, Row, Form, Label, Input, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import "../../assets/scss/custom/pages/datatables.scss";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import EditableForm from "./editableForm";
import Upload from "../../components/Common/Upload";
import StepperHead from "../../components/Common/StepperHead";
import UploadLocation from "./UploadLocation";
import UploadTableLocation from "./uploadTableLocation";
import getNetworkLocationsListAPI from "../../api/getNetworkLocationsListAPI";
import saveMultipleNetworkLocationAPI from "../../api/saveMultipleNetworkLocationAPI";
import deleteNetworkLocationAPI from "../../api/deleteNetworkLocationAPI";
import getOrganizationDetailsAPI from "../../api/getOrganizationDetailsAPI";
import getDivisionsDetailAPI from "../../api/getDivisionsDetailAPI"
import getDivisionsAPI from "../../api/getDivisionsAPI";
import ROUTES from "../../routes/routes";

function Location({ setHide, hide }) {
  const [showUploadField, setShowUploadField] = useState(true);
  const [showTable, setShowTable] = useState(false);
  // const userDetails = JSON.parse(localStorage.getItem('authUser'));
  // const organizationId = userDetails.data.organizationUserId
  // console.log("organizationId ==", organizationId)
  // const navigate = useNavigate();

  // useEffect(()=> {
  //   if(netcounter < 20){
  //     navigate("/geography")
  //   }
  // })

  const [updateId, setUpdateId] = useState(null);
  const [updateLocation, setUpdateLocation] = useState(null);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [locName, setLocName] = useState("")
  const [orgId, setOrgId] = useState('')
  const [userId, setUserId] = useState('')
  const [divisionId, setDivisionId] = useState({})
  const [divisionName, setDivisionName] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [uploadHide, setUploadHide] = useState(false);
  let number = 1;
  
  const [modal_backdrop, setmodal_backdrop] = useState({
    visible: false,
    text: "",
  });

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  function tog_backdrop(d) {
    setmodal_backdrop({ visible: d ?? false, text: d });
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [locations, setLocations] = useState([]);
  const [locationName, setLocationName] = useState("");

  const getOrganizationDetails = async () => {
    try {
      const response = await getOrganizationDetailsAPI(5);
      setOrgId(response.data.data.id);
      setUserId(response.data.data.userId)
      // }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    getOrganizationDetails();
  }, []);

  // const getDivision = async () => {
  //   try {
  //     const response = await getDivisionsDetailAPI({id:12});
  //     console.log("response div", response)
  //     // setOrgId(response.data.data.id);
  //     // setUserId(response.data.data.userId)
  //     // }
  //   } catch (error) {
  //     console.log("Erorr reg res", error);
  //   }
  // };
  // useEffect(() => {
  //   getDivision();
  // }, []);

  const locationList = async () => {
    try {
      const response = await getNetworkLocationsListAPI();
      if (response) {
        setLocations(response.data);
      }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    locationList();
  }, []);

  const dataToSend = responseData.map((tableData) => ({
    sbu: tableData.divisionName,
    location: String(tableData.locationName),
    regionId: String(tableData.region),
    state: String(tableData.state),
    city: String(tableData.selectCityDistrict),
    pincode: String(tableData.pincode),
    longitude: String(23.190),
    latitude: String(34.901),
    organizationId: orgId, 
    userId: userId,
    divisionId: tableData.sBU
}));

  const saveMultipleLocations = async () => {
    try {
      delete tableData.rows.action;
      const value = tableData.rows
      const data = await saveMultipleNetworkLocationAPI(dataToSend);
      toast.success("Location Uploaded succesfully")
      locationList();
      
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };

  // // const LocationsSingle = async() => {
  // //   try{
  // //     const response = await getNetworkLocationsListAPI();
  // //     if(response){
  // //       console.log("Response", response)
  // //     }
  // //   }
  // //   catch(error){

  // //   }
  // // };

  // useEffect(() => {

  // },[])

  const handleUploadResponse = (data) => {
    if (data && data.length > 0) {
      setResponseData(data);
      setShowTable(true)
      setShowUploadField(false);
    }
  };

  const deleteRow = (targetIndex) => {

    const updatedResponseData = responseData.filter((data,index) => index !== targetIndex);
    setResponseData(updatedResponseData);
  };

  useEffect(() => {
    if (responseData.length > 0) {
      setUploadHide(true);
    }
  }, [responseData])

  const tableData = {
    columns: [
      {
        label: "Sr.No.",
        field: "srn",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU",
        field: "sbu",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location Name",
        field: "location",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region",
        field: "region",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pincode",
        field: "pincode",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      ...responseData.map((tableData, index) => ({
        srn : index+1,
        sbu: tableData.sBU,
        location: tableData.locationName,
        region: tableData.region,
        state: tableData.state,
        city: tableData.selectCityDistrict,
        pincode: tableData.pincode,
        organizationId: orgId,
        divisionId: tableData.sBU,
        action: (
          <div style={{ display: "flex" }}>
            <button className="btn btn-group"
              onClick={() => {
                deleteRow(index); 
                // tog_delete();
                // handleDeleteClick(tableData.id, tableData.location);
              }}
              data-toggle="modal"
              >
              <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
            </button>
          </div>
        ),
      })),
    ],
  };

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU",
        field: "sbu",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location Name",
        field: "location",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region",
        field: "region",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pincode",
        field: "pincode",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: [
      ...locations.map((data, i) => ({
        sr: ++i,
        sbu: data.divisions.name,
        location: `${data.location} (${data.id})`,
        region: data.regions.name,
        state: data.state,
        city: data.city,
        pincode: data.pincode,
        action: (
          <div style={{ display: "flex" }}>
            <button className="btn btn-group"
            onClick={() => {tog_backdrop("editable form");setUpdateId(data.id)}}>
              <i class="fas fa-pen" style={{ color: "#0022a0" }}></i>
            </button>
            <button className="btn btn-group"
              onClick={() => {
                tog_delete();
                handleDeleteClick(data.id, data.location);
              }}
              data-toggle="modal"
              >
              <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
            </button>
          </div>
        ),
      })),
    ],
  };
  const [locationId, setLocationId] = useState(null);
  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
    removeBodyCss();
  }
  const handleDeleteClick = (id, location) => {
    setLocationId(id);
    setLocationName(location)
    
  };

  const handleEdit = (location) => {
    setLocName(location)
  };

  const handleSubmitRemove = async (locationId) => {
    try {
      
      const { data } = await deleteNetworkLocationAPI(locationId);
      setLocationId(null);
      setmodal_delete(false);
      toast.success("Location Deleted Successfully");
      locationList();
    } catch (error) {
      console.log(error);      
    }
  };

  const getDivisionsDetails = async () => {
    
    try {
      const { data } = await getDivisionsAPI();

      if (data) {
        const divisionData = data;
        // setState(data.data);
        const options = divisionData.map((state) => ({
          value: state.id,
          label: state.name,
        }));

        const idDivision = divisionData.map((state) => ({
          value: state.id,
          label: state.id,
        }));

        setDivisionName(options);
        setDivisionId(idDivision)
      }
      
    } catch (error) {
      console.log("Error state", error);
    }
  };

  useEffect(() => {
    getDivisionsDetails();
  }, []);

  // const handleRowClick = (location) => {
  //   setLocName(location.location); // Assuming the location object has a "location" property
  //   setUpdateId(location.id); // Assuming the location object has an "id" property
  // };

 

  return (
    <>
    <div className="location">
      <div className="addNewBtn">
        <div className="row">
          <div className="col-md-6">
            <div className="locationHead">
              <h5>Location</h5>
            </div>
          </div>
          <div className="col-md-6">
            <div className="locationBtn">
              <button
                onClick={() => {
                  tog_backdrop("Bulk Upload");
                }}
                data-toggle="modal"
                className="btn btn-primary changeO mx-3"
              >
                Bulk Upload
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setHide(false);
                }}
              >
                Add Location
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-lg-6">
          <div className="formatOptions">
            <button
              style={{
                border: "none",
                margin: "0 5px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                background: "#fff",
                padding: "8px",
                borderRadius: "7px",
              }}
              onClick={() => 
                setHide('mapView')
                }
            >
              <img
                src={Drive}
                alt=""
                style={{
                  height: "1.5em",
                  objectFit: "contain",
                  width: "100%",
                  background: "#fff",
                }}
              />
            </button>
            <button
              style={{
                border: "none",
                margin: "0 5px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                background: "#fff",
                padding: "8px",
                borderRadius: "7px",
              }}
            >
              <img
                src={Pdf}
                alt=""
                style={{
                  height: "1.5em",
                  objectFit: "contain",
                  width: "100%",
                  background: "#fff",
                }}
              />
            </button>
            <button
              style={{
                border: "none",
                margin: "0 5px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                background: "#fff",
                padding: "8px",
                borderRadius: "7px",
              }}
              onClick={exportToExcel}
            >
              <img
                src={Excel}
                alt=""
                style={{
                  height: "1.5em",
                  objectFit: "contain",
                  width: "100%",
                  background: "#fff",
                }}
              />
            </button>
          </div>
        </div> */}

      <div className="dataTable">
        <MDBDataTable responsive bordered data={data} footer={false} />
      </div>
      <div className="text-center saveBtn">
      <Link to={hide ? ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN : ""}>
          <button
            onClick={() => {
              if (!hide) {
                setHide(true);
              }
            }}
            className="btn btn-group mt-2"
          >
            Next
          </button>
        </Link> 
      </div>

        {/* Modal for Delete Division */}
        <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        // backdrop={"static"}
        // scrollable={true}
        // id="staticBackdrop"
        // centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Delete
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_delete(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete {locationName}?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancelModal btn"
            onClick={() => {
              setmodal_delete(false);
              
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="nextMasterModal btn"
            onClick={() => handleSubmitRemove(locationId)}
          >
            Delete
          </button>
        </div>
      </Modal>

      <Modal
        size="xl"
        isOpen={modal_backdrop.visible}
        toggle={() => {
          tog_backdrop(false);
        }}
        // backdrop={"static"}
        // scrollable={true}
        // id="staticBackdrop"
        // centered
      >
          <button
            onClick={() => {
              tog_backdrop(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        {
          modal_backdrop.text === "editable form" ? <EditableForm  updateId={updateId} tog_backdrop={tog_backdrop} locationList={locationList}/> : 
          <>
      
        <div className="modal-header">
          {/* <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_backdrop(false);
            }}
            aria-label="Close"
          ></button> */}
        </div>
        <div className="modal-body">
          <div className="mt-4">
            <div className="mb-0 text-center">
              {showUploadField && <div className="saveBtn">
                <a className="btn btn-group mt-0 mb-2" href={`${config.api.base_url}/network-locations/900/download`}>
                  Download CSV
                </a>
              </div>}
              {showUploadField && <Label className="form-label" htmlFor="formrow-firstname-input">
                This CSV Contains header values in the first column
              </Label>}
              {/* <Upload /> */}
              {showUploadField && <UploadLocation onUploadResponse={handleUploadResponse}/>}
              {showTable && (<div className="dataTable">
                <MDBDataTable responsive bordered data={tableData} footer={false} />
              </div>)}
              {/* {responseData !== null && <UploadTableLocation responseData={responseData} />} */}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button"
            className="cancelModal btn"
            onClick={() => {
              tog_backdrop(false); 
              setShowUploadField(true); 
              setShowTable(false);
            }}
            >
            Cancel
          </button>
          <button type="button" onClick={()=>{
            saveMultipleLocations(tableData);
            setShowUploadField(true); 
            setShowTable(false);
            // setHide("upload")
            tog_backdrop(false);
            // setTimeout(() => {
              
            // }, 1500)
          }} 
          className="btn btn-primary">
            Upload
          </button>
        </div>
        {/* <AddLocation responseData={responseData}/> */}
        </>
        }
      </Modal>
    </div>
    </>
  );
}

export default Location;
