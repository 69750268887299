import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { MDBDataTable } from "mdbreact";
import { useFormik } from "formik";
import { FaCheck } from "react-icons/fa";
import { Modal, Label, Input, Form, Alert, FormFeedback, Button } from "reactstrap";
import ReactSelect from "react-select";

import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods";
import { errorMessage } from "../../../../module/error-methods";
import MountComponent, { MountCondition } from "../../../../module/mount-components";

import { storeBrandAPI, getBrandsAPI, updateBrandAPI, deleteBrandAPI } from "../../../../api/brand.api";
import config from "../../../../config/config";
import Dropzone from "react-dropzone";
import DeleteModal from "../../../../module/delete-modal.component";

export default React.memo(({ userPermissions, tab }) => {
  const [loading, setLoading] = useState(false);

  const [deleteModal, toggleDeleteModal] = useState(false);
  const [updateModal, toggleUpdateModal] = useState(false);

  const [moduleState, setModuleState] = useState({});
  const [brandsList, setBrandsList] = useState([]);

  const [notificationState, toggleNotificationState] = useState({ visible: false });

  const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const getBrands = async () => {
    setLoading(true);
    await getBrandsAPI().then(({ data }) => {
      if (lodash.isArray(data)) { return setBrandsList([...data]); }
    }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
    setLoading(false);
  };

  /** @useFormiks ------------------------------------------------------------------------------------------------------------------------- */

  const brandInitialValues = {
    name: '',
    image: null,
    status: "Active",
  };

  const updateBrandValidation = {
    name: Yup.string().required("Oops! Please enter brand name."),
    image: Yup.mixed().test("fileType", "Only image files are allowed", (value) => {
      if (lodash.isString(value)) { return true; }
      if (lodash.isEmpty(value)) { return true; }
      return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
    }),
  };

  const updateBrandFormik = useFormik({
    enableReinitialize: true,
    initialValues: { ...brandInitialValues },
    validationSchema: Yup.object({ ...updateBrandValidation }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const payload = lodash.pick(values, ['name', 'status']);

        if (lodash.isObject(values.image)) { payload.image = values.image; }

        await updateBrandAPI(values.id, { ...payload });
        await getBrands();

        toggleNotificationState({ visible: true, color: 'success', message: "Success! Brand created successfully." });
        resetForm();
      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); toggleUpdateModal(false); setModuleState({}); }
    },
  });

  const brandValidation = {
    name: Yup.string().required("Oops! Please enter brand name."),
    image: Yup.mixed().required("Oops! Please upload image.")
      .test("fileType", "Only image files are allowed", (value) => {
        if (lodash.isString(value) && lodash.isEmpty(value) === false) {
          return true;
        }
        return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
      }),
  };

  const brandFormik = useFormik({
    enableReinitialize: true,
    initialValues: { ...brandInitialValues },
    validationSchema: Yup.object({ ...brandValidation }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true); const payload = lodash.omit(values, 'imagePreview');

        await storeBrandAPI(payload);
        await getBrands();

        toggleNotificationState({ visible: true, color: 'success', message: "Success! Brand created successfully." });
        resetForm();
      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); setModuleState({}); }
    },
  });

  /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

  const handleDropzone = useCallback(async (images, formikSource) => {
    const sourceFile = images[0];
    const reader = new FileReader();
    const image = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(sourceFile);
    });

    formikSource.setFieldValue('image', sourceFile);
    formikSource.setFieldValue('imagePreview', image);
  });

  const handleConfirmDeleteCallback = useCallback(async () => {
    try {
      await deleteBrandAPI(moduleState.id);
      await getBrands();

      toggleNotificationState({ visible: true, color: 'success', message: `Success! "${moduleState.name}" deleted successfully.` });
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
    } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
  });

  const handleDeleteModalCallback = useCallback((state = {}) => {
    setModuleState({ ...state })
    toggleDeleteModal(true);
  });

  const handleUpdateModalCallback = useCallback((state = {}) => {
    setModuleState({ ...state });
    toggleUpdateModal(true);
    return updateBrandFormik.setValues({ ...state });
  });

  const handleCancelUpdateModalCallback = useCallback(() => {
    setModuleState({});
    toggleUpdateModal(false);
    return updateBrandFormik.setValues({});
  });

  /** ----------------------------------------------------------------------------------------------------------------------------------- */

  const dataTableHandler = () => {
    const columns = [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "desc",
        width: 150,
      },
      {
        label: "Brand Name",
        field: "brandName",
        sort: "desc",
        width: 270,
      },
      {
        label: "Brand Image",
        field: "image",
        sort: "disabled",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        sort: "desc",
        width: 270,
      },
    ];
    const rows = [
      ...brandsList.map((details, index) => ({
        sr: ++index,
        brandName: details.name,
        status: details.status,
        image: (<div> <img style={{ height: "50px", width: "50px" }} src={`${config.api.base_url}public/documents/${details.image}`} /></div>),
        id: details.id,
        action: (
          <div className="functionalBtn editDataShow">
            <MountComponent condition={userPermissions.PRODUCT_MASTERS_UPDATE}>
              <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
              </button>
            </MountComponent>
            <MountComponent condition={userPermissions.PRODUCT_MASTERS_DELETE}>
              <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
              </button>
            </MountComponent>
          </div>
        ),
      })),
    ];

    if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
      columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
    }

    return updateDataTable({ columns: [...columns], rows: [...rows] });
  };

  /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { if (tab === "BRANDS_TAB") { getBrands(); } }, [tab]);
  useEffect(() => {
    if (tab === "BRANDS_TAB") { brandFormik.resetForm(); updateBrandFormik.resetForm(); }
  }, [tab]);

  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
  }, [notificationState.visible]);

  useEffect(() => { dataTableHandler() }, [brandsList]);

  /** ----------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <div className="">
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible} >
        {notificationState.message}
      </Alert>

      <form className="row mainText" onSubmit={brandFormik.handleSubmit}>
        <div className="mt-1 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 bg-transparent" style={{ height: "40px" }}><div className="mainFormSubmit">
          <div className="error-input">
            <Input
              placeholder="Please enter Brand name."
              className="h-100"
              name="name"
              invalid={
                brandFormik.errors.name &&
                brandFormik.touched.name
              }
              value={brandFormik.values.name}
              onChange={brandFormik.handleChange}
            ></Input>
            <FormFeedback
              valid={
                !brandFormik.errors.name &&
                !brandFormik.touched.name
              }
            >
              {brandFormik.errors.name}
            </FormFeedback>
          </div>
          <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
        </div></div>
        <div className="col-md-6 col-sm-12 col-xs-12"><div className="mb-2">
          <label htmlFor="example-date-input" className="col-form-label"> Brand Image</label>
          <Dropzone onDrop={(images) => { handleDropzone(images, brandFormik); }}>
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone"><div className="dz-message" {...getRootProps()}>
                <input
                  {...getInputProps()}
                  accept="image/jpeg, image/png"
                />
                <div className="">
                  <i className="display-4 text-muted uil uil-cloud-upload" />
                </div>
                <div className="d-content">
                  <h4>Drop file here or click to upload</h4>
                  <span>JPEG,PNG(Max: 2MB)</span>
                  <MountComponent condition={brandFormik.touched.image && brandFormik.errors.image}>
                    <h4 className="mt-3 mb-3" style={{ color: "#f46a6a" }}>
                      {brandFormik.errors.image}
                    </h4>
                  </MountComponent>
                </div>
              </div></div>
            )}
          </Dropzone>
        </div></div>
        <div className="col-md-6 col-sm-12 col-xs-12"><div className="mb-2">
          <MountComponent condition={brandFormik.values.imagePreview}>
            <img
              src={brandFormik.values.imagePreview}
              alt="..."
              style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
            />
          </MountComponent>
        </div></div>
      </form>

      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={dataTable} footer={false} />
      </div>

      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          handleConfirmDeleteCallback={handleConfirmDeleteCallback}
          message={`Are you sure you want to delete ${moduleState.name} ?`}
        ></DeleteModal>
      </Modal>{/* Modal for delete brands */}

      <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Edit Brand</h5>
          <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close" ></button>
        </div>
        <Form className="needs-validation" onSubmit={updateBrandFormik.handleSubmit}>
          <div className="modal-body"><div className="row">
            <div className="col-12"><div className="form-group mb-3">
              <Label htmlFor="name">Brand name</Label>
              <Input name="name" placeholder="Brand name" type="text" className="form-control" id="name"
                onChange={updateBrandFormik.handleChange}
                onBlur={updateBrandFormik.handleBlur}
                value={updateBrandFormik.values.name || ""}
                invalid={updateBrandFormik.touched.name && updateBrandFormik.errors.name ? true : false}
              />
              <MountComponent condition={updateBrandFormik.touched.name && updateBrandFormik.errors.name}>
                <FormFeedback type="invalid"> {updateBrandFormik.errors.name} </FormFeedback>
              </MountComponent>
            </div></div>
            <div className="col-12"><div className="form-group mb-3">
              <Label htmlFor="validationCustom01">Status</Label>
              <ReactSelect
                options={statusOptions}
                placeholder="Select Status"
                onChange={({ value }) => { updateBrandFormik.setFieldValue("status", value); }}
                value={statusOptions.find(({ value }) => value === updateBrandFormik.values.status) ?? null}
                theme={(theme) => themeReactSelect(theme, updateBrandFormik.touched.status && updateBrandFormik.errors.status)}
                styles={{
                  control: (baseStyles, state) =>
                    styleReactSelect(baseStyles, state, updateBrandFormik.touched.status && updateBrandFormik.errors.status),
                }}
              ></ReactSelect>
              <MountComponent condition={updateBrandFormik.touched.status && updateBrandFormik.errors.status}>
                <small className="select-error">{updateBrandFormik.errors.status}</small>
              </MountComponent>
            </div></div>

            <div className="col-6"><div className="form-group mb-3">
              <Dropzone onDrop={(images) => { handleDropzone(images, updateBrandFormik); }}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone"><div className="dz-message" {...getRootProps()}>
                    <input {...getInputProps()} accept="image/jpeg, image/png" />
                    <div className="">
                      <i className="display-4 text-muted uil uil-cloud-upload" />
                    </div>
                    <div className="d-content">
                      <h4>Drop file here or click to upload</h4>
                      <span>JPEG,PNG(Max: 2MB)</span>
                      <MountComponent condition={updateBrandFormik.touched.image && updateBrandFormik.errors.image}>
                        <h4 className="mt-3 mb-3" style={{ color: "#f46a6a" }} >
                          {updateBrandFormik.errors.image}
                        </h4>
                      </MountComponent>
                    </div>
                  </div></div>
                )}
              </Dropzone>
            </div></div>

            <div className="col-6"><div className="form-group mb-3">
              <MountCondition condition={!lodash.isEmpty(updateBrandFormik.values.imagePreview)}>
                <MountCondition.True>
                  <img
                    src={updateBrandFormik.values.imagePreview}
                    alt={updateBrandFormik.values.imagePreview}
                    style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                  ></img>
                </MountCondition.True>
                <MountCondition.False>
                  <img
                    src={`${config.api.base_url}public/documents/${updateBrandFormik.values.image}`}
                    alt="..."
                    style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                  ></img>
                </MountCondition.False>
              </MountCondition>
            </div></div>
          </div></div>
          <div className="modal-footer">
            <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>Cancel</button>
            <button type="submit" className="nextMasterModal btn">Update</button>
          </div>
        </Form>
      </Modal>{/* Modal for edit brands */}
    </div>
  );
});
