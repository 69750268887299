import { server } from '../config/config'

export const storeUnitAPI = (payload = {}) => server({
    method: 'post',
    url: `units`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const getUnitsAPI = (queryParams = {}) => server({
    method: 'get',
    url: `units`,
    params: { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getUnitAPI = (id) => server({
    method: 'get',
    url: `units/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateUnitAPI = (id, payload = {}) => server({
    method: 'put',
    url: `units/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteUnitAPI = (id) => server({
    method: 'delete',
    url: `units/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
