import config, {server} from '../config/config'
import axios from 'axios'

export const getPinCodesListAPI = async (params) => {
    
    return await server({
        method  : 'get',
        url     : `/location/pin-codes`,
        params  : {states:[params.states], cities:[params.cities]},
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getPinCodesListAPI