import { server } from '../config/config';

export const getNetworkLocationsAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `network-locations`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getNetworkLocationAPI = (id) => server({
    method  : 'get',
    url     : `network-locations/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const storeNetworkLocationAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-locations`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getNetworkLocationImportInfoAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-locations/info`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const uploadMultipleNetworkLocationsAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-locations/multiple`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const updateNetworkLocationAPI = (id,payload = {}) => server({
    method  : 'put',
    url     : `network-locations/${id}`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteNetworkLocationAPI = (id) => server({
    method  : 'delete',
    url     : `network-locations/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
