import React from "react";
import { createBrowserRouter, createRoutesFromElements, useNavigation, Navigate, useLoaderData } from "react-router-dom";
import { Route, Outlet } from "react-router-dom";

import { MountCondition } from "./module/mount-components";
import { AppLoader } from "./module/loader";

import homeRoute, { privateRoutes, authRoutes } from "./routes/index.routes";

import HorizontalLayout from "./components/HorizontalLayout";
import { authentication, useAuthentication, userOrganizationPermission } from "./routes/middleware/auth.middleware";
import ErrorElement from "./module/error.components";

const AuthWrapper = React.memo(() => (<React.Fragment><Outlet /></React.Fragment>));
const OrganizationWrapper = React.memo(({ wrapperChildren }) => {
    return (<React.Fragment>{wrapperChildren}</React.Fragment>)
});

const OrganizationUserWrapper = React.memo(({ wrapperChildren }) => {
    const { state } = useNavigation();
    const { haveAccess, redirect } = useLoaderData();
    return (
        <MountCondition condition={state === "loading"}>
            <MountCondition.True><AppLoader /></MountCondition.True>
            <MountCondition.False>
                <MountCondition condition={haveAccess}>
                    <MountCondition.True>{wrapperChildren}</MountCondition.True>
                    <MountCondition.False><Navigate to={redirect} /></MountCondition.False>
                </MountCondition>
            </MountCondition.False>
        </MountCondition>
    );
});

const AppWrapper = React.memo(({ children }) => (
    <HorizontalLayout>
        <MountCondition condition={userOrganizationPermission()}>
            <MountCondition.True><OrganizationWrapper wrapperChildren={children} /></MountCondition.True>
            <MountCondition.False><OrganizationUserWrapper wrapperChildren={children} /></MountCondition.False>
        </MountCondition>
    </HorizontalLayout>
));

const AuthenticationWrapper = React.memo(() => (
    <MountCondition condition={useAuthentication()}>
        <MountCondition.True><Outlet /></MountCondition.True>
        <MountCondition.False>
            <Navigate to="/login" />
        </MountCondition.False>
    </MountCondition>
));

export const appRouter = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorElement />}>
        <Route element={<AuthWrapper />}>
            {authRoutes.map((route, index) => (
                <Route exact key={index} path={route.path} element={
                    <React.Fragment>{route.component}</React.Fragment>}
                ></Route>
            ))}
        </Route>
        <Route element={<AuthenticationWrapper />}>
            {privateRoutes.map((route, index) => (
                <Route loader={async (props) => await authentication(props, route)} exact key={index} path={route.path} element={
                    <AppWrapper>{route.component}</AppWrapper>}
                ></Route>
            ))}
            <Route exact path={homeRoute.path} element={
                <HorizontalLayout>{homeRoute.component}</HorizontalLayout>}
            ></Route>
        </Route>
    </Route>
));
