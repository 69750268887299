import React, { useState, useEffect, useCallback } from "react";
import store from "store";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Common/withRouter";
import config from "../../../../src/config/config"
import user from "../../../assets/images/profile-pic.png";
import ROUTES from "../../../routes/routes";

// users
import user4 from "../../../assets/images/logo-dark.png";
import getUsersDetailsAPI from "../../../api/getUsersDetailsAPI";
import {  useParams } from 'react-router-dom';
import { toast } from "react-toastify";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const params = useParams();

  const [username, setusername] = useState('');
  const [modal_delete, setmodal_delete] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [image, setImage] = useState(user4);
  // const [defaultImage, setDefaultImage] = useState('');
  
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const navigate = useNavigate();

  const getUsersDetails = async () => {
    await getUsersDetailsAPI(obj.data.id).then(({ data : response }) => {

    }).catch((error) => {});
    // try {
    //     const { data : response } = await getUsersDetailsAPI(obj.data.id);
    //     const userDetails = response.data;

    //     console.clear(); console.log(userDetails)

    //     if (userDetails.profilePicture) { 
    //         console.log(userDetails.profilePicture)
    //         setImage(`${config.api.base_url}public/documents/${userDetails.profilePicture}`); 
    //     }

    //     return setUserDetails(userDetails);

    //   } catch (error) { toast.error(error.message); }
  };

  useEffect(() => { getUsersDetails(); return () => null; }, [params])

  useEffect(() => {
    if(obj?.hasOwnProperty('data')){
      const formattedName = obj?.data?.firstName?.slice(0,1).toUpperCase() + obj?.data?.firstName?.slice(1,obj?.data?.firstName?.length);
      setusername(formattedName)
    }
    else{
      const formattedName = obj?.name?.slice(0,1).toUpperCase() + obj?.name?.slice(1,obj?.name?.length);
      setusername(formattedName)
    }
  }, [username])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        // const obj = JSON.parse(localStorage.getItem("authUser"));
        // setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        if (obj.username) {
          setusername(obj.username);
        } else {
          setusername(obj.name);
        }
      }
    }
  }, [props.success]);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_delete() {
    setmodal_delete(!modal_delete);
    // removeBodyCss();
}

  // @coder-kabir ----------------------------------------------------------------------------

    const handleLogout = useCallback((event) => {
        event.preventDefault(); store.clearAll();
        tog_delete(false);
        return navigate("/logout");
    });

  // -----------------------------------------------------------------------------------------

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            id="profile-image"
            className="rounded-circle header-profile-user"
            src={user}
            // src={`${config.api.base_url}public/documents/${userDetails.profilePicture}`} 
            // alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {username}
          </span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="span" >
            <Link to="/account">{props.t("Account")} </Link>
          </DropdownItem> */}
          <DropdownItem tag="a">
            <Link to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.PROFILE}>{props.t("Your Profile")} </Link>
          </DropdownItem>
          {/* <DropdownItem tag="a">
            <Link to="/organization">{props.t("Organization Details")}</Link>
          </DropdownItem>
          <DropdownItem tag="a">
            <Link to="/network">{props.t("Network Details")}</Link>
          </DropdownItem>
          <DropdownItem tag="a">
            <Link to="/user">{props.t("Admin Users")}</Link>
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            {props.t("Billing Details")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          {/* <Link to="/logout" className="dropdown-item">
            <span>{props.t("Logout")}</span>
          </Link> */}
          <Link to="#" className="dropdown-item" onClick={handleLogout}>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal
            isOpen={modal_delete}
            toggle={() => {
                tog_delete();
            }}
            backdrop={"static"}
            scrollable={true}
            id="staticBackdrop"
            centered
            >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                Delete
            </h5>
            <button
                type="button"
                className="btn-close"
                onClick={() => {
                    setmodal_delete(false);
                }}
                aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <p>Are you sure you want to Log Out ?</p>
            </div>
            <div className="modal-footer">
                <button
                type="button"
                className="cancelModal btn"
                onClick={() => {
                    setmodal_delete(false);
                }}
                >
                Cancel
                </button>
                <button
                type="button"
                className="nextMasterModal btn btn-group"
                onClick={() => navigate("/logout")}
                >
                Confirm
                </button>
            </div>
        </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
