import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";

import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";
import { useFormik } from "formik";

import Select from "react-select";
import { Modal, FormGroup, Label, Input, Form, Alert, Button, FormFeedback } from "reactstrap";

import { styleReactSelect, themeReactSelect } from '../../../../module/select-methods';
import { errorMessage } from "../../../../module/error-methods";

import MountComponent from "../../../../module/mount-components";
import DeleteModal from '../../../../module/delete-modal.component';

import { storeVariantAPI, updateVariantAPI, getVariantsAPI, deleteVariantAPI  } from "../../../../api/variant.api";

export default React.memo(({ userPermissions, tab }) => {
    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [variantsList, setVariantsList] = useState([]);

    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const optionStatus = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" }
    ]

    const getVariants = async () => {
        setLoading(true);
        await getVariantsAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setVariantsList([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @useFormiks ------------------------------------------------------------------------------------------------------------------------- */

      const variantInitialValues = {
        name: '',
        status: 'Active',
      };

      const variantValidation = {
          name: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Only alphabets and spaces are allowed')
            .min(3, 'Name must be at least 3 characters')
            .trim().required('Name is required'),
      };

      const updateVariantFormik = useFormik({
          enableReinitialize: true,
          initialValues: { ...variantInitialValues },
          validationSchema: Yup.object({ ...variantValidation }),
          onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true); const { name, status, id } = values;

                await updateVariantAPI(id, { name, status });
                await getVariants();

                toggleNotificationState({ visible: true, message: 'Success! variant updated successfully.' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); setModuleState({}); }
          },
      });

      const variantFormik = useFormik({
          enableReinitialize: true,
          initialValues: { ...variantInitialValues },
          validationSchema: Yup.object({ ...variantValidation }),
          onSubmit: async (values, { resetForm }) => {
              try {
                  setLoading(true);
                  
                  await storeVariantAPI({ name: values.name, status: "Active" });
                  await getVariants();
                  
                  toggleNotificationState({ visible: true, message: 'Success! Variant added successfully.' });
                  resetForm();
              } catch (error) {
                  toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
              } finally { setLoading(false); setModuleState({}); }
          },
      });

  /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteVariantAPI(moduleState.id);
            await getVariants();

            toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" deleted successfully !` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateVariantFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateVariantFormik.setValues({});
    });

  /** ----------------------------------------------------------------------------------------------------------------------------------- */
      const dataTableHandler = () => {
          const columns = [
            {
              label: "Sr.No.",
              field: "sr",
              sort: "asc",
              width: 150,
            },
            {
              label: "Variant Name",
              field: "variantName",
              sort: "asc",
              width: 270,
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 270,
            }
          ];
          const rows = [
            ...variantsList.map((details, index) => ({
              sr: ++index,
              variantName: details.name,
              status: details.status,
              action: (
                <div className="functionalBtn editDataShow">
                    <MountComponent condition={userPermissions.PRODUCT_MASTERS_UPDATE}>
                        <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                            <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                        </button>
                    </MountComponent>
                    <MountComponent condition={userPermissions.PRODUCT_MASTERS_DELETE}>
                        <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                            <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                        </button>
                    </MountComponent>
                </div>
              ),
            })),
          ];
        
          if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
          }

          return updateDataTable({ columns: [...columns], rows: [...rows] });
      };

  /** @useEffects -------------------------------------------------------------------------------------------------------------------------- */

      useEffect(() => { if (tab === "VARIANTS_TAB") { getVariants(); } }, [tab]);
      useEffect(() => {
          if (tab === "VARIANTS_TAB") { variantFormik.resetForm(); updateVariantFormik.resetForm(); }
      }, [tab]);
  
      useEffect(() => {
          if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
      }, [notificationState.visible]);
  
      useEffect(() => { dataTableHandler() }, [variantsList]);
  
  /** --------------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <div className="">
        <MountComponent condition={loading}>
            <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
            </div></div></div>
        </MountComponent>

        <Alert color={notificationState.color} isOpen={notificationState.visible}>
            {notificationState.message}
        </Alert>

        <MountComponent condition={userPermissions.PRODUCT_MASTERS_CREATE}>
            <form className="row mainText" onSubmit={variantFormik.handleSubmit}>
                <div className="col-12 bg-transparent pe-0 mb-2" style={{ height: '40px' }}><div className="mainFormSubmit">
                  <div className="error-input">
                      <Input placeholder="Please enter Variant" className="h-100" name="name"
                          invalid={variantFormik.touched.name && variantFormik.errors.name}
                          value={variantFormik.values.name}
                          onChange={variantFormik.handleChange}
                      ></Input>
                      <MountComponent condition={variantFormik.touched.name && variantFormik.errors.name}>
                          <FormFeedback valid={variantFormik.isValid}>{variantFormik.errors.name}</FormFeedback>
                      </MountComponent>
                  </div>
                  <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
                </div></div>
            </form>
        </MountComponent>

        <div className="dataTable-hide-extra-props mt-3">
            <MDBDataTable responsive bordered data={dataTable} footer={false} />
        </div>
      
        <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
            <DeleteModal
                toggleDeleteModal={toggleDeleteModal}
                handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                message={`Are you sure you want to delete ${moduleState.name} ?`}
            ></DeleteModal>
        </Modal>{/* Modal for delete variant */}
      
        <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Update Variant</h5>
                <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
            </div>
            <Form className="needs-validation" onSubmit={updateVariantFormik.handleSubmit}>
                <div className="modal-body">
                    <FormGroup className="mb-3">
                        <Label htmlFor="unit-name">Variant name</Label>
                        <Input name="name" placeholder="type name" type="text" className="form-control" id="unit-name"
                            onChange={updateVariantFormik.handleChange}
                            onBlur={updateVariantFormik.handleBlur}
                            value={updateVariantFormik.values.name || ""}
                            invalid={updateVariantFormik.touched.name && updateVariantFormik.errors.name ? true : false}
                        />
                        <MountComponent condition={updateVariantFormik.touched.name && updateVariantFormik.errors.name}>
                            <FormFeedback type="invalid"> {updateVariantFormik.errors.name} </FormFeedback>
                        </MountComponent>
                    </FormGroup>
                    <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">Status</Label>
                            <Select
                            options={optionStatus}
                            placeholder="Select Status"
                            onChange={({ value }) => { updateVariantFormik.setFieldValue("status", value); }}
                            value={optionStatus.find(({ value }) => value === updateVariantFormik.values.status) ?? null}
                            theme={(theme) => themeReactSelect(theme, updateVariantFormik.touched.status && updateVariantFormik.errors.status)}
                            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateVariantFormik.touched.status && updateVariantFormik.errors.status) }}
                            />
                    </FormGroup>
                </div>
                <div className="modal-footer">
                    <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>Cancel</button>
                    <button type="submit" className="nextMasterModal btn">Update</button>
                </div>
            </Form>
        </Modal>{/* Modal for update variant */}
    </div>
  );
});
