import React from "react";
import { Link } from "react-router-dom";
import { Modal, Label, Form, Input, FormFeedback } from "reactstrap";
import Select from "react-select";

import imageGoodDrive from "../../../../assets/images/driveview.png";
import imageExcel from "../../../../assets/images/excel.png";
import imageVectorMap from "../../../../assets/images/google-map-vector-icon.png"

import { MDBDataTable } from "mdbreact";

import DeleteModal from "../../../../module/delete-modal.component";
import MountComponent from "../../../../module/mount-components";
import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods.js";

import ROUTES from "../../../../routes/routes";

export default React.memo((props) => {
    const { channelPartners, channelPartnerState } = props;
    const { deleteModal, toggleDeleteModal, handleDeleteCallback, handleConfirmDeleteCallback } = props; /** delete modal ref props */
    const { updateModal, toggleUpdateModal, handleUpdateCallback } = props; /** update modal ref props */
    const { userPermissions, toggleImportModal } = props;
    const { divisionOptions, designationOptions, locationOptions } = props /** dependencies array's */
    const { updateChannelPartnerFormik } = props; /** update formik dependency */
    const { handleVectorMap, handleGoogleMap, handleXLSXExport } = props;
    const { filters, handleFilter } = props; /** dependencies array's */

    const data = {
        columns: [
            {
                label: "Sr.No.",
                field: "srn",
                sort: "asc",
                width: 150,
            },
            {
                label: "Channel Partner Name",
                field: "partnerName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Mobile",
                field: "mobile",
                sort: "asc",
                width: 270,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "SBU/Division",
                field: "sbu",
                sort: "asc",
                width: 100,
            },
            {
                label: "Designation",
                field: "designation",
                sort: "asc",
                width: 100,
            },
            {
                label: "Location",
                field: "location",
                sort: "asc",
                width: 150,
            }, {
                label: "Action",
                field: "action",
                sort: "disabled",
                width: 100,
            },
        ],
        rows: [
            ...channelPartners.map((details, index) => ({
                srn: ++index,
                partnerName: `${details.firstName} ${details.lastName}`,
                mobile: details.mobileNo,
                email: details.email,
                sbu: details.divisions.name,
                designation: details.distributionHierarchy.name,
                location: details.networkLocation.location,
                action: (
                    <div className="editDataShow functionalBtn">
                        <MountComponent condition={userPermissions.CHANNEL_PARTNER_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateCallback(details) }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.CHANNEL_PARTNER_DELETE}>
                            <button className="btn btn-group border ms-1" onClick={() => { handleDeleteCallback(details) }}>
                                <i className="fa fa-trash" aria-hidden="true" style={{ color: "red" }}></i>
                            </button>
                        </MountComponent>
                    </div>
                )
            })),
        ],
    };

    return (
        <div className="userTable"><div>
            <div className="col-lg-12 col-md-12 col-sm-10 mb-3 mt-3 bg-transparent"><div className="d-flex justify-content-between">
                <section>
                    <MountComponent condition={userPermissions.CHANNEL_PARTNER_CREATE}>
                        <div className="bg-transparent text-right btn-css">
                            <button onClick={() => { toggleImportModal(true); }} className="btn btn-warning me-3">
                                Bulk Upload
                            </button>
                            <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER_CREATE} className="btn btn-primary">Add New</Link>
                        </div>
                    </MountComponent>
                </section>
                <section>
                    <button onClick={handleVectorMap} style={{ border: "none", margin: "0 5px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", background: "#fff", padding: "8px", borderRadius: "7px", }}>
                        <img src={imageVectorMap} alt="" style={{ height: "1.5em", objectFit: "contain", width: "100%", background: "#fff", }} />
                    </button>
                    {/* <button onClick={handleGoogleMap} style={{ border: "none", margin: "0 5px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", background: "#fff", padding: "8px", borderRadius: "7px", }}>
                        <img src={imageGoodDrive} alt="" style={{ height: "1.5em", objectFit: "contain", width: "100%", background: "#fff", }} />
                    </button> */}
                    <button onClick={handleXLSXExport} style={{ borderRadius: "7px", padding: "8px", background: "#fff", border: "none", margin: "0 5px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", }}>
                        <img src={imageExcel} alt="" style={{ height: "1.5em", objectFit: "contain", width: "100%", background: "#fff", }} />
                    </button>
                </section>
            </div></div>

            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 mt-3"><div className="row" >
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3"><div className="mb-2">
                    <Select
                        name="divisionId"
                        onChange={handleFilter}
                        options={divisionOptions}
                        value={divisionOptions.find(({ value }) => value === filters.divisionId) ?? null}
                        classNamePrefix=""
                        className=""
                        placeholder="Select SBU"
                    ></Select>
                </div></div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3"><div className="mb-2">
                    <Select
                        name="designationId"
                        onChange={handleFilter}
                        options={designationOptions}
                        classNamePrefix=""
                        className=""
                        placeholder="Select Designation"
                        value={designationOptions.find(({ value }) => value === filters.designationId) ?? null}
                    ></Select>
                </div></div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3"><div className="mb-2">
                    <Select
                        name="locationId"
                        onChange={handleFilter}
                        options={locationOptions}
                        classNamePrefix=""
                        className=""
                        placeholder="Select Location"
                        value={locationOptions.find(({ value }) => value === filters.locationId) ?? null}
                    ></Select>
                </div></div>
            </div></div>

            <div className="UserDataTable"><div className="col-12"><div className="userProfileTable">
                <div className="dataTable-hide-extra-props mt-3">
                    <MDBDataTable responsive bordered data={data} footer={false} />
                </div>
                <div className="resumeBtn mt-4 text-center">
                    <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN} className="btn btn-group me-3">Previous</Link>
                    <Link to={ROUTES.NETWORK_MANAGER.PATH} className="btn btn-group">Dashboard</Link>
                </div>
            </div></div></div>

            <Modal isOpen={deleteModal} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${channelPartnerState.firstName} ?`}
                ></DeleteModal>
            </Modal>

            <Modal isOpen={updateModal} backdrop={"static"} scrollable={false} id="staticBackdrop" size="xl">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">Edit Location</h5>
                    <button className="btn-close" type="button" onClick={() => { toggleUpdateModal(false); }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal row" onSubmit={updateChannelPartnerFormik.handleSubmit} >
                        <div className="col-md-6 col-lg-6">
                            <div className="mt-4">
                                <div className="mb-0">
                                    <Label
                                        className="form-label"
                                        htmlFor="formrow-firstname-input"
                                    >
                                        First Name
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        className="form-control"
                                        onChange={updateChannelPartnerFormik.handleChange}
                                        onBlur={updateChannelPartnerFormik.handleBlur}
                                        value={updateChannelPartnerFormik.values.firstName || ""}
                                        invalid={
                                            updateChannelPartnerFormik.touched.firstName &&
                                                updateChannelPartnerFormik.errors.firstName
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                {updateChannelPartnerFormik.touched.firstName &&
                                    updateChannelPartnerFormik.errors.firstName ? (
                                    <FormFeedback type="invalid">
                                        {updateChannelPartnerFormik.errors.firstName}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mt-4">
                                <div className="mb-0">
                                    <Label
                                        className="form-label"
                                        htmlFor="formrow-firstname-input"
                                    >
                                        Last Name
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        className="form-control"
                                        id="formrow-firstname-input"
                                        onChange={updateChannelPartnerFormik.handleChange}
                                        onBlur={updateChannelPartnerFormik.handleBlur}
                                        value={updateChannelPartnerFormik.values.lastName || ""}
                                        invalid={
                                            updateChannelPartnerFormik.touched.lastName &&
                                                updateChannelPartnerFormik.errors.lastName
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                {updateChannelPartnerFormik.touched.lastName &&
                                    updateChannelPartnerFormik.errors.lastName ? (
                                    <FormFeedback type="invalid">
                                        {updateChannelPartnerFormik.errors.lastName}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mt-4">
                                <div className="mb-0">
                                    <Label
                                        className="form-label"
                                        htmlFor="formrow-firstname-input"
                                    >
                                        Mobile
                                    </Label>
                                    <Input
                                        type="number"
                                        placeholder="Enter Mobile Number"
                                        name="mobileNo"
                                        className="form-control"
                                        id="formrow-firstname-input"
                                        onChange={updateChannelPartnerFormik.handleChange}
                                        onBlur={updateChannelPartnerFormik.handleBlur}
                                        value={updateChannelPartnerFormik.values.mobileNo || ""}
                                        invalid={
                                            updateChannelPartnerFormik.touched.mobileNo &&
                                                updateChannelPartnerFormik.errors.mobileNo
                                                ? true
                                                : false
                                        }
                                    />
                                    {updateChannelPartnerFormik.touched.mobileNo &&
                                        updateChannelPartnerFormik.errors.firstName ? (
                                        <FormFeedback type="invalid">
                                            {updateChannelPartnerFormik.errors.mobileNo}
                                        </FormFeedback>
                                    ) : null}
                                </div>


                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mt-4">
                                <div className="mb-0">
                                    <Label
                                        className="form-label"
                                        htmlFor="formrow-firstname-input"
                                    >
                                        Email
                                    </Label>
                                    <Input
                                        type="email"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        className="form-control"
                                        id="formrow-firstname-input"
                                        onChange={updateChannelPartnerFormik.handleChange}
                                        onBlur={updateChannelPartnerFormik.handleBlur}
                                        value={updateChannelPartnerFormik.values.email || ""}
                                        invalid={
                                            updateChannelPartnerFormik.touched.email && updateChannelPartnerFormik.errors.email
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                {updateChannelPartnerFormik.touched.email && updateChannelPartnerFormik.errors.email ? (
                                    <FormFeedback type="invalid">
                                        {updateChannelPartnerFormik.errors.email}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mt-4"><div className="mb-0">
                                <Label className="form-label">Division Name</Label>
                                <Select
                                    className=""
                                    options={divisionOptions}
                                    name="divisionId"
                                    inputId="aria-example-input"
                                    isSearchable
                                    onChange={({ value }) => { updateChannelPartnerFormik.setFieldValue("divisionId", value); }}
                                    value={divisionOptions.find(({ value }) => value === updateChannelPartnerFormik.values.divisionId)}
                                    onBlur={updateChannelPartnerFormik.handleBlur}
                                    invalid={updateChannelPartnerFormik.touched.divisionId && updateChannelPartnerFormik.errors.divisionId ? true : false}
                                    theme={(theme) => themeReactSelect(theme, updateChannelPartnerFormik.errors.divisionId)}
                                    styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateChannelPartnerFormik.errors.divisionId) }}
                                ></Select>
                                <MountComponent condition={updateChannelPartnerFormik.touched.divisionId && updateChannelPartnerFormik.errors.divisionId}>
                                    <small className="text-danger">{updateChannelPartnerFormik.errors.divisionId}</small>
                                </MountComponent>
                            </div></div>
                        </div>

                        <div className="col-md-6 col-lg-6">
                            <div className="mt-4"><div className="mb-0">
                                <Label className="form-label">Designation</Label>
                                <Select
                                    className=""
                                    name="designation"
                                    isSearchable
                                    onBlur={updateChannelPartnerFormik.handleBlur}
                                    onChange={({ value }) => {
                                        updateChannelPartnerFormik.setFieldValue("designation", value);
                                    }}
                                    value={designationOptions.find(({ value }) =>
                                        value === updateChannelPartnerFormik.values.designation
                                    )}
                                    options={designationOptions}
                                    invalid={updateChannelPartnerFormik.touched.designation && updateChannelPartnerFormik.errors.designation ? true : false}
                                    theme={(theme) => themeReactSelect(theme, updateChannelPartnerFormik.errors.designation)}
                                    styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateChannelPartnerFormik.errors.designation) }}
                                >
                                </Select>
                                <MountComponent condition={updateChannelPartnerFormik.touched.designation && updateChannelPartnerFormik.errors.designation}>
                                    <small className="text-danger">{updateChannelPartnerFormik.errors.designation}</small>
                                </MountComponent>
                            </div></div>
                        </div>

                        <div className="col-md-6 col-lg-6"><div className="mt-4"><div className="mb-0">
                            <Label className="form-label">Location</Label>
                            <Select
                                className=""
                                name="location"
                                isSearchable
                                onBlur={updateChannelPartnerFormik.handleBlur}
                                onChange={({ value }) => {
                                    updateChannelPartnerFormik.setFieldValue("location", value);
                                }}
                                value={locationOptions.find(
                                    ({ value }) => value === updateChannelPartnerFormik.values.location
                                )}
                                options={locationOptions}
                                invalid={updateChannelPartnerFormik.touched.location && updateChannelPartnerFormik.errors.location ? true : false}
                                theme={(theme) => themeReactSelect(theme, updateChannelPartnerFormik.errors.location)}
                                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateChannelPartnerFormik.errors.location) }}
                            >
                            </Select>
                            <MountComponent condition={updateChannelPartnerFormik.touched.location && updateChannelPartnerFormik.errors.location}>
                                <small className="text-danger">{updateChannelPartnerFormik.errors.location}</small>
                            </MountComponent>
                        </div></div></div>

                        <div className="col-12 mt-5 text-center">
                            <button type="button" className="btn btn-primary" onClick={() => { toggleUpdateModal(false); }}>Cancel</button>
                            <button type="submit" className="btn btn-primary  mx-3" >Update</button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div></div>
    )
});