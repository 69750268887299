import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";

import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";
import { useFormik } from "formik";

import Select from "react-select";
import { Modal, FormGroup, Label, Input, Form, Alert, Button, FormFeedback } from "reactstrap";

import { styleReactSelect, themeReactSelect } from '../../../../module/select-methods';
import { errorMessage } from "../../../../module/error-methods";

import MountComponent from "../../../../module/mount-components";
import DeleteModal from '../../../../module/delete-modal.component';

import { storeTypeAPI, updateTypeAPI, getTypesAPI, deleteTypeAPI } from "../../../../api/types.api";

export default React.memo(({ userPermissions, tab }) => {

  const [loading, setLoading] = useState(false);

  const [deleteModal, toggleDeleteModal] = useState(false);
  const [updateModal, toggleUpdateModal] = useState(false);

  const [moduleState, setModuleState] = useState({});
  const [typesList, setTypesList] = useState([]);

  const [notificationState, toggleNotificationState] = useState({ visible: false });

  const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

  const optionStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
  ]

  const getTypes = async () => {
    setLoading(true);
    await getTypesAPI().then(({ data }) => {
      if (lodash.isArray(data)) { return setTypesList([...data]); }
    }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
    setLoading(false);
  };

  /** @useFormiks ------------------------------------------------------------------------------------------------------------------------- */

  const typeInitialValues = {
    name: '',
    status: 'Active',
  };

  const typeValidation = {
    name: Yup.string().matches(/^[a-zA-Z ]*$/, 'Only alphabets and spaces are allowed')
      .min(3, 'Name must be at least 3 characters')
      .trim().required('Name is required'),
  };

  const updateTypeFormik = useFormik({
    enableReinitialize: true,
    initialValues: { ...typeInitialValues },
    validationSchema: Yup.object({ ...typeValidation }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);

        await updateTypeAPI(values.id, { name: values.name, status: values.status });
        await getTypes();

        toggleNotificationState({ visible: true, message: 'Success! type updated successfully.' });
        resetForm();
      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); toggleUpdateModal(false); setModuleState({}); }
    },
  });

  const typeFormik = useFormik({
    enableReinitialize: true,
    initialValues: { ...typeInitialValues },
    validationSchema: Yup.object({ ...typeValidation }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);

        await storeTypeAPI({ name: values.name, status: "Active" });
        await getTypes();

        toggleNotificationState({ visible: true, message: 'Success! type added successfully.' });
        resetForm();
      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); setModuleState({}); }
    },
  });

  /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

  const handleConfirmDeleteCallback = useCallback(async () => {
    try {
      await deleteTypeAPI(moduleState.id);
      await getTypes();

      toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" deleted successfully !` });
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
    } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
  });

  const handleDeleteModalCallback = useCallback((state = {}) => {
    setModuleState({ ...state })
    toggleDeleteModal(true);
  });

  const handleUpdateModalCallback = useCallback((state = {}) => {
    setModuleState({ ...state });
    toggleUpdateModal(true);
    return updateTypeFormik.setValues({ ...state });
  });

  const handleCancelUpdateModalCallback = useCallback(() => {
    setModuleState({});
    toggleUpdateModal(false);
    return updateTypeFormik.setValues({});
  });

  /** ----------------------------------------------------------------------------------------------------------------------------------- */

  const dataTableHandler = () => {
    const columns = [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type Name",
        field: "typeName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 270,
      }
    ];
    const rows = [
      ...typesList.map((details, index) => ({
        sr: ++index,
        typeName: details.name,
        status: details.status ? details.status : "Active",
        action: (
          <div className="functionalBtn editDataShow">
            <MountComponent condition={userPermissions.PRODUCT_MASTERS_UPDATE}>
              <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
              </button>
            </MountComponent>
            <MountComponent condition={userPermissions.PRODUCT_MASTERS_DELETE}>
              <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
              </button>
            </MountComponent>
          </div>
        ),
      })),
    ];

    if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
      columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
    }

    return updateDataTable({ columns: [...columns], rows: [...rows] });
  };

  /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { if (tab === "TYPES_TAB") { getTypes(); } }, [tab]);
  useEffect(() => {
    if (tab === "TYPES_TAB") { typeFormik.resetForm(); updateTypeFormik.resetForm(); }
  }, [tab]);

  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
  }, [notificationState.visible]);

  useEffect(() => { dataTableHandler() }, [typesList]);

  /** ----------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <div className="">
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible}>
        {notificationState.message}
      </Alert>

      <MountComponent condition={userPermissions.PRODUCT_MASTERS_CREATE}>
        <form className="row mainText" onSubmit={typeFormik.handleSubmit}>
          <div className="col-12 bg-transparent pe-0 mb-2" style={{ height: '40px' }}><div className="mainFormSubmit">
            <div className="error-input">
              <Input placeholder="Please enter Type" className="h-100" name="name"
                invalid={typeFormik.touched.name && typeFormik.errors.name}
                value={typeFormik.values.name}
                onChange={typeFormik.handleChange}
              ></Input>
              <MountComponent condition={typeFormik.touched.name && typeFormik.errors.name}>
                <FormFeedback valid={typeFormik.isValid}>{typeFormik.errors.name}</FormFeedback>
              </MountComponent>
            </div>
            <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
          </div></div>
        </form>
      </MountComponent>

      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={dataTable} footer={false} />
      </div>

      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          handleConfirmDeleteCallback={handleConfirmDeleteCallback}
          message={`Are you sure you want to delete ${moduleState.name} ?`}
        ></DeleteModal>
      </Modal>{/* Modal for delete type */}

      <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Update Type</h5>
          <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
        </div>
        <Form className="needs-validation" onSubmit={updateTypeFormik.handleSubmit}>
          <div className="modal-body">
            <FormGroup className="mb-3">
              <Label htmlFor="name">Type</Label>
              <Input name="name" placeholder="Type name" type="text" className="form-control" id="name"
                onChange={updateTypeFormik.handleChange}
                onBlur={updateTypeFormik.handleBlur}
                value={updateTypeFormik.values.name || ""}
                invalid={updateTypeFormik.touched.name && updateTypeFormik.errors.name ? true : false}
              />
              <MountComponent condition={updateTypeFormik.touched.name && updateTypeFormik.errors.name}>
                <FormFeedback type="invalid"> {updateTypeFormik.errors.name} </FormFeedback>
              </MountComponent>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label htmlFor="status">Status</Label>
              <Select
                options={optionStatus}
                placeholder="Select Status"
                onChange={({ value }) => { updateTypeFormik.setFieldValue("status", value); }}
                value={optionStatus.find(({ value }) => value === updateTypeFormik.values.status) ?? null}
                theme={(theme) => themeReactSelect(theme, updateTypeFormik.touched.status && updateTypeFormik.errors.status)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateTypeFormik.touched.status && updateTypeFormik.errors.status) }}
              />
              <MountComponent condition={updateTypeFormik.touched.status && updateTypeFormik.errors.status}>
                <small className="select-error">{updateTypeFormik.errors.status}</small>
              </MountComponent>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>Cancel</button>
            <button type="submit" className="nextMasterModal btn">Update</button>
          </div>
        </Form>
      </Modal>{/* Modal for update type */}
    </div>
  );
});
