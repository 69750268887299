import React from "react";
import lodash from "lodash";

export default React.memo(() => {
    const session  = JSON.parse(localStorage.getItem("authUser"));
    const username = lodash.get(session,'data.firstName','');

  return (
      <div className=""><div className="dashCard">
          <h2>Welcome <span style={{ textTransform: "capitalize" }}>{ username }</span></h2>
          <p>
            Connect, Engage, Analyze, Grow your channel network all in one
            platform. connect your Brand with Sales Teams & Channel Partners to
            get better Visibility, Communication, Collaboration & Engagement,
            create Trust and Loyalty amongst your Network providing rich data
            analytics for quick decisions, resulting in an efficient sales
            operation for faster Business Growth.
          </p>
      </div></div>
); });

 