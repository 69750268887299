import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

import logo from "../../../../assets/images/logo-dark.png";
import cornerImage from "../../../../assets/images/corner.png";

import socialImageFacebook from "../../../../assets/images/facebook.png";
import socialImageInstagram from "../../../../assets/images/instagram.png";
import socialImageLinkedin from "../../../../assets/images/Linkedin.png";
import socialImageTwitter from "../../../../assets/images/twitter.png";

import ROUTES from "../../../../routes/routes"

export default React.memo(() => (
  <React.Fragment>
    <div className="account-pages boxCenter">
      <Container>
        <Row className="shadowBox mt-6">
          <Col xs={12}>
            <div className="thanksLogoColumn">
              <img src={logo} alt="" />
            </div>
            <div className="thanksContent">
              <p className="thanksHead">Thank you for registering with Giffy Network</p>
              <p className="thanksSubHead">
                Join the Giffy Network community to stay updated on how to
                get more out of your channel networks
              </p>
              <div className="socialIcons">
                <a target="_blank" href="https://www.linkedin.com/company/giffy-network/"><img src={socialImageLinkedin} alt="" className="socialMedia" /></a>
                <a target="_blank" href="https://twitter.com/giffynetwork"><img src={socialImageTwitter} alt="" className="socialMedia" /></a>
                <a target="_blank" href="https://www.facebook.com/giffynetwork/"><img src={socialImageFacebook} alt="" className="socialMedia" /></a>
                <a target="_blank" href="https://www.instagram.com/giffynetwork/"><img src={socialImageInstagram} alt="" className="socialMedia" /></a>
              </div>
            </div>
            <div className="proceedSection">
              <Link to={ROUTES.AUTHENTICATION.LOGIN}><button className="proceedButton">Proceed to Login</button></Link>
            </div>
            <div>
              <img src={cornerImage} alt="" className="thanksCornerImage" />
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  </React.Fragment>
));
