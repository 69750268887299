import React from "react";

import MountComponent from "../../module/mount-components";
import NetworkModule from "../../module/network-module.components.jsx";

import RightSideProfile from "../Dashboard/right-side-profile";

import NetworkManagerGuide from "../Network-Manager-Module/network-tab.jsx";
import NetworkEngageGuide from "../Network-Engage-Module/network-tab.jsx";
import CatalogManagerGuide from "./network-tab.jsx";
import LoyaltyManagerGuide from "../Loyalty-Manager-Module/network-tab.jsx"

import StartGuide from "./start-guide.jsx";
import Content from "./content.jsx";

import PERMISSIONS from "../../routes/permissions";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Network Manager | Giffy Network";
    const showGirl = false;

    return (
    <React.Fragment>
      <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-12 mt-1"><Content></Content></div>
            <div className="col-12 mt-2">
                <NetworkModule defaultActive={PERMISSIONS.CATALOG_MANAGER.NAME}>
                    <NetworkModule.NetworkManagerTab>
                        <NetworkManagerGuide></NetworkManagerGuide>
                    </NetworkModule.NetworkManagerTab>

                    <NetworkModule.NetworkEngageTab>
                        <NetworkEngageGuide></NetworkEngageGuide>
                    </NetworkModule.NetworkEngageTab>

                    <NetworkModule.CatalogManagerTab>
                        <CatalogManagerGuide></CatalogManagerGuide>
                    </NetworkModule.CatalogManagerTab>
                    
                    <NetworkModule.LoyaltyManagerTab>
                        <LoyaltyManagerGuide></LoyaltyManagerGuide>
                    </NetworkModule.LoyaltyManagerTab>
                </NetworkModule>
            </div>
            <div className="col-12 mt-3"><StartGuide></StartGuide></div>
            <div className="col-lg-3 col-md-12">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
      </div></div></div>
    </React.Fragment>
); };
