import React from "react";
import lodash from "lodash";
import Multiselect from "react-multiselect-checkboxes";

const ConditionalRender = React.memo(({ condition, children }) => condition === true ? children : <></>);

const Select = React.memo((props) => {
  const getDropdownButtonLabel = ({ placeholderButtonLabel: label, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${label}: All`;
    } else { return `${label}: ${value.length} selected`; }
  }

  const handleSelect = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      props.updateValue(event.name, [{ label: "All", value: "*" }, ...props.options]);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      props.updateValue(event.name, []);
    } else if (event.action === "deselect-option") {
      props.updateValue(event.name, value.filter((o) => o.value !== event.option.value));
    } else { props.updateValue(event.name, value); }
  }

  const options = props.name === "pinCodes" ? [...props.options] : [{ label: "All", value: "*" }, ...props.options];

  return (
    <React.Fragment>
      <Multiselect options={[...options]} width={'100%'} placeholderButtonLabel={props.label} id={props.label} name={props.name} getDropdownButtonLabel={getDropdownButtonLabel}
        value={props.value}
        onChange={handleSelect}
      ></Multiselect>
      <ConditionalRender condition={!lodash.isUndefined(props.hasError)}>
        <p style={{ margin: '5px 0px 0px 5px', color: '#bf2828' }}>{props.error}</p>
      </ConditionalRender>
    </React.Fragment>
  );
});

export default Select;
