import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";

import ReactSelect from "react-select";
import { Alert, Modal, Button, Input, FormFeedback, Form, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";

import { getDivisionsAPI } from "../../../../api/division.api";
import { deleteSalesHierarchyAPI, getSalesHierarchiesAPI, storeSalesHierarchyAPI, updateSalesHierarchyAPI } from "../../../../api/sales-hierarchy.api";

import MountComponent from "../../../../module/mount-components";
import { errorMessage } from "../../../../module/error-methods";

import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods";
import DeleteModal from "../../../../module/delete-modal.component";

export default React.memo(({ tab, userPermissions }) => {
    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [divisionOptions, setDivisionsOptions] = useState([]);
    const [salesHierarchy, setSalesHierarchies] = useState([]);
    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const divisionList = async () => {
        await getDivisionsAPI().then(({ data }) => {
            if (lodash.isArray(data)) {
                const options = data.map(({ name, id }) => ({ label: name, value: id }));
                return setDivisionsOptions([...options])
            }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
    };

    const salesHierarchyList = async () => {
        setLoading(true);
        await getSalesHierarchiesAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setSalesHierarchies([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteSalesHierarchyAPI(moduleState.id);
            await salesHierarchyList();
            toggleNotificationState({ visible: true, message: `"${moduleState.name}" Deleted successfully !` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateSalesHierarchyFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateSalesHierarchyFormik.setValues({});
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const salesHierarchyInitialValues = {
        name: '', divisionId: null,
    };

    const salesHierarchyValidation = {
        divisionId: Yup.string().required("Please select division."),
        name: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters and spaces are allowed')
            .min(3, 'Name must be at least 3 characters').trim()
            .required('Name is required'),
    };

    const updateSalesHierarchyFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...salesHierarchyInitialValues },
        validationSchema: Yup.object({ ...salesHierarchyValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                const { id, divisionId, name } = values;

                await updateSalesHierarchyAPI(id, { divisionId, name });
                await salesHierarchyList();

                toggleNotificationState({ visible: true, message: 'Sales Hierarchy updated successfully !' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); }
        },
    });

    const salesHierarchyFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...salesHierarchyInitialValues },
        validationSchema: Yup.object({ ...salesHierarchyValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);

                await storeSalesHierarchyAPI({ ...salesHierarchyInitialValues, ...values });
                await salesHierarchyList();

                toggleNotificationState({ visible: true, message: 'Sales Hierarchy created successfully !' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); }
        },
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const dataTableHandler = () => {
        const columns = [
            {
                label: "Sr.No.",
                field: "sr",
                sort: "asc",
                width: 150,
            }, {
                label: "SBU Name",
                field: "divisionName",
                sort: "asc",
                width: 270,
            }, {
                label: "Hierarchy Name",
                field: "name",
                sort: "asc",
                width: 270,
            }
        ];
        const rows = [
            ...salesHierarchy.map((details, index) => ({
                sr: ++index,
                name: `${details.name} (${details.id})`,
                divisionName: `${details.division.name} (${details.division.id})`,
                id: details.id,
                action: (
                    <div className="functionalBtn editDataShow">
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_DELETE}>
                            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                    </div>
                ),
            })),
        ];

        if (userPermissions.NETWORK_DETAILS_UPDATE || userPermissions.NETWORK_DETAILS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
        }

        return updateDataTable({ columns: [...columns], rows: [...rows] });
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => {
        if (tab === "SALES_HIERARCHY_TAB") { divisionList(); salesHierarchyList(); }
        return () => null;
    }, [tab]);

    useEffect(() => {
        if (tab === "SALES_HIERARCHY_TAB") { salesHierarchyFormik.resetForm(); updateSalesHierarchyFormik.resetForm(); }
        return () => null;
    }, [tab]);

    useEffect(() => {
        if (notificationState.visible) {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
        }
    }, [notificationState.visible]);

    useEffect(() => { dataTableHandler() }, [salesHierarchy]);
    /** ---------------------------------------------------------------------------------------------------------------------------------- */


    return (
        <div className="">
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <MountComponent condition={userPermissions.NETWORK_DETAILS_CREATE}>
                <form className="row mainText" onSubmit={salesHierarchyFormik.handleSubmit}>
                    <div className="mt-1 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 bg-transparent" style={{ height: '3em' }}>
                        <ReactSelect
                            options={divisionOptions}
                            placeholder="Select Division/SBU"
                            onChange={({ value }) => { salesHierarchyFormik.setFieldValue("divisionId", value); }}
                            value={divisionOptions.find(({ value }) => value === salesHierarchyFormik.values.divisionId) ?? null}
                            theme={(theme) => themeReactSelect(theme, salesHierarchyFormik.touched.divisionId && salesHierarchyFormik.errors.divisionId)}
                            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, salesHierarchyFormik.touched.divisionId && salesHierarchyFormik.errors.divisionId) }}
                        ></ReactSelect>
                        <MountComponent condition={salesHierarchyFormik.touched.divisionId && salesHierarchyFormik.errors.divisionId}>
                            <small className="select-error">{salesHierarchyFormik.errors.divisionId}</small>
                        </MountComponent>
                    </div>
                    <div className="mt-1 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 bg-transparent" style={{ height: '40px' }}>
                        <div className="mainFormSubmit">
                            <div className="error-input">
                                <Input placeholder="Please enter sales hierarchy name." className="h-100" name="name"
                                    invalid={salesHierarchyFormik.touched.name && salesHierarchyFormik.errors.name}
                                    value={salesHierarchyFormik.values.name}
                                    onChange={salesHierarchyFormik.handleChange}
                                ></Input>
                                <FormFeedback valid={!salesHierarchyFormik.touched.name && !salesHierarchyFormik.errors.name}>{salesHierarchyFormik.errors.name}</FormFeedback>
                            </div>
                            <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
                        </div>
                    </div>

                </form>
            </MountComponent>

            <div className="dataTable-hide-extra-props mt-3">
                <MDBDataTable responsive bordered data={dataTable} footer={false} />
            </div>

            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${moduleState.name} ?`}
                ></DeleteModal>
            </Modal>
            {/* Modal for Delete Sales Hierarchy */}

            <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Update Sales Hierarchy</h5>
                    <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
                </div>
                <Form className="needs-validation" onSubmit={updateSalesHierarchyFormik.handleSubmit}>
                    <div className="modal-body"><div className="form-group mb-3">
                        <Label className="bg-transparent mb-1"><strong>Division Name</strong></Label>
                        <ReactSelect
                            options={divisionOptions}
                            placeholder="Select Division/SBU"
                            onChange={({ value }) => { updateSalesHierarchyFormik.setFieldValue("divisionId", value); }}
                            value={divisionOptions.find(({ value }) => value === updateSalesHierarchyFormik.values.divisionId) ?? null}
                            theme={(theme) => themeReactSelect(theme, updateSalesHierarchyFormik.errors.divisionId)}
                            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateSalesHierarchyFormik.errors.divisionId) }}
                        ></ReactSelect>
                        <MountComponent condition={updateSalesHierarchyFormik.touched.divisionId && updateSalesHierarchyFormik.errors.divisionId}>
                            <small className="select-error">{updateSalesHierarchyFormik.errors.divisionId}</small>
                        </MountComponent>

                        <div className="mt-3 h-100 bg-transparent">
                            <Label className="bg-transparent mb-0"><strong>Sales Hierarchy Name</strong></Label>
                            <Input placeholder="Please enter sales hierarchy name." className="mt-1" name="name"
                                invalid={updateSalesHierarchyFormik.errors.name}
                                value={updateSalesHierarchyFormik.values.name}
                                onChange={updateSalesHierarchyFormik.handleChange}
                            ></Input>
                            {(updateSalesHierarchyFormik.touched.name && updateSalesHierarchyFormik.errors.name) && (<FormFeedback type="invalid"> {updateSalesHierarchyFormik.errors.name} </FormFeedback>)}
                        </div>

                    </div></div>

                    <div className="modal-footer">
                        <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>
                            Cancel
                        </button>
                        <button type="submit" className="nextMasterModal btn">
                            Update
                        </button>
                    </div>
                </Form>
            </Modal>
            {/* Modal for Edit Sales Hierarchy */}
        </div>
    )
});
