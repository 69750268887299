import React, { useState, useEffect } from "react";
import { Col, Row, Form, Label, Input, Alert } from "reactstrap";
import "../../../../assets/scss/custom/pages/datatables.scss";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import lodash from "lodash";
import { MountCondition } from "../../../../module/mount-components";
import getDivisionsAPI from "../../../../api/getDivisionsAPI";
import getRegionAPI from "../../../../api/getRegionAPI";
import getNetworkLocationsAPI from "../../../../api/getNetworkLocationsAPI";
import getRegionByDivisionAPI from "../../../../api/getRegionByDivisionAPI";
import getNetworkSalesmenListAPI from "../../../../api/getNetworkSalesmenListAPI";
import getNetworkChannelPartnerAPI from "../../../../api/getNetworkChannelPartnerAPI";
import saveAudiencesAPI from "../../../../api/saveAudiencesAPI";
import getRegionDetailsAPI from "../../../../api/getRegionDetailsAPI";
import getSalesDesignationAPI from "../../../../api/getSalesDesignationAPI";
import getDistributionDesignationAPI from "../../../../api/getDistributionDesignationAPI";
import MountComponent from "../../../../module/mount-components";

export default () => {
  const navigate = useNavigate();
  // const { id } = useParams();

  const [salesOptions, setSalesOptions] = useState([]);
  const [salesmanOptions, setSalesmanOptions] = useState([]);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);

  const [salesData, setSalesData] = useState([]);
  const [salesmenData, setSalesmenData] = useState([]);

  const [divisionData, setDivisionData] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);

  const [locationData, setLocationData] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const [locationDetails, setLocationDetails] = useState([]);

  const [regionData, setRegionData] = useState([]);
  const [distributionData, setDistributionData] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [distributionOptions, setDistributionOptions] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedHierarchy, setSelectedHierarchy] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [regions, setRegions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  const [initiallySelectedRows, setInitiallySelectedRows] = useState([]);

  const [isDivisionSelected, setIsDivisionSelected] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  const [loading, setLoading] = useState(false);

  const notificationInitialState = { visible: false, message: '', color: '' }
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      source: "",
      audienceRows: [{ networkSalesmenId: "" }],
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Audience Name"),

      source: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Select User"),
    }),

    onSubmit: async (values) => {
      try {
        const networkSalesmenIds = checkedRows;
        if (networkSalesmenIds.length == 0 && saveClicked) {
          toggleNotificationState({ visible: true, color: "danger", message: `Please select at least one ${selectedUser.label}` })
        }

        const updatedAudienceRows = networkSalesmenIds?.map((id) => ({
          networkSalesmenId: id,
        }));

        const newValues = lodash.cloneDeep(values);
        lodash.set(newValues, "audienceRows", updatedAudienceRows);

        validation.setValues(newValues);
        // Update the values object in Formik

        await saveAudiencesAPI(newValues);
        toggleNotificationState({ visible: true, color: "success", message: "Audience saved successfully" })
        setTimeout(() => {
          navigate("/network-engage/audience");
        }, 1500);
      } catch (error) {
        // toggleNotificationState({ visible: true, color: "danger", message: "Error" })
      }
    },
  });


  const styleReactSelect = (baseStyles, state, error) => {
    return {
      ...baseStyles,
      "&:hover": {
        boxShadow: error ? "0 0 0 1px #dc3545" : "0 0 0 1px #ced4da",
        outlineColor: error ? "#dc3545" : "#ced4da",
        borderColor: error ? "#f46a6a" : "#ced4da",
        borderWidth: "1px",
      },
      borderColor: error ? "#f46a6a" : "#ced4da",
    };
  };

  const themeReactSelect = (theme, error) => {
    return {
      ...theme,
      boxShadow: "none",
      border: "solid 0.1px",
      outline: "none",
      borderWidth: "0.1px",
      colors: { ...theme.colors, primary: error ? "#dc3545" : "#99a7d9" },
    };
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    validation.setFieldValue(
      "source",
      selectedOption ? selectedOption.value : ""
    );
    setSelectedDivision(null);
    setSelectedHierarchy(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setAreAllChecked(false)
    setCheckedRows([]);
    setSalesOptions([]);
    setDistributionOptions([]);
  };

  const handleLocationChange = async (selectedOption) => {
    setSelectedLocation(selectedOption);
    setAreAllChecked(false)
    setCheckedRows([]);
  };

  const handleRegionChange = async (selectedOption) => {
    setSelectedRegion(selectedOption);
    setSelectedRegion((prevSelectedDivision) => {
      validation.setFieldValue("regionId", prevSelectedDivision?.value);
      return prevSelectedDivision;
    });

    try {
      const { data } = await getNetworkLocationsAPI(selectedRegion.value);
      const locationDetails = data;
      const options = locationDetails?.map((location) => ({
        value: location?.id,
        label: location?.location,
      }));

      setLocationData(locationDetails);
      setLocationOptions(options);
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
    setSelectedLocation(null);
    setAreAllChecked(false)
    setCheckedRows([]);
  };

  const handleHierarchyChange = (selectedOption) => {
    setSelectedHierarchy(selectedOption);
    setAreAllChecked(false)
    setCheckedRows([]);
  };

  const handleDivisionChange = (selectedOption) => {

    setSelectedDivision(selectedOption);
    setSelectedDivision((prevSelectedDivision) => {
      validation.setFieldValue("divisionId", prevSelectedDivision?.value);
      return prevSelectedDivision;
    });
    setIsDivisionSelected(true);
    setSelectedHierarchy(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setAreAllChecked(false)
    setCheckedRows([]);
  };


  const getRegions = async () => {
    try {
      const { data } = await getRegionAPI();
      if (data) {
        const regionData = data;
        const options = regionData?.map((region) => ({
          value: region.id,
          label: region.name,
        }));
        setRegionData(regionData);
        setRegionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getRegions();
  }, []);

  const getRegionData = async () => {
    try {
      const { data } = await getRegionDetailsAPI();
      if (data) {
        const regionData = data;
        const options = regionData?.map((region) => ({
          value: region.id,
          label: region.name,
        }));
        setRegionData(regionData);
        setRegionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getRegionData();
  }, []);

  const getDistributionData = async (divisionId) => {
    try {
      const { data } = await getDistributionDesignationAPI(divisionId);
      if (data) {
        const distributionData = data;
        const options = distributionData?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));
        setDistributionData(data);
        setDistributionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDistributionData(selectedDivision?.value);
  }, [selectedDivision]);

  const getRegionName = (regionId) => {
    const region = regionData.find((r) => r.id === regionId);
    return region ? region.name : "";
  };

  const getDivisionData = async () => {
    try {
      const { data } = await getDivisionsAPI();
      if (data) {
        const divisionData = data;
        const options = divisionData?.map((division) => ({
          value: division?.id,
          label: division?.name,
        }));
        setDivisionData(divisionData);
        setDivisionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDivisionData();
  }, []);

  const getSalesman = async () => {
    try {
      const { data } = await getNetworkSalesmenListAPI();

      if (data) {
        const userData = data;
        const options = userData?.map((user) => ({
          value: user?.salesHierarchy?.id,
          label: user?.salesHierarchy?.name,
        }));
        setSalesmenData(data);
        setSalesmanOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSalesman();
  }, []);

  const getDistributions = async () => {
    try {
      const { data } = await getNetworkChannelPartnerAPI();
      if (data) {
        const distributionData = data;
        const options = distributionData?.map((user) => ({
          value: user?.distributionHierarchy?.id,
          label: user?.distributionHierarchy?.name,
        }));
        setDistributions(data);
        setDistributionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDistributions();
  }, []);

  useEffect(() => {
    if (selectedDivision) {
      if (selectedUser?.value === "SALES_HIERARCHY") {
        const filteredSalesData = salesData?.filter(
          (user) => user?.divisions?.name === selectedDivision?.label
        );
        const uniqueSalesHierarchies = Array?.from(
          new Set(filteredSalesData?.map((user) => user?.salesHierarchy?.name))
        );
        const hierarchyOptions = uniqueSalesHierarchies?.map((name) => ({
          value: name,
          label: name,
        }));
        setHierarchyOptions(hierarchyOptions);
      } else if (selectedUser?.value === "DISTRIBUTION_HIERARCHY") {
        const filteredDistributionData = distributionData?.filter(
          (user) => user?.divisions?.name === selectedDivision?.label
        );
        const uniqueDistributionHierarchies = Array?.from(
          new Set(
            filteredDistributionData?.map(
              (user) => user?.distributionHierarchy?.name
            )
          )
        );
        const hierarchyOptions = uniqueDistributionHierarchies?.map((name) => ({
          value: name,
          label: name,
        }));
        setHierarchyOptions(hierarchyOptions);
      }
    }
  }, [selectedDivision, salesData, distributionData, selectedUser]);

  const getSalesData = async (divisionId) => {
    try {
      const { data } = await getSalesDesignationAPI(divisionId);
      if (data) {
        const userData = data;
        const options = userData?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));
        setSalesData(data);
        setSalesOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSalesData(selectedDivision?.value);
  }, [selectedDivision]);

  const resetFilters = () => {
    setSelectedDivision(null);
    setSelectedHierarchy(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setSalesOptions([]);
    setDistributionOptions([]);
    setRegions([]);
    setLocationOption([]);
    setAreAllChecked(false)
    setCheckedRows([]);
    getSalesData();
    setSaveClicked(false);
  };

  const regionList = async (divisionId) => {
    try {
      const response = await getRegionByDivisionAPI(divisionId);
      if (response) {
        const regionData = response.data;
        const options = regionData?.map((state) => ({
          value: state?.id,
          label: state?.name,
        }));
        setRegions(options);
      }
    } catch (error) {
      console.log("Error reg", error);
    }
  };
  useEffect(() => {
    regionList(selectedDivision?.value);
  }, [selectedDivision]);

  const getLocationsData = async (regionId) => {
    try {
      const { data } = await getNetworkLocationsAPI(regionId);
      if (data) {
        const locationDetails = data;
        const options = locationDetails?.map((location) => ({
          value: location?.id,
          label: location?.location,
        }));
        setLocationDetails(locationDetails);
        setLocationOption(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLocationsData(selectedRegion?.value);
  }, [selectedRegion]);

  const handleCheckboxChange = (id) => {
    const isChecked = checkedRows.includes(id);
    setCheckedRows((prevRows) =>
      isChecked ? prevRows.filter((rowId) => rowId !== id) : [...prevRows, id]
    );
    
  };

  const checkAllHandler = () => {
    setAreAllChecked(!areAllChecked);
    const allRows = selectedUser?.value === "SALES_HIERARCHY" ? salesmenData : distributions;
  
    if (initiallySelectedRows.length === 0) {
      setInitiallySelectedRows(allRows.map((data) => data.id));
    }
    
    const filteredRows = allRows.filter(
      (item) =>
        (selectedDivision ? item?.divisions?.name === selectedDivision?.label : true) &&
        (selectedHierarchy
          ? selectedUser?.value === "SALES_HIERARCHY"
            ? item?.salesHierarchy?.name === selectedHierarchy?.label
            : item?.distributionHierarchy?.name === selectedHierarchy?.label
          : true) &&
        (selectedRegion
          ? getRegionName(item?.networkLocation?.regionId) === selectedRegion?.label
          : true) &&
        (selectedLocation ? item?.networkLocation?.location === selectedLocation?.label : true)
    );
  
    const allRowIds = filteredRows.map((data) => data.id);
  
    setCheckedRows((prevRows) =>
      areAllChecked ? [] : [...prevRows, ...allRowIds]
    );
  };

  const [areAllChecked, setAreAllChecked] = useState(false);

  const data = {
    columns: [
      {
        label: (<input
        class="form-check-input"
        type="checkbox"
        value=""
        onChange={checkAllHandler}
        checked={areAllChecked}
        />),
        field: "check",
        sort: "disabled",
        width: 20,
      },
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile No",
        field: "mobileNo",
        sort: "asc",
        width: 150,
      },
      {
        label: "email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU",
        field: "sbuName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region",
        field: "region",
        sort: "asc",
        width: 270,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 270,
      },
    ],
    rows: [
      ...(selectedUser?.value === "SALES_HIERARCHY"
        ? salesmenData
        : distributions
      )
        .filter(
          (item) =>
            (selectedDivision
              ? item?.divisions?.name === selectedDivision?.label
              : true) &&
            (selectedHierarchy
              ? selectedUser?.value === "SALES_HIERARCHY"
                ? item?.salesHierarchy?.name === selectedHierarchy?.label
                : item?.distributionHierarchy?.name === selectedHierarchy?.label
              : true) &&
            (selectedRegion
              ? getRegionName(item?.networkLocation?.regionId) ===
                selectedRegion?.label
              : true) &&
            (selectedLocation
              ? item?.networkLocation?.location === selectedLocation?.label
              : true)
        )
        ?.map((data, i) => ({
          check: (
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id={`row${i}`}
              onChange={() => handleCheckboxChange(data.id)}
              checked={checkedRows.includes(data.id)}
            />
          ),
          sr: ++i,
          name: data?.firstName + data?.lastName,
          mobileNo: data?.mobileNo,
          email: data?.email,
          sbuName: data?.divisions?.name,
          designation: data?.salesHierarchy
            ? data?.salesHierarchy?.name
            : data?.distributionHierarchy?.name,
          region: getRegionName(data?.networkLocation?.regionId),
          location: data?.networkLocation?.location,
        })),
    ],
  };

  const userOption = [
    {
      options: [
        { label: "Salesmen", value: "SALES_HIERARCHY" },
        { label: "Distributors", value: "DISTRIBUTION_HIERARCHY" },
      ],
    },
  ];

  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
    return () => null;
  }, [notificationState.visible]);

  return (
    <React.Fragment>
      <MountCondition condition={loading}>
        <MountCondition.True>
          <div className="fullscreen-loader">
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          </div>
        </MountCondition.True>
      </MountCondition>

      <MountComponent condition={notificationState.visible}>
        <Alert className="pt-2 pb-2" color={notificationState.color}>
          { notificationState.message }
        </Alert>
      </MountComponent>

      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <div className="submitForm">
            <Col lg={12}>
              <h4 className="mt-3 fw-bold mb-3 d-flex justify-content-center">
                Add Audience
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    <Form>
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Audiences List Name
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Enter Audiences List Name"
                          className="form-control"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Form>
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="mt-4">
                    <div className="mb-0">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        Select User
                      </Label>

                      <Select
                        className=""
                        name="source"
                        isSearchable
                        options={userOption}
                        onChange={handleUserChange}
                        styles={{
                          control: (baseStyles, state) =>
                            styleReactSelect(
                              baseStyles,
                              state,
                              validation.errors.source
                            ),
                        }}
                        theme={(theme) =>
                          themeReactSelect(theme, validation.errors.source)
                        }
                        value={userOption.find(
                          (option) => option.value === validation.values.source
                        )}
                      ></Select>
                      <small class="select-error">{validation.errors.source}</small>
                    </div>
                  </div>
                </Col>

                <div className="addNewBtn">
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        Filters
                      </Label>
                    </div>
                    <div className="col-md-6">
                      <div className="locationBtn">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            resetFilters();
                          }}
                        >
                          Reset Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dataTable">
                  <Row>
                    <Col lg={3} md={3}>
                      <div className="mt-4">
                        <div className="mb-0">
                          <Select
                            className=""
                            name="divisionId"
                            isSearchable
                            options={divisionOptions}
                            placeholder="Select SBU"
                            onChange={handleDivisionChange}
                            value={selectedDivision}
                          >
                          </Select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={3}>
                      <div className="mt-4">
                        <div className="mb-0">
                          <Select
                            className=""
                            isSearchable
                            options={isDivisionSelected && selectedUser?.label == "Salesmen" ? salesOptions : distributionOptions}
                            onChange={handleHierarchyChange}
                            placeholder="Select Hierarchy"
                            value={selectedHierarchy}
                            noOptionsMessage={() => "Select Division"}
                          ></Select>
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} md={3}>
                      <div className="mt-4">
                        <div className="mb-0">
                          <Select
                            className=""
                            isSearchable
                            name="regionId"
                            options={isDivisionSelected ? regions : []}
                            onChange={handleRegionChange}
                            placeholder={"Select Region"}
                            value={selectedRegion}
                            noOptionsMessage={() => "Select Division"}
                          ></Select>
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} md={3}>
                      <div className="mt-4">
                        <div className="mb-0">
                          <Select
                            className=""
                            isSearchable
                            options={isDivisionSelected ? locationOption : []}
                            onChange={handleLocationChange}
                            placeholder={"Select Location"}
                            value={selectedLocation}
                            noOptionsMessage={() => "Select Region"}
                          ></Select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="dataTable mt-4">
                    {selectedUser && (
                      <MDBDataTable
                        responsive
                        bordered
                        data={data}
                        checkbox
                        footer={false}
                      />
                    )}
                  </div>
                </div>
              </Row>
            </Col>
            <Col
              lg={12}
              className="d-flex"
              style={{ justifyContent: "center" ,marginTop:"2em"}}
            >
              <div className="saveBtn">
                <button className="btn btn-group" type="submit" onClick={() => setSaveClicked(true)}>
                  Save
                </button>
              </div>
              <div className="saveBtn">
                <Link></Link>
                <button
                  className="btn btn-group"
                  onClick={() => navigate("/network-engage/audience")}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </div>
        </Row>
      </Form>
    </React.Fragment>
  );
};
