import { server } from '../config/config'

export const storeMeasurementAPI = (payload = {}) => server({
    method: 'post',
    url: `measurement`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const getMeasurementsAPI = (queryParams = {}) => server({
    method: 'get',
    url: `measurement`,
    params: { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getMeasurementAPI = (id) => server({
    method: 'get',
    url: `measurement/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateMeasurementAPI = (id, payload = {}) => server({
    method: 'put',
    url: `measurement/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteMeasurementAPI = (id) => server({
    method: 'delete',
    url: `measurement/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
