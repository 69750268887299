import { server } from '../config/config';

export const getStatesListAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `/location/states`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getCitiesListAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `/location/cities`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getPinCodesListAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `/location/pin-codes`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});