import React from "react";
import lodash from "lodash";
import { Link } from "react-router-dom";

import catalog from "../../assets/images/catalog-banner.png";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

import { useNetworkHook, useHttpRequest } from "../../module/network-tab-methods";
import MountComponent from "../../module/mount-components";

export default React.memo(() => {
    const { NAME, CHILDREN: { PRODUCT_MASTERS, PRODUCT } } = PERMISSIONS.CATALOG_MANAGER;
    const totalModules = {
        [PRODUCT_MASTERS.NAME]: { label: 'Start Catalog Manager', target: PRODUCT_MASTERS.NAME, path: ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS },
        [PRODUCT.NAME]: { label: 'Resume', target: PRODUCT.NAME, path: ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT },
        [NAME]: { label: 'Completed', target: NAME, path: ROUTES.CATALOG_MANAGER.PATH },
    };

    const { array: storedModules } = useHttpRequest({ module: NAME });
    const { step } = useNetworkHook(storedModules, (array) => {
        switch (true) {
            case lodash.isEmpty(array): { return totalModules[PRODUCT_MASTERS.NAME]; }
            case !lodash.includes(array, PRODUCT_MASTERS.NAME): { return totalModules[PRODUCT_MASTERS.NAME]; };
            case !lodash.includes(array, PRODUCT.NAME): { return totalModules[PRODUCT.NAME]; };
            default: { return totalModules[NAME]; };
        }
    });

    return (
        <div className="row">
            <div className="col-md-4">
                <div className="netImage"><img src={catalog} /></div>
            </div>
            <div className="col-md-8">
                <div className="netContent">
                    <h2 className="text-primary">
                        <strong>
                            <span className="pt-2 head-catalog">Enhance your Product Visibility and Sales</span>
                        </strong>
                    </h2>
                    <ul className="hide-bullet-catalog">
                        <li>
                            <strong className="text-black"><i aria-hidden="true" class="fas fa-archive mx-2"></i>Dynamic Online Catalog</strong>
                            <ul className="mt-1">
                                <li className="text-black">Add, Edit Products as per brand/category</li>
                                <li className="text-black">Multi level Pricing</li>
                                <li className="text-black">Discounts & Promotions</li>
                            </ul>
                        </li>
                        <li className="mt-1 text-black"><strong><i aria-hidden="true" class="fas fa-archive mx-2"></i>Improved visibility of product range to Channels</strong></li>
                        <li className="mt-1 text-black"><strong><i aria-hidden="true" class="fas fa-archive mx-2"></i>Better presentation of product range</strong></li>
                        <li className="mt-1 text-black"><strong><i aria-hidden="true" class="fas fa-archive mx-2"></i>Always available product information</strong></li>
                        <li className="mt-1 text-black"><strong><i aria-hidden="true" class="fas fa-archive mx-2"></i>Easy sharing & collaboration</strong></li>
                        <li className="mt-1 text-black">
                            <strong><i aria-hidden="true" class="fas fa-archive mx-2"></i>Instant Updates</strong>
                            <ul className="mt-1">
                                <li>Catalog changes</li>
                                <li>New product Launches</li>
                                <li>New pricing</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <MountComponent condition={(step.target !== NAME)}>
                    <Link to={step?.path} className="btn btn-primary w-auto networkBtn">
                        {step?.label}
                    </Link>
                </MountComponent>
            </div>
        </div>
    );
});
