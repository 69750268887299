import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import store from "store";
import lodash from "lodash";

import { useFormik } from "formik";
import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";

import Select from "react-select";
import { Alert, Modal, Button, Label, Input, FormFeedback, Form } from "reactstrap";

import Quest from "../../../../assets/images/icon/question.png";

import { getDivisionsAPI } from "../../../../api/division.api";
import { checkPermissions } from "../../../../api/organization-users.api";
import { deleteGeographyRegionAPI, getGeographyRegionsAPI, storeGeographyRegionAPI, updateGeographyRegionAPI } from "../../../../api/geography-regions.api";

import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods";
import { errorMessage } from "../../../../module/error-methods";

import MountComponent from "../../../../module/mount-components";
import DeleteModal from "../../../../module/delete-modal.component";

import PERMISSIONS from "../../../../routes/permissions";

export default React.memo((props) => {
  const userSession = store.get('session');
  const userDetails = lodash.get(userSession, 'data', {});

  const { CHILDREN: { GEOGRAPHY } } = PERMISSIONS.NETWORK_MANAGER;

  const [loading, setLoading] = useState(false);

  const [deleteModal, toggleDeleteModal] = useState(false);
  const [updateModal, toggleUpdateModal] = useState(false);

  const [moduleState, setModuleState] = useState({});
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

  const [divisionOptions, setDivisionsOptions] = useState([]);
  const [geographyRegions, setGeographyRegions] = useState([]);

  const [userPermissions, setUserPermissions] = useState({
    GEOGRAPHY_CREATE: userDetails.userRole === "ORGANIZATION" ? true : false,
    GEOGRAPHY_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
    GEOGRAPHY_DELETE: userDetails.userRole === "ORGANIZATION" ? true : false,
  });

  /** @apis ------------------------------------------------------------------------------------------------------------------------- */

  const divisionList = async () => {
    await getDivisionsAPI().then(({ data }) => {
      if (lodash.isArray(data)) {
        const options = data.map(({ name, id }) => ({ label: name, value: id }));
        return setDivisionsOptions([...options])
      }
    }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
  };

  const geographyRegionList = async () => {
    setLoading(true);
    await getGeographyRegionsAPI().then(({ data }) => {
      if (lodash.isArray(data)) { return setGeographyRegions([...data]); }
    }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
    setLoading(false);
  };

  const getPermissions = async () => {
    const modulePermissions = GEOGRAPHY.CHILDREN;
    const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

    await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
      const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
      return setUserPermissions({ ...userPermissions, ...response });
    }).catch(() => { });
  }

  const geographicRegionFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '', divisionId: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters and spaces are allowed')
            .min(3, 'Name must be at least 3 characters')
            .trim().required('Name is required'),
      divisionId: Yup.string().required("Please select division."),
    }),
    onSubmit: async (payload, { resetForm }) => {
      try {
        setLoading(true);

        await storeGeographyRegionAPI(payload);
        await geographyRegionList();

        toggleNotificationState({ visible: true, color: 'success', message: 'Region stored successfully !' });
        return resetForm();

      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); toggleUpdateModal(false); }
    },
  });

  const updateGeographyFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '', divisionId: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters and spaces are allowed')
            .min(3, 'Name must be at least 3 characters')
            .trim().required('Name is required'),
      divisionId: Yup.string().required("Please select division."),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const { id, divisionId, name } = values;

        await updateGeographyRegionAPI(id, { divisionId, name });
        await geographyRegionList();

        toggleNotificationState({ visible: true, color: 'success', message: 'Region updated successfully !' });
        return resetForm();

      } catch (error) {
        toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); toggleUpdateModal(false); }
    },
  });

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU Name",
        field: "sbu",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: geographyRegions.map((details, index) => ({
      sr: ++index,
      sbu: details.division ? details.division.name : '',
      name: `${details.name} (${details.id})`,
      action: (
        <div className="functionalBtn editDataShow">
          <MountComponent condition={userPermissions.GEOGRAPHY_UPDATE}>
            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
              <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
            </button>
          </MountComponent>
          <MountComponent condition={userPermissions.GEOGRAPHY_DELETE}>
            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
              <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
            </button>
          </MountComponent>
        </div>
      ),
    })),
  };

  /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

  const handleConfirmDeleteCallback = useCallback(async () => {
    try {
      await deleteGeographyRegionAPI(moduleState.id);
      await geographyRegionList();
      toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" Deleted successfully !` });
    } catch (error) {
      toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
    } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
  });

  const handleDeleteModalCallback = useCallback((state = {}) => {
    setModuleState({ ...state })
    toggleDeleteModal(true);
  });

  const handleUpdateModalCallback = useCallback((state = {}) => {
    setModuleState({ ...state });
    toggleUpdateModal(true);
    return updateGeographyFormik.setValues({ ...state });
  });

  const handleCancelUpdateModalCallback = useCallback(() => {
    setModuleState({});
    toggleUpdateModal(false);
    return updateGeographyFormik.setValues({});
  });

  /** ----------------------------------------------------------------------------------------------------------------------------------- */

  /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);
  useEffect(() => { divisionList(); geographyRegionList(); }, []);
  useEffect(() => {
    if (notificationState.visible) {
      setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
    }
  }, [notificationState.visible]);

  /** ---------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible} >
        {notificationState.message}
      </Alert>

      <div className="divisionss"><div className="Divisionhead">
        <h5>New label</h5>
        <img src={Quest} />
      </div></div>

      <div className="row mb-4"><div className="col-md-4"><div className="divName">
        <span>Region</span>
      </div></div></div>

      <MountComponent condition={userPermissions.GEOGRAPHY_CREATE}>
        <form className="row mainText" onSubmit={geographicRegionFormik.handleSubmit}>
          <div className="mt-1 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 bg-transparent" style={{ height: '3em' }}>
            <Select
              options={divisionOptions}
              placeholder="Select Division/SBU"
              onChange={({ value }) => { geographicRegionFormik.setFieldValue("divisionId", value); }}
              value={divisionOptions.find(({ value }) => value === geographicRegionFormik.values.divisionId) ?? null}
              theme={(theme) => themeReactSelect(theme, geographicRegionFormik.touched.divisionId && geographicRegionFormik.errors.divisionId)}
              styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, geographicRegionFormik.touched.divisionId && geographicRegionFormik.errors.divisionId) }}
            ></Select>
            <MountComponent condition={geographicRegionFormik.touched.divisionId && geographicRegionFormik.errors.divisionId}>
              <small className="select-error">{geographicRegionFormik.errors.divisionId}</small>
            </MountComponent>
          </div>
          <div className="mt-1 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 bg-transparent" style={{ height: '40px' }}><div className="mainFormSubmit">
            <div className="error-input">
              <Input placeholder="Please enter region name." className="h-100" name="name"
                invalid={geographicRegionFormik.errors.name && geographicRegionFormik.touched.name}
                value={geographicRegionFormik.values.name}
                onChange={geographicRegionFormik.handleChange}
              ></Input>
              <FormFeedback valid={!geographicRegionFormik.errors.name && !geographicRegionFormik.touched.name}>
                {geographicRegionFormik.errors.name}
              </FormFeedback>
            </div>
            <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
          </div></div>
        </form>
      </MountComponent>

      <div className="dataTable-hide-extra-props mt-3">
        <MDBDataTable responsive bordered data={data} footer={false} />
      </div>

      <div className="text-center mt-4">
        <button className="btn btn-primary" onClick={() => { props.switchScreen('multiple-level'); }} >Next</button>
      </div>

      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <DeleteModal
          toggleDeleteModal={() => { toggleDeleteModal(false); }}
          handleConfirmDeleteCallback={handleConfirmDeleteCallback}
          message={`Are you sure you want to delete ${moduleState.name} ?`}
        ></DeleteModal>
      </Modal>{/* Modal for Delete Geography Level */}

      <Modal isOpen={updateModal} toggle={() => { toggleUpdateModal(false); }} backdrop={"static"} scrollable={false} id="staticBackdrop" centered>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Edit Geography</h5>
          <button aria-label="Close" type="button" className="btn-close" onClick={() => { toggleUpdateModal(false); }}></button>
        </div>
        <Form className="needs-validation" onSubmit={updateGeographyFormik.handleSubmit}>
          <div className="modal-body">
            <div className="col-12"><div className="mb-2">
              <Select
                value={divisionOptions.find(({ value }) => value === updateGeographyFormik.values.divisionId) ?? null}
                onChange={({ value }) => { updateGeographyFormik.setFieldValue('divisionId', value); }}
                options={divisionOptions}
                classNamePrefix=""
                className=""
                placeholder="Select Division/SBU"
                theme={(theme) => themeReactSelect(theme, updateGeographyFormik.touched.divisionId && updateGeographyFormik.errors.divisionId)}
                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateGeographyFormik.touched.divisionId && updateGeographyFormik.errors.divisionId) }}
              />
              <MountComponent condition={updateGeographyFormik.touched.divisionId && updateGeographyFormik.errors.divisionId}>
                <small className="select-error">{updateGeographyFormik.errors.divisionId}</small>
              </MountComponent>
            </div></div>
            <div className="col-12"><div className="form-group mb-3">
              <Label htmlFor="">Geography Level name</Label>
              <Input
                name="name"
                placeholder="Geography Level name"
                type="text"
                className="form-control"
                id="validationCustom01"
                onChange={updateGeographyFormik.handleChange}
                onBlur={updateGeographyFormik.handleBlur}
                value={updateGeographyFormik.values.name || ""}
                invalid={updateGeographyFormik.touched.name && updateGeographyFormik.errors.name ? true : false}
              />
              <MountComponent condition={updateGeographyFormik.touched.name && updateGeographyFormik.errors.name}>
                <FormFeedback type="invalid">{updateGeographyFormik.errors.name}</FormFeedback>
              </MountComponent>
            </div></div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleCancelUpdateModalCallback}>Cancel</button>
            <button type="submit" className="btn btn-primary">Update</button>
          </div>
        </Form>
      </Modal>{/* Modal for Edit Division */}
    </React.Fragment>
  );
});
