import config,{server} from '../config/config'

export const updateAudienceAPI = async (params,id) => {
    
    return await server({
        method  : 'patch',
        url     : `/audience/${id}`,
        data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default updateAudienceAPI