import React, { useState, useEffect } from "react";
import lodash from "lodash";
import * as Yup from "yup";

import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import Select from "react-select";

import logo from "../../../../assets/images/logo-dark.png";
import imageRegistrationIcon from "../../../../assets/images/registerIcon.png";
import RegisterImage from "../../../../assets/images/registerImage.png";
import cornerImage from "../../../../assets/images/corner.png";

import { requestSignUp } from "../../../../api/authentication.api";
import getBusinessRulesAPI from "../../../../api/getBusinessRulesAPI";

import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods";
import MountComponent from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";
import ReCAPTCHA from "react-google-recaptcha";

const notificationInitialState = { visible: false, message: '', color: '' }

export default React.memo((props) => {

  const [loading, setLoading] = useState(false);
  const [businessRoleOptions, setBusinessRoleOptions] = useState([]);
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organizationName: "",
      organizationBusinessRole: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      termsAndConditions: false,
      googleCaptcha: "",
    },
    validationSchema: Yup.object().shape({
      organizationName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Organisation Name"),
      organizationBusinessRole: Yup.string().required(
        "Business Designation is required"
      ),
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter First Name"),
      lastName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Last Name"),
      email: Yup.string().email().required("Please Enter Your Email"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, 'Contact number must be 10 digits')
        .required('Contact number is required'),
      termsAndConditions: Yup.boolean()
        .oneOf([true])
        .required("Please accept the terms and conditions"),
      googleCaptcha: Yup.string()
        .required("Please complete the reCAPTCHA challenge to verify that you are not a robot."),
    }),

    onSubmit: async (values) => {
      try {

        setLoading(true); toggleNotificationState({ ...notificationInitialState });
        const { email, phoneNumber } = values
        const payload = lodash.omit(values, ['termsAndConditions','googleCaptcha']);

        await requestSignUp(payload);

        props.switchScreen({ screen: "registration-verification" });
        props.updateUserDetails({ email, phoneNumber });

      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response.status === 423) {
            const userDetails = lodash.get(error.response, 'data.user', {});
            const { email, phoneNumber } = userDetails;

            props.switchScreen({ screen: "registration-verification" });
            props.updateUserDetails({ email, phoneNumber });

          } else { toggleNotificationState({ visible: true, color: "danger", message: error.response.data.error }); }
        } else { toggleNotificationState({ visible: true, color: "danger", message: 'Oops! Something went wrong' }); }
      } finally { setLoading(false); }
    },
  });

  const getBusinessRoleOptions = async () => {
    await getBusinessRulesAPI().then(({ data: response }) => {
      if (!lodash.isArray(response.data)) { return false; }
      setBusinessRoleOptions([...response.data.map(({ roleName }) => ({
        value: roleName, label: roleName
      }))]);
    }).catch(() => { });

    setLoading(false)
  };


  /** @useEffects ------------------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { setLoading(true); getBusinessRoleOptions(); }, []);
  useEffect(() => {
    if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
    return () => null;
  }, [notificationState.visible]);

  /** ------------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>
      <div className="account-pages boxCenter">
        <div className="Registerlogo">
          <img src={logo} alt="" height="32" className="register-logo" />
        </div>
        <Container>
          <Row className="align-items-center justify-content-center hello">
            <Col md={6} lg={6} xl={6} className="hello1">
              <img src={RegisterImage} alt="" height="35" className="register-image" />
            </Col>
            <Col md={6} lg={6} xl={6} className="hello2">
              <Card>
                <CardBody className="px-4 py-1  cardInternal">
                  <div className="text-center mt-2">
                    <img src={imageRegistrationIcon} alt="" height="22" className="register-logo" />
                    <h5 className="text-primary AuthHead">Register Account</h5>
                    <p className="text-muted authSubHead">Get more out of your Channel Networks</p>
                    <MountComponent condition={notificationState.visible}>
                        <Alert visible={notificationState.visible} className="pt-1 pb-1" color={notificationState.color}>
                          {notificationState.message}
                        </Alert>
                    </MountComponent>
                  </div>

                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={validation.handleSubmit}>
                      <div className="mb-2 row">
                        <Col xs={6} sm={6} className="mobLabelPart">
                          <Label className="form-label formHead">Organization Name<sup className="text-danger">*</sup></Label>
                          <Input
                            className="form-control  formControlCustom"
                            name="organizationName"
                            type="text"
                            placeholder="Organization Full Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.organizationName || ""}
                            invalid={validation.touched.organizationName && validation.errors.organizationName ? true : false}
                          />
                          <MountComponent condition={validation.touched.organizationName && validation.errors.organizationName}>
                            <FormFeedback type="invalid">{validation.errors.organizationName}</FormFeedback>
                          </MountComponent>
                        </Col>

                        <Col lg={6} sm={6} className="mobLabelPart">
                          <div className="mb-0">
                            <Label className="form-label formHead">
                              Business Designation<sup className="text-danger">*</sup>
                            </Label>

                            <Select
                              name="organizationBusinessRole"
                              placeholder="Select Business Designation"
                              onChange={({ value }) => { validation.setFieldValue("organizationBusinessRole", value) }}
                              value={businessRoleOptions.find(({ value }) => value === validation.values.organizationBusinessRole) ?? null}
                              onBlur={validation.handleBlur}
                              options={businessRoleOptions}
                              theme={(theme) => themeReactSelect(theme, validation.touched.organizationBusinessRole && validation.errors.organizationBusinessRole)}
                              styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, validation.touched.organizationBusinessRole && validation.errors.organizationBusinessRole) }}
                            />
                            <MountComponent condition={validation.touched.organizationBusinessRole && validation.errors.organizationBusinessRole}>
                              <small className="select-error">
                                {validation.errors.organizationBusinessRole}
                              </small>
                            </MountComponent>
                          </div>
                        </Col>
                      </div>

                      <div className="mb-2 row">
                        <Col xs={6} sm={6} className="mobLabelPart">
                          <Label className="form-label formHead">
                            First Name<sup className="text-danger">*</sup>
                          </Label>

                          <Input
                            className="form-control  formControlCustom"
                            name="firstName"
                            type="text"
                            placeholder="Enter First Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                                validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                            validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </Col>

                        <Col xs={6} sm={6} className="mobLabelPart">
                          <Label className="form-label formHead">
                            Last Name<sup className="text-danger">*</sup>
                          </Label>

                          <Input
                            className="form-control  formControlCustom"
                            name="lastName"
                            type="text"
                            placeholder="Enter Last Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                                validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                            validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>

                      <div className="mb-2 row">
                        <Col md={6} sm={6} className="mobLabelPart">
                          <Label className="form-label formHead">Email <sup className="text-danger">*</sup></Label>

                          <Input
                            id="email"
                            name="email"
                            className="form-control  formControlCustom"
                            placeholder="Enter your Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>

                        <Col md={6} sm={6} className="mobLabelPart">
                          <Label className="form-label formHead">Phone Number <sup className="text-danger">*</sup></Label>
                          <Input
                            id="Number"
                            name="phoneNumber"
                            className="form-control formControlCustom"
                            placeholder="+91 "
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phoneNumber || ""}
                            invalid={
                              validation.touched.phoneNumber &&
                                validation.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phoneNumber &&
                            validation.errors.phoneNumber ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phoneNumber}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>

                      <div className="mb-2 mt-3 row"><div className="col-md-6">
                        <ReCAPTCHA
                          size="normal"
                          sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"
                          onChange={(value) => { validation.setFieldValue('googleCaptcha', value); }}
                        ></ReCAPTCHA>
                        <MountComponent condition={validation.touched.googleCaptcha && validation.errors.googleCaptcha}>
                          <small className="select-error">{validation.errors.googleCaptcha}</small>
                        </MountComponent>
                      </div></div>

                      <div className="form-check">
                        <Input
                          type="checkbox"
                          name="termsAndConditions"
                          className="form-check-input"
                          checked={validation.values.termsAndConditions || false}
                          onChange={({ target }) => { validation.setFieldValue("termsAndConditions", target.checked); }}
                          invalid={validation.touched.termsAndConditions && validation.errors.termsAndConditions ? true : false}
                        />

                        <label className="form-check-label " htmlFor="auth-terms-condition-check">
                          <p className="text-dark termsCheck">
                            Creating an account means you accept our <a href={"https://giffy.network/privacy-policy/"}>Privacy
                              Policy</a> and <a href={"https://giffy.network/terms-conditions/"}>Terms of Service</a>
                          </p>
                        </label>
                      </div>

                      <div className="mt-1 text-end">
                        <button type="submit" className="btn btn-primary w-sm waves-effect waves-light createButton">
                          Create Account
                        </button>
                      </div>

                      <div className="mt-2 text-center">
                        <p className="text-muted mb-0 logText">
                          Already have an account ?{" "}
                          <Link to={ROUTES.AUTHENTICATION.LOGIN} className="fw-medium text-primary logButton">
                            {" "} Log In
                          </Link>
                        </p>
                      </div>
                    </Form>

                    <div>
                      <img src={cornerImage} alt="" className="cornerImage" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});
