import React from "react";

function ZoneTable() {
  return (
    <div>
      
        <div className="table-responsive">
          <div className="text-center p-5 m-5">Coming Soon</div>
          {/* <table
            className="table table-bordered mb-0 "
            style={{ border: "1px solid #ccc" }}
          >
            <thead>
              <tr>
                <th>Region</th>
                <th>Zone</th>
                <th>Territory</th>
                <th>Area</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Region1</td>
                <td>Zone1</td>
                <td>Territory</td>
                <td>Area</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Area1</td>
              </tr>
              <tr>
                <td></td>
                <td>Zone2</td>
                <td>Territory2</td>
                <td></td>
              </tr>
              <tr>
                <td>Region2</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Region3</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table> */}
        </div>
      
    </div>
  );
}

export default ZoneTable;
