import config,{server} from '../config/config'

export const getOrganizationDetailsAPI = async (userId) => {
  
    return await server({
        method  : 'get',
        url     : `organization/${userId}`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getOrganizationDetailsAPI