import React, { useState, useEffect } from "react";
import { Col, Row, Form, Label, Input, Alert } from "reactstrap";
import "../../../../assets/scss/custom/pages/datatables.scss";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MountCondition } from "../../../../module/mount-components";
import getDivisionsAPI from "../../../../api/getDivisionsAPI";
import getRegionAPI from "../../../../api/getRegionAPI";
import getNetworkLocationsAPI from "../../../../api/getNetworkLocationsAPI";
import getRegionByDivisionAPI from "../../../../api/getRegionByDivisionAPI";
import getNetworkSalesmenListAPI from "../../../../api/getNetworkSalesmenListAPI";
import getNetworkChannelPartnerAPI from "../../../../api/getNetworkChannelPartnerAPI";
import getRegionDetailsAPI from "../../../../api/getRegionDetailsAPI";
import getAudienceViewAPI from "../../../../api/getAudienceViewAPI"
import updateAudienceAPI from "../../../../api/updateAudienceAPI";
import getDistributionDesignationAPI from "../../../../api/getDistributionDesignationAPI";
import MountComponent from "../../../../module/mount-components";

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const audienceId = params.id;

  const [salesOptions, setSalesOptions] = useState([]);

  const [salesData, setSalesData] = useState([]);
  const [salesmenData, setSalesmenData] = useState([]);

  const [divisionOptions, setDivisionOptions] = useState([]);

  const [regionData, setRegionData] = useState([]);
  const [distributionData, setDistributionData] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [distributionOptions, setDistributionOptions] = useState([]);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedHierarchy, setSelectedHierarchy] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [regions, setRegions] = useState([]);
  const [audienceData, setAudienceData] = useState([]);
  const [rowAudience, setRowAudience] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [isDivisionSelected, setIsDivisionSelected] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  const [initiallySelectedRows, setInitiallySelectedRows] = useState([]);
  const [areAllChecked, setAreAllChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  const notificationInitialState = { visible: false, message: '', color: '' }
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const getAudience = async (audienceId) => {
    try {
      const { data } = await getAudienceViewAPI(audienceId);
      const audienceDetails = data.data;
      setAudienceData(audienceDetails);
      setRowAudience(audienceDetails.audienceRows)
    } catch (error) {
    }
  };

  useEffect(() => {
    setLoading(true);
    // Update the state with the current id
    getAudience(audienceId);
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: audienceData.name,
      source: audienceData.source,
      audienceRows: [{networkSalesmenId: audienceData?.audienceRows?.map((item) => item.id)}],
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Audience Name"),

      source: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Select User"),
    }),

    onSubmit: async (values) => {
      try {

        const selectedRowsData = selectedRows.length 
        ? rowAudience.filter((row) => selectedRows.includes(row?.networkSalesman?.id)) 
        : rowAudience;

      // Prepare the data for API
      const requestData = {
        ...validation.values,
        audienceRows: selectedRowsData.map((row) => ({
          networkSalesmenId: row?.networkSalesman?.id,
        })),
      };
        // Update the values object in Formik
        await updateAudienceAPI(requestData, audienceId);
        toggleNotificationState({ visible: true, color: "success", message: "Audience saved successfully!" })
        setTimeout(() => {
          navigate("/network-engage/audience");
        }, 1500);
      } catch (error) {
        toggleNotificationState({ visible: true, color: "danger", message: error.response.data.message})
      }
    },
  });

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    validation.setFieldValue(
      "source",
      selectedOption ? selectedOption.value : ""
    );
    setSelectedDivision(null);
    setSelectedHierarchy(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setAreAllChecked(false)
    setCheckedRows([]);
    setSalesOptions([]);
    setSelectedRows([])
    setDistributionOptions([]);
  };

  const getUserData = async (userType) => {
    try {
      setLoading(true);
      if (userType === "SALES_HIERARCHY") {
        const { data } = await getNetworkSalesmenListAPI();
        setRowAudience(data);
        setSalesData(data)
      } else if (userType === "DISTRIBUTION_HIERARCHY") {
        const { data } = await getNetworkChannelPartnerAPI();
        setRowAudience(data);
        setSalesData(data)
      }
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      getUserData(selectedUser.value);
    }
  }, [selectedUser]);

  const handleLocationChange = async(selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const handleRegionChange = async (selectedOption) => {
    setSelectedRegion(selectedOption);
    setSelectedRegion((prevSelectedDivision) => {
      validation.setFieldValue("regionId", prevSelectedDivision?.value);
      return prevSelectedDivision;
    });
    try {
      await getNetworkLocationsAPI(selectedOption.value);
      
    } catch (error) {
    }
  };

  const handleHierarchyChange = (selectedOption) => {
    setSelectedHierarchy(selectedOption);
  };

  const handleDivisionChange = (selectedOption) => {
    setSelectedDivision(selectedOption);
    setSelectedDivision((prevSelectedDivision) => {
      validation.setFieldValue("divisionId", prevSelectedDivision?.value);
      return prevSelectedDivision;
    });
    setIsDivisionSelected(true);
    setSelectedHierarchy(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setAreAllChecked(false)
    setCheckedRows([]);
  };

  const getRegions = async () => {
    try {
      const { data } = await getRegionAPI();
      if (data) {
        const regionData = data;
        setRegionData(regionData);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getRegions();
  }, []);

  const getRegionData = async () => {
    try {
      const { data } = await getRegionDetailsAPI();
      if (data) {
        const regionData = data;
        setRegionData(regionData);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getRegionData();
  }, []);

  const getDistributionData = async (divisionId) => {
    try {
      const { data } = await getDistributionDesignationAPI(divisionId);
      if (data) {
        const distributionData = data;
        const options = distributionData?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));
        setDistributionData(data);
        setDistributionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDistributionData(selectedDivision?.value);
  }, [selectedDivision]);

  const getRegionName = (regionId) => {
    const region = regionData.find((r) => r.id === regionId);
    return region ? region.name : "";
  };

  const getDivisionData = async () => {
    try {
      const { data } = await getDivisionsAPI();
      if (data) {
        const divisionData = data;
        const options = divisionData?.map((division) => ({
          value: division?.id,
          label: division?.name,
        }));
        setDivisionOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDivisionData();
  }, []);

  const getSalesman = async () => {
    try {
      const { data } = await getNetworkSalesmenListAPI();

      if (data) {
        const userData = data;
        const options = userData?.map((user) => ({
          value: user?.salesHierarchy?.id,
          label: user?.salesHierarchy?.name,
        }));
        setSalesmenData(data);
        setSalesOptions(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSalesman();
  }, []);

  const resetFilters = () => {
    setSelectedDivision(null);
    setSelectedHierarchy(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setSalesOptions([]);
    setDistributionOptions([]);
    setRegions([]);
    setLocationOption([]);
    setAreAllChecked(false)
    setCheckedRows([]);
    setSaveClicked(false);
  };

  const regionList = async (divisionId) => {
    try {
      const response = await getRegionByDivisionAPI(divisionId);
      if (response) {
        const regionData = response.data;
        const options = regionData?.map((state) => ({
          value: state?.id,
          label: state?.name,
        }));
        setRegions(options);
      }
    } catch (error) {
      console.log("Error reg", error);
    }
  };
  useEffect(() => {
    regionList(selectedDivision?.value);
  }, [selectedDivision]);

  const getLocationsData = async (regionId) => {
    try {
      const { data } = await getNetworkLocationsAPI(regionId);
      if (data) {
        const locationDetails = data;
        const options = locationDetails?.map((location) => ({
          value: location?.id,
          label: location?.location,
        }));
        setLocationOption(options);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      return setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLocationsData(validation.values.regionId); 
  }, [validation.values.regionId]);

  const handleCheckboxChange = (networkSalesmenId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(networkSalesmenId)) {
        // If already selected, remove from the list
        return prevSelectedRows.filter((id) => id !== networkSalesmenId);
      } else {
        // If not selected, add to the list
        return [...prevSelectedRows, networkSalesmenId];
      }
    });
  };

  const checkAllHandler = () => {
    const allRows = selectedUser === "SALES_HIERARCHY" ? salesmenData : distributions;
  
    if (initiallySelectedRows.length === 0) {
      setInitiallySelectedRows(allRows.map((data) => data.id));
    }
    
    const filteredRows = allRows.filter(
      (item) =>
        (selectedDivision ? item?.divisions?.name === selectedDivision?.label : true) &&
        (selectedHierarchy
          ? selectedUser === "SALES_HIERARCHY"
            ? item?.salesHierarchy?.name === selectedHierarchy?.label
            : item?.distributionHierarchy?.name === selectedHierarchy?.label
          : true) &&
        (selectedRegion
          ? getRegionName(item?.networkLocation?.regionId) === selectedRegion?.label
          : true) &&
        (selectedLocation ? item?.networkLocation?.location === selectedLocation?.label : true)
    );
  
    const allRowIds = filteredRows.map((data) => data.id);
  
    setCheckedRows((prevRows) =>
      areAllChecked ? [] : [...prevRows, ...allRowIds]
    );
    setAreAllChecked(!areAllChecked);
  };

  useEffect(() => {
    checkAllHandler();
  },[])

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedUser) {
        if (selectedUser === "SALES_HIERARCHY") {
          const { data } = await getNetworkSalesmenListAPI();
          setRowAudience(data);
        } else if (selectedUser === "DISTRIBUTION_HIERARCHY") {
          const { data } = await getNetworkChannelPartnerAPI();
          setRowAudience(data);
        }
      }
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedUser, selectedDivision, selectedHierarchy, selectedRegion]);

  useEffect(() => {
    // Set selectedRows to include all row IDs when the component is loaded
      const allRowIds = selectedUser ? [] : rowAudience.map((data) => data?.id);
      setSelectedRows(allRowIds);
  }, [rowAudience, selectedUser]);

  const data = {
    columns: [
      {
        label: (
          <input
            className="form-check-input"
            type="checkbox"
            onChange={checkAllHandler}
            checked={areAllChecked}
          />
        ),
        field: "check",
        sort: "disabled",
        width: 20,
      },
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "First Name",
        field: "firstName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile No",
        field: "mobileNo",
        sort: "asc",
        width: 150,
      },
      {
        label: "email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "SBU",
        field: "sbuName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 270,
      },
      {
        label: "Region",
        field: "region",
        sort: "asc",
        width: 270,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 270,
      },
    ],
    rows: [
      ...rowAudience?.filter(
        (item) =>
          (selectedDivision
            ? item?.divisions?.name === selectedDivision?.label
            : true) &&
          (selectedHierarchy
            ? selectedUser === "SALES_HIERARCHY"
              ? item?.salesHierarchy?.name === selectedHierarchy?.label
              : item?.distributionHierarchy?.name === selectedHierarchy?.label
            : true) &&
          (selectedRegion
            ? getRegionName(item?.networkLocation?.regionId) ===
              selectedRegion?.label
            : true) &&
          (selectedLocation
            ? item?.networkLocation?.location === selectedLocation?.label
            : true)
      )?.map((data, i) => ({
          check: (
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id={`row${i}`}
              onChange={() => handleCheckboxChange(data?.id)}
              checked={selectedRows.includes(data?.id)}
            />
          ),
          sr: ++i,
          firstName: data?.networkSalesman ? data?.networkSalesman?.firstName : data?.firstName,
          lastName: data?.networkSalesman ? data?.networkSalesman?.lastName : data?.lastName,
          mobileNo: data?.networkSalesman ? data?.networkSalesman?.mobileNo : data?.mobileNo,
          email: data?.networkSalesman ? data?.networkSalesman?.email : data?.email,
          sbuName: data?.networkSalesman ? data?.networkSalesman?.divisions?.name : data?.divisions?.name,
          designation: data?.salesHierarchy
            ? data?.salesHierarchy?.name
            : data?.distributionHierarchy?.name,
          region: data?.networkSalesman?.networkLocation?.regions?.name,
          location:  data?.networkSalesman ? data?.networkSalesman?.networkLocation?.location : data?.networkLocation?.location,
        })),
    ],
  };

  const userOption = [
    {
      options: [
        { label: "Salesmen", value: "SALES_HIERARCHY" },
        { label: "Distributors", value: "DISTRIBUTION_HIERARCHY" },
      ],
    },
  ];

  

  const selectedOption = userOption[0].options.find(
    (option) => option.value === validation.values.source
  );

  return (
    <React.Fragment>
      <MountCondition condition={loading}>
        <MountCondition.True>
          <div className="fullscreen-loader">
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          </div>
        </MountCondition.True>
      </MountCondition>

      <MountComponent condition={notificationState.visible}>
        <Alert className="pt-2 pb-2" color={notificationState.color}>
          { notificationState.message }
        </Alert>
      </MountComponent>

        <div>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <div className="submitForm">
                <Col lg={12}>
                  <h4 className="mt-3 fw-bold mb-3 d-flex justify-content-center">
                    Edit Audience
                  </h4>
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="mt-4">
                        <Form>
                          <div className="mb-0">
                            <Label
                              className="form-label"
                              htmlFor="formrow-firstname-input"
                            >
                              Audiences List Name
                            </Label>
                            <Input
                              type="text"
                              name="name"
                              placeholder="Enter Audiences List Name"
                              className="form-control"
                              id="formrow-firstname-input"
                              onChange={validation.handleChange}
                              value={validation.values.name || ""}
                            />
                          </div>
                        </Form>
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="mt-4">
                        <div className="mb-0">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            Select User
                          </Label>

                          <Select
                            className=""
                            name="source"
                            isSearchable
                            options={userOption[0].options}
                            onChange={handleUserChange}
                            value={selectedOption}
                          ></Select>
                        </div>
                      </div>
                    </Col>

                    <div className="addNewBtn">
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            Filters
                          </Label>
                        </div>
                        <div className="col-md-6">
                          <div className="locationBtn">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => {
                                resetFilters();
                              }}
                            >
                              Reset Filters
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dataTable">
                      <Row>
                        <Col lg={3} md={3}>
                          <div className="mt-4">
                            <div className="mb-0">
                              <Select
                                className=""
                                name="divisionId"
                                isSearchable
                                options={divisionOptions}
                                placeholder="Select SBU"
                                onChange={handleDivisionChange}
                                value={selectedDivision}
                              >
                              </Select>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={3}>
                          <div className="mt-4">
                            <div className="mb-0">
                              <Select
                                className=""
                                isSearchable
                                options={
                                  isDivisionSelected && selectedUser == "SALES_HIERARCHY" ? salesOptions : distributionOptions
                                }
                                onChange={handleHierarchyChange}
                                placeholder="Select Hierarchy"
                                value={selectedHierarchy}
                                noOptionsMessage={() => "Select Division"}
                              ></Select>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3} md={3}>
                          <div className="mt-4">
                            <div className="mb-0">
                              <Select
                                className=""
                                isSearchable
                                name="regionId"
                                options={isDivisionSelected ? regions : []}
                                onChange={handleRegionChange}
                                placeholder={"Select Region"}
                                value={selectedRegion}
                                noOptionsMessage={() => "Select Division"}
                              >
                              </Select>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3} md={3}>
                          <div className="mt-4">
                            <div className="mb-0">
                              <Select
                                className=""
                                isSearchable
                                options={
                                  isDivisionSelected ? locationOption : []
                                }
                                onChange={handleLocationChange}
                                placeholder={"Select Location"}
                                value={selectedLocation}
                                noOptionsMessage={() => "Select Region"}
                              >
                              </Select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="dataTable mt-4">
                        <MDBDataTable
                          responsive
                          bordered
                          data={data}
                          checkbox
                          footer={false}
                        />
                      </div>
                    </div>
                  </Row>
                </Col>
                <hr style={{ color: "#ced4da", border: "1px solid" }} />
                <Col
                  lg={12}
                  className="d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div className="saveBtn">
                    <button className="btn btn-group" type="submit">
                      Save
                    </button>
                  </div>
                  <div className="saveBtn">
                    <button
                      className="btn btn-group"
                      onClick={() => navigate("/network-engage/audience")}
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </div>
            </Row>
          </Form>
        </div>
    </React.Fragment>
  );
};
