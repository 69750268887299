import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { Alert, Row, Col, Form } from "reactstrap";

import OtpInput from 'react-otp-input';

import logo from "../../../../assets/images/logo-dark.png";
import imageOTP from "../../../../assets/images/otpLogo.png";
import imageRegister from "../../../../assets/images/registerImage.png";
import cornerImage from "../../../../assets/images/corner.png";

import { CountdownComponent } from "./components";
import MountComponent, { MountCondition } from "../../../../module/mount-components";

import { requestVerificationOTP, signUpVerification } from "../../../../api/authentication.api";

const notificationInitialState = { visible: false, message: '', color: '' }

export default React.memo((props) => {
    const { email, phoneNumber } = props.userDetails;
    const [loading, setLoading] = useState(false);
    const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });
    const [countdownState, toggleCountdownState] = useState({ active: false, key: 0, time: 0 });

    /** @useFormiks ----------------------------------------------------------------------------------------------------------------- */

    const accountVerificationValidationSchema = {
        email: Yup.string().email('Invalid email address'),
        contactNumber: Yup.string()
            .matches(/^\d{10}$/, 'Contact number must be 10 digits'),
        emailOTP: Yup.string().required('Alert! OTP is required')
            .matches(/^[0-9]+$/, "Must be only digits").min(6, 'Must be exactly 6 digits').max(6, 'Must be exactly 6 digits'),
        mobileOTP: Yup.string().required('Alert! OTP is required')
            .matches(/^[0-9]+$/, "Must be only digits").min(6, 'Must be exactly 6 digits').max(6, 'Must be exactly 6 digits')
    };

    const accountVerificationInitialValues = {
        email: email,
        phoneNumber: phoneNumber,
        emailOTP: null,
        mobileOTP: null
    }

    const requestVerificationInitialValues = {
        email: email,
        phoneNumber: phoneNumber,
    }

    const requestVerificationValidationSchema = {
        email: Yup.string().email('Invalid email address'),
        contactNumber: Yup.string().matches(/^\d{10}$/, 'Contact number must be 10 digits')
    };

    const accountVerificationFormik = useFormik({
        enableReinitialize: false,
        validationSchema: Yup.object({ ...accountVerificationValidationSchema }),
        initialValues: { ...accountVerificationInitialValues },
        onSubmit: async (payload) => {
            try {
                setLoading(true); toggleNotificationState({ ...notificationInitialState });

                await signUpVerification(payload);

                props.switchScreen({ screen: "thank-you" });
                props.userDetails({ email: null, phoneNumber: null });

            } catch (error) {
                if (error instanceof AxiosError) {
                    toggleNotificationState({ visible: true, color: 'danger', message: error.response.data.error });
                }
                else { toggleNotificationState({ visible: true, color: 'danger', message: 'Oops! Something went wrong!' }); }
            } finally { setLoading(false); }
        }
    });

    const requestVerificationFormik = useFormik({
        enableReinitialize: false,
        validationSchema: Yup.object({ ...requestVerificationValidationSchema }),
        initialValues: { ...requestVerificationInitialValues },
        onSubmit: async (payload) => {
            try {
                setLoading(true); toggleNotificationState({ ...notificationInitialState });

                await requestVerificationOTP(payload);
                toggleCountdownState({ active: true, key: countdownState.key + 1, time: Date.now() + 59000 });
            } catch (error) {
                if (error instanceof AxiosError) {
                    toggleNotificationState({ visible: true, color: 'danger', message: error.response.data.error });
                }
                else { toggleNotificationState({ visible: true, color: 'danger', message: 'sorry something went wrong!' }); }
            } finally { setLoading(false); }
        }
    });

    /** @handlers ------------------------------------------------------------------------------------------------------------------- */

    const handleCountdownComplete = useCallback(() => {
        return toggleCountdownState({ active: false, key: countdownState.key + 1, time: 0 })
    });

    /** @useEffects ----------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { requestVerificationFormik.submitForm(); }, []);
    useEffect(() => {
        if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
        return () => null;
    }, [notificationState.visible]);

    /** ----------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <div className="account-pages boxCenter"><div className="container">
                <div className="row"><div className="col-lg-12"><div className="text-center">
                    <Link to="/" className="mb-2 d-block auth-logo">
                        <img src={logo} alt="" height="32" className="logo logo-dark m-auto" />
                    </Link>
                </div></div></div>

                <Row className="align-items-center justify-content-center hello">
                    <Col md={6} lg={6} xl={6} className="hello1">
                        <img src={imageRegister} alt="" className="register-image" />
                    </Col>
                    <Col md={6} lg={6} xl={6} className="hello2">
                        <div className="card OtpCard"><div className="card-body OtpCardBody">
                            <div className="text-center">
                                <img src={imageOTP} alt="" height="32" className="register-logo OtpCardLogo" />
                                <h5 className="text-primary AuthHead">OTP Verification</h5>
                            </div>
                            <Alert visible={notificationState.visible} className="pt-1 pb-1" color={notificationState.color}>
                                {notificationState.message}
                            </Alert>

                            <Form className="form-horizontal" onSubmit={accountVerificationFormik.handleSubmit}>
                                <div className="otpSection">
                                    <div className="OtpPart">
                                        <p className="otpText">We sent a verification code to <span className="otpNumber">{accountVerificationFormik.values.phoneNumber}</span></p>
                                        <div className="otpSetup">
                                            <OtpInput
                                                value={accountVerificationFormik.values.mobileOTP}
                                                onChange={(otp) => { accountVerificationFormik.setFieldValue('mobileOTP', otp); }}
                                                numInputs={6}
                                                isInputNum={true}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => (<input {...props} />)}
                                                inputType="tel"
                                            />
                                        </div>
                                        <MountComponent condition={accountVerificationFormik.touched.mobileOTP && accountVerificationFormik.errors.mobileOTP}>
                                            <small className="select-error text-center mt-2">{accountVerificationFormik.errors.mobileOTP}</small>
                                        </MountComponent>
                                    </div>
                                    <div className="OtpPart">
                                        <p className="otpText">and enter the OTP sent to <span className="otpNumber">{accountVerificationFormik.values.email}</span></p>
                                        <div className="otpSetup">
                                            <OtpInput
                                                value={accountVerificationFormik.values.emailOTP}
                                                onChange={(otp) => { accountVerificationFormik.setFieldValue('emailOTP', otp); }}
                                                numInputs={6}
                                                isInputNum={true}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => (<input {...props} />)}
                                                inputType="tel"
                                            />
                                        </div>
                                        <MountComponent condition={accountVerificationFormik.touched.emailOTP && accountVerificationFormik.errors.emailOTP}>
                                            <small className="select-error text-center mt-2">{accountVerificationFormik.errors.emailOTP}</small>
                                        </MountComponent>
                                    </div>
                                </div>

                                <div className="col-md-12 text-center my-3">
                                    <MountCondition condition={countdownState.active}>
                                        <MountCondition.True>
                                            <CountdownComponent
                                                countdownState={countdownState}
                                                handleCountdownComplete={handleCountdownComplete}
                                            ></CountdownComponent>
                                        </MountCondition.True>
                                        <MountCondition.False>
                                            <span className="resend-span-body" onClick={() => { requestVerificationFormik.submitForm(); }}>
                                                Resend OTP
                                            </span>
                                        </MountCondition.False>
                                    </MountCondition>
                                </div>

                                <div className="mt-1 text-end">
                                    <button className="btn btn-primary w-sm waves-effect waves-light createButton" type="submit">
                                        Verify
                                    </button>
                                </div>
                            </Form>
                            <div>
                                <img src={cornerImage} alt="OtpCornerImage" className="OtpCornerImage" />
                            </div>
                        </div></div>
                    </Col>
                </Row>
            </div></div>
        </React.Fragment>
    )
});
