import React from "react";

import MountComponent from '../../../../module/mount-components';
import RightSideProfile from "../../../Dashboard/right-side-profile";

import TimelineGuide from "../../timeline-guide";

import PERMISSIONS from "../../../../routes/permissions";
import Source from "./source";

// ----------------------------------------------------------------------------------------------------------------

export default React.memo(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = " Geography | Giffy Network";
    
    const showGirl = false;
    const { CHILDREN: { GEOGRAPHY } } = PERMISSIONS.NETWORK_MANAGER;
      
    return (
        <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12"><div className="zone"><div className="profile">
                <div className="actives">
                    <TimelineGuide heading="Setup Your Geography" activePage={GEOGRAPHY}></TimelineGuide>
                </div>
                <Source></Source>
            </div></div></div>
            <div className="col-lg-3 col-md-12">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
        </div></div></div>
    )
});
