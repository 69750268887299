import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import lodash from "lodash";

import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { Alert, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";

import MountComponent from "../../../../module/mount-components";
import { errorMessage, toastErrorMessage } from "../../../../module/error-methods";
import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods.js";

import { getStatesListAPI, getCitiesListAPI, getPinCodesListAPI } from "../../../../api/location.api.js";
import { getGeographyRegionsAPI } from "../../../../api/geography-regions.api.js";
import { getDivisionsAPI } from "../../../../api/division.api.js";

import { storeOrganizationModule } from "../../../../api/organizationModuleApi";
import { storeNetworkLocationAPI } from "../../../../api/network-location.api.js";

import ROUTES from "../../../../routes/routes.js";
import PERMISSIONS from "../../../../routes/permissions";

export default React.memo(() => {
  const { NAME, CHILDREN: { LOCATION } } = PERMISSIONS.NETWORK_MANAGER;
  
  const [loading, setLoading] = useState(false);
  const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });
  
  const navigate = useNavigate();
  
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [pinOptions, setPinOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);


  /** @formik --------------------------------------------------------------------------------------------------------------------- */

  const locationInitialValues = {
    location: null,
    regionId: null,
    city: null,
    state: null,
    pincode: null,
    divisionId: null,
  }

  const locationValidation = {
    location: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please Enter location"),
    pincode:
      Yup.string().required("Please enter your Pincode"),
    state: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Please select state."),
    city: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Please select city."),
    divisionId: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Please select division."),
    regionId: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Please select region."),
  }

  const locationFormik = useFormik({
    enableReinitialize: true,
    initialValues: { ...locationInitialValues },
    validationSchema: Yup.object({ ...locationValidation }),
    onSubmit: async (payload) => {
      try {

        await storeNetworkLocationAPI({ ...payload })
        await storeOrganizationModule({ module: NAME, moduleLevel: LOCATION.NAME })

        toggleNotificationState({ visible: true, color: 'success', message: 'Success! The network location has been created.' });
        setTimeout(() => {
          navigate(ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION);
        }, 3000);

      } catch (error) {
          toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
      } finally { setLoading(false); }
    },
  });

  /** @apis ----------------------------------------------------------------------------------------------------------------------- */

    const getDivisionOptions = async () => {
      await getDivisionsAPI().then(({ data }) => {
        if (lodash.isArray(data)) {
          return setDivisionOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
        }
      }).catch((error) => toastErrorMessage(error));
    };
    const getRegionOptions = async () => {
      if (!locationFormik.values.divisionId) { return true; }
      setLoading(true);
      await getGeographyRegionsAPI({ divisionId: locationFormik.values.divisionId }).then(({ data }) => {
        if (lodash.isArray(data)) {
          locationFormik.setFieldValue('regionId',null);
          return setRegionOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
        }
      }).catch((error) => toastErrorMessage(error));
      setLoading(false);
    };

    const getStateOptions = async () => {
      await getStatesListAPI().then(({ data: response }) => {
        if (lodash.isArray(response.data)) {
          return setStateOptions([...response.data.map(({ state }) => ({ label: state, value: state }))]);
        }
      }).catch((error) => toastErrorMessage(error));
    };
    const getCitiesOptions = async () => {
      const { state } = locationFormik.values;
      if (!state) { return true; }
      setLoading(true);
      await getCitiesListAPI({ states: [state] }).then(({ data: response }) => {
        if (lodash.isArray(response.data)) {
          locationFormik.setFieldValue('city',null);
          return setCityOptions([...response.data.map(({ province }) => ({ label: province, value: province }))]);
        }
      }).catch((error) => toastErrorMessage(error));
      setLoading(false);
    };
    const getPinCodeOptions = async () => {
      const { state, city } = locationFormik.values;
      if (!state && !city) { return true; }
      setLoading(true);
      await getPinCodesListAPI({ states: [state], cities: [city] }).then(({ data: response }) => {
        if (lodash.isArray(response.data)) {
          locationFormik.setFieldValue('pincode',null);
          return setPinOptions([...response.data.map(({ zipcode, place }) => ({ value: zipcode, label: `${zipcode} (${place})`, }))]);
        }
      }).catch((error) => toastErrorMessage(error));
      setLoading(false);
    };

  /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

  useEffect(() => { getDivisionOptions(); getStateOptions(); }, []);
  useEffect(() => { getRegionOptions() }, [locationFormik.values.divisionId]);
  useEffect(() => { getCitiesOptions() }, [locationFormik.values.state]);
  useEffect(() => { getPinCodeOptions() }, [locationFormik.values.city]);

  useEffect(() => {
    if (notificationState.visible) {
      setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000);
    }
  }, [notificationState.visible]);

  /** ---------------------------------------------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <MountComponent condition={loading}>
        <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
        </div></div></div>
      </MountComponent>

      <Alert color={notificationState.color} isOpen={notificationState.visible}  >
        {notificationState.message}
      </Alert>

      <Form className="form-horizontal row" onSubmit={locationFormik.handleSubmit} >
        <div className="col-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="divisionId-input">Division Name</Label>
          <Select className="" name="divisionId"
            onChange={({ value }) => { locationFormik.setFieldValue("divisionId", value); }}
            value={divisionOptions.find(({ value }) => value === locationFormik.values.divisionId) ?? null}
            options={divisionOptions}
            invalid={locationFormik.touched.divisionId && locationFormik.errors.divisionId ? true : false}
            theme={(theme) => themeReactSelect(theme, locationFormik.touched.divisionId && locationFormik.errors.divisionId)}
            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, locationFormik.touched.divisionId && locationFormik.errors.divisionId) }}
          ></Select>
          <MountComponent condition={locationFormik.touched.divisionId && locationFormik.errors.divisionId}>
            <small className="select-error">{locationFormik.errors.divisionId}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="location-input">Location</Label>
          <Input
            type="text"
            placeholder="Enter Location"
            name="location"
            className="form-control"
            id="location-input"
            onChange={locationFormik.handleChange}
            onBlur={locationFormik.handleBlur}
            value={locationFormik.values.location}
            invalid={locationFormik.touched.location && locationFormik.errors.location ? true : false}
          />
          <MountComponent condition={locationFormik.touched.location && locationFormik.errors.location}>
            <FormFeedback type="invalid">{locationFormik.errors.location}</FormFeedback>
          </MountComponent>
        </div></div></div>

        <div className="col-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="region-input">Region</Label>
          <Select className="" name="regionId"
            onChange={({ value }) => { locationFormik.setFieldValue("regionId", value); }}
            value={regionOptions.find(({ value }) => value === locationFormik.values.regionId) ?? null}
            options={regionOptions}
            invalid={locationFormik.touched.regionId && locationFormik.errors.regionId ? true : false}
            theme={(theme) => themeReactSelect(theme, locationFormik.touched.regionId && locationFormik.errors.regionId)}
            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, locationFormik.touched.regionId && locationFormik.errors.regionId) }}
          ></Select>
          <MountComponent condition={locationFormik.touched.regionId && locationFormik.errors.regionId}>
            <small className="select-error">{locationFormik.errors.regionId}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="state-input">State</Label>
          <Select className="" name="state"
            onChange={({ value }) => { locationFormik.setFieldValue("state", value); }}
            value={stateOptions.find(({ value }) => value === locationFormik.values.state) ?? null}
            options={stateOptions}
            invalid={locationFormik.touched.state && locationFormik.errors.state ? true : false}
            theme={(theme) => themeReactSelect(theme, locationFormik.touched.state && locationFormik.errors.state)}
            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, locationFormik.touched.state && locationFormik.errors.state) }}
          ></Select>
          <MountComponent condition={locationFormik.touched.state && locationFormik.errors.state}>
            <small className="select-error">{locationFormik.errors.state}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="city-input">City</Label>
          <Select className="" name="city"
            onChange={({ value }) => { locationFormik.setFieldValue("city", value); }}
            value={cityOptions.find(({ value }) => value === locationFormik.values.city) ?? null}
            options={cityOptions}
            invalid={locationFormik.touched.city && locationFormik.errors.city ? true : false}
            theme={(theme) => themeReactSelect(theme, locationFormik.touched.city && locationFormik.errors.city)}
            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, locationFormik.touched.city && locationFormik.errors.city) }}
          ></Select>
          <MountComponent condition={locationFormik.touched.city && locationFormik.errors.city}>
            <small className="select-error">{locationFormik.errors.city}</small>
          </MountComponent>
        </div></div></div>

        <div className="col-6"><div className="mt-4"><div className="mb-0">
          <Label className="form-label" htmlFor="pincode-input">Pin Code</Label>
          <Select className="" name="pincode"
            onChange={({ value }) => { locationFormik.setFieldValue("pincode", value); }}
            value={pinOptions.find(({ value }) => value === locationFormik.values.pincode) ?? null}
            options={pinOptions}
            invalid={locationFormik.touched.pincode && locationFormik.errors.pincode ? true : false}
            theme={(theme) => themeReactSelect(theme, locationFormik.touched.pincode && locationFormik.errors.pincode)}
            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, locationFormik.touched.pincode && locationFormik.errors.pincode) }}
          ></Select>
          <MountComponent condition={locationFormik.touched.pincode && locationFormik.errors.pincode}>
            <small className="select-error">{locationFormik.errors.pincode}</small>
          </MountComponent>
        </div></div></div>

        <hr className="mt-3" style={{ color: "#ced4da", border: "1px solid" }} />
        <div className="col-12 d-flex justify-content-center">
          <div className="saveBtn">
            <button className="btn btn-group" type="submit">Save</button>
          </div>
          <div className="saveBtn mx-2">
            <Link to={ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION} type="button" className="btn btn-group">Cancel</Link>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
});
