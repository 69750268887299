import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import lodash from "lodash";

import getOrganizationModules from "../api/organizationModuleApi"

const errorMessage = (error) => {
    const errorMessage = "Something went wrong";
    const apiError = error.isAxiosError ? lodash.get(error, 'response.data.error') : errorMessage;
    return process.env.NODE_ENV === "development" ? apiError : errorMessage;
}

const statusColors = {
    available: {
        main: "#fff",
        accent: "#37AADE",
        highlight: "#37AADE",
        dashed: "#37AADE"
    },
    completed: {
        main: "#006837",
        highlight: "#3fcd75",
        accent: "#C6F7D2",
        dashed: "#4CAF50"
    },
    pending: {
        main: "#000",
        accent: "#e5e5e5",
        highlight: "#e5e5e5",
        dashed: "#e5e5e5"
    }
};

export const useApiData = (params) => {
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {

            const { data: response } = await getOrganizationModules(params);

            if (lodash.isArray(response.data) === false) throw new Error('invalid data');

            const moduleLevels = lodash.map(response.data, 'moduleLevel');

            setArray(moduleLevels);

        } catch (error) { toast.error(errorMessage(error)); } finally { setLoading(false); }
    };

    useEffect(() => { fetchData(); return () => { }; }, []);

    return { array, loading };
};

export const getColor = (state,timeline = {}) => {
    switch (true) {
        case lodash.includes(state,timeline.current):  return statusColors.completed;
        case lodash.includes(state,timeline.previous): return statusColors.available;
        default: return statusColors.pending;
    }
};
