import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import store from "store";
import classnames from "classnames";

import { Link } from "react-router-dom";
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// import doubtImage from "../../../assets/images/icon/doubts-button.png";
import { MountCondition } from "../../../module/mount-components.jsx";

import GeographyReport from "./geography-reports.component.jsx";
import LocationReport from "./location-reports.component.jsx";
import SalesmanReport from "./salesman-reports.component.jsx";
import ChannelPartnerReport from "./channel-partner-reports.component.jsx";

import PERMISSIONS from "../../../routes/permissions.js";
import ROUTES from "../../../routes/routes.js";
import { checkPermissions } from "../../../api/organization-users.api.js";
import GoogleMap from "./google-map.jsx";

export default React.memo(() => {
  const userSession = store.get("session");
  const userDetails = lodash.get(userSession, "data", {});

  const [tab, toggleTab] = useState("GEOGRAPHY_TAB");

  const [hide, setHide] = useState(true);

  const tabsStructure = {
    GEOGRAPHY_TAB: { next: "LOCATION_TAB" },
    LOCATION_TAB: { next: "SALESMAN_TAB" },
    SALESMAN_TAB: { next: "CHANNEL_PARTNER_TAB" },
    CHANNEL_PARTNER_TAB: { next: null },
  };

  const switchTab = useCallback((tabId) => {
    return toggleTab(tabId);
  });
  const nextTab = useCallback(() => {
    return toggleTab(tabsStructure[tab].next);
  });

  const [userPermissions, setUserPermissions] = useState({
    NETWORK_DETAILS_CREATE:
      userDetails.userRole === "ORGANIZATION" ? true : false,
    NETWORK_DETAILS_UPDATE:
      userDetails.userRole === "ORGANIZATION" ? true : false,
    NETWORK_DETAILS_DELETE:
      userDetails.userRole === "ORGANIZATION" ? true : false,
  });

  const getPermissions = async () => {
    const modulePermissions =
      PERMISSIONS.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN;
    const searchPermissions = lodash.map(
      modulePermissions,
      (data) => data.NAME
    );

    await checkPermissions({ searchKeywords: [...searchPermissions] })
      .then(({ data }) => {
        const response = lodash.mapValues(
          lodash.mapKeys(data.data, "name"),
          "permission"
        );
        return setUserPermissions({ ...userPermissions, ...response });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (userDetails.userRole !== "ORGANIZATION") {
      getPermissions();
    }
  }, [tab]);

  return (
    <React.Fragment>
      <div className="master nmreport">
        <div className="">
          <div className="card-body">
            <div className="horizontal-wizard wizard clearfix horizontal">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classnames({
                      currentReport: tab === "GEOGRAPHY_TAB",
                    })}
                  >
                    <NavLink
                      className={classnames(
                        { active: tab === "GEOGRAPHY_TAB" },
                        "mainInfo"
                      )}
                      onClick={() => {
                        switchTab("GEOGRAPHY_TAB");
                      }}
                    >
                      <div className="TabsName cursor-pointer">
                        Geography{" "}
                        <span className="numb">
                          {/* <img src={doubtImage} /> */}
                          <div className="infoooo">
                            <span>Geography</span>
                          </div>
                        </span>
                      </div>{" "}
                    </NavLink>
                  </NavItem>

                  <NavItem
                    className={classnames({
                      currentReport: tab === "LOCATION_TAB",
                    })}
                  >
                    <NavLink
                      className={classnames(
                        { active: tab === "LOCATION_TAB" },
                        "mainInfo"
                      )}
                      onClick={() => {
                        switchTab("LOCATION_TAB");
                      }}
                    >
                      <div className="TabsName cursor-pointer">
                        Location{" "}
                        <span className="numb">
                          {/* <img src={doubtImage} /> */}
                          <div className="infoooo">
                            <span>Location</span>
                          </div>
                        </span>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem
                    className={classnames({
                      currentReport: tab === "SALESMAN_TAB",
                    })}
                  >
                    <NavLink
                      className={classnames(
                        { active: tab === "SALESMAN_TAB" },
                        "mainInfo"
                      )}
                      onClick={() => {
                        switchTab("SALESMAN_TAB");
                      }}
                    >
                      <div className="TabsName cursor-pointer">
                        Salesman{" "}
                        <span className="numb">
                          {/* <img src={doubtImage} /> */}
                          <div className="infoooo">
                            <span>Salesman</span>
                          </div>
                        </span>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem
                    className={classnames({
                      currentReport: tab === "CHANNEL_PARTNER_TAB",
                    })}
                  >
                    <NavLink
                      className={classnames(
                        { active: tab === "CHANNEL_PARTNER_TAB" },
                        "mainInfo"
                      )}
                      onClick={() => {
                        switchTab("CHANNEL_PARTNER_TAB");
                      }}
                    >
                      <div className="TabsName cursor-pointer">
                        Channel Partner{" "}
                        <span className="numb">
                          {/* <img src={doubtImage} /> */}
                          <div className="infoooo">
                            <span>Channel Partner</span>
                          </div>
                        </span>
                      </div>
                    </NavLink>
                  </NavItem>
                </ul>
              </div>

              <div className="content clearfix">
                <TabContent activeTab={tab}>
                  <TabPane tabId={"GEOGRAPHY_TAB"}>
                    <GeographyReport tab={tab}></GeographyReport>
                  </TabPane>
                  <TabPane tabId={"LOCATION_TAB"}>
                    <LocationReport tab={tab}></LocationReport>
                  </TabPane>
                  <TabPane tabId={"SALESMAN_TAB"}>
                  { hide !== "map" ? (
                    <SalesmanReport tab={tab} setHide={setHide} />
                    ) : (
                      <GoogleMap setHide={setHide} />
                    )
                  }
                  </TabPane>
                  <TabPane tabId={"CHANNEL_PARTNER_TAB"}>
                  { hide !== "mapView" ? (
                    <ChannelPartnerReport tab={tab} setHide={setHide} />
                    ) : (
                      <GoogleMap setHide={setHide} />
                    )
                  }
                  </TabPane>
                </TabContent>
              </div>

              <div className="actions clearfix text-center my-4">
                <MountCondition
                  condition={lodash.isNull(tabsStructure[tab].next)}
                >
                  <MountCondition.True>
                    <Link
                      to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS}
                      className="nextMaster btn btn-group"
                    >
                      Next
                    </Link>
                  </MountCondition.True>
                  <MountCondition.False>
                    <Link
                      to={false}
                      className="nextMaster btn btn-group"
                      onClick={nextTab}
                    >
                      Next
                    </Link>
                  </MountCondition.False>
                </MountCondition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
