import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import userDetailsAPI from "../../api/userDetailsAPI";
import { toast } from "react-toastify";
import getBusinessRulesAPI from "../../api/getBusinessRulesAPI";  
import Avatar from "react-avatar";
import config from "../../../src/config/config"
import getUsersDetailsAPI from "../../api/getUsersDetailsAPI";
import users from "../../assets/images/users/avatar-7.jpg"
import user from "../../assets/images/profile-pic.png"
import defaultLogo from "../../assets/images/logo-dark.png";

function MainProfile() {
  const [count, setCount] = useState(0);
  const [imagePreview, setImagePreview] = useState(user);
  const fileInputRef = useRef(null);
  const [img, setImg] = useState(null);
  const [optionList,setOptionList] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [reloadPage, setReloadPage] = useState(false);

  const MAX_FILE_SIZE = 1024 * 2000; //100KB

  const SUPPORTED_FORMATS = ["image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png", 
  "image/svg",
  "image/webp"];

  const navigate = useNavigate();
  const authUser = JSON.parse(localStorage.getItem("authUser"));

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const name = RegExp(/^[A-Za-z ]+$/);

  // function isValidFileType(profilePicture, image) {
  //   return profilePicture && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  // }


  // useEffect(() => {
  //   // Listen for changes in userDetails and trigger page reload once
  //   if (reloadPage) {
  //     window.location.reload();
  //   }
  // }, [reloadPage]);

  // useEffect(() => {
  //   // Set reloadPage to true when userDetails changes
  //   setReloadPage(true);
  // }, [userDetails]);

  const fetchData = async() => {
    try{
        const response = await getBusinessRulesAPI();
        setOptionList(response.data?.data);
      }
    catch(error){
        console.log("Erorr reg", error)
    }
  };

  const handleImageChange = (event) => {
    // validation.setFieldValue("profilePicture", event.currentTarget.files[0]);
    const selectedFile = event.currentTarget.files[0];

    if(selectedFile){
      validation.setFieldValue("profilePicture", selectedFile);
      setImg(selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
        // localStorage.setItem("profilePicture", reader.result);
      };
      reader.readAsDataURL(event.currentTarget.files[0]);

      validation.setFieldValue("profilePicture", selectedFile);
    }
    
    
  };

  const handleEditClick = () => {
    // Trigger a click event on the hidden input element
    const fileInput = document.getElementById("profile-picture-input");
    if (fileInput) {
      fileInput.click();
    }
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      // profilePicture: authUser.data.profilePicture,
      organizationName: authUser.data.organizationName,
      organizationBusinessRole: "Owner",
      firstName: authUser.data.firstName,
      lastName: authUser.data.lastName,
      profilePicture: user
      // email: authUser.data.email,
      // phoneNumber: authUser.data.phoneNumber,
    },

    validationSchema: Yup.object({
      profilePicture: Yup.mixed().test(
        "fileSize",
        "The selected image exceeds the file size limit of 7MB.",
        (value) => !value || value || value.size <= 7000000
      ),
      // .test("is-valid-type", "Not a valid image type",
      //   value => isValidFileType(value && value.name.toLowerCase(), "profilePicture"))
      // .test("is-valid-size", "Max allowed size is 2 MB",
      //   value => value && value.size <= MAX_FILE_SIZE),

      organizationName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Organisation Name"),

      organizationBusinessRole: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Business Role "),

      firstName: Yup.string()
        .matches(name, "Only characters are allowed")
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter First Name"),

      lastName: Yup.string()
        .matches(name, "Only characters are allowed")
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Last Name"),

      // email: Yup.string().email(),

      // phoneNumber: Yup.string()
      //   .matches(phoneRegExp, "Phone number is not valid")
      //   .test(
      //     "length",
      //     "Phone number should be of exactly 10 digits",
      //     (val) => val?.length == 10
      //   ),
    }),

    onSubmit: async (values) => {
      
        try {

            const newCount = 20;
            const userData = JSON.parse(localStorage.getItem("authUser"));
            const { data } = await userDetailsAPI(values, {
                id: userData.userId,
            });

            userData.data = { ...userData.data, ...data.data };

            localStorage.setItem("authUser", JSON.stringify(userData));
            localStorage.setItem("counter", newCount.toString());

            setCount(newCount);
            toast.success("Profile Updated Successfully");
            setTimeout(() => { navigate("/account"); },1000);
          
        } catch (error) { return toast.error(error.message) }
    },
  });
  const stepper = ["profile", "organization", "masters", "user", "billing"];

  useEffect(() => {
    fetchData();
  },[])


  const getUsersDetails = async () => {
    try {
        const { data : response } = await getUsersDetailsAPI(authUser.data.id);
        const userDetails = response.data;

        if (userDetails.profilePicture) { setImagePreview(`${config.api.base_url}public/documents/${userDetails.profilePicture}`); }

        return setUserDetails(userDetails);

      } catch (error) { toast.error(error.message); }
  };

  useEffect(() => { getUsersDetails(); return () => null; }, [])

  return (
    <div className="">
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg={3} md={3}>
            <div className="profileImageSection">
              <h5>Your Profile</h5>
              <div className="profileImage">
                {/* <h5 type="file" name="file">M</h5> */}
                {/* <Link>
                  <img className="edit" src={Edit} />
                </Link> */}
                {/* <Input /> */}
                {/* <Avatar
                  src={imagePreview}
                  size="100"
                  round
                  className="edit"
                  onClick={handleAvatarClick}
                  style={{ cursor: 'pointer' }}
                /> */}
                <i onClick={() => {
                    const inputElement = document.getElementById("file-input");
                    if (inputElement) {
                      inputElement.click();
                    }
                  }} class="fas fa-pen custom-pen-design"></i>
                <input
                  type="file"
                  name="profilePicture"
                  title="Select a file"
                  accept="image/png"
                  onChange={handleImageChange}
                  // ref={fileInputRef}
                  // defaultValue={authUser.data.profilePicture}
                  // value={validation.values.profilePicture}
                  style={{
                    position: "absolute",
                    backgroundColor: "none",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                ></input>
                    {/* onClick={handleImageChange}> */}
                {/* <div
                  className="edit-icon"
                  onChange={handleImageChange}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                >
                  
                </div> */}
                
                  {imagePreview && (
                    <div>
                      <Avatar src={imagePreview} size="150" opacity="0" round />
                    </div>
                  )}
              
                {/* <button className="btn btn-group">
                    <i class="fas fa-pen" style={{ color: "#0022a0" }}></i>
                </button> */}
              </div>
            </div>
          </Col>
          <Col lg={9} md={9}>
            <div className="submitForm">
              <Col lg={12}>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <Form>
                        <div className="mb-0">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            Organisation Name
                          </Label>
                          <Input
                            type="text"
                            placeholder="Sample Organisation Name"
                            name="organizationName"
                            className="form-control"
                            id="formrow-firstname-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue={authUser.data.organizationName}
                            value={validation.values.organizationName}
                            invalid={
                              validation.touched.organizationName &&
                              validation.errors.organizationName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.organizationName &&
                          validation.errors.organizationName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.organizationName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      {/* <Form> */}
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Business Role
                        </Label>
                        <select
                          className="form-control"
                          name="organizationBusinessRole"
                          onChange={validation.handleChange}
                          value={validation.values.organizationBusinessRole}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.organizationBusinessRole &&
                            validation.errors.organizationBusinessRole
                              ? true
                              : false
                          }
                          placeholder="Select Role">
                            {optionList.map((item) => (
                              <option key={item._id} value={item.roleName}>
                                  {item.roleName}
                              </option>
                          ))}
                        </select>
                        {validation.touched.organizationBusinessRole &&
                        validation.errors.organizationBusinessRole ? (
                          <FormFeedback type="invalid">
                            {validation.errors.organizationBusinessRole}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {/* </Form> */} 
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <Form>
                        <div className="mb-0">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            First Name
                          </Label>
                          <Input
                            type="text"
                            name="firstName"
                            placeholder="Sample First Name"
                            className="form-control"
                            id="formrow-firstname-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue={authUser.firstName}
                            value={validation.values.firstName}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <Form>
                        <div className="mb-0">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            Last Name
                          </Label>
                          <Input
                            type="text"
                            name="lastName"
                            placeholder="Sample Last Name"
                            className="form-control"
                            id="formrow-firstname-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue={authUser.lastName}
                            value={validation.values.lastName}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <Form>
                        <div className="mb-0">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            Email
                          </Label>
                          <Input
                            type="text"
                            name="email"
                            readOnly
                            className="form-account"
                            id="formrow-firstname-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue={authUser?.email}
                            value={authUser?.data?.email}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {/* <span>{authUser.data.email}</span> */}
                          {/* {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null} */}
                        </div>
                      </Form>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="mt-4">
                      <Form>
                        <div className="mb-0">
                          <Label
                            className="form-label"
                            htmlFor="formrow-firstname-input"
                          >
                            Phone Number
                          </Label>
                          <Input
                            type="text"
                            name="phoneNumber"
                            readOnly
                            className="form-account"
                            id="formrow-firstname-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue={authUser?.phoneNumber}
                            value={authUser?.data?.phoneNumber}
                            invalid={
                              validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                          {/* <span>{authUser.data.phoneNumber}</span> */}
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </Col>
          <Col lg={12}>
            <div className="saveBtn mt-4">
              <button type="submit" className="btn btn-group">
                Save
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}


export default MainProfile;
