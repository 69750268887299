import config, {server} from '../config/config'
import axios from 'axios'

export const getCityListAPI = async (params) => {
    
    return await server({
        method  : 'get',
        url     : `location/cities`,
        params  : {states:[params.state]},
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getCityListAPI