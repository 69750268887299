import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";

import ReactSelect from "react-select";
import { Alert, Modal, Button, Input, FormFeedback, Form, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";

import { getDivisionsAPI } from "../../../../api/division.api";
import { deleteDistributionHierarchyAPI, getDistributionHierarchiesAPI, storeDistributionHierarchyAPI, updateDistributionHierarchyAPI } from "../../../../api/distribution-hierarchy.api";

import MountComponent from "../../../../module/mount-components";
import { errorMessage } from "../../../../module/error-methods";

import { themeReactSelect, styleReactSelect } from "../../../../module/select-methods";
import DeleteModal from "../../../../module/delete-modal.component";

export default React.memo(({ tab, userPermissions }) => {
    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [divisionOptions, setDivisionsOptions] = useState([]);
    const [distributionHierarchy, setDistributionHierarchies] = useState([]);
    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const divisionList = async () => {
        await getDivisionsAPI().then(({ data }) => {
            if (lodash.isArray(data)) {
                const options = data.map(({ name, id }) => ({ label: name, value: id }));
                return setDivisionsOptions([...options])
            }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
    };

    const distributionHierarchyList = async () => {
        setLoading(true);
        await getDistributionHierarchiesAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setDistributionHierarchies([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteDistributionHierarchyAPI(moduleState.id);
            await distributionHierarchyList();
            toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" Deleted successfully !` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateDistributionHierarchyFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateDistributionHierarchyFormik.setValues({});
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const distributionHierarchyInitialValues = {
        name: '', divisionId: null,
    };

    const distributionHierarchyValidation = {
        divisionId: Yup.string().required("Please select division."),
        name: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters and spaces are allowed')
            .min(3, 'Name must be at least 3 characters')
            .trim().required('Name is required'),
    };

    const updateDistributionHierarchyFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...distributionHierarchyInitialValues },
        validationSchema: Yup.object({ ...distributionHierarchyValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                const { id, divisionId, name } = values;

                await updateDistributionHierarchyAPI(id, { divisionId, name });
                await distributionHierarchyList();

                toggleNotificationState({ visible: true, message: 'Distribution Hierarchy updated successfully !' });
                return resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); }
        },
    });

    const distributionHierarchyFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...distributionHierarchyInitialValues },
        validationSchema: Yup.object({ ...distributionHierarchyValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);

                await storeDistributionHierarchyAPI({ ...distributionHierarchyInitialValues, ...values });
                await distributionHierarchyList();

                toggleNotificationState({ visible: true, color: 'success', message: 'Distribution Hierarchy created successfully !' });
                return resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); }
        },
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const dataTableHandler = () => {
        const columns = [
            {
                label: "Sr.No.",
                field: "sr",
                sort: "asc",
                width: 150,
            }, {
                label: "SBU Name",
                field: "divisionName",
                sort: "asc",
                width: 270,
            }, {
                label: "Hierarchy Name",
                field: "name",
                sort: "asc",
                width: 270,
            },
        ];
        const rows = [
            ...distributionHierarchy.map((details, index) => ({
                sr: ++index,
                name: `${details.name} (${details.id})`,
                divisionName: `${details.division.name} (${details.division.id})`,
                id: details.id,
                action: (
                    <div className="functionalBtn editDataShow">
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_DELETE}>
                            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                    </div>
                ),
            })),
        ];

        if (userPermissions.NETWORK_DETAILS_UPDATE || userPermissions.NETWORK_DETAILS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
        }

        return updateDataTable({ columns: [...columns], rows: [...rows] });
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => {
        if (tab === "DISTRIBUTION_HIERARCHY_TAB") { divisionList(); distributionHierarchyList(); }
        return () => null;
    }, [tab]);

    useEffect(() => {
        if (tab === "DISTRIBUTION_HIERARCHY_TAB") { distributionHierarchyFormik.resetForm(); updateDistributionHierarchyFormik.resetForm(); }
        return () => null;
    }, [tab]);

    useEffect(() => {
        if (notificationState.visible) {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
        }
    }, [notificationState.visible]);

    useEffect(() => { dataTableHandler() }, [distributionHierarchy]);

    /** ---------------------------------------------------------------------------------------------------------------------------------- */

    return (
        <div className="">
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <MountComponent condition={userPermissions.NETWORK_DETAILS_CREATE}>
                <form className="row mainText" onSubmit={distributionHierarchyFormik.handleSubmit}>
                    <div className="mt-1 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 bg-transparent" style={{ height: '3em' }}>
                        <ReactSelect
                            options={divisionOptions}
                            placeholder="Select Division/SBU"
                            onChange={({ value }) => { distributionHierarchyFormik.setFieldValue("divisionId", value); }}
                            value={divisionOptions.find(({ value }) => value === distributionHierarchyFormik.values.divisionId) ?? null}
                            theme={(theme) => themeReactSelect(theme, distributionHierarchyFormik.touched.divisionId && distributionHierarchyFormik.errors.divisionId)}
                            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, distributionHierarchyFormik.touched.divisionId && distributionHierarchyFormik.errors.divisionId) }}
                        ></ReactSelect>
                        <MountComponent condition={distributionHierarchyFormik.touched.divisionId && distributionHierarchyFormik.errors.divisionId}>
                            <small className="select-error">{distributionHierarchyFormik.errors.divisionId}</small>
                        </MountComponent>
                    </div>
                    <div className="mt-1 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 bg-transparent" style={{ height: '40px' }}><div className="mainFormSubmit">
                        <div className="error-input">
                            <Input placeholder="Please enter distribution hierarchy name." className="h-100" name="name"
                                invalid={distributionHierarchyFormik.errors.name && distributionHierarchyFormik.touched.name}
                                value={distributionHierarchyFormik.values.name}
                                onChange={distributionHierarchyFormik.handleChange}
                            ></Input>
                            <FormFeedback valid={!distributionHierarchyFormik.errors.name && !distributionHierarchyFormik.touched.name}>
                                {distributionHierarchyFormik.errors.name}
                            </FormFeedback>
                        </div>
                        <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
                    </div></div>
                </form>
            </MountComponent>

            <div className="dataTable-hide-extra-props mt-3">
                <MDBDataTable responsive bordered data={dataTable} footer={false} />
            </div>

            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${moduleState.name} ?`}
                ></DeleteModal>
            </Modal>
            {/* Modal for Delete Distribution Hierarchy */}

            <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Update Distribution Hierarchy</h5>
                    <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
                </div>
                <Form className="needs-validation" onSubmit={updateDistributionHierarchyFormik.handleSubmit}>
                    <div className="modal-body"><div className="form-group mb-3">
                        <Label className="bg-transparent mb-1"><strong>Division Name</strong></Label>
                        <ReactSelect
                            options={divisionOptions}
                            placeholder="Select Division/SBU"
                            onChange={({ value }) => { updateDistributionHierarchyFormik.setFieldValue("divisionId", value); }}
                            value={divisionOptions.find(({ value }) => value === updateDistributionHierarchyFormik.values.divisionId) ?? null}
                            theme={(theme) => themeReactSelect(theme, updateDistributionHierarchyFormik.touched.divisionId && updateDistributionHierarchyFormik.errors.divisionId)}
                            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateDistributionHierarchyFormik.touched.divisionId && updateDistributionHierarchyFormik.errors.divisionId) }}
                        ></ReactSelect>
                        <MountComponent condition={updateDistributionHierarchyFormik.touched.divisionId && updateDistributionHierarchyFormik.errors.divisionId}>
                            <small className="select-error">{updateDistributionHierarchyFormik.errors.divisionId}</small>
                        </MountComponent>

                        <div className="mt-3 h-100 bg-transparent">
                            <Label className="bg-transparent mb-0"><strong>Distribution Hierarchy Name</strong></Label>
                            <Input placeholder="Please enter distribution hierarchy name." className="mt-1" name="name"
                                invalid={updateDistributionHierarchyFormik.touched.name && updateDistributionHierarchyFormik.errors.name}
                                value={updateDistributionHierarchyFormik.values.name}
                                onChange={updateDistributionHierarchyFormik.handleChange}
                            ></Input>
                            <FormFeedback valid={!updateDistributionHierarchyFormik.errors.name && !updateDistributionHierarchyFormik.touched.name}>
                                {updateDistributionHierarchyFormik.errors.name}
                            </FormFeedback>
                        </div>

                    </div></div>

                    <div className="modal-footer">
                        <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>
                            Cancel
                        </button>
                        <button type="submit" className="nextMasterModal btn">
                            Update
                        </button>
                    </div>
                </Form>
            </Modal>
            {/* Modal for Edit Distribution Hierarchy */}
        </div>
    )
});
