import config,{server} from '../config/config'

export const getNetworkLocationsAPI = async (id) => {
    
    return await server({
        method  : 'get',
        url     : `network-locations/?regionId=${id}`,
        // data    :  {location:[params.divisionId]},
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getNetworkLocationsAPI