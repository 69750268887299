import React from "react";
import { Col, Container, Row } from "reactstrap";
import RightSideProfile from "../Dashboard/right-side-profile";
import ProductDetails from "./source";

const Index = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
                {/* <Catalog/> */}
                <ProductDetails />
            </Col>
            <Col lg={3}>
              {/* <RightSideProfile /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
