import React from "react";
import lodash from "lodash";
import { Link } from "react-router-dom";

import { getColor, useApiData, useGuideHook } from "../../module/start-guide-methods.js";
import { useNetworkHook } from "../../module/network-tab-methods";

import { LevelContainer, LevelIndex, LevelLink, ProgressCircle } from "../../module/start-guide-components";
import MountComponent from "../../module/mount-components";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

export default React.memo(() => {
    const { NAME, CHILDREN: { PROFILE, ORGANIZATION_DETAILS, NETWORK_DETAILS, USER_RIGHTS, ADMIN_USERS } } = PERMISSIONS.QUICK_START_GUIDE;
    const totalModules = {
        [PROFILE.NAME]: { current: PROFILE.NAME, previous: null },
        [ORGANIZATION_DETAILS.NAME]: { current: ORGANIZATION_DETAILS.NAME, previous: PROFILE.NAME },
        [NETWORK_DETAILS.NAME]: { current: NETWORK_DETAILS.NAME, previous: ORGANIZATION_DETAILS.NAME },
        [USER_RIGHTS.NAME]: { current: USER_RIGHTS.NAME, previous: NETWORK_DETAILS.NAME },
        [ADMIN_USERS.NAME]: { current: ADMIN_USERS.NAME, previous: USER_RIGHTS.NAME },
    };

    const totalProgressModules = {
        [PROFILE.NAME]: { label: 'Quick Start Guide', path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.PROFILE },
        [ORGANIZATION_DETAILS.NAME]: { label: 'Resume', path: ROUTES.ORGANIZATION_DETAILS.PATH },
        [NETWORK_DETAILS.NAME]: { label: 'Resume', path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.NETWORK_DETAILS },
        [USER_RIGHTS.NAME]: { label: 'Resume', path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS },
        [ADMIN_USERS.NAME]: { label: 'Resume', path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS },
        [NAME]: { label: 'Completed', path: ROUTES.HOME.PATH },
    };

    const { array: storedModules } = useApiData({ module: NAME });
    const { count, render } = useGuideHook(storedModules, totalModules);
    const { step } = useNetworkHook(storedModules, (array) => {
        switch (true) {
            case lodash.isEmpty(array): { return totalProgressModules[PROFILE.NAME]; }
            case !lodash.includes(array, PROFILE.NAME): { return totalProgressModules[PROFILE.NAME]; };
            case !lodash.includes(array, ORGANIZATION_DETAILS.NAME): { return totalProgressModules[ORGANIZATION_DETAILS.NAME]; };
            case !lodash.includes(array, NETWORK_DETAILS.NAME): { return totalProgressModules[NETWORK_DETAILS.NAME]; };
            case !lodash.includes(array, USER_RIGHTS.NAME): { return totalProgressModules[USER_RIGHTS.NAME]; };
            case !lodash.includes(array, ADMIN_USERS.NAME): { return totalProgressModules[ADMIN_USERS.NAME]; };
            default: { return totalProgressModules[NAME]; };
        }
    });

    return (
        <React.Fragment>
            <div className="guide"><div className="row">
                <MountComponent condition={render}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="guideContent">
                            <h4>Quick Start Guide</h4>
                        </div>
                        <LevelContainer source={PROFILE.NAME}>
                            <LevelIndex color={getColor(storedModules, totalModules[PROFILE.NAME])}><span>1</span></LevelIndex>
                            <LevelLink to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.PROFILE}>Setup Your Profile</LevelLink>
                        </LevelContainer>
                        <LevelContainer source={ORGANIZATION_DETAILS.NAME}>
                            <LevelIndex color={getColor(storedModules, totalModules[ORGANIZATION_DETAILS.NAME])}><span>2</span></LevelIndex>
                            <LevelLink to={ROUTES.ORGANIZATION_DETAILS.PATH}>Organization</LevelLink>
                        </LevelContainer>
                        <LevelContainer source={NETWORK_DETAILS.NAME}>
                            <LevelIndex color={getColor(storedModules, totalModules[NETWORK_DETAILS.NAME])}>3</LevelIndex>
                            <LevelLink to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.NETWORK_DETAILS}>Network Details</LevelLink>
                        </LevelContainer>
                        <LevelContainer source={USER_RIGHTS.NAME}>
                            <LevelIndex color={getColor(storedModules, totalModules[USER_RIGHTS.NAME])}>4</LevelIndex>
                            <LevelLink to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS}>User Rights</LevelLink>
                        </LevelContainer>
                        <LevelContainer source={ADMIN_USERS.NAME}>
                            <LevelIndex color={getColor(storedModules, totalModules[ADMIN_USERS.NAME])}>5</LevelIndex>
                            <LevelLink to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS}>Users</LevelLink>
                        </LevelContainer>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto">
                        <ProgressCircle count={count}></ProgressCircle>
                    </div>
                </MountComponent>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-auto">
                    <Link className="btn btn-primary mt-4" style={{ width: '13em' }} to={step?.path}>
                        {step?.label}
                    </Link>
                </div>
            </div></div>
        </React.Fragment>
    );
});