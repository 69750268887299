import React, { useState, useEffect } from "react";
import Editable from "react-bootstrap-editable";
import {
  Col,
  Row,
  Modal,
  FormGroup,
  Form,
  Label,
  Input,
  Alert,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import getSalesHierarchies from "../../api/getSalesHierarchies";
import deleteSalesHierarchy from "../../api/deleteSalesHierarchy";
import updateSalesHierarchyAPI from "../../api/updateSalesHierarchyAPI";
import getDivisionsAPI from "../../api/getDivisionsAPI";
import getSaleSaveAPI from "../../api/getSaleSaveAPI";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MDBDataTable } from "mdbreact";

function SaleHierarchy({tabId, setIsListEmpty}) {
  const [value, setValue] = useState("superuser");
  const [selectedGroup, setselectedGroup] = useState(null);
  const [modal_delete, setmodal_delete] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [visible, setVisible] = useState(false);

  const [divisionId, setDivisionId] = useState(null);
  const [salesId, setSalesId] = useState(null);
  const [salesName, setSalesName] = useState("");
  const [salesValue, setSalesValue] = useState(salesName ? salesName : "");
  const [salesLevels, setSalesLevels] = useState([]);
  const [label, setLabel] = useState({})
  const [resetKey, setResetKey] = useState(0);
  const [divisionAPICalls, setDivisionAPICalls] = useState(0);

  const [selectedSBU, setSelectedSBU] = useState(null);

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  
  const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
  const organizationId = authUserOrg?.data?.data?.id;

  function handleSelectGroup(selectedValue) {
      setSelectedSBU(selectedValue);
  }

  
  const filteredSales = selectedSBU
  ? salesLevels.filter((sales) => sales?.division?.name === selectedSBU.label)
  : salesLevels;

  console.log("filteredSales sdd", filteredSales)

  // function handleSelectGroup(selectedValue) {
  //   setselectedGroup(selectedValue);
  //   // const selectedLabel = selectedGroup.label;
  //   // setLabel(selectedLabel);
  // }

  const SalesHierarchyList = async () => {
    try {
      const response = await getSalesHierarchies();
      if (response) {
        if(response?.data?.length===0){
          setIsListEmpty(true);
        }
        setSalesLevels(response.data);
        console.log("Sales levels", salesLevels)
      }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    SalesHierarchyList();
  }, []);

  const handleSubmit = async (newValue) => {

    const hasSpecialCharacters = /[^a-zA-Z0-9\s]/.test(newValue);

    if (hasSpecialCharacters) {
    // Display an error message or take appropriate action
    toast.error("Special characters not allowed");
    return;
    }

    try {
      const payload = {
        userId: authUser.userId,
        name: newValue,
        divisionId: selectedSBU ? selectedSBU.value: null,
        organizationId: organizationId,
      };

      if (selectedSBU && newValue.trim() !== "") {
        // Add new row
        const newRow = {
            sr: salesLevels.length + 1,
            sbu: selectedSBU.label,
            name: newValue,
        };
        setSalesLevels((prevRegions) => [...prevRegions, newRow]);
    }

      setResetKey((prevKey) => prevKey + 1);

      const { data } = await getSaleSaveAPI(payload);
      
        if(data){
          setIsListEmpty(false)
        }
        setVisible(true);
        setselectedGroup(null)
        toast.success("Sales Heirarchy Added Successfully");
        SalesHierarchyList();
        const isEmpty = salesLevels.length === 0;
        // Call the callback function to inform the parent component
        // onApiSuccess(isEmpty);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error("Sales Heirarchy Already Exist");
      }
      if (error.response.status === 400) {
        if(selectedGroup === null){
          toast.error("SBU/Division Cannot be empty !");
        }else{
          toast.error("Sales Heirarchy Cannot be empty !");
        }
      }
    }
  };

  const handleEditClick = (salesId, salesName, divisionId) => {
    setSalesId(salesId);
    setDivisionId(divisionId);
    setSalesName(salesName);
    setSalesValue(salesName);
    // setselectedGroup(divisionId ? optionGroupEdit[0].options.find(option => option.value === divisionId) : null);
    const selectedSBUForEdit = divisionId ? optionGroupEdit[0].options.find(option => option.value === divisionId) : null;
    setselectedGroup(selectedSBUForEdit);
    setSelectedSBU(selectedSBUForEdit);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_delete() {
    setmodal_delete(!modal_delete);
    removeBodyCss();
  }

  function tog_edit() {
    setmodal_edit(!modal_edit);
    removeBodyCss();
  }

  const handleSubmitRemove = async (salesId) => {
    try {
      const { data } = await deleteSalesHierarchy(salesId);
      setSalesId(null);
      setmodal_delete(false);
      toast.success("Sales Heirarchy Deleted Successfully");
      SalesHierarchyList();
    } catch (error) {
      console.log("Errorxx", error);

      //
      // toast.error(error.response.data.error);
    }
  };

  const [selectedEditGroup, setselectedEditGroup] = useState(
    divisionId ? divisionId : null
  );

  function handleSelectEditGroup(selectedEditGroup) {
    setselectedEditGroup(selectedEditGroup);
    setSelectedSBU(selectedEditGroup);
  }

  const optionGroupEdit = [
    {
      options: divisions.map((DivisionName) => {
        return { label: DivisionName.name, value: DivisionName.id };
      }),
    },
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      organizationId: organizationId,
      name: salesName,
      userId: authUser.userId,
      divisionId: selectedGroup ? selectedGroup.value : "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Sales Name"),
      divisionId: Yup.number().required("Please select division"),
    }),
    onSubmit: async (values) => {
      const hasSpecialCharacters = /[^a-zA-Z0-9\s]/.test(values.name);

            if (hasSpecialCharacters) {
            // Display an error message or take appropriate action
            toast.error("Special characters not allowed");
            return;
        }
      try {
        const payload = {
          userId: authUser.userId,
          name: values.name,
          divisionId: selectedSBU ? selectedSBU.value : null, // Use selectedSBU here
          organizationId: organizationId,
      };

        const { data } = await updateSalesHierarchyAPI(payload, {
          id: salesId,
        });
        setSalesId(null);
        setSalesName("");
        setmodal_edit(false);
        toast.success("Sales Heirarchy Updated Successfully");
        SalesHierarchyList();
      } catch (error) {
        if(error.response.status == 409){
          setSalesName(values.name);
          setmodal_edit(false);
          toast.error("Sales Heirarchy  already exists")
        }
        setmodal_edit(false);
      }
    },
  });

  const divisionList = async () => {
    try {
      const response = await getDivisionsAPI();
      if (response) {
        setDivisions(response.data);
        console.log("setDivisions", divisions)
      }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    if (divisionAPICalls <= 2) {
      divisionList();
  }
  }, [tabId]);

  const optionGroup = [
    {
      options: divisions.map((DivisionName) => {
        return { label: DivisionName.name, value: DivisionName.id };
      }),
    },
  ];

  // useEffect(() => {
  //   if (divisionId) {
  //     setselectedEditGroup(optionGroupEdit[0].options.find((option) => option.value === divisionId));
  //   }
  // }, [divisionId, optionGroupEdit]);

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Heirarchy Name",
        field: "hraName",
        sort: "asc",
        width: 270,
      },
      {
        label: "SBU Name",
        field: "sbuName",
        sort: "asc",
        width: 270,
      },
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: [
      ...filteredSales.map((levels, i) => ({
        sr: ++i,
        hraName: levels.name,
        sbuName: levels.division.name,
        id: levels.id,
        action: (
          <div className="functionalBtn editDataShow">
            <button
              className="btn btn-group border"
              onClick={() => {
                tog_edit();
                handleEditClick(levels.id, levels.name, levels.divisionId);
              }}
            >
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-group border"
              onClick={() => {
                tog_delete();
                handleEditClick(levels.id, levels.name, levels.divisionId);
              }}
              data-toggle="modal"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        ),
      })),
    ],
  };

  return (
    <div className="division">
      <Row className="justify-content-center">
        <Col lg={12}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-2">
                
                <Select
                  value={selectedSBU}
                  // onChange={(props) => { setDivisionId(props.value); handleSelectGroup(); }}
                  onChange={(selectedValue) => handleSelectGroup(selectedValue)}
                  options={optionGroup}
                  classNamePrefix="select2-selectionsssss"
                  className="mainSelect"
                  placeholder="Select Division/SBU"
                />
              </div>
            </div>
            <div className="col-md-6">
              <Editable
                key={resetKey}
                alwaysEditing={true}
                disabled={false}
                editText={value}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText
                className="mainText"
                type="textfield"
                value={value}
                onSubmit={handleSubmit}
                onChange={(newValue) => setValue(newValue)}
              />
            </div>
          </div>
          <div className="customeDatatable">
            <MDBDataTable responsive bordered data={data} footer={false} />
          </div>
          {/* <div className="editDataShow">
            {salesLevels.map((levels) => (
            <>
            <div className="data">
              <h5>{levels.name}</h5>
              <div className="functionalBtn">
                <button 
                  className="btn btn-group border"
                  onClick={() => {
                    tog_edit();
                    handleEditClick(levels.id, levels.name, levels.divisionId);
                  }}>
                  <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
                <button 
                    className="btn btn-group border"
                    onClick={() => {
                    tog_delete();
                    handleEditClick(levels.id, levels.name, levels.divisionId);
                  }}
                  data-toggle="modal"
                  >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            </>
            ))} 
          </div> */}
        </Col>
      </Row>
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        backdrop={"static"}
        scrollable={true}
        id="staticBackdrop"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Delete
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_delete(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete {validation.values.name} ?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="cancelModal btn"
            onClick={() => {
              setmodal_delete(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="nextMasterModal btn"
            onClick={() => handleSubmitRemove(salesId)}
          >
            Delete
          </button>
        </div>
      </Modal>

      {/* Modal for Edit Division */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          tog_edit();
        }}
        backdrop={"static"}
        scrollable={true}
        id="staticBackdrop"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Edit Sales Heirarchy
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_edit(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-2">
                    <Label htmlFor="validationCustom01">
                        Division Name
                    </Label>
                    <Select
                      value={selectedSBU}
                      // onChange={(selectedValue) => handleSelectEditGroup(selectedValue)}
                      onChange={handleSelectEditGroup}
                      options={optionGroupEdit}
                      classNamePrefix="select2-selectionsssss"
                      className="mainSelect"
                      placeholder="Select Division/SBU"
                    />
                  </div>
                </Col>

              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Sales name</Label>
                  <Input
                    name="name"
                    placeholder="Sales name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="cancelModal btn"
              onClick={() => {
                setmodal_edit(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="nextMasterModal btn">
              Update
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default SaleHierarchy;
