import React, { useState, useEffect, useRef } from "react";
import { Form, Label } from "reactstrap";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import lodash from "lodash";
import { MountCondition } from "../../../module/mount-components";
import bannerImage from "../../../assets/images/Banner-product.png";
import getProductListAPI from "../../../api/getProductListAPI";
import getCategoryDetails from "../../../api/getCategoriesAPI";
import config from "../../../config/config";
import phone from "../../../assets/images/icon/phone.png";
import logoDark from "../../../assets/images/logo-dark.png";

export default React.memo(() => {
  const [loading, setLoading] = useState(false);
  const [categoryItem, setCategoryItem] = useState([]);
  const [category, setCategory] = useState([]);
  const contentRef = useRef(null);

  // @apis ----------------------------------------------------------------------------------------------------------------------------------
  const productDetails = async () => {
    try {
      setLoading(true);
      const { data } = await getProductListAPI();

      if (data) {
        const itemDetails = data.data;
        setCategoryItem(itemDetails);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    productDetails();
  }, []);

  const categoryDetails = async () => {
    try {
      setLoading(true);
      const { data } = await getCategoryDetails();

      if (data) {
        const category = data;
        setCategory(category);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    categoryDetails();
  }, []);

  const handlePageBrochure = async () => {
    const content = contentRef.current;

    try {
      const images = content.querySelectorAll("img");
      const imagePromises = Array.from(images).map(async (img) => {
        const response = await fetch(img.src);
        const blob = await response.blob();
        const dataUrl = URL.createObjectURL(blob);
        img.src = dataUrl; // Set the data URL directly to the image
      });

      // Wait for images to load or resolve immediately if already loaded
      await Promise.all(imagePromises);

      const options = {
        margin: 10,
        filename: "Product_brochure.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf(content, options);
    } catch (error) {
    }
  };

  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  return (
    <React.Fragment>
      <MountCondition condition={loading}>
        <MountCondition.True>
          <div className="fullscreen-loader">
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          </div>
        </MountCondition.True>
      </MountCondition>
      <Form className="form-horizontal">
        <div className="mainHead">
          <h5>Product Brochure</h5>
          <div className="newbtn">
            <button
              type="button"
              className="btn btn-group ml-auto"
              onClick={handlePageBrochure}
              style={{ marginBottom: "0 !important" }}
            >
              Generate Brochure
            </button>
          </div>
        </div>
        <div ref={contentRef}>
          <div className="mb-0">
            <Label className="form-label" htmlFor="formrow-firstname-input">
              <img src={bannerImage} alt="" style={{ width: "100%" }} />
            </Label>
          </div>
          <div>
            {category.map((item) => (
              <MountCondition condition={!lodash.isEmpty(lodash.filter(categoryItem,({ categories }) => categories.name === item.name))}>
                <MountCondition.True>
                    <div key={item.id} className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h5
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          {item.name} (Category)
                        </h5>
                      </div>
                    </div>
                    <div className="row mt-4">
                      {categoryItem
                        .filter((product) => product.categories.name === item.name)
                        .map((product) => (
                          <div key={product.id} className="col-md-3 col-sm-6 mb-4">
                            <div className="card productCard">
                              <img
                                src={`${config.api.base_url}public/products/${product.image}`}
                                alt={`Product ${product.id}`}
                                className="img-fluid-product"
                              />
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: "-20px",
                                  }}
                                >
                                  {product.title}
                                </h5>
                                <p
                                  className="card-text"
                                  style={{ textAlign: "center" }}
                                >
                                  {product.description.length > 70
                                    ? `${product.description.slice(0, 50)}...`
                                    : product.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    </div>
                </MountCondition.True>
                 <MountCondition.False>
                      <React.Fragment></React.Fragment>
                 </MountCondition.False>
              </MountCondition>
            ))}
          </div>
          <div className="mainHead" style={{ marginBottom: "-20px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={phone}
                alt=""
                height="30px"
                width="30px"
                style={{ marginRight: "10px" }}
              />
              <div style={{ marginTop: "40px" }}>
                <p style={{ marginBottom: "2px" }}>+023 1800-425-1969</p>
                <p>www.adidas.com</p>
              </div>
            </div>
            <div className="newbtn">
              <img
                src={logoDark}
                alt=""
                height="40px"
                width="120px"
                style={{ marginRight: "10px", float: "right" }}
              />
              <div style={{ marginTop: "50px" }}>
                <p>@ 2023 Giffy Network LLP All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
});
