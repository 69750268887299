import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik"

import Select from "react-select";
import Dropzone from "react-dropzone";
import { Label, Input, Table, FormFeedback, Alert } from "reactstrap"

import getCategoriesAPI from "../../../../api/getCategoriesAPI";
import getUnitsAPI from "../../../../api/getUnitsAPI";
import getTaxesAPI from "../../../../api/getTaxesAPI";
import getBrandsAPI from "../../../../api/getBrandsAPI";
import getTypesAPI from "../../../../api/getTypesAPI";
import getSubCategoriesAPI from "../../../../api/getSubCategoriesAPI";
import getVariantsAPI from "../../../../api/getVariantsAPI";
import getSubVariantsAPI from "../../../../api/getSubVariantsAPI";
import updateProductDetailsAPI from "../../../../api/updateProductDetailsAPI";
import getProductDetailsAPI from "../../../../api/getProductDetailsAPI";
import { getCurrenciesAPI } from '../../../../api/currency.api';
import { getMeasurementsAPI } from '../../../../api/measurement.api';

import { validationSchema, initialValues, initialVariantState, initialVariantErrors, updateInitialValues } from "./helper";

import MountComponent, { MountCondition } from '../../../../module/mount-components';
import ROUTES from "../../../../routes/routes";
import { errorMessage } from "../../../../module/error-methods";

const useApiData = (apiFunction, setOptionsFunction, errorMessage, query, options = true) => {
    const fetchData = async () => {
        try {
            const response = await apiFunction(query);
            const selectOptions = lodash.map(response.data, ({ name, id }) => ({ label: name, value: id }));

            if (options === true) { setOptionsFunction(selectOptions); }
            else { setOptionsFunction(response.data); }
        } catch (error) {}
    };

    useEffect(() => { fetchData(); return () => { }; }, [apiFunction, setOptionsFunction, errorMessage]);
};

const notificationInitialState = { visible: false, message: '', color: '' }
export default () => {

    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

    const productFormik = useFormik({
        initialValues: { ...initialValues },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            try {

                setLoading(true);
                const removeImagePreview = (object) => lodash.omit(object, 'imagePreview');
                const formDataWithoutPreview = removeImagePreview(data);

                formDataWithoutPreview.variants = lodash.map(
                    formDataWithoutPreview.variants,
                    (variant) => removeImagePreview(variant)
                );

                await updateProductDetailsAPI(params.id, formDataWithoutPreview);
 
                toggleNotificationState({ visible: true, color: "success", message: "Success! Product details updated successfully!" });

                setTimeout(() => {
                    navigate(ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCTS)
                }, 3500);

            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); }
        },
    });

    // @get-data ------------------------------------------------------------------------------------------

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [taxOptions, setTaxOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [measurementOptions, setMeasurementOptions] = useState([]);

    const [variantOptions, setVariantOptions] = useState([]);
    const [subVariantOptions, setSubVariantOptions] = useState([]);
    const [variantState, setVariantState] = useState({ ...initialVariantState });
    const [variantErrors, setVariantErrors] = useState({ ...initialVariantErrors });

    const [variantOptionsVisibility, setVariantOptionsVisibility] = useState(true);

    const [productTypes] = useState([
        { label: 'Real Product', value: 'Real Product' },
        { label: 'Redemption Product', value: 'Redemption Product' },
    ]);

    useApiData(getCategoriesAPI, setCategoryOptions, 'Categories', null);
    useApiData(getUnitsAPI, setUnitOptions, 'Units', null);
    useApiData(getTaxesAPI, setTaxOptions, 'Taxes', null);
    useApiData(getBrandsAPI, setBrandOptions, 'Brands', null);
    useApiData(getTypesAPI, setTypeOptions, 'Types', null);
    useApiData(getSubCategoriesAPI, setSubCategoryOptions, 'Sub Categories', { categoryId: productFormik.values.categoryId });
    useApiData(getVariantsAPI, setVariantOptions, 'Variants', null);
    useApiData(getSubVariantsAPI, setSubVariantOptions, 'Sub Variants', null, false);
    useApiData(getCurrenciesAPI, setCurrencyOptions, 'Currencies', null);
    useApiData(getMeasurementsAPI, setMeasurementOptions, 'Measurements', null);

    const getSubCategoryDetails = async () => {
        await getSubCategoriesAPI({ categoryId: productFormik.values.categoryId }).then(({ data }) => {
            const options = lodash.map(data, (category) => ({
                value: category.id,
                label: category.name,
            }));
            return setSubCategoryOptions(options)
        }).catch((error) => { return setSubCategoryOptions([]) });
    };

    const getProductDetails = async () => {
        try {
            const { data } = await getProductDetailsAPI(params.id);
            const productState = updateInitialValues(data)

            productFormik.setValues({ ...productState.updatedProduct });

            setVariantState({ ...productState.updatedVariantState });
            setVariantOptionsVisibility(false);
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    };

    useEffect(() => { getSubCategoryDetails() }, [productFormik.values.categoryId]);

    useEffect(() => { getProductDetails(); }, []);

    // -------------------------------------------------------------------------------------------------------------------------------

    // @methods ----------------------------------------------------------------------------------------------------------------------

    const styleReactSelect = (baseStyles, state, error) => {
        return {
            ...baseStyles,
            '&:hover': {
                boxShadow: error ? '0 0 0 1px #dc3545' : '0 0 0 1px #ced4da',
                outlineColor: error ? '#dc3545' : '#ced4da',
                borderColor: error ? '#f46a6a' : '#ced4da',
                borderWidth: '1px'
            },
            borderColor: error ? '#f46a6a' : '#ced4da',
        };
    }
    const themeReactSelect = (theme, error) => {
        return {
            ...theme,
            boxShadow: 'none', border: 'solid 0.1px', outline: 'none', borderWidth: '0.1px',
            colors: { ...theme.colors, primary: error ? '#dc3545' : '#99a7d9', },
        }
    }

    const handleImageChange = useCallback((images, imageColumn, imagePreviewColumn, source = false) => {
        const file = images[0];
        const reader = new FileReader();

        reader.onload = () => {
            if (source === true) {
                return setVariantState({
                    ...variantState,
                    [imageColumn]: file, [imagePreviewColumn]: reader.result
                })
            }

            productFormik.setFieldValue(imageColumn, file);
            productFormik.setFieldValue(imagePreviewColumn, reader.result);
        };
        reader.readAsDataURL(file);
    });

    const handleProductVariants = useCallback((variants) => {
        return setVariantState({ ...variantState, options: [...variants] })
    }, [variantState]);

    const handleVariantStateOption = useCallback((subVariantOption, variant) => {
        const updatedOptions = lodash.map(lodash.clone(variantState.options), (state) => {
            if (state.value === variant) { state.subVariant = subVariantOption }

            return state;
        });

        return setVariantState({ ...variantState, options: [...updatedOptions] });
    }, [variantState]);

    const addNewVariantRow = useCallback(() => {
        try {

            const validationSchema = Yup.object({
                options: Yup.array().of(Yup.object().shape({
                    subVariant: Yup.object().shape({
                        label: Yup.string().required('Sub variant label is required'),
                        value: Yup.string().required('Sub variant value is required'),
                    }).required('Sub variant is required'),
                })),
                unitId: Yup.string().required('Unit is required'),
                mrp: Yup.number().required('MRP Price is required'),
                regularPrice: Yup.number().required('Regular Price is required'),
                distributorPrice: Yup.number().required('Distributor Price is required'),
                image: Yup.mixed().required('Variant image is required').test('fileType', 'Only image files are allowed', (value) => {
                    return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
                }),
            });

            validationSchema.validateSync(variantState, { abortEarly: false });

            productFormik.setFieldValue('variants', [...productFormik.values.variants, { ...lodash.cloneDeep(variantState) }]);

            if (variantOptionsVisibility === true) { setVariantOptionsVisibility(false) }

            return setVariantState({ ...initialVariantState, options: [...variantState.options] });

        } catch (errors) {

            const errorsState = {};

            if (Yup.ValidationError.isError(errors)) {
                lodash.forEach(lodash.get(errors, 'inner', []), (error) => {
                    errorsState[error.path] = { error: true, message: error.message };
                });

                setVariantErrors({ ...initialVariantErrors, ...errorsState });

            } else { setVariantErrors({ ...initialVariantErrors }); }
        }
    }, [productFormik]);

    const removeVariantRow = useCallback((index) => {
        if ((lodash.size(productFormik.values.variants) - 1) === 0) {
            setVariantOptionsVisibility(true);
            setVariantState({ ...initialVariantState, options: [], isPreviousVariantsDelete: true });
        }

        const updatedRows = [...productFormik.values.variants];
        updatedRows.splice(index, 1);

        return productFormik.setFieldValue('variants', [...updatedRows]);
    }, [productFormik]);

    // -------------------------------------------------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert className="pt-2 pb-2" isOpen={notificationState.visible} color={notificationState.color}>
                {notificationState.message}
            </Alert>

            <form className="form-horizontal row" onSubmit={(e) => { e.preventDefault(); return productFormik.handleSubmit(); }}>
                {/* <pre>{JSON.stringify(productFormik.errors,null,2)}</pre> */}

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="productType">Product Type</Label>
                    <Select
                        name="productType"
                        isSearchable
                        placeholder="Select Product Type"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("productType", value)
                        }}
                        value={productTypes.find(
                            ({ value }) => value === productFormik.values.productType
                        )}
                        options={productTypes}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.productType)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.productType) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.productType}</p>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="product-title">Title</Label>
                    <Input
                        name="title"
                        value={productFormik.values.title}
                        onChange={productFormik.handleChange}
                        onBlur={productFormik.handleBlur}
                        placeholder="Type Product Title"
                        className="form-control"
                        id="product-title"
                        invalid={productFormik.errors.title}
                    ></Input>
                    <FormFeedback>{productFormik.errors.title}</FormFeedback>
                </div></div></div>

                <div className="col-lg-12 col-md-12"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="description">Description</Label>
                    <Input
                        type="textarea"
                        name="description"
                        placeholder="Type Product Description"
                        className="form-control"
                        id="description"
                        onChange={productFormik.handleChange}
                        onBlur={productFormik.handleBlur}
                        invalid={productFormik.errors.description}
                        value={productFormik.values.description}
                    />
                    <FormFeedback>{productFormik.errors.description}</FormFeedback>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="categoryId">Product Category</Label>
                    <Select
                        name="categoryId"
                        isSearchable
                        isLoading={false}
                        placeholder="Select Product Category"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("categoryId", value)
                        }}
                        value={categoryOptions.find(
                            ({ value }) => value === productFormik.values.categoryId
                        )}
                        options={categoryOptions}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.categoryId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.categoryId) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.categoryId}</p>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="subCategoryId">Product Sub Category</Label>
                    <Select
                        name="subCategoryId"
                        isSearchable
                        isLoading={false}
                        placeholder="Select Product Sub Category"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("subCategoryId", value)
                        }}
                        value={subCategoryOptions.find(
                            ({ value }) => value === productFormik.values.subCategoryId
                        )}
                        options={subCategoryOptions}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.subCategoryId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.subCategoryId) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.subCategoryId}</p>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="typeId">Product Type</Label>
                    <Select
                        name="typeId"
                        isSearchable
                        isLoading={false}
                        placeholder="Select Product Type"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("typeId", value)
                        }}
                        value={typeOptions.find(
                            ({ value }) => value === productFormik.values.typeId
                        )}
                        options={typeOptions}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.typeId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.typeId) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.typeId}</p>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="productSubCategoryId">Product Brand</Label>
                    <Select
                        name="brandId"
                        isSearchable
                        placeholder="Select Product Brand"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("brandId", value)
                        }}
                        value={brandOptions.find(
                            ({ value }) => value === productFormik.values.brandId
                        )}
                        options={brandOptions}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.brandId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.brandId) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.brandId}</p>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="measurementId">Measurement</Label>
                    <Select
                        options={measurementOptions}
                        className=""
                        name="measurementId"
                        isSearchable
                        placeholder="Select Product Measurement"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("measurementId", value);
                        }}
                        value={measurementOptions.find(
                            ({ value }) => value === productFormik.values.measurementId
                        )}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.measurementId && productFormik.touched.measurementId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.measurementId && productFormik.touched.measurementId) }}
                    ></Select>
                    <MountComponent condition={productFormik.errors.measurementId && productFormik.touched.measurementId}>
                        <small className="select-error"></small>
                    </MountComponent>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="unitId">Unit</Label>
                    <Select
                        options={unitOptions}
                        className=""
                        name="unitId"
                        isSearchable
                        placeholder="Select Product Unit"
                        onChange={({ value }) => {
                            productFormik.setFieldValue("unitId", value);
                        }}
                        value={unitOptions.find(
                            ({ value }) => value === productFormik.values.unitId
                        )}
                        onBlur={productFormik.handleBlur}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.unitId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.unitId) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.unitId}</p>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="tags">Tags</Label>
                    <Input
                        type="text"
                        name="tags"
                        value={productFormik.values.tags}
                        onChange={productFormik.handleChange}
                        onBlur={productFormik.handleBlur}
                        placeholder="Tags"
                        className="form-control"
                        id="tags"
                        invalid={productFormik.errors.tags}
                    />
                    <FormFeedback>{productFormik.errors.tags}</FormFeedback>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="currencyId">Currency</Label>
                    <Select
                        options={currencyOptions}
                        className=""
                        name="currencyId"
                        isSearchable
                        placeholder="Select Product Unit"
                        onBlur={productFormik.handleBlur}
                        onChange={({ value }) => {
                            productFormik.setFieldValue("currencyId", value);
                        }}
                        value={currencyOptions.find(
                            ({ value }) => value === productFormik.values.currencyId
                        )}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.currencyId && productFormik.touched.currencyId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.currencyId && productFormik.touched.currencyId) }}
                    ></Select>
                    <MountComponent condition={productFormik.errors.currencyId && productFormik.touched.currencyId}>
                        <small className="select-error"></small>
                    </MountComponent>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="mrp">MRP Price</Label>
                    <Input
                        type="text"
                        name="mrp"
                        className="form-control"
                        id="mrp"
                        placeholder="Type Product MRP Price"
                        onChange={productFormik.handleChange}
                        onBlur={productFormik.handleBlur}
                        invalid={productFormik.errors.mrp}
                        value={productFormik.values.mrp}
                    />
                    <FormFeedback>{productFormik.errors.mrp}</FormFeedback>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="regularPrice">Regular Price</Label>
                    <Input
                        type="text"
                        name="regularPrice"
                        className="form-control"
                        id="regularPrice"
                        placeholder="Type Product Regular Price"
                        onChange={productFormik.handleChange}
                        onBlur={productFormik.handleBlur}
                        invalid={productFormik.errors.regularPrice}
                        value={productFormik.values.regularPrice}
                    />
                    <FormFeedback>{productFormik.errors.mrp}</FormFeedback>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="distributorPrice">Distributor Price</Label>
                    <Input
                        type="text"
                        name="distributorPrice"
                        className="form-control"
                        id="distributorPrice"
                        placeholder="Type Product Distributor Price"
                        onChange={productFormik.handleChange}
                        onBlur={productFormik.handleBlur}
                        invalid={productFormik.errors.distributorPrice}
                        value={productFormik.values.distributorPrice}
                    />
                    <FormFeedback>{productFormik.errors.distributorPrice}</FormFeedback>
                </div></div></div>

                <div className="col-lg-6 col-md-6"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label" htmlFor="tax">Tax</Label>
                    <Select
                        options={taxOptions}
                        className=""
                        name="taxId"
                        isSearchable
                        placeholder="Select Tax"
                        onChange={({ value }) => {
                            productFormik.setFieldValue("taxId", value);
                        }}
                        value={taxOptions.find(
                            (option) => option.value === productFormik.values.taxId
                        )}
                        onBlur={productFormik.handleBlur}
                        theme={(theme) => themeReactSelect(theme, productFormik.errors.taxId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, productFormik.errors.taxId) }}
                    ></Select>
                    <p style={{ color: '#f46a6a', fontSize: '10px' }}>{productFormik.errors.taxId}</p>
                </div></div></div>

                <div className="col-lg-12 col-md-12"><div className="row">
                    <div className={productFormik.values.imagePreview ? `col-lg-6 col-md-6 bg-transparent` : `col-lg-12 col-md-12 bg-transparent`}><div className="mt-4"><div className="mb-0">
                        <Label className="form-label" htmlFor="variant-image">Product Image</Label>
                        <Dropzone accept="image/*" onDrop={(images) => { handleImageChange(images, 'image', 'imagePreview'); }}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone"><div className="dz-message" {...getRootProps()}>
                                    <input {...getInputProps()} accept="image/jpeg, image/png" />
                                    <div className="">
                                        <i className="display-4 text-muted uil uil-cloud-upload" />
                                    </div>
                                    <div className="d-content">
                                        <h4>Drop file here or click to upload</h4>
                                        <span>JPEG,PNG(Max: 2MB)</span>
                                        <h4 className="mt-3 mb-3" style={{ color: '#f46a6a' }}>{productFormik.errors.image}</h4>
                                    </div>
                                </div></div>
                            )}
                        </Dropzone>
                    </div></div></div>

                    <div className="col-lg-6 col-md-6 bg-transparent"><div className="mt-4"><div className="mb-0">
                        {productFormik.values.imagePreview && (
                            <img
                                src={productFormik.values.imagePreview}
                                alt={`Product Image Preview`}
                                style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                            />
                        )}
                    </div></div></div>
                </div></div>

                <div className="card mt-3 shadow-lg"><div className="card-body"><div className="row">
                    <MountCondition condition={variantOptionsVisibility}>
                        <MountCondition.True>
                            <h5 className="mt-2" style={{ color: '#495057', fontSize: 'large', fontWeight: '600' }} >Variants</h5>
                            <div className="col-md-6"><div className="mt-4"><div className="mb-0">
                                <Label className="form-label" htmlFor="tax">Select attributes to create variants</Label>
                                <Select
                                    options={variantOptions}
                                    className=""
                                    name="tax"
                                    isSearchable
                                    isMulti
                                    placeholder="Select Variant"
                                    onChange={handleProductVariants}
                                    theme={(theme) => themeReactSelect(theme, false)}
                                ></Select>
                            </div></div></div>
                            <hr className="mt-4" style={{ borderColor: '#CDCDCD' }} />
                        </MountCondition.True>
                    </MountCondition>

                    <MountCondition condition={!lodash.isEmpty(variantState.options) && variantState.isPreviousVariantsDelete}>
                        <MountCondition.True>
                            <h5 className="mt-2" style={{ color: '#495057', fontSize: 'large', fontWeight: '600' }}>Create Variant</h5>

                            {variantState.options.map(({ label, value }, optionIndex) => (
                                <div className="col-lg-4 col-md-4"><div className="mt-4"><div className="mb-0">
                                    <Label className="form-label" htmlFor={`${label}-${value}`}> {label}</Label>
                                    <Select
                                        options={subVariantOptions.filter((option) => {
                                            return option.variantsId === value
                                        }).map((option = {}) => {
                                            return { value: option.id, label: option.name, }
                                        })}
                                        isSearchable
                                        placeholder="Select Product Unit"
                                        onChange={(option) => { handleVariantStateOption(option, value) }}
                                    ></Select>
                                </div></div></div>
                            ))}

                            <div className="col-lg-4 col-md-4"><div className="mt-4"><div className="mb-0">
                                <Label className="form-label" htmlFor="unitId">Unit</Label>
                                <Select
                                    options={unitOptions}
                                    className=""
                                    name={`unitId`}
                                    isSearchable
                                    placeholder="Select Product Unit"
                                    onChange={({ value }) => {
                                        setVariantErrors({ ...variantErrors, unitId: { ...initialVariantErrors.unitId } });
                                        setVariantState({ ...variantState, unitId: value });
                                    }}
                                    value={unitOptions.find(({ value }) => value === variantState.unitId) ?? null}
                                    theme={(theme) => themeReactSelect(theme, variantErrors.unitId.error)}
                                    styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, variantErrors.unitId.error) }}
                                ></Select>
                                <p style={{ color: '#f46a6a', fontSize: '10px' }}>{variantErrors.unitId.message}</p>
                            </div></div></div>

                            <div className="col-lg-4 col-md-4"><div className="mt-4"><div className="mb-0">
                                <Label className="form-label" htmlFor="mrp">MRP Price</Label>
                                <Input
                                    type="text"
                                    name={`mrp`}
                                    className="form-control"
                                    id="mrp"
                                    placeholder="Type Product MRP Price"
                                    onChange={({ target }) => {
                                        setVariantErrors({ ...variantErrors, mrp: { ...initialVariantErrors.mrp } });
                                        setVariantState({ ...variantState, [target.name]: target.value });
                                    }}
                                    invalid={variantErrors.mrp.error}
                                    value={variantState.mrp}
                                />
                                <FormFeedback>{variantErrors.mrp.message}</FormFeedback>
                            </div></div></div>

                            <div className="col-lg-4 col-md-4"><div className="mt-4"><div className="mb-0">
                                <Label className="form-label" htmlFor="regularPrice">Regular Price</Label>
                                <Input
                                    type="text"
                                    name={`regularPrice`}
                                    className="form-control"
                                    id="regularPrice"
                                    placeholder="Type Product Regular Price"
                                    onChange={({ target }) => {
                                        setVariantErrors({ ...variantErrors, regularPrice: { ...initialVariantErrors.regularPrice } });
                                        setVariantState({ ...variantState, [target.name]: target.value });
                                    }}
                                    invalid={variantErrors.regularPrice.error}
                                    value={variantState.regularPrice}
                                />
                                <FormFeedback>{variantErrors.regularPrice.message}</FormFeedback>
                            </div></div></div>

                            <div className="col-lg-4 col-md-4"><div className="mt-4"><div className="mb-0">
                                <Label className="form-label" htmlFor="distributorPrice">Distributor Price</Label>
                                <Input
                                    type="text"
                                    name={`distributorPrice`}
                                    className="form-control"
                                    id="distributorPrice"
                                    placeholder="Type Product Distributor Price"
                                    onChange={({ target }) => {
                                        setVariantErrors({ ...variantErrors, distributorPrice: { ...initialVariantErrors.distributorPrice } });
                                        setVariantState({ ...variantState, [target.name]: target.value });
                                    }}
                                    invalid={variantErrors.distributorPrice.error}
                                    value={variantState.distributorPrice}
                                />
                                <FormFeedback>{variantErrors.distributorPrice.message}</FormFeedback>
                            </div></div></div>

                            <div className="col-lg-12 col-md-12"><div className="row">
                                <div className={variantState.imagePreview ? `col-lg-6 col-md-6 bg-transparent` : `col-lg-12 col-md-12 bg-transparent`}><div className="mt-4"><div className="mb-0">
                                    <Label className="form-label" htmlFor="variant-image">Variant Image</Label>
                                    <Dropzone accept="image/*" onDrop={(images) => { handleImageChange(images, `image`, `imagePreview`, true); }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone"><div className="dz-message" {...getRootProps()}>
                                                <input {...getInputProps()} accept="image/jpeg, image/png" />
                                                <div className="">
                                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                                </div>
                                                <div className="d-content">
                                                    <h4>Drop file here or click to upload</h4>
                                                    <span>JPEG,PNG(Max: 2MB)</span>
                                                    <h4 className="mt-3 mb-3" style={{ color: '#f46a6a' }}>{variantErrors.image.message}</h4>
                                                </div>
                                            </div></div>
                                        )}
                                    </Dropzone>
                                </div></div></div>

                                <div className="col-lg-6 col-md-6 bg-transparent"><div className="mt-4"><div className="mb-0">
                                    {variantState.imagePreview && (
                                        <img src={variantState.imagePreview} style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }} />
                                    )}
                                </div></div></div>
                            </div></div>

                            <div className="col-lg-12 col-md-12"><div className="d-flex flex-row-reverse">
                                <button className="btn btn-primary btn-sm mt-1" type="button" onClick={addNewVariantRow}>Create Variant</button>
                            </div></div>
                        </MountCondition.True>
                    </MountCondition>

                    <MountCondition condition={lodash.size(productFormik.values.variants) !== 0}>
                        <MountCondition.True>
                            <hr className="mt-4" style={{ borderColor: '#CDCDCD' }} />
                            <h5 className="mt-2" style={{ color: '#495057', fontSize: 'large', fontWeight: '600' }}>Variants List</h5>
                            <Table bordered responsive className="mt-3 table-responsive">
                                <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        {variantState.options.map((variant, index) => (<th key={variant?.value}>{variant?.label}</th>))}
                                        <th>Unit</th>
                                        <th>MRP</th>
                                        <th>Regular Price</th>
                                        <th>Distributor Price</th>
                                        <th>Product Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productFormik.values.variants.map((variant, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                {variant.options.map((variant) => (<td>{variant?.subVariant?.label}</td>))}
                                                <td>{variant.unitId}</td>
                                                <td>{variant.mrp}</td>
                                                <td>{variant.regularPrice}</td>
                                                <td>{variant.distributorPrice}</td>
                                                <td>{variant.imagePreview && (<img src={variant.imagePreview} style={{ width: '50px', height: '50px' }} className="img-fluid" />)}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" type="button" onClick={() => { removeVariantRow(index); }}>
                                                        <i className="fa fa-trash" ></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </MountCondition.True>
                    </MountCondition>
                </div></div></div>

                <MountCondition condition={!lodash.isEmpty(productFormik.values.variants)}>
                    <MountCondition.True>
                        <div className="col-12 mt-3 d-flex flex-row-reverse p-0">
                            <div className="saveBtn">
                                <button type="submit" className="btn btn-group btn-sm ps-3 pe-3" onClick={() => productFormik.setFieldValue('status', "Draft")}>
                                    Save Draft
                                </button>
                            </div>
                            <div className="saveBtn">
                                <button type="submit" className="btn btn-group btn-sm ps-3 pe-3" onClick={() => productFormik.setFieldValue('status', "Publish")}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </MountCondition.True>
                </MountCondition>
            </form>
        </React.Fragment>
    )
}