import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";

import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";
import { useFormik } from "formik";


import Select from "react-select";
import { Modal, FormGroup, Label, Input, Form, Alert, Button, FormFeedback } from "reactstrap";

import { styleReactSelect, themeReactSelect } from '../../../../module/select-methods';
import { errorMessage } from "../../../../module/error-methods";

import MountComponent from "../../../../module/mount-components";
import DeleteModal from '../../../../module/delete-modal.component';

import { storeUnitAPI, getUnitsAPI, updateUnitAPI, deleteUnitAPI } from "../../../../api/unit.api";

export default React.memo(({ userPermissions, tab }) => {

  const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [unitsList, setUnitsList] = useState([]);

    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const optionStatus = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" }
    ]

    const getUnits = async () => {
        setLoading(true);
        await getUnitsAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setUnitsList([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @useFormiks ------------------------------------------------------------------------------------------------------------------------- */

      const unitInitialValues = {
        name: '',
        status: 'Active',
      };

      const unitValidation = {
          name: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Only alphabets and spaces are allowed')
            .min(3, 'Name must be at least 3 characters')
            .trim().required('Name is required'),
      };

      const updateUnitFormik = useFormik({
          enableReinitialize: true,
          initialValues: { ...unitInitialValues },
          validationSchema: Yup.object({ ...unitValidation }),
          onSubmit: async (values, { resetForm }) => {
            try {

              setLoading(true); const { name, status, id } = values;

              await updateUnitAPI(id, { name, status });
              await getUnits();

              toggleNotificationState({ visible: true, message: 'Success! Unit updated successfully.' });
              resetForm();
            } catch (error) {
                  toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); setModuleState({}); }
          },
      });

      const unitFormik = useFormik({
          enableReinitialize: true,
          initialValues: { ...unitInitialValues },
          validationSchema: Yup.object({ ...unitValidation }),
          onSubmit: async (values, { resetForm }) => {
              try {
                  setLoading(true); 

                  await storeUnitAPI({ name: values.name, status: "Active" });
                  await getUnits();

                  toggleNotificationState({ visible: true, message: 'Success! Unit added successfully.' });
                  resetForm();
              } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
              } finally { setLoading(false); }
          },
      });

    /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

        const handleConfirmDeleteCallback = useCallback(async () => {
            try {
                await deleteUnitAPI(moduleState.id);
                await getUnits();

                toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" deleted successfully !` });
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
        });

        const handleDeleteModalCallback = useCallback((state = {}) => {
            setModuleState({ ...state })
            toggleDeleteModal(true);
        });

        const handleUpdateModalCallback = useCallback((state = {}) => {
            setModuleState({ ...state });
            toggleUpdateModal(true);
            return updateUnitFormik.setValues({ ...state });
        });

        const handleCancelUpdateModalCallback = useCallback(() => {
            setModuleState({});
            toggleUpdateModal(false);
            return updateUnitFormik.setValues({});
        });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */
          
        const dataTableHandler = () => {  
            const columns = [
              {
                label: "Sr.No.",
                field: "sr",
                sort: "asc",
                width: 150,
              },
              {
                label: "Unit Name",
                field: "unitName",
                sort: "asc",
                width: 270,
              },
              {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 270,
              },
            ];
            const rows = [
              ...lodash.map(unitsList,(details, index) => ({
                sr: ++index,
                unitName: details.name,
                status: details.status,
                action: (
                  <div className="functionalBtn editDataShow">
                        <MountComponent condition={userPermissions.PRODUCT_MASTERS_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.PRODUCT_MASTERS_DELETE}>
                            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                    </div>
                ),
              })),
            ];

            if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
              columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
            }

            return updateDataTable({ columns: [...columns], rows: [...rows] });
        }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

      useEffect(() => { if (tab === "UNITS_TAB") { getUnits(); } }, [tab]);
      useEffect(() => {
          if (tab === "UNITS_TAB") { unitFormik.resetForm(); updateUnitFormik.resetForm(); }
      }, [tab]);
  
      useEffect(() => {
          if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
      }, [notificationState.visible]);
  
      useEffect(() => { dataTableHandler(); }, [unitsList]);
  
      /** ----------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <div className="">
        <MountComponent condition={loading}>
            <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
            </div></div></div>
        </MountComponent>

        <Alert color={notificationState.color} isOpen={notificationState.visible}>
            {notificationState.message}
        </Alert>

        <MountComponent condition={userPermissions.PRODUCT_MASTERS_CREATE}>
            <form className="row mainText" onSubmit={unitFormik.handleSubmit}>
              <div className="col-12 bg-transparent pe-0 mb-2" style={{ height: '40px' }}><div className="mainFormSubmit">
                  <div className="error-input">
                      <Input placeholder="Please enter Unit" className="h-100" name="name"
                          invalid={!unitFormik.isValid}
                          value={unitFormik.values.name}
                          onChange={unitFormik.handleChange}
                      ></Input>
                      <FormFeedback valid={unitFormik.isValid}>{unitFormik.errors.name}</FormFeedback>
                  </div>
                  <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
              </div></div>
            </form>
        </MountComponent>

        <div className="dataTable-hide-extra-props mt-3">
            <MDBDataTable responsive bordered data={dataTable} footer={false} />
        </div>
      
        <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
              <DeleteModal
                  toggleDeleteModal={toggleDeleteModal}
                  handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                  message={`Are you sure you want to delete ${moduleState.name} ?`}
              ></DeleteModal>
        </Modal>{/* Modal for delete unit */}
          
        <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
            <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Update Unit</h5>
                  <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
            </div>
            <Form className="needs-validation" onSubmit={updateUnitFormik.handleSubmit} >
              <div className="modal-body">
                    <FormGroup className="mb-3">
                      <Label htmlFor="unit-name">Unit name</Label>
                        <Input name="name" placeholder="type name" type="text" className="form-control" id="unit-name"
                            onChange={updateUnitFormik.handleChange}
                            onBlur={updateUnitFormik.handleBlur}
                            value={updateUnitFormik.values.name || ""}
                            invalid={updateUnitFormik.touched.name && updateUnitFormik.errors.name ? true : false}
                        />
                        <MountComponent condition={updateUnitFormik.touched.name && updateUnitFormik.errors.name}>
                            <FormFeedback type="invalid"> {updateUnitFormik.errors.name} </FormFeedback>
                        </MountComponent>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom01">Status</Label>
                        <Select
                          options={optionStatus}
                          placeholder="Select Status"
                          onChange={({ value }) => { updateUnitFormik.setFieldValue("status", value); }}
                          value={optionStatus.find(({ value }) => value === updateUnitFormik.values.status) ?? null}
                          theme={(theme) => themeReactSelect(theme, updateUnitFormik.touched.status && updateUnitFormik.errors.status)}
                          styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateUnitFormik.touched.status && updateUnitFormik.errors.status) }}
                        />
                  </FormGroup>
              </div>
              <div className="modal-footer">
                    <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>Cancel</button>
                    <button type="submit" className="nextMasterModal btn">Update</button>
              </div>
            </Form>
        </Modal>{/* Modal for update unit */}
    </div>
  );
});
