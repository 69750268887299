import React from "react";

import { LevelContainer, LevelIndex, LevelLink, ProgressCircle } from "../../module/start-guide-components";
import { getColor, useApiData, useGuideHook } from "./start-guide-methods";
import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";
import { MountCondition } from "../../module/mount-components";

export default React.memo(() => {
    const { NAME, CHILDREN: { TEMPLATE_MASTER, AUDIENCE_MASTER, CAMPAIGN_MASTER } } = PERMISSIONS.NETWORK_ENGAGE;
    const structure = {
        [TEMPLATE_MASTER.NAME]: { current: TEMPLATE_MASTER.NAME, previous: null },
        [AUDIENCE_MASTER.NAME]: { current: AUDIENCE_MASTER.NAME, previous: TEMPLATE_MASTER.NAME },
        [CAMPAIGN_MASTER.NAME]: { current: CAMPAIGN_MASTER.NAME, previous: AUDIENCE_MASTER.NAME },
    };

    const { array: state } = useApiData({ module: NAME });
    const { count } = useGuideHook(state, structure);

    return (
        <MountCondition condition={(count > 0 && count < 100 )}>
            <MountCondition.True>
        <div className="guide"><div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="guideContent">
                    <h4>Quick Start Guide</h4>
                </div>
                <LevelContainer source={TEMPLATE_MASTER.NAME}>
                    <LevelIndex color={getColor(state,structure[TEMPLATE_MASTER.NAME])}><span>1</span></LevelIndex>
                    <LevelLink to={ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE}>Template Master</LevelLink>
                </LevelContainer>
                <LevelContainer source={AUDIENCE_MASTER.NAME}>
                    <LevelIndex color={getColor(state,structure[AUDIENCE_MASTER.NAME])}>2</LevelIndex>
                    <LevelLink to={ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE}>Audiences</LevelLink>
                </LevelContainer>
                <LevelContainer source={CAMPAIGN_MASTER.NAME}>
                    <LevelIndex color={getColor(state,structure[CAMPAIGN_MASTER.NAME])}>3</LevelIndex>
                    <LevelLink to={ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN}>Campaign</LevelLink>
                </LevelContainer>
            </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto">
                    <ProgressCircle count={count}></ProgressCircle>
                </div>
        </div></div>
            </MountCondition.True>
        </MountCondition>
    )
});