import React from "react"
import TimelineContainer, { TimelineContent, TimelineHeading } from "../../module/timeline-components"
import { getColor, useApiData } from "../../module/timeline-methods";
import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

export default React.memo(({ heading, activePage }) => {
    const { NAME, CHILDREN: { PROFILE, ORGANIZATION_DETAILS, NETWORK_DETAILS, USER_RIGHTS, ADMIN_USERS } } = PERMISSIONS.QUICK_START_GUIDE;
    const { array: state } = useApiData({ module: NAME });

    const structure = {
        [PROFILE.NAME]: { current: PROFILE.NAME, previous: null, active: activePage?.NAME === PROFILE.NAME },
        [ORGANIZATION_DETAILS.NAME]: { current: ORGANIZATION_DETAILS.NAME, previous: PROFILE.NAME, active: activePage?.NAME === ORGANIZATION_DETAILS.NAME },
        [NETWORK_DETAILS.NAME]: { current: NETWORK_DETAILS.NAME, previous: ORGANIZATION_DETAILS.NAME, active: activePage?.NAME === NETWORK_DETAILS.NAME },
        [USER_RIGHTS.NAME]: { current: USER_RIGHTS.NAME, previous: NETWORK_DETAILS.NAME, active: activePage?.NAME === USER_RIGHTS.NAME },
        [ADMIN_USERS.NAME]: { current: ADMIN_USERS.NAME, previous: USER_RIGHTS.NAME, active: activePage?.NAME === ADMIN_USERS.NAME },
    };

    return (
        <React.Fragment>
            <TimelineHeading>{heading}</TimelineHeading>
            <TimelineContainer>
                <TimelineContent
                    navigate={ROUTES.ORGANIZATION_DETAILS.CHILDREN.PROFILE}
                    color={getColor(state, structure[PROFILE.NAME])}
                    index={1}
                    active={structure[PROFILE.NAME]['active']}
                    heading="profile">
                </TimelineContent>
                <TimelineContent
                    navigate={ROUTES.ORGANIZATION_DETAILS.PATH}
                    color={getColor(state, structure[ORGANIZATION_DETAILS.NAME])}
                    index={2}
                    active={structure[ORGANIZATION_DETAILS.NAME]['active']}
                    heading="organization">
                </TimelineContent>
                <TimelineContent
                    navigate={ROUTES.ORGANIZATION_DETAILS.CHILDREN.NETWORK_DETAILS}
                    color={getColor(state, structure[NETWORK_DETAILS.NAME])}
                    index={3}
                    active={structure[NETWORK_DETAILS.NAME]['active']}
                    heading="masters">
                </TimelineContent>
                <TimelineContent
                    navigate={ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS}
                    color={getColor(state, structure[USER_RIGHTS.NAME])}
                    index={4}
                    active={structure[USER_RIGHTS.NAME]['active']}
                    heading="user rights">
                </TimelineContent>
                <TimelineContent
                    navigate={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS}
                    color={getColor(state, structure[ADMIN_USERS.NAME])}
                    index={5}
                    active={structure[ADMIN_USERS.NAME]['active']}
                    heading="user">
                </TimelineContent>
            </TimelineContainer>
        </React.Fragment>
    )
});
