import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import Editable from "react-bootstrap-editable";
import { MDBDataTable } from "mdbreact";

import updateUserRoleAPI from "../../api/updateUserRoleAPI";
import getUserRoleAPI from "../../api/getUserRoleAPI"
import saveUserRoleAPI from "../../api/saveUserRoleAPI";
import deleteUserRoleAPI from "../../api/deleteUserRoleAPI";
import { storeOrganizationModule } from "../../api/organizationModuleApi";
import getUserRolesAPI, { storeUserRoleAPI } from '../../api/userRoleAPI'

import { Modal,FormGroup,Label,Input,FormFeedback, Form } from "reactstrap";
import { errorMessage } from "../../module/error-methods";

function UserRole({ setIsListEmpty,tabId }) {

  const [userRoleId, setUserRoleId] = useState(null);
  const [userRoleName, setUserRoleName] = useState("");
  const [value, setValue] = useState(userRoleName ? userRoleName : "");
  const [userRole, setUserRole] = useState([]);

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
  const organizationId = authUserOrg?.data?.data?.id;
  const [visible, setVisible] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const [modal_delete, toggleDeleteModal] = useState(false);
  const [modal_edit, toggleUpdateModal] = useState(false);
  const [error, setError] = useState();
  const [roleAPICalls, setRoleAPICalls] = useState(0);

  const userRoleList = async () => {
      try {
            
        const { data : response } = await getUserRolesAPI();

        if (response) { if (response?.data?.length > 0) {
          setUserRole(response.data); setIsListEmpty(false);
        } } 
      } catch (error) { toast.error(errorMessage(error)) }
  };
 
  useEffect(() => {
    if (tabId  === 5) { userRoleList(); }
  }, [tabId]);

  const handleSubmit = async (newValue) => {
    try {
          
          const payload = {
            userId: authUser.userId,
            name: newValue,
            organizationId: organizationId,
          };
      
        const { data } = await storeUserRoleAPI(payload);

        toast.success("User Role created successfully !");

        await storeOrganizationModule({ module: 'DASHBOARD_GUIDE', moduleLevel: 'NETWORK_DETAILS' });
        await storeOrganizationModule({ module: 'ORGANIZATION_DETAILS', moduleLevel: 'MASTERS_MODULE' });

        userRoleList();
        setResetKey((prevKey) => prevKey + 1);
        setValue("");

      } catch (error) { toast.error(errorMessage(error));  }
  };

  const handleEditClick = (roleId, roleName) => {

    setUserRoleId(roleId);
    setUserRoleName(roleName);
    console.log("setUserRoleName", roleName)
    setValue(roleName);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_delete() {
    toggleDeleteModal(!modal_delete);
    removeBodyCss();
  }
  function tog_edit() {
    toggleUpdateModal(!modal_edit);
    removeBodyCss();
  }

  const handleSubmitRemove = async (userRoleId) => {
    console.log("userRoleId del", userRoleId)
    try {
      const { data } = await deleteUserRoleAPI(userRoleId);
      setUserRoleId(null);
      toggleDeleteModal(false);
      toast.success("User Role Deleted Successfully");
      userRoleList();
    } catch (error) {
      console.log(error);
      setUserRoleId(null);
      toggleDeleteModal(false);
      toast.error(error.response.data.error);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      role: userRoleName,
      userId: authUser.userId,
      organizationId: organizationId,
    },

    validationSchema: Yup.object({
      role: Yup.string().required("Please Enter User Role"),
    }),
    onSubmit: async (values) => {
      console.log("values user rol")
      try {
        const { data } = await updateUserRoleAPI(values, {
          id: userRoleId,
        });
        if (data?.data) {
          setIsListEmpty(false);
        }
        setUserRoleId(null);
        setUserRoleName("");
        toggleUpdateModal(false);
        toast.success("User Role Updated Successfully");
        userRoleList();

      } catch (error) {
        if (error.response.data.error == "User Role must contain only alphabets and spaces.") {
          toggleUpdateModal(false);
          toast.error("User Role must contain only alphabets and spaces.")
        }
        if (error.response.status == 409) {
          setUserRoleName(values.name)
          toggleUpdateModal(false);
          toast.error("User Role name already exists")
        }
        // toast.error("")
      }
    },
  });

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Role",
        field: "userRole",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: [
      ...userRole.map((userRole, i) => ({
        sr: ++i,
        userRole: userRole.name,
        action: (
          <div className="editDataShow functionalBtn">
            <button
              className="btn btn-group border"
              onClick={() => {
                tog_edit();
                handleEditClick(userRole.id, userRole.role);
              }}
            >
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-group border mx-2"
              onClick={() => {
                tog_delete();
                handleEditClick(userRole.id, userRole.role);
              }}
              data-toggle="modal"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        ),
      })),
    ],
  };
  return (
    <div className="userRole">
        <div className="row justify-content-center"><div className="col-12">
            <Editable
              key={resetKey}
              alwaysEditing={true}
              disabled={false}
              editText={value}
              isValueClickable={false}
              mode="inline"
              placement="top"
              placeholder="Name of your User Role"
              showText
              className="mainText"
              type="textfield"
              value={value}
              onSubmit={handleSubmit}
              onChange={(newValue) => setValue(newValue)}
            />
            <div className="customeDatatable">
              <MDBDataTable responsive bordered data={data} footer={false} />
            </div>
        </div></div>

        <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Delete</h5>
                <button className="btn-close" type="button" onClick={() => { toggleDeleteModal(false); }} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete {validation.values.name}?</p>
            </div>
            <div className="modal-footer">
                <button className="cancelModal btn" type="button" onClick={() => { toggleDeleteModal(false); }}>Cancel</button>
                <button className="nextMasterModal btn" type="button" onClick={() => handleSubmitRemove(userRoleId)}>Delete</button>
            </div>
        </Modal>

        <Modal isOpen={modal_edit} toggle={() => { tog_edit(); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Edit User Role</h5>
                <button className="btn-close" type="button" onClick={() => { toggleUpdateModal(false); }} aria-label="Close"></button>
            </div>

            <Form className="needs-validation" onSubmit={validation.handleSubmit}>
                <div className="modal-body"><div className="row"><div className="col-12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom01">User Role</Label>
                        <Input
                            name="name"
                            placeholder="User Role"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role || ""}
                            invalid={ validation.touched.role && validation.errors.role ? true : false }
                        />
                        { validation.touched.role && validation.errors.role ? 
                            (<FormFeedback type="invalid">{validation.errors.role}</FormFeedback>) : null
                        }
                      </FormGroup>
                </div></div></div>
                <div className="modal-footer">
                    <button type="button" className="cancelModal btn" onClick={() => { toggleUpdateModal(false); }}>Cancel</button>
                    <button type="submit" className="nextMasterModal btn">Update</button>
                </div>
            </Form>
        </Modal>
    </div>
  );
}

export default React.memo(UserRole);
