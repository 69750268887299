import lodash from "lodash";
import { toast } from "react-toastify";

export const errorMessage = (error) => {
    const defaultErrorMessage = "Something went wrong";
    const apiErrorMessage = lodash.get(error, 'response.data.message');

    return apiErrorMessage || defaultErrorMessage;
    if (process.env.NODE_ENV !== "development") { return defaultErrorMessage; }
};

export const toastErrorMessage = (error) => {
    if (process.env.NODE_ENV !== "development") { return toast.error(error.message) }
    return '';
}
