import config ,{server} from '../config/config'

export const getNetworkSalesmenListAPI = async (params) => {
    
    return await server({
        method  : 'get',
        url     : `network-salesmen`,
        data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getNetworkSalesmenListAPI