import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import store from "store";

import MountComponent from '../../../../module/mount-components';

import MultipleLevels from "./multiple-levels.component.jsx";
import SingleLevels from './single-levels.component.jsx';

import PERMISSIONS from "../../../../routes/permissions";
import { checkPermissions } from "../../../../api/organization-users.api";

export default React.memo(() => {
    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [screen, switchScreen] = React.useState('single-level');
    const [userPermissions, setUserPermissions] = useState({
        GEOGRAPHY_CREATE: userDetails.userRole === "ORGANIZATION" ? true: false,
        GEOGRAPHY_VIEW:   userDetails.userRole === "ORGANIZATION" ? true: false,
        GEOGRAPHY_UPDATE: userDetails.userRole === "ORGANIZATION" ? true: false,
        GEOGRAPHY_DELETE: userDetails.userRole === "ORGANIZATION" ? true: false,
    });

    /** @api ------------------------------------------------------------------------------------------------------------------------- */
    
        const getPermissions = async () => {
            const modulePermissions = PERMISSIONS.NETWORK_MANAGER.CHILDREN.GEOGRAPHY.CHILDREN;
            const searchPermissions = lodash.map(modulePermissions,(data) => data.NAME);

            await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
                const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
                return setUserPermissions({ ...userPermissions, ...response });
            }).catch(() => {});
        }

        useEffect(() => { if(userDetails.userRole !== "ORGANIZATION") { getPermissions(); } },[]);

    /** ------------------------------------------------------------------------------------------------------------------------------ */

    /** @handlers ------------------------------------------------------------------------------------------------------------------- */

        const switchScreenHandler = useCallback((screenId) => { return switchScreen(screenId) });

    /** ----------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountComponent condition={screen === "single-level"}>
                <SingleLevels permissions={userPermissions} switchScreen={switchScreenHandler}></SingleLevels>
                </MountComponent>
            <MountComponent condition={screen === "multiple-level"}>
                <MultipleLevels permissions={userPermissions} switchScreen={switchScreenHandler}></MultipleLevels>
            </MountComponent>
        </React.Fragment>
    )
});