import React, { useState, useEffect } from "react";
import lodash from "lodash";

import { CardBody, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import doubtsButton from "../../assets/images/icon/doubts-button.png"
import Division from "./division";
import Hierarchy from "./hierarchy";
import SaleHierarchy from "./saleHierarchy";
import DistributionHierarchy from "./distributionHierarchy";
import StepperHead from "../../components/Common/StepperHead";
import getGeographyLevelAPI from "../../api/getGeographyLevelAPI";
import getSalesHierarchies from "../../api/getSalesHierarchies";
import getDistributionHierarchyAPI from "../../api/getDistributionHierarchyAPI";
import getDivisionsAPI from "../../api/getDivisionsAPI";
import getUsersDetailsAPI from "../../api/getUsersDetailsAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import updateUserGuideAPI from "../../api/updateUserGuideAPI"
import UserRole from "./user-role";
//Import Breadcrumb
import ROUTES from "../../routes/routes.js";
import { checkPermissions } from "../../api/organization-users.api";
import PERMISSIONS from '../../routes/permissions.js'

const MainNetwork = ({ divisionData }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [count, setCount] = useState(1);
  const [dashboardCount, setDashboardCount] = useState('');
  const progressCount = localStorage.getItem("counter");
  const navigate = useNavigate();
  const networkCount = localStorage.getItem("maincounter");
  const userData = JSON.parse(localStorage.getItem("authUser"));

  const [permissions,setPermissions] = useState({
      NETWORK_DETAILS_CREATE: false,
      NETWORK_DETAILS_UPDATE: false,
      NETWORK_DETAILS_DELETE: false,
  });

  const getPermissions = async () => {
      const createPermission  = PERMISSIONS.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN.NETWORK_DETAILS_CREATE.NAME;
      const updatePermission  = PERMISSIONS.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN.NETWORK_DETAILS_UPDATE.NAME;
      const deletePermission  = PERMISSIONS.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN.NETWORK_DETAILS_DELETE.NAME;

      await checkPermissions({ searchKeywords: [createPermission,updatePermission,deletePermission] }).then(({ data: userPermissions }) => {
        const response = lodash.mapValues(lodash.mapKeys(userPermissions.data, 'name'), 'permission');
        return setPermissions({ ...permissions, ...response });
      }).catch(() => {});
  };

  useEffect(() => { getPermissions() },[]);

  const handleChageTab = (tabParam) => {
    try {
      setActiveTab(tabParam);
      console.log("setActiveTab", activeTab);
    } catch (error) {
      console.error(error);
    }
  };

  const handleListChange = (isListEmpty) => {
    setIsListEmpty(isListEmpty);
  };

  const userDetails = async () => {
    try {
      const { data } = await getUsersDetailsAPI(userData.data.id);
      if (data) {
        setDashboardCount(data.data.dashboardStartGuide)
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  // function toggleTab(tab) {
  //   if (activeTab !== tab) {
  //     if (tab >= 1 && tab <= 4) {
  //       setactiveTab(tab);
  //     }
  //   }
  // }
  // function toggleTabVertical(tab) {
  //   if (activeTabVartical !== tab) {
  //     if (tab >= 1 && tab <= 4) {
  //       setoggleTabVertical(tab);
  //     }
  //   }
  // }

  const handleSubmit = async() => {
    console.log("handleSubmit 2");
    setCount(count + 1);
    console.log("setCount", count)
    // if (count === 4 && (divisionLength == false || geographyLength == false || salesLength == false || distributionLength == false)) {
    //   console.log("divisionLength",divisionLength)
    //   console.log("geographyLength",geographyLength)
    //   console.log("salesLength",salesLength)
    //   console.log("distributionLength",distributionLength)
    //   toast.error("Please enter all masters data");
    // }
    if (count === 4 && progressCount <= 25) {
      const currentCount = parseInt(localStorage.getItem("counter") || "0", 25);
      const newCount = 50;
      localStorage.setItem("counter", newCount.toString());
      setCount(newCount);
    }
    if(count == 1 && dashboardCount < 50){
      console.log("here 4")
      const guideResponse = await updateUserGuideAPI('dashboardStartGuide', userData.data.id);

      if(guideResponse.data.status === true) {
        const newCount = 25;
        localStorage.setItem("dashboardStartGuide", newCount.toString());
      }
    }
  };

  return (
    <React.Fragment>
      <div className="master">
        <div className="">
          <CardBody>
            <div className="vertical-wizard wizard clearfix vertical">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classnames({
                      current: activeTab === 1,
                    })}
                  >
                    <NavLink
                      className={classnames(
                        {
                          active: activeTab === 1,
                        },
                        "mainInfo"
                      )}
                      onClick={() => {
                        handleChageTab(1);
                        // toggleTabVertical(1);
                      }}
                    >
                      <p className="TabsName">
                        Division /SBU{" "}
                        <span className="numb">
                          {/* <i className="fas fa-question"></i> */}
                          <img src={doubtsButton}/>
                          <div className="infoooo">
                            <span>Division SBU</span>
                          </div>
                        </span>
                      </p>{" "}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      current: activeTab === 2,
                    })}
                  >
                    <NavLink
                      className={classnames(
                        {
                          active: activeTab === 2,
                        },
                        "mainInfo"
                      )}
                      onClick={() => {
                        handleChageTab(2);
                        // toggleTabVertical(2);
                      }}
                    >
                      Geography Heirarchy{" "}
                      <span className="numb">
                        {/* <i className="fas fa-question"></i> */}
                        <img src={doubtsButton}/>
                        <div className="infoooo">
                          <span>Geography Heirarchy</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      current: activeTab === 3,
                    })}
                  >
                    <NavLink
                      className={classnames(
                        {
                          active: activeTab === 3,
                          done: true,
                        },
                        "mainInfo"
                      )}
                      onClick={() => {
                        handleChageTab(3);
                        // toggleTabVertical(3);
                      }}
                    >
                      Sales Heirarchy{" "}
                      <span className="numb">
                        {/* <i className="fas fa-question"></i> */}
                        <img src={doubtsButton}/>
                        <div className="infoooo">
                          <span>Sales Heirarchy</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      current: activeTab === 4,
                    })}
                  >
                    <NavLink
                      // className={classnames({
                      //   active: activeTabVartical === 4,
                      //   done: true,
                      // },"mainInfo")}
                      onClick={() => {
                        handleChageTab(4);
                        // toggleTabVertical(4);
                      }}
                    >
                      Distribution Heirarchy{" "}
                      <span className="numb">
                        {/* <i className="fas fa-question"></i> */}
                        <img src={doubtsButton}/>
                        <div className="infoooo">
                          <span>Distribution Heirarchy</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      current: activeTab === 5,
                    })}
                  >
                    <NavLink
                      // className={classnames({
                      //   active: activeTabVartical === 4,
                      //   done: true,
                      // },"mainInfo")}
                      onClick={() => {
                        handleChageTab(5);
                        // toggleTabVertical(4);
                      }}
                    >
                      User Role{" "}
                      <span className="numb">
                        {/* <i className="fas fa-question"></i> */}
                        <img src={doubtsButton}/>
                        <div className="infoooo">
                          <span>User Role</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Division setIsListEmpty={handleListChange} permissions={permissions} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Hierarchy
                      setIsListEmpty={handleListChange}
                      tabId={activeTab}
                    />
                  </TabPane>
                  <TabPane tabId={3}>
                    <SaleHierarchy setIsListEmpty={handleListChange} tabId={activeTab}/>
                  </TabPane>
                  <TabPane tabId={4}>
                    <DistributionHierarchy setIsListEmpty={handleListChange} tabId={activeTab}/>
                  </TabPane>
                  <TabPane tabId={5}>
                    <UserRole setIsListEmpty={handleListChange} tabId={activeTab}/>
                  </TabPane>
                </TabContent>
                {/* </div> */}
              </div>
              {/* {activeTabVartical === 4 && (divisionLength == false || geographyLength == false || salesLength == false || distributionLength == false) ? 
              <div className="actions clearfix text-center my-4">
                <Link
                  // to={activeTabVartical === 4 ? "/user" : ""}
                  onClick={() => {
                    toggleTabVertical(activeTabVartical + 1); handleNext();
                  }}
                  className="nextMaster btn btn-group"
                >
                  Next
                </Link>
              </div> 
              :  */}
              <div className="actions clearfix text-center my-4">
                {console.log("activeTab", activeTab)}
                <Link
                  to={activeTab >= 4 && isListEmpty === false && ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS}
                  onClick={() => {
                    if (isListEmpty === true && activeTab === 4) {
                      toast.error("Please fill all the data");
                    } else if (activeTab >= 1 && activeTab < 4) {
                      setActiveTab(activeTab + 1);
                    } else {
                      if (networkCount < 50) {
                        const newCount = 50;
                        localStorage.setItem(
                          "maincounter",
                          newCount.toString()
                        );
                        setCount(newCount);
                        
                      }
                      console.log("Move to user");
                      handleSubmit();
                      navigate(ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS);
                    }

                    // toggleTabVertical(activeTabVartical + 1); handleSubmit();
                  }}
                  className="nextMaster btn btn-group"
                >
                  Next
                </Link>
              </div>
              {/* Check if any list is empty and display a message */}
              {/* {isList1Empty || isList2Empty || isList3Empty || isList4Empty ? (
                <p>One or more lists are empty!</p>
              ) : null} */}
            </div>
          </CardBody>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(MainNetwork);
