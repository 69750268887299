import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import NetImage from "../../assets/images/net.png";
import NetImage1 from "../../assets/images/netimage1.png";
import NetImage2 from "../../assets/images/netimage2.png";
import { Link } from "react-router-dom";
import getUsersDetailsAPI from "../../api/getUsersDetailsAPI"

function NetworkTab() {
  const [activeTab1, setactiveTab1] = useState("5");
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const [dashboardCount, setDashboardCount] = useState('');

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  }

  const userDetails = async () => {
    try {
      const { data } = await getUsersDetailsAPI(userData.data.id);
      if (data) {
        setDashboardCount(data.data.dashboardStartGuide)
        console.log("Dashboard count", dashboardCount)
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  let destination = "/organization";

  switch (dashboardCount) {
    case 0:
      destination = "/organization";
      break;
    case 25:
      destination = "/masters";
      break;
    case 50:
      destination = "/user";
      break;
    case 75:
      destination = "/user";
      break;
    default:
      destination = "/organization"; // Default to organization if num is not 1, 2, or 3
  }

  return (
    <div className="netTab">
      <Col>
        <Card>
          <CardBody className="cardbody">
            <Nav pills>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab1 === "5",
                  })}
                  onClick={() => {
                    toggle1("5");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Network Manager</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab1 === "6",
                  })}
                  onClick={() => {
                    toggle1("6");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">Network Engage</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab1 === "7",
                  })}
                  onClick={() => {
                    toggle1("7");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block">Catalog Manager</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab1 === "8",
                  })}
                  onClick={() => {
                    toggle1("8");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-star"></i>
                  </span>
                  <span className="d-none d-sm-block">Loyalty Manager</span>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab1} className="p-3 text-muted">
              <TabPane tabId="5">
                <Row>
                  <Col xl={4}>
                    <div className="netImage">
                      <img src={NetImage} />
                    </div>
                  </Col>
                  <Col xl={8}>
                    <div className="netContent">
                      <h4>Get Visibility of your Channel Network</h4>
                      <ul className="netFirst">
                        <li>
                          Easily Integrate your Sales Teams & Channel Network
                        </li>
                        <li>Get visibility of your Secondary network</li>
                        <li>Locate easily on Google Maps</li>
                        <li>Region wise Channel Insights</li>
                      </ul>
                      <ul className="netSecond">
                        <li>Market Penetration</li>
                        <li>Distribution Reach</li>
                        <li>Unrepresented Markets</li>
                        <li>Retail Coverage</li>
                        <li>Product Penetration and many more..</li>
                      </ul>
                    </div>
                  </Col>
                  <Link
                    to={destination}
                    className="btn btn-group networkBtn"
                  >
                    {dashboardCount >=25 ? "Resume" : "Setup Your Network"}
                  </Link>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col xl={4}>
                    <div className="netImage">
                      <img src={NetImage1} />
                    </div>
                  </Col>
                  <Col xl={8}>
                    <div className="netContent">
                      <h4>Easily talk to any or all of your Network</h4>
                      <ul className="netFirst before">
                        <li>
                          Single Panel for all communication needs - WhatsApp,
                          <br />
                          Email, SMS
                        </li>
                        <li>
                          Send messages to entire network at same time, anytime
                        </li>
                        <li>No limits on number of messages</li>
                        <li>
                          Run campaigns, schedule campaigns across multi
                          <br />
                          communication channels in one click
                        </li>
                        <li>Ready to Use Templates</li>
                        <li>Instant Information update to entire network</li>
                        <li>Easy & Efficient way to connect</li>
                      </ul>
                    </div>
                  </Col>
                  <Link
                    to={destination}
                    className="btn btn-group networkBtn"
                  >
                    {dashboardCount >=25 ? "Resume" : "Setup Your Network"}
                  </Link>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row>
                  <Col xl={4}>
                    <div className="netImage expadd">
                      <img src={NetImage2} />
                    </div>
                  </Col>
                  <Col xl={8}>
                    <div className="netContent">
                      <h4>Get Visibility of your Channel Network</h4>
                      <ul className="netFirst after">
                        <li>Channel Loyalty App</li>
                        <li>Create custom incentives and loyalty schemes</li>
                        <li>QR code based quick scan</li>
                        <li>Easy to aggregate Loyalty Points</li>
                        <li>Customised Rewards Catalog</li>
                        <li>Easy redemption through points</li>
                        <li>
                          Incentivise, Retain & Grow your Channel Partners
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Link
                    to={destination}
                    className="btn btn-group networkBtn"
                  >
                    {dashboardCount >=25 ? "Resume" : "Setup Your Network"}
                  </Link>
                </Row>
              </TabPane>
              <TabPane tabId="8">
                <Row>
                  <Col xl={4}>
                    <div className="netImage expadd">
                      <img src={NetImage2} />
                    </div>
                  </Col>
                  <Col xl={8}>
                    <div className="netContent">
                      <h4>Get Visibility of your Channel Network</h4>
                      <ul className="netFirst after">
                        <li>Channel Loyalty App</li>
                        <li>Create custom incentives and loyalty schemes</li>
                        <li>QR code based quick scan</li>
                        <li>Easy to aggregate Loyalty Points</li>
                        <li>Customised Rewards Catalog</li>
                        <li>Easy redemption through points</li>
                        <li>
                          Incentivise, Retain & Grow your Channel Partners
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Link
                    to={destination}
                    className="btn btn-group networkBtn"
                  >
                    {dashboardCount >=25 ? "Resume" : "Setup Your Network"}
                  </Link>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default NetworkTab;
