export default {
    'QUICK_START_GUIDE' : {
        'LABEL'   : 'Quick Start Guide',
        'NAME'    : 'QUICK_START_GUIDE',
        'CHECKED' : false,

        'CHILDREN': {
            'PROFILE': {
                'LABEL'    : 'Profile',
                'NAME'     : 'PROFILE',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'PROFILE_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'PROFILE_VIEW'   },
                    'PROFILE_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'PROFILE_UPDATE' },
                }
            },

            'ORGANIZATION_DETAILS': {
                'LABEL'    : 'Organization Details',
                'NAME'     : 'ORGANIZATION_DETAILS',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'ORGANIZATION_DETAILS_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'ORGANIZATION_DETAILS_CREATE'    },
                    'ORGANIZATION_DETAILS_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'ORGANIZATION_DETAILS_VIEW'   },
                    'ORGANIZATION_DETAILS_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'ORGANIZATION_DETAILS_UPDATE' },
                    'ORGANIZATION_DETAILS_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'ORGANIZATION_DETAILS_DELETE' },
                }
            },

            'NETWORK_DETAILS': {
                'LABEL'   : 'Network Details',
                'NAME'    : 'NETWORK_DETAILS',
                'CHECKED' : false,
                'CHILDREN' : {
                    'NETWORK_DETAILS_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'NETWORK_DETAILS_CREATE'    },
                    'NETWORK_DETAILS_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'NETWORK_DETAILS_VIEW'   },
                    'NETWORK_DETAILS_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'NETWORK_DETAILS_UPDATE' },
                    'NETWORK_DETAILS_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'NETWORK_DETAILS_DELETE' },
                },
            },

            'USER_RIGHTS': {
                'LABEL'    : 'User Rights',
                'NAME'     : 'USER_RIGHTS',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'USER_RIGHTS_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'USER_RIGHTS_CREATE'    },
                    'USER_RIGHTS_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'USER_RIGHTS_VIEW'   },
                    'USER_RIGHTS_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'USER_RIGHTS_UPDATE' },
                },
            },

            'ADMIN_USERS': {
                'LABEL'    : 'Admin Users',
                'NAME'     : 'ADMIN_USERS',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'ADMIN_USERS_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'ADMIN_USERS_CREATE'    },
                    'ADMIN_USERS_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'ADMIN_USERS_VIEW'   },
                    'ADMIN_USERS_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'ADMIN_USERS_UPDATE' },
                    'ADMIN_USERS_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'ADMIN_USERS_DELETE' },
                },
            },
        }
    },

    'NETWORK_MANAGER' :{
        'LABEL'   : 'Network Manager',
        'NAME'    : 'NETWORK_MANAGER',
        'CHECKED' : false,

        'CHILDREN' :{
            'GEOGRAPHY': { 
                'LABEL'    : 'Geography',
                'NAME'     : 'GEOGRAPHY',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'GEOGRAPHY_CREATE': { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'GEOGRAPHY_CREATE' },
                    'GEOGRAPHY_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'GEOGRAPHY_VIEW'   },
                    'GEOGRAPHY_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'GEOGRAPHY_UPDATE' },
                    'GEOGRAPHY_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'GEOGRAPHY_DELETE' },
                    'GEOGRAPHY_MAP':    { 'LABEL' : 'Map',    'CHECKED' : false, 'NAME' : 'GEOGRAPHY_MAP'    },
                }
            },

            'LOCATION' : { 
                'LABEL'    : 'Location',
                'NAME'     : 'LOCATION',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'LOCATION_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'LOCATION_CREATE'    },
                    'LOCATION_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'LOCATION_VIEW'   },
                    'LOCATION_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'LOCATION_UPDATE' },
                    'LOCATION_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'LOCATION_DELETE' },
                }
            },

            'SALESMEN' : { 
                'LABEL'    : 'Salesmen',
                'NAME'     : 'SALESMEN',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'SALESMEN_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'SALESMEN_CREATE'    },
                    'SALESMEN_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'SALESMEN_VIEW'   },
                    'SALESMEN_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'SALESMEN_UPDATE' },
                    'SALESMEN_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'SALESMEN_DELETE' },
                }
            },

            'CHANNEL_PARTNER' : { 
                'LABEL'    : 'Channel Partner',
                'NAME'     : 'CHANNEL_PARTNER',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'CHANNEL_PARTNER_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'CHANNEL_PARTNER_CREATE'    },
                    'CHANNEL_PARTNER_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'CHANNEL_PARTNER_VIEW'   },
                    'CHANNEL_PARTNER_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'CHANNEL_PARTNER_UPDATE' },
                    'CHANNEL_PARTNER_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'CHANNEL_PARTNER_DELETE' },
                }
            },

            'BUSINESS_DATA'   : { 
                'LABEL'    : 'Business Data',
                'NAME'     : 'BUSINESS_DATA',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'BUSINESS_DATA_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'BUSINESS_DATA_CREATE'    },
                    'BUSINESS_DATA_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'BUSINESS_DATA_VIEW'   },
                    'BUSINESS_DATA_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'BUSINESS_DATA_UPDATE' },
                    'BUSINESS_DATA_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'BUSINESS_DATA_DELETE' },
                }
            },
        }
    },

    'NETWORK_ENGAGE' : {
        'LABEL'   : 'Network Engage',
        'NAME'    : 'NETWORK_ENGAGE',
        'CHECKED' : false,
        'CHILDREN' :{
            'TEMPLATE_MASTER': { 
                'LABEL'    : 'Templates',
                'NAME'     : 'TEMPLATE_MASTER',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'TEMPLATE_MASTER_CREATE': { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'TEMPLATE_MASTER_CREATE'    },
                    'TEMPLATE_MASTER_CLONE': { 'LABEL' : 'Update',    'CHECKED' : false, 'NAME' : 'TEMPLATE_MASTER_CLONE'    },
                    'TEMPLATE_MASTER_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'TEMPLATE_MASTER_VIEW'   },
                    'TEMPLATE_MASTER_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'TEMPLATE_MASTER_UPDATE' },
                    'TEMPLATE_MASTER_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'TEMPLATE_MASTER_DELETE' },
                }
            },

            'AUDIENCE_MASTER': { 
                'LABEL'    : 'Audience',
                'NAME'     : 'AUDIENCE_MASTER',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'AUDIENCE_MASTER_CREATE': { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'AUDIENCE_MASTER_CREATE'    },
                    'AUDIENCE_MASTER_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'AUDIENCE_MASTER_VIEW'   },
                    'AUDIENCE_MASTER_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'AUDIENCE_MASTER_UPDATE' },
                    'AUDIENCE_MASTER_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'AUDIENCE_MASTER_DELETE' },
                }
            },

            'CAMPAIGN_MASTER': { 
                'LABEL'    : 'Campaign',
                'NAME'     : 'CAMPAIGN_MASTER',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'CAMPAIGN_MASTER_CREATE': { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'CAMPAIGN_MASTER_CREATE'    },
                    'CAMPAIGN_MASTER_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'CAMPAIGN_MASTER_VIEW'   },
                    'CAMPAIGN_MASTER_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'CAMPAIGN_MASTER_UPDATE' },
                    'CAMPAIGN_MASTER_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'CAMPAIGN_MASTER_DELETE' },
                }
            },
        }
    },

    'CATALOG_MANAGER' : {
        'LABEL'   : 'Catalog Manager',
        'NAME'    : 'CATALOG_MANAGER',
        'CHECKED' : false,

        'CHILDREN' :{
            'PRODUCT_MASTERS' : {
                'LABEL'    : 'Product Masters',
                'NAME'     : 'PRODUCT_MASTERS',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'PRODUCT_MASTERS_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'PRODUCT_MASTERS_CREATE'    },
                    'PRODUCT_MASTERS_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'PRODUCT_MASTERS_VIEW'   },
                    'PRODUCT_MASTERS_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'PRODUCT_MASTERS_UPDATE' },
                    'PRODUCT_MASTERS_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'PRODUCT_MASTERS_DELETE' },
                }
            },

            'PRODUCT' : {
                'LABEL'    : 'Product',
                'NAME'     : 'PRODUCT',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'PRODUCT_CREATE':    { 'LABEL' : 'Add',    'CHECKED' : false, 'NAME' : 'PRODUCT_CREATE'    },
                    'PRODUCT_VIEW':   { 'LABEL' : 'View',   'CHECKED' : false, 'NAME' : 'PRODUCT_VIEW'   },
                    'PRODUCT_UPDATE': { 'LABEL' : 'Update', 'CHECKED' : false, 'NAME' : 'PRODUCT_UPDATE' },
                    'PRODUCT_DELETE': { 'LABEL' : 'Delete', 'CHECKED' : false, 'NAME' : 'PRODUCT_DELETE' },
                }
            },
            
            'BROCHURE_MASTER' : {
                'LABEL'    : 'Brochure',
                'NAME'     : 'BROCHURE_MASTER',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'BROCHURE_MASTER_VIEW':     { 'LABEL' : 'View',     'CHECKED' : false, 'NAME' : 'BROCHURE_MASTER_VIEW'   },
                    'BROCHURE_MASTER_SHARE':    { 'LABEL' : 'Share',    'CHECKED' : false, 'NAME' : 'BROCHURE_MASTER_SHARE' },
                    'BROCHURE_MASTER_DOWNLOAD': { 'LABEL' : 'Download', 'CHECKED' : false, 'NAME' : 'BROCHURE_MASTER_DOWNLOAD' },
                }
            },

            'CATALOGS': {
                'LABEL'    : 'CATALOGS',
                'NAME'     : 'CATALOG',
                'CHECKED'  : false,
                'CHILDREN' : {
                    'CATALOGS_VIEW': { 'LABEL' : 'View', 'CHECKED' : false, 'NAME' : 'CATALOGS_VIEW'   },
                }
            },
        }
    },

    'LOYALTY_MANAGER' :{
        'LABEL'   : 'Loyalty Manager',
        'NAME'    : 'LOYALTY_MANAGER',
        'CHECKED' : false,
    },
}