import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Location from "../AddLocation/location";
import RightSideProfile from "../Dashboard/right-side-profile";
import AddProducts from "./addProducts";
import StepperHead from "../../components/Common/StepperHead";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import AddPage from "../User/addPage";
import Product from "./product";
import SourceProduct from "./source";
import { Link, useNavigate } from "react-router-dom";
import ProductView from "./productView";

function AddProduct(responseData) {

  const [selectedGroup, setselectedGroup] = useState(null);

  const userCount = localStorage.getItem("maincounter");
  const navigate=  useNavigate();
  // useEffect(()=> {
  //   if(userCount < 75){
  //     navigate("/dashboard")
  //     toast.error("Please Fill all data")
  //   }
  // },[])
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [hide, setHide] = useState(true);
  const [Showhide, SetShowHide] = useState(true);
  
  // const [responseData, setResponseData] = useState(null)
   const stepper = [
    "product masters",
    "product",
    "media",
    "business data",
  ];

  function handleSelectGroup(selectedOption) {
    setselectedGroup(selectedOption.value);
    console.log("selectedGroup", selectedOption.value);
    // <Product selectedGroup={selectedGroup}/>
    navigate('/product', { state: { selectedGroup: selectedOption.value } })
  }

  const optionGroup = [
    {
      options: [
        { label: "Real Product", value: "Real Product" },
        { label: "Redemption Product", value: "Redemption Product" },
      ],
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="profile">
                <div className="text-center">
                  <h3>Products</h3>
                </div>

                {/* <div className="">
                  <div className="locationBtn d-flex justify-content-end"> */}
                    {/* <Select
                      value={selectedGroup}
                      onChange={handleSelectGroup}
                      options={optionGroup[0].options}
                      // classNamePrefix="select2-selectionsssss"
                      // className="btn btn-primary"
                      placeholder="Add Product"
                      className="text-start mx-2"
                    /> */}
                    {/* {selectedGroup && <AddProducts/>} */}
                    {/* <button
                      className="btn btn-primary changeO"
                      onClick={() => {
                        navigate('/product')
                      }}
                    >
                      Bulk Upload
                    </button> */}
                  {/* </div>
                </div>       */}

                {false ? (
                  <AddPage SetShowHide={SetShowHide}/>
                  // <ProductView/>
                ) :  (
                  <React.Fragment>
                    {/* <Product setHide={setHide} hide={hide} /> */}
                    {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
                    <SourceProduct/>
                  </React.Fragment>
                ) }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddProduct;
