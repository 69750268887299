import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import store from "store";

import TimelineGuide from "../../timeline-guide";
import RightSideProfile from "../../../Dashboard/right-side-profile";
import MountComponent, { MountCondition } from "../../../../module/mount-components";

import { errorMessage } from "../../../../module/error-methods";
import { Alert } from "reactstrap";

import getOrganizationsUsersListAPI, { checkPermissions, deleteOrganizationUser } from "../../../../api/organization-users.api";

import PERMISSIONS from '../../../../routes/permissions.js';
import ROUTES from "../../../../routes/routes";

import Info from "./info";
import Table from "./table";
import { Link } from "react-router-dom";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Users | Giffy Network";

    const showGirl = false;
    const { CHILDREN: { ADMIN_USERS } } = PERMISSIONS.QUICK_START_GUIDE;

    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [loading, setLoading] = useState(true);
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });
    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const [organizationUser, setOrganizationUser] = useState({});
    const [deleteModal, toggleDeleteModal] = useState(false);

    const [apiStatus, setApiStatus] = useState('pending');
    const [organizationUsers, setOrganizationUsers] = useState([]);

    const [userPermissions, setUserPermissions] = useState({
        ADMIN_USERS_CREATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        ADMIN_USERS_VIEW: userDetails.userRole === "ORGANIZATION" ? true : false,
        ADMIN_USERS_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        ADMIN_USERS_DELETE: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    /** @apis ----------------------------------------------------------------------------------------------------------------------- */

    const getOrganizationUsers = async () => {
        try {
            const { data: response } = await getOrganizationsUsersListAPI();
            if (lodash.isArray(response?.data)) { setOrganizationUsers(response?.data) }
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); setApiStatus('completed'); }
    }

    const getPermissions = async () => {
        const modulePermissions = PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    const dataTableHandler = () => {
        const columns = [{
            label: "Sr.No.",
            field: "sr",
            sort: "asc",
            width: 50,
        }, {
            label: "First Name",
            field: "firstName",
            sort: "asc",
            width: 150,
        }, {
            label: "Last Name",
            field: "lastName",
            sort: "asc",
            width: 150,
        }, {
            label: "Mobile",
            field: "phoneNumber",
            sort: "asc",
            width: 150,
        }, {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 150,
        }, {
            label: "Organization",
            field: "organization",
            sort: "asc",
            width: 150,
        }, {
            label: "User Role",
            field: "userRole",
            sort: "asc",
            width: 150,
        },
        ];
        const rows = [
            ...organizationUsers.map((details, index) => ({
                sr: ++index,
                firstName: details.user.firstName,
                lastName: details.user.lastName,
                phoneNumber: details.user.phoneNumber,
                email: details.user.email,
                organization: details.organization?.companyName,
                userRole: details.userRole?.name,
                action: (
                    <React.Fragment>
                        <MountComponent condition={userPermissions.ADMIN_USERS_UPDATE}>
                            <Link className="btn btn-group border ms-1" to={String(ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS_UPDATE).replace(':id', details.id)}>
                                <i className="fas fa-pencil-alt" aria-hidden="true" style={{ color: "#0022A0" }}></i>
                            </Link>
                        </MountComponent>
                        <MountComponent condition={userPermissions.ADMIN_USERS_DELETE}>
                            <button className="btn btn-group border ms-1" onClick={() => { handleDeleteCallback(details) }}>
                                <i className="fa fa-trash" aria-hidden="true" style={{ color: "red" }}></i>
                            </button>
                        </MountComponent>
                    </React.Fragment>
                ),
            })),
        ];

        if (userPermissions.ADMIN_USERS_UPDATE || userPermissions.ADMIN_USERS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
        }

        return updateDataTable({ columns: [...columns], rows: [...rows] });
    }

    /** @handlers ------------------------------------------------------------------------------------------------------------------ */

    const handleDeleteCallback = useCallback((userDetails = {}) => {
        setOrganizationUser({ ...userDetails });
        toggleDeleteModal(true);
    });

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteOrganizationUser(organizationUser.id);
            await getOrganizationUsers();

            toggleNotificationState({ visible: true, color: 'success', message: 'Success! Organization user deleted.' });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setOrganizationUser({}) }
    });

    /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { getOrganizationUsers() }, []);
    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);
    useEffect(() => {
        if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
    }, [notificationState.visible]);
    useEffect(() => { dataTableHandler() }, [organizationUsers]);

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>
            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <div className="page-content"><div className="container-fluid"><div className="row">
                <div className="col-lg-12"><div className="profile">
                    <div className="actives">
                        <TimelineGuide heading="Setup Your Users" activePage={ADMIN_USERS}></TimelineGuide>
                    </div>
                    <MountComponent condition={apiStatus === "completed"}>
                        <MountCondition condition={lodash.isEmpty(organizationUsers)}>
                            <MountCondition.True>
                                <Info userPermissions={userPermissions}></Info>
                            </MountCondition.True>
                            <MountCondition.False>
                                <Table
                                    dataTable={dataTable}
                                    userPermissions={userPermissions}
                                    organizationUser={organizationUser}
                                    organizationUsers={organizationUsers}
                                    deleteModal={deleteModal}
                                    toggleDeleteModal={toggleDeleteModal}
                                    handleDeleteCallback={handleDeleteCallback}
                                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                                ></Table>
                            </MountCondition.False>
                        </MountCondition>
                    </MountComponent>
                </div></div>
                <div className="col-lg-3">
                    <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
                </div>
            </div></div></div>
        </React.Fragment>
    );
};
