import config, {server} from '../config/config'

export const deleteProductAPI = async (productId) => {
    
    return await server({
        method  : 'delete',
        url     : `product-details/${productId}`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default deleteProductAPI