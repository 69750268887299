import React from "react";
import App from "./App"

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom";

import { appRouter } from "./app.jsx";
import store from "./store"

import { RootLoader } from "./module/loader";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
      <RouterProvider router={appRouter} fallbackElement={<RootLoader />}/>
  </Provider>
)
{/* <React.StrictMode>
</React.StrictMode> */}



// import React from "react"
// import App from "./App"
// import * as serviceWorker from "./serviceWorker"
// import { BrowserRouter } from "react-router-dom"
// import { Provider } from "react-redux"
// import store from "./store"
// import ReactDOM from "react-dom/client";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
// )

// serviceWorker.unregister()


// import React, { useCallback, useEffect, useState } from "react";
// import {
//   createBrowserRouter,
//   createRoutesFromElements,
//   RouterProvider,
//   Route,
//   Link,
//   useAsyncValue,
//   Await,
//   useNavigation,
//   Outlet,
//   json,
//   Navigate
// }
//   from "react-router-dom";
// import ReactDOM from "react-dom/client";

// function ProductVariants() {
//   const variants = useAsyncValue();
//   console.clear(); console.log(variants)
//   return <div>{/* ... */}</div>;
// }

// const promise = () => new Promise((resolve) => {
//   setTimeout(() => { resolve({ title: "awesome" }) }, 5000);
// });

// const usePromise = () => {
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState({})

//   const apiCall = useCallback(async () => {
//     const response = await promise();
//     setLoading(false);
//     setData(response)
//   });

//   useEffect(() => { apiCall(); }, []);

//   return { loading, data }
// }

// const App = () => (
//   <React.Fragment>
//     <h2>App Page</h2>
//     <Link to="/users" className="btn btn-sm btn-primary">Users Page</Link>
//   </React.Fragment>
// );

// const UsersPage = () => (
//   <React.Fragment>
//     <h2>Users Page</h2>
//     <ul>
//       <li><Link to="/" className="btn btn-sm btn-primary">Home</Link></li>
//       <li><Link to="/users/8045-se230" className="btn btn-sm btn-primary">User Details</Link></li>
//     </ul>
//   </React.Fragment>
// );

// const UserPage = () => {
//   console.log('times rendered');
//   const { loading, data } = usePromise();
//   if (loading) { return (<React.Fragment>Loading... </React.Fragment>) }
//   return (
//     <React.Fragment>
//       <h2>User Details Page</h2>
//       <Link to="/" style={{ margin: '10px' }}>Home</Link>
//       <Link to="/users" style={{ margin: '10px' }}>Back</Link>
//     </React.Fragment>
//   )
// };

// const AppWrapper = () => {
//   const { state } = useNavigation();
//   if (state === "loading") { return (<div style={{ padding: '10px', backgroundColor: 'bisque' }}><h2>App Wrapper</h2><React.Fragment>Loading... </React.Fragment></div>) }

//   return (<div style={{ padding: '10px', backgroundColor: 'bisque' }}><h2>App Wrapper</h2><Outlet></Outlet></div>)
// }

// const AuthWrapper = () => {
//   const { state } = useNavigation();

//   if (state === "loading") { return (<div style={{ padding: '10px', backgroundColor: 'cyan' }}><h2>App Wrapper</h2><React.Fragment>Loading... </React.Fragment></div>) }

//   return <Navigate to="/"></Navigate>

//   return (<div style={{ padding: '10px', backgroundColor: 'cyan' }}><h2>App Wrapper</h2><Outlet></Outlet></div>)
// }


// const GroupRoutes = (...props) => {
//   return (
//     <React.Fragment>
//       <Route path="users" {...props}>
//         <Route element={<UsersPage />} index />
//         <Route path=":userId" element={<UserPage />} />
//       </Route>
//     </React.Fragment>
//   )
// }

// const loader = async () => {
//   const response = await promise();
//   return json({ response })
// }

// const router = createBrowserRouter(createRoutesFromElements(
//   <Route>
//     <Route element={<AppWrapper />}>
//       <Route path="/">
//         <Route element={<App />} index />
//       </Route>
//     </Route>
//     <Route element={<AuthWrapper />}>
//       <Route path="users">
//         <Route element={<UsersPage />} index loader={loader} />
//         <Route path=":userId" element={<UserPage />} loader={loader} />
//       </Route>
//     </Route>
//   </Route>
// ));

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <RouterProvider router={router}></RouterProvider>
//   </React.StrictMode>
// )