import React, { useCallback, useEffect, useState } from "react";
import expirePlugin from "store/plugins/expire";

import * as Yup from "yup";
import { useFormik } from "formik";

import classnames from "classnames";
import store from "store";
import lodash from "lodash";
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";

import OtpInput from "react-otp-input";
import {
  Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label, Alert,
} from "reactstrap";

import {
  TabContent, TabPane, NavLink, NavItem, Nav,
} from "reactstrap";

import logo from "../../../../assets/images/logo-dark.png";
import RegisterImage from "../../../../assets/images/registerImage.png";
import imageLoginBanner from "../../../../assets/images/loginlogo.svg";
import cornerImage from "../../../../assets/images/corner.png";

import { server } from "../../../../config/config";

import getOrganizationDetailsAPI from "../../../../api/getOrganizationDetailsAPI";

import ROUTES from "../../../../routes/routes";
import { requestSignInOTP, signInVerification } from "../../../../api/authentication.api";
import { CountdownComponent } from "./components";

import MountComponent, { MountCondition } from "../../../../module/mount-components";

const notificationInitialState = { visible: false, message: '', color: '' }

export default React.memo(() => {
    const navigate = useNavigate();
    
    const [tab, toggleTab] = useState('LOGIN_WITH_EMAIL');
    const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });
    const [loading,setLoading]= useState(false);
    const [countdownState,toggleCountdownState] = useState({ active: false, key: 0, time: 0 });


    const requestLoginValidation = Yup.object({
      email: Yup.string().email('Invalid email address')
        .test('dynamic-email-require-validation', 'Email is required', (value) => {
            if(tab === "LOGIN_WITH_EMAIL"){ return !lodash.isEmpty(value); }
            return true;
        }),
      contactNumber: Yup.string()
        .matches(/^\d{10}$/, 'Contact number must be 10 digits')
        .test('dynamic-phoneNumber-require-validation', 'Contact number is required', (value) => {
            if(tab === "LOGIN_WITH_PHONE_NUMBER"){ return !lodash.isEmpty(value); }
            return true;
        }),
    });

    const requestLoginFormik = useFormik({
        enableReinitialize: false,
        initialValues: { email: "", phoneNumber: "" },
        validationSchema: requestLoginValidation,
        onSubmit: async (values) => {
          try {

              const payload = {}; setLoading(true); toggleNotificationState({ ...notificationInitialState });

              if(tab === "LOGIN_WITH_EMAIL") { payload.email = values.email; }
              if(tab === "LOGIN_WITH_PHONE_NUMBER"){ payload.phoneNumber = values.phoneNumber; }

              await requestSignInOTP(payload);
              toggleCountdownState({ active: true, key: countdownState.key + 1, time: Date.now() + 59000 });

              if(tab === "LOGIN_WITH_EMAIL"){ toggleNotificationState({ visible: true, color: "success", message: "OTP sent successfully! Check your email." }); }
              if(tab === "LOGIN_WITH_PHONE_NUMBER"){ toggleNotificationState({ visible: true, color: "success", message: "OTP sent successfully! Check your phone." }); }

          } catch (error) {
              if (error instanceof AxiosError) {
                  if(error.response.status === 404) { toggleNotificationState({ visible: true, color: "danger", message:'Account not found'}); }
                  else if(error.response.status === 423) { 
                      toggleNotificationState({ visible: true, color: "danger", message:'Sorry your account is not verified. Redirect in 3 seconds.'}); 
                      setTimeout(() => { 
                          const userDetails  = lodash.get(error.response,'data.user',{});
                          const searchParams = new URLSearchParams();
                          const pathname = ROUTES.AUTHENTICATION.VERIFY_ACCOUNT;

                          searchParams.append('email', userDetails.email);
                          searchParams.append('phoneNumber', userDetails.phoneNumber);

                          navigate(`${pathname}?${searchParams}`); 
                      }, 3000);
                  }
                  else { toggleNotificationState({ visible: true, color: "danger", message:error.response.data.error}); }
              } else { toggleNotificationState({ visible: true, color: "danger", message:'Sorry something went wrong'}); } 
          } finally{ setLoading(false); }}
    });

    const loginVerificationValidation = Yup.object({
      email: Yup.string().email('Invalid email address')
        .test('dynamic-email-require-validation', 'Email is required', (value) => {
            if(tab === "LOGIN_WITH_EMAIL"){ return !lodash.isEmpty(value); }
            return true;
        }),
      contactNumber: Yup.string()
        .matches(/^\d{10}$/, 'Contact number must be 10 digits')
        .test('dynamic-phoneNumber-require-validation', 'Contact number is required', (value) => {
            if(tab === "LOGIN_WITH_PHONE_NUMBER"){ return !lodash.isEmpty(value); }
            return true;
          }),
      emailOTP: Yup.string().required("OTP is require.")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "Must be exactly 6 digits")
        .max(6, "Must be exactly 6 digits"),
    });
  
    const otpValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: requestLoginFormik.values.email,
            phoneNumber: requestLoginFormik.values.phoneNumber,
            emailOTP: '',
        },
        validationSchema: loginVerificationValidation,

        onSubmit: async (values) => {
            try {

                const payload = { emailOTP : values.emailOTP }; setLoading(true); toggleNotificationState({ ...notificationInitialState });

                if(tab === "LOGIN_WITH_EMAIL") { payload.email = values.email; }
                if(tab === "LOGIN_WITH_PHONE_NUMBER"){ payload.phoneNumber = values.phoneNumber; }

                const response = await signInVerification(payload);

                server.defaults.headers.common["Authorization"] = response.data.token;
                
                const dataOrganization = await getOrganizationDetailsAPI(response.data.userId);
                const { id: organizationId } = lodash.get(dataOrganization, 'data.data', {});

                localStorage.setItem("authUser", JSON.stringify(response.data));
                localStorage.setItem("authUserOrg", JSON.stringify(dataOrganization));

                store.addPlugin(expirePlugin)
                store.set('session', { ...response.data, organizationId }, { expires: 300 });

                return navigate(ROUTES.HOME.PATH);

          } catch(error){
                if (error instanceof AxiosError) { 
                  toggleNotificationState({ visible: true, color: 'danger', message: error.response.data.error }); }
                else { toggleNotificationState( { visible: true, color: 'danger', message: 'sorry something went wrong!' }); }
          } finally{ setLoading(false); }
        },
    });

  /** @handlers ------------------------------------------------------------------------------------------------------------------- */

      const handleCountdownComplete = useCallback(() => {
          return toggleCountdownState({ active: false, key: countdownState.key + 1, time: 0 })
      });

      const handleTabSwitch = useCallback((tabId) => {
          requestLoginFormik.resetForm();
          otpValidation.resetForm();
          return toggleTab(tabId)
      });

  /** ----------------------------------------------------------------------------------------------------------------------------- */

  /** @useEffects ----------------------------------------------------------------------------------------------------------------- */

    useEffect(() => {
      if(notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, message: '' }); }, 3000); }
      return () => null;
    },[notificationState.visible]); 

  /** ----------------------------------------------------------------------------------------------------------------------------- */
    
  return (
    <React.Fragment>
      <MountComponent condition={loading}>
          <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
          </div></div></div>
      </MountComponent>
      <div className="account-pages boxCenter">
        <div className="text-center p-1">
          <img src={logo} alt="" height="32" className="register-logo" />
        </div>
        <Container>
          <Row className="align-items-center justify-content-center hello">
            <Col md={6} lg={6} xl={6} className="hello1">
              <img src={RegisterImage} alt="" height="35" className="register-image" />
            </Col>
            <Col md={6} lg={6} xl={6} className="loginHalfBox">
              <Col xl={12}>
                <Card className="outerCard">
                  <CardBody className="outerCardBody">
                    <Nav tabs>
                        <NavItem>
                            <NavLink onClick={() => { handleTabSwitch('LOGIN_WITH_PHONE_NUMBER') }} style={{ cursor: "pointer" }} className={classnames({ active: tab === "LOGIN_WITH_PHONE_NUMBER" })}>
                                <span className="d-sm-block">Log In with Mobile Number</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => { handleTabSwitch("LOGIN_WITH_EMAIL"); }} style={{ cursor: "pointer" }} className={classnames({ active: tab === "LOGIN_WITH_EMAIL" })}>
                                  <span className="d-sm-block">Log In with Email</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={tab} className="text-muted">
                      <TabPane tabId="LOGIN_WITH_PHONE_NUMBER">
                        <Card className="innerCard">
                          <CardBody className="innerCardBody">

                            <div className="text-center mt-2">
                                <img src={imageLoginBanner} alt="" height="35" className="register-image" />
                                <h5 className="text-primary AuthHead">Log in</h5>
                                <p className="text-muted authSubHead">
                                  {" "} To continue to Giffy Network{" "}
                                </p>
                            </div>

                            <div className="">
                                <MountComponent condition={notificationState.visible}>
                                    <Alert className="pt-1 pb-1" color={notificationState.color}>
                                        { notificationState.message }
                                    </Alert>
                                </MountComponent>
                                <Form className="form-horizontal" onSubmit={requestLoginFormik.handleSubmit}>
                                  <div className="mb-2 loginFormLabel new-login-form">
                                    <div className="col-md-8">
                                      <Label className="form-label formHead">Phone Number</Label>
                                      <Input
                                        name="phoneNumber"
                                        className="form-control formControlCustom"
                                        placeholder="Enter phone number"
                                        type="text"
                                        onChange={requestLoginFormik.handleChange}
                                        onBlur={requestLoginFormik.handleBlur}
                                        value={requestLoginFormik.values.phoneNumber || ""}
                                        invalid={
                                          ((requestLoginFormik.touched.phoneNumber && requestLoginFormik.errors.phoneNumber) || 
                                           (otpValidation.touched.phoneNumber && otpValidation.errors.phoneNumber)) ? true : false
                                        }
                                      />
                                      <MountComponent condition={((otpValidation.touched.phoneNumber && otpValidation.errors.phoneNumber) || (requestLoginFormik.touched.phoneNumber && requestLoginFormik.errors.phoneNumber))}>
                                          <FormFeedback className="otp-invalid">{requestLoginFormik.errors.phoneNumber || otpValidation.errors.phoneNumber}</FormFeedback>
                                      </MountComponent>
                                    </div>

                                    <div className="col-md-4 button-column">
                                        <MountCondition condition={countdownState.active}>
                                            <MountCondition.True>
                                                <CountdownComponent 
                                                    countdownState={countdownState} 
                                                    handleCountdownComplete={handleCountdownComplete}
                                                ></CountdownComponent>
                                            </MountCondition.True>
                                            <MountCondition.False>
                                                <button disabled={requestLoginFormik.isSubmitting} type="submit" className="btn btn-primary w-100 waves-effect waves-light createButton">
                                                    Generate OTP
                                                </button>
                                            </MountCondition.False>
                                        </MountCondition>
                                    </div>
                                  </div>

                                  <div className="mt-2">
                                    <span className="otpCheck">
                                      A 6 digit OTP will be sent via SMS to verify
                                      your mobile number
                                    </span>
                                  </div>
                                </Form>

                                <Form className="form-horizontal" onSubmit={otpValidation.handleSubmit}>
                                    <div className="mb-2 loginFormLabel">
                                        <Label className="form-label formHead">
                                          Enter OTP
                                        </Label>
                                        <div className="otpSetup">
                                          <OtpInput
                                            value={otpValidation.values.emailOTP}
                                            onChange={(otp) => { otpValidation.setFieldValue('emailOTP',otp); }}
                                            numInputs={6}
                                            isInputNum={true}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => (<input {...props} />)}
                                            inputType="tel"
                                          />
                                        </div>
                                        <MountComponent condition={otpValidation.touched.emailOTP && otpValidation.errors.emailOTP}>
                                              <p type="invalid">{otpValidation.errors.emailOTP}</p>
                                        </MountComponent>
                                    </div>

                                    <div className="mt-1 text-end">
                                      <button
                                        className="btn btn-primary w-sm waves-effect waves-light createButton"
                                        type="submit"
                                      >
                                        Verify
                                      </button>
                                    </div>

                                  <div className="mt-4 text-center">
                                    <p className="mb-2 logText">
                                      Don't have an account ?{" "}
                                      <Link to={ROUTES.AUTHENTICATION.REGISTER} className="fw-medium text-primary logButton">
                                        {" "} Register Now{" "} {" "}
                                      </Link>
                                    </p>
                                  </div>
                                </Form>
                            </div>
                          </CardBody>
                        </Card>
                        <div>
                          <img
                            src={cornerImage}
                            alt=""
                            className="LoginCornerImage"
                          />
                        </div>
                      </TabPane>
                      <TabPane tabId="LOGIN_WITH_EMAIL">
                        <Card className="innerCard">
                          <CardBody className="innerCardBody">
                            <div className="text-center mt-2">
                                <img src={imageLoginBanner} alt="" height="35" className="register-image" />
                                <h5 className="text-primary AuthHead">Log in</h5>
                                <p className="text-muted authSubHead">To continue to Giffy Network</p>
                            </div>

                            <div className="">
                                  <MountComponent condition={notificationState.visible}>
                                      <Alert className="pt-1 pb-1" color={notificationState.color}>
                                          { notificationState.message }
                                      </Alert>
                                  </MountComponent>
                                <Form className="form-horizontal" onSubmit={requestLoginFormik.handleSubmit}>
                                    <div className="mb-2 loginFormLabel new-login-form">
                                        <div className="col-md-8">
                                          <Label className="form-label formHead">
                                            Email Address
                                          </Label>
                                          <Input
                                            name="email"
                                            className="form-control formControlCustom"
                                            placeholder="Enter email"
                                            type="email"
                                            onChange={requestLoginFormik.handleChange}
                                            onBlur={requestLoginFormik.handleBlur}
                                            value={requestLoginFormik.values.email || ""}
                                            invalid={
                                              ((requestLoginFormik.touched.email && requestLoginFormik.errors.email) || 
                                               (otpValidation.touched.email && otpValidation.errors.email)) ? true : false
                                            }
                                          />
                                          <MountComponent condition={((otpValidation.touched.email && otpValidation.errors.email) || (requestLoginFormik.touched.email && requestLoginFormik.errors.email))}>
                                              <FormFeedback type="invalid">{requestLoginFormik.errors.email || otpValidation.errors.email}</FormFeedback>
                                          </MountComponent>
                                        </div>
                                        <div className="col-md-4 button-column">
                                            <MountCondition condition={countdownState.active}>
                                                <MountCondition.True>
                                                    <CountdownComponent 
                                                        countdownState={countdownState} 
                                                        handleCountdownComplete={handleCountdownComplete}
                                                    ></CountdownComponent>
                                                </MountCondition.True>
                                                <MountCondition.False>
                                                    <button disabled={requestLoginFormik.isSubmitting} type="submit" className="btn btn-primary w-100 waves-effect waves-light createButton">
                                                        Generate OTP
                                                    </button>
                                                </MountCondition.False>
                                            </MountCondition>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <span className="otpCheck">
                                            A 6 digit OTP will be sent via Email to verify your Email account
                                        </span>
                                    </div>
                                </Form>

                                <Form className="form-horizontal" onSubmit={otpValidation.handleSubmit}>
                                  <div className="mb-2 loginFormLabel">
                                    <Label className="form-label formHead">
                                      Enter OTP
                                    </Label>
                                    <div className="otpSetup">
                                      <OtpInput
                                          value={otpValidation.values.emailOTP}
                                          onChange={(otp) => { otpValidation.setFieldValue('emailOTP',otp); }}
                                          numInputs={6}
                                          isInputNum={true}
                                          renderSeparator={<span>-</span>}
                                          renderInput={(props) => (<input {...props} />)}
                                          inputType="tel"
                                      />
                                    </div>
                                    <MountComponent condition={otpValidation.touched.emailOTP && otpValidation.errors.emailOTP}>
                                        <p className="otp-invalid">{otpValidation.errors.emailOTP}</p>
                                    </MountComponent>
                                  </div>

                                  <div className="mt-1 text-end">
                                    <button
                                      className="btn btn-primary w-sm waves-effect waves-light createButton"
                                      type="submit"
                                    >
                                      Verify
                                    </button>
                                  </div>

                                  <div className="mt-4 text-center">
                                    <p className="mb-2 logText">
                                      Don't have an account ?{" "}
                                      <Link  to={ROUTES.AUTHENTICATION.REGISTER} className="fw-medium text-primary logButton" >
                                        {" "} Register Now{" "} {" "}
                                      </Link>
                                    </p>
                                  </div>
                                </Form>
                            </div>
                          </CardBody>
                        </Card>
                        <div>
                          <img
                            src={cornerImage}
                            alt=""
                            className="LoginCornerImage"
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});

