import { server } from '../config/config'

export default async () => await server({
    method  : 'get',
    url     : `user-roles`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const storeUserRoleAPI = async (payload = {}) => await server({
    method  : 'post',
    url     : `user-roles`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const storeUserRolePermissionsAPI = async (id,payload) => await server({
    method  : 'post',
    url     : `user-roles/${id}/permissions`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getUserRolePermissionsAPI = async (id) => await server({
    method  : 'get',
    url     : `user-roles/${id}/permissions`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
