import React from "react"
import TimelineContainer, { TimelineContent, TimelineHeading } from "../../module/timeline-components"
import { getColor, useApiData } from "../../module/timeline-methods";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

export default React.memo(({ heading, activePage }) => {
    const { NAME, CHILDREN: { TEMPLATE_MASTER, AUDIENCE_MASTER, CAMPAIGN_MASTER } } = PERMISSIONS.NETWORK_ENGAGE;
    const { array: state } = useApiData({ module: NAME });

    const structure = {
        [TEMPLATE_MASTER.NAME]: { current: TEMPLATE_MASTER.NAME, previous: null,                 active: activePage?.NAME === TEMPLATE_MASTER.NAME },
        [AUDIENCE_MASTER.NAME]: { current: AUDIENCE_MASTER.NAME, previous: AUDIENCE_MASTER.NAME, active: activePage?.NAME === AUDIENCE_MASTER.NAME },
        [CAMPAIGN_MASTER.NAME]: { current: CAMPAIGN_MASTER.NAME, previous: CAMPAIGN_MASTER.NAME, active: activePage?.NAME === CAMPAIGN_MASTER.NAME },
    }

    return (
        <React.Fragment>
            <TimelineHeading>{heading}</TimelineHeading>
            <TimelineContainer>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE} 
                    color={getColor(state, structure[TEMPLATE_MASTER.NAME])}      
                    index={1}
                    active={structure[TEMPLATE_MASTER.NAME]['active']} 
                    heading="Template Master">
                </TimelineContent>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE}  
                    color={getColor(state, structure[AUDIENCE_MASTER.NAME])}        
                    index={2}
                    active={structure[AUDIENCE_MASTER.NAME]['active']} 
                    heading="Audiences">
                </TimelineContent>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN}  
                    color={getColor(state, structure[CAMPAIGN_MASTER.NAME])}        
                    index={3}
                    active={structure[CAMPAIGN_MASTER.NAME]['active']} 
                    heading="Campaign">
                </TimelineContent>
            </TimelineContainer>
        </React.Fragment>
    )
});
