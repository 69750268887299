import { server } from '../config/config'

export const storeSalesHierarchyAPI = (payload = {}) => server({
    method  : 'post',
    url     : `sales-hierarchy`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getSalesHierarchiesAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `sales-hierarchy`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getSalesHierarchyAPI = (id) => server({
    method  : 'get',
    url     : `sales-hierarchy/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateSalesHierarchyAPI = (id, payload = {}) => server({
    method: 'put',
    url: `sales-hierarchy/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteSalesHierarchyAPI = (id) => server({
    method: 'delete',
    url: `sales-hierarchy/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});