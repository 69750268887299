import config,{server} from '../config/config'

export const deleteUserRoleAPI = async (userRoleId) => {
    
    return await server({
        method  : 'delete',
        url     : `user-role/${userRoleId}`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default deleteUserRoleAPI