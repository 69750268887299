import React, { useState } from "react";
import AddCampaign from "./source.jsx";
import Preview from "./preview.jsx";
import RightSideProfile from "../../../Dashboard/right-side-profile.js";
import { MountCondition } from "../../../../module/mount-components.jsx";

export default(() => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  
  const defaultCampaignState = { 
    sourceEmail: "hello@giffy.network", 
    sourceName: "Giffy Network",
    status:"RUNNING",
    campaignAudience:[]
  }


  const [screen,switchScreen] = useState('create-campaign');
  const [campaignState,setCampaignState] = useState({ ...defaultCampaignState });

  const handleCampaignStateChange = (newState) => {
      setCampaignState((prevCampaignState) => ({
        ...prevCampaignState,
        ...newState,
      }));
  };

  return (
    <React.Fragment>
      <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12"><div className="profile">
                <MountCondition condition={screen === 'create-campaign'}>
                    <MountCondition.True>
                        <AddCampaign switchScreen={switchScreen} campaignState={campaignState} setCampaignState={handleCampaignStateChange} screen={screen}></AddCampaign> 
                    </MountCondition.True>
                    <MountCondition.False>
                        <Preview switchScreen={switchScreen} campaignState={campaignState} ></Preview>
                    </MountCondition.False>
                </MountCondition>
            </div></div>
            <div className="col-md-3">
                {/* <RightSideProfile></RightSideProfile> */}
            </div>
    </div></div></div>
    </React.Fragment>
  );
});