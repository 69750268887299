import React, { useState, useRef, useCallback, useEffect } from "react";
import * as Yup from "yup";

import EmailEditor from "react-email-editor";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Form, Label, Row, Modal, Col, FormGroup, Input, Alert } from "reactstrap";

import MountComponent from "../../../../module/mount-components";
import { errorMessage } from "../../../../module/error-methods";

import { storeOrganizationModule } from "../../../../api/organizationModuleApi";

import ROUTES from '../../../../routes/routes';
import PERMISSIONS from '../../../../routes/permissions';
import { storeTemplateAPI } from "../../../../api/template.api";

export default React.memo(() => {
    const { NAME, CHILDREN: { TEMPLATE_MASTER } } = PERMISSIONS.NETWORK_ENGAGE;

    const navigate = useNavigate();
    const emailEditorRef = useRef(null);

    const [modalEdit, toggleStoreModal] = useState(false);
    const [discardModal, toggleDiscardModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [notificationState, toggleNotificationState] = useState({ visible: false, message: '', color: '' });

    const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
    const organizationId = authUserOrg.data.data.id;

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            template: "",
            image: null,
            organizationId: organizationId,
        },
      validationSchema: Yup.object({
        name: Yup.string().required("Please Enter Template Name"),
      }),
      onSubmit: async (values) => {
        try {
            setLoading(true);

            await storeTemplateAPI(values);
            await storeOrganizationModule({ module : NAME, moduleLevel : TEMPLATE_MASTER.NAME })

            toggleNotificationState({ visible: true, color: 'success', message: "Template saved successfully" });
            setTimeout(() => { navigate(ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE); }, 3000);
        } catch (error) {
          toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error.message) });
        } finally { setLoading(false); toggleStoreModal(false); }
      },
  });

  function removeBodyCss() { document.body.classList.add("no_padding"); }
  function tog_edit() { toggleStoreModal(!modalEdit); removeBodyCss(); }

  /** @handlers ---------------------------------------------------------------------------------------------------------------------- */
    
    const exportHtml = useCallback(async () => {
        try {

          if (!emailEditorRef.current) { return true; }; setLoading(true);

          const templateContainer = emailEditorRef.current?.editor;
          const response = await new Promise((resolve) => {
            templateContainer?.exportHtml((data) => resolve(data));
          });

          validation.setFieldValue("template", response.html);
          validation.setFieldValue("templateJson", JSON.stringify(response.design));

          return toggleStoreModal(true);

        } catch (error) { 
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error.message) });
        } finally { setLoading(false); }
    });

    const handleDiscardModal = useCallback(() => { toggleDiscardModal(!discardModal); });

  /** @useEffects -------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => {
        if(notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, message: '' }); }, 3000); }
    },[notificationState.visible]); 

  /** --------------------------------------------------------------------------------------------------------------------------------- */


  return (
    <React.Fragment>
        <MountComponent condition={loading}>
              <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
              </div></div></div>
        </MountComponent>

        <MountComponent condition={notificationState.visible}>
            <Alert color={notificationState.color}>{ notificationState.message}</Alert>
        </MountComponent>

        <React.Fragment>
            <EmailEditor ref={emailEditorRef} id="email-editor-container" options={{ tools: false }} specialLinks={false}/>
            <div className="mt-3 mb-3 d-flex justify-content-center"><div className="saveBtn">
              <button className="btn btn-group  ps-3 pe-3" onClick={exportHtml}>Save</button>
              <button className="btn btn-group  ps-3 pe-3" onClick={handleDiscardModal}>Discard</button>
            </div></div>
        </React.Fragment>

      <Modal isOpen={modalEdit} toggle={() => { tog_edit(); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
        <div className="modal-header p-1 ">
          <h5 className="modal-title" id="staticBackdropLabel">Save template</h5>
          <button type="button" className="btn-close" onClick={() => { toggleStoreModal(false); }} aria-label="Close" ></button>
        </div>

        <Form className="needs-validation p-0" onSubmit={validation.handleSubmit}>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Template name</Label>
                  <Input
                    name="name"
                    placeholder="Enter Template name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer p-0 bg-transparent">
            <button type="submit" id="dl-png" className="nextMasterModal btn  ps-3 pe-3">Save</button>
            <button type="button" className="cancelModal btn  ps-3 pe-3" onClick={() => { toggleStoreModal(false); }}>
              Cancel
            </button>
          </div>
        </Form>
      </Modal>

      {/* Modal for Discard */}
      <Modal isOpen={discardModal} toggle={handleDiscardModal} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
          <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Discard Template</h5>
              <button type="button" className="btn-close" onClick={handleDiscardModal} aria-label="Close" ></button>
          </div>
          <Form className="needs-validation" onSubmit={(e) => {
              e.preventDefault();
              return validation.handleSubmit();
            }}
          >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">
                    Changes you made may not be saved. Are you sure you want to quit?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Link to={ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE} className="nextMasterModal btn">
              Confirm
            </Link>
            <button type="button" className="cancelModal btn" onClick={handleDiscardModal}>Cancel</button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
});
