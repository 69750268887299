import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";

export const xlsxExport = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"], };
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array", });
    const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(dataBlob, fileName);
};

export const xlsxImport = (binaryData) => {
    const workbook = XLSX.read(binaryData, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
    const headerRow = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })[0];

    return { sheetData, headerRow }
}