import React from "react"
import TimelineContainer, { TimelineContent, TimelineHeading } from "../../module/timeline-components"
import { getColor, useApiData } from "../../module/timeline-methods";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

export default React.memo(({ heading, activePage }) => {
    const { NAME, CHILDREN: { GEOGRAPHY, LOCATION, SALESMEN, CHANNEL_PARTNER /** , BUSINESS_DATA */ } } = PERMISSIONS.NETWORK_MANAGER;
    const { array: state } = useApiData({ module: NAME });

    const structure = {
        [GEOGRAPHY.NAME]: { current: GEOGRAPHY.NAME, previous: null, active: activePage?.NAME === GEOGRAPHY.NAME },
        [LOCATION.NAME]:  { current: LOCATION.NAME,  previous: GEOGRAPHY.NAME, active: activePage?.NAME === LOCATION.NAME },
        [SALESMEN.NAME]:        { current: SALESMEN.NAME, previous: LOCATION.NAME, active: activePage?.NAME === SALESMEN.NAME },
        [CHANNEL_PARTNER.NAME]: { current: CHANNEL_PARTNER.NAME, previous: SALESMEN.NAME, active: activePage?.NAME === CHANNEL_PARTNER.NAME },
        // [BUSINESS_DATA.NAME]:   { current: BUSINESS_DATA.NAME, previous: BUSINESS_DATA.NAME, active: activePage?.NAME === BUSINESS_DATA.NAME },
    }

    return (
        <React.Fragment>
            <TimelineHeading>{heading}</TimelineHeading>
            <TimelineContainer>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} 
                    color={getColor(state, structure[GEOGRAPHY.NAME])}      
                    index={1}
                    active={structure[GEOGRAPHY.NAME]['active']} 
                    heading="Geography">
                </TimelineContent>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION}  
                    color={getColor(state, structure[LOCATION.NAME])}        
                    index={2}
                    active={structure[LOCATION.NAME]['active']} 
                    heading="Location">
                </TimelineContent>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN}  
                    color={getColor(state, structure[SALESMEN.NAME])}        
                    index={3}
                    active={structure[SALESMEN.NAME]['active']} 
                    heading="Sales Man">
                </TimelineContent>
                <TimelineContent 
                    navigate={ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER} 
                    color={getColor(state, structure[CHANNEL_PARTNER.NAME])}  
                    index={4}
                    active={structure[CHANNEL_PARTNER.NAME]['active']} 
                    heading="Channel Partner">
                </TimelineContent>
                {/* <TimelineContent 
                    navigate={ROUTES.NETWORK_MANAGER.PATH}               
                    color={getColor(state, structure[BUSINESS_DATA.NAME])}
                    index={5}
                    active={structure[BUSINESS_DATA.NAME]['active']} 
                    heading="Business Data">
                </TimelineContent>  */}
            </TimelineContainer>
        </React.Fragment>
    )
});
