import React from "react";
import { Link } from "react-router-dom";

import MountComponent, { MountCondition } from '../../../../module/mount-components';

import UserPermissions from "./source";
import upComping from "../../../../assets/images/comming.jpg"
import TimelineGuide from "../../timeline-guide";

import RightSideProfile from "../../../Dashboard/right-side-profile";
import ROUTES from "../../../../routes/routes";
import PERMISSIONS from "../../../../routes/permissions";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Users Rights | Giffy Network";
    const showGirl = false;
    const { CHILDREN: { USER_RIGHTS } } = PERMISSIONS.QUICK_START_GUIDE;

    return (
        <React.Fragment>
            <div className="page-content"><div className="container-fluid"><div className="row">
                <div className="col-lg-12 col-md-12"><div className="profile">
                    <MountCondition condition={showGirl}>
                        <MountCondition.True>
                            <div className="profile  pt-5 pb-5" style={{ textAlign: 'center' }}>
                                <h2 className="mt-5">
                                    <b><span style={{ color: '#000' }}>User Right</span> </b>
                                    <b><span style={{ color: '#0022a0' }}>Module</span></b>
                                </h2>
                                <div className="">
                                    <img className="mb-5 mt-2" src={upComping} style={{ height: "147px", width: "406px" }} />
                                </div>
                                <Link to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS} className="nextMaster btn btn-group text-white">Users</Link>
                            </div>
                        </MountCondition.True>
                        <MountCondition.False>
                            <div className="actives">
                                <TimelineGuide heading="Setup User Rights" activePage={USER_RIGHTS}></TimelineGuide>
                            </div>
                            <UserPermissions></UserPermissions>
                        </MountCondition.False>
                    </MountCondition>
                </div></div>
                <div className="col-lg-3 col-md-12">
                    <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
                </div>
            </div></div></div>
        </React.Fragment>
    );
}
