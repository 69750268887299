import { Navigate } from "react-router-dom";

import PERMISSIONS from './permissions';
import ROUTES from "./routes";

/** organization module ----------------------------------------------------------------- */

import Home from "../pages/Home/index";
import Unauthorized from '../pages/Authentication-Module/errors/403'

import Profile from "../pages/Organization-Module/profile/view"
import OrganizationMasters from "../pages/Organization-Module/masters/view";
import Organization from "../pages/Organization-Module/organization/view";
import OrganizationUserRights from "../pages/Organization-Module/user-rights/view";
import OrganizationUsers from "../pages/Organization-Module/users/view";
import CreateOrganizationUser from "../pages/Organization-Module/users/create";
import UpdateOrganizationUser from "../pages/Organization-Module/users/update";

/** ------------------------------------------------------------------------------------- */

/** network manger module -------------------------------------------------------------- */

import NetworkManger from "../pages/Network-Manager-Module/index";

import Geography from "../pages/Network-Manager-Module/geography/view";
import Location from "../pages/Network-Manager-Module/location/view";
import CreateLocation from "../pages/Network-Manager-Module/location/create";
import Salesman from "../pages/Network-Manager-Module/sales-man/view";
import CreateSalesman from "../pages/Network-Manager-Module/sales-man/create";
import ChannelPartner from "../pages/Network-Manager-Module/channel-partner/view";
import CreateChannelPartner from "../pages/Network-Manager-Module/channel-partner/create";
import Reports from "../pages/Network-Manager-Module/reports";

/** ------------------------------------------------------------------------------------ */

/** network engage module -------------------------------------------------------------- */

import NetworkEngage from "../pages/Network-Engage-Module"

import Template from "../pages/Network-Engage-Module/Templates/list"
import TemplateCreate from "../pages/Network-Engage-Module/Templates/create";
import TemplateClone from "../pages/Network-Engage-Module/Templates/clone"

import Audience from "../pages/NetworkEngage/Audience/view";
import AudienceCreate from "../pages/NetworkEngage/Audience/create";
import AudienceUpdate from "../pages/NetworkEngage/Audience/update";

import Campaign from "../pages/NetworkEngage/Campaign/view";
import CampaignCreate from "../pages/NetworkEngage/Campaign/create";
import CampaignView from "../pages/NetworkEngage/Campaign/email-preview";

/** ------------------------------------------------------------------------------------ */

/** catalog manger module -------------------------------------------------------------- */

import CatalogManager from "../pages/Catalog-Manager-Module";

import ProductMasters from "../pages/Catalog-Manager-Module/product-masters/view";

import Products      from "../pages/Catalog-Manager-Module/product/view";
import CreateProduct from "../pages/Catalog-Manager-Module/product/create";
import UpdateProduct from "../pages/Catalog-Manager-Module/product/update";

import ProductCatalog from "../pages/Catalog-Manager-Module/product-catalog/view";
import ViewProductCatalog from "../pages/NewCatalogDetails";

import Brochure from "../pages/Catalog-Manager-Module/brochure-data";

/** ------------------------------------------------------------------------------------ */

/** public module  --------------------------------------------------------------------- */

import Login from "../pages/Authentication-Module/login/view";
import VerifyAccount from "../pages/Authentication-Module/verify-account/view"
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication-Module/registration/view";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Account from "../pages/Account";
/** ------------------------------------------------------------------------------------ */

export const authRoutes = [
    { path: ROUTES.AUTHENTICATION.LOGIN, component: <Login /> },
    { path: "/logout", component: <Logout /> },
    { path: "/forgot-password", component: <ForgetPwd /> },
    { path: ROUTES.AUTHENTICATION.REGISTER, component: <Register /> },
    { path: ROUTES.AUTHENTICATION.VERIFY_ACCOUNT, component: <VerifyAccount /> },
];

export const privateRoutes = [
    {
        path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.PROFILE,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.PROFILE,
        component: <Profile />
    },
    {
        path: ROUTES.ORGANIZATION_DETAILS.PATH,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ORGANIZATION_DETAILS,
        component: <Organization />
    }, {
        path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.NETWORK_DETAILS,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS,
        component: <OrganizationMasters />
    }, {
        path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.USER_RIGHTS,
        component: <OrganizationUserRights />
    }, {
        path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS,
        component: <OrganizationUsers />
    }, {
        path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS_CREATE,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS.CHILDREN.ADMIN_USERS_CREATE,
        component: <CreateOrganizationUser />
    }, {
        path: ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS_UPDATE,
        permission: PERMISSIONS.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS.CHILDREN.ADMIN_USERS_UPDATE,
        component: <UpdateOrganizationUser />
    },

    {
        path: ROUTES.NETWORK_MANAGER.PATH,
        permission: PERMISSIONS.NETWORK_MANAGER,
        component: <NetworkManger />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.GEOGRAPHY,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.GEOGRAPHY,
        component: <Geography />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.LOCATION,
        component: <Location />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.LOCATION_CREATE,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.LOCATION.CHILDREN.LOCATION_CREATE,
        component: <CreateLocation />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.SALESMEN,
        component: <Salesman />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.SALESMAN_CREATE,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.SALESMEN.CHILDREN.SALESMEN_CREATE,
        component: <CreateSalesman />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER,
        component: <ChannelPartner />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER_CREATE,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER.CHILDREN.CHANNEL_PARTNER_CREATE,
        component: <CreateChannelPartner />
    }, {
        path: ROUTES.NETWORK_MANAGER.CHILDREN.REPORTS,
        permission: PERMISSIONS.NETWORK_MANAGER.CHILDREN.REPORTS,
        component: <Reports />
    }, {
        path: ROUTES.NETWORK_ENGAGE.PATH,
        component: <NetworkEngage />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER,
        component: <Template />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE_CREATE,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER.CHILDREN.TEMPLATE_MASTER_CREATE,
        component: <TemplateCreate />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE_CLONE,
        component: <TemplateClone />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER,
        component: <Audience />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE_CREATE,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER.CHILDREN.AUDIENCE_MASTER_CREATE,
        component: <AudienceCreate />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE_UPDATE,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER.CHILDREN.AUDIENCE_MASTER_UPDATE,
        component: <AudienceUpdate />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER,
        component: <Campaign />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_CREATE,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER.CHILDREN.CAMPAIGN_MASTER_CREATE,
        component: <CampaignCreate />
    }, {
        path: ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_VIEW,
        permission: PERMISSIONS.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER.CHILDREN.CAMPAIGN_MASTER_UPDATE,
        component: <CampaignView />
    },



    {
        path: ROUTES.CATALOG_MANAGER.PATH,
        permission: PERMISSIONS.CATALOG_MANAGER,
        component: <CatalogManager />
    }, {
        path: ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS,
        component: <ProductMasters />
    }, {
        path: ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCTS,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.PRODUCT,
        component: <Products />
    }, {
        path: ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.PRODUCT.CHILDREN.PRODUCT_CREATE,
        component: <CreateProduct />
    }, {
        path: ROUTES.CATALOG_MANAGER.CHILDREN.UPDATE_PRODUCT,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.PRODUCT.CHILDREN.PRODUCT_UPDATE,
        component: <UpdateProduct />
    },{
        path: ROUTES.CATALOG_MANAGER.CHILDREN.CATALOGS,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.CATALOGS,
        component: <ProductCatalog />
    }, {
        path: ROUTES.CATALOG_MANAGER.CHILDREN.CATALOG_DETAILS,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.CATALOGS.CHILDREN.CATALOGS_VIEW,
        component: <ViewProductCatalog />
    },
    {
        path: ROUTES.CATALOG_MANAGER.CHILDREN.BROCHURE,
        permission: PERMISSIONS.CATALOG_MANAGER.CHILDREN.CATALOGS.CHILDREN.CATALOGS_VIEW,
        component: <Brochure />

    },
    { path: ROUTES.ERRORS.UNAUTHORIZED, component: <Unauthorized /> },
    {
        path: "/account",
        component: <Account />
    }, {
        path: '*',
        component: <Navigate to="/" />
    }
];

export default {
    path: ROUTES.HOME.PATH,
    exact: true,
    component: <Home props={true} />,
}