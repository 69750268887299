import React from "react";
import styled from "styled-components";

const LoadingScreen = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Use viewport height to center vertically */
    margin: 0; /* Remove default body margin */
`;
const LoadingText = styled.h4`text-align: center;`;

export const AppLoader = React.memo(() => (
    <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
    </div></div></div>
));

export const RootLoader = React.memo(() => (
    <LoadingScreen>
        <LoadingText>Loading....</LoadingText>
    </LoadingScreen>
));
