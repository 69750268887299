import React from "react";
import RightSideProfile from "../../../Dashboard/right-side-profile.js";

import AddAudience from "./source.jsx";
import StepperHead from "../../../../components/Common/StepperHead.js";

export default(() => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const stepper = ["template", "Audience", "Campaign", "General Report"];

  return (
    <React.Fragment>
      <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12"><div className="profile">
                {/* <div className="actives">
                    <StepperHead data={stepper} />
                </div> */}
                <AddAudience></AddAudience>
            </div></div>
            <div className="col-md-3">
                {/* <RightSideProfile></RightSideProfile> */}
            </div>
    </div></div></div>
    </React.Fragment>
  );
});

