import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";

import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";
import { useFormik } from "formik";

import Select from "react-select";
import { Modal, FormGroup, Label, Input, Form, Alert, Button, FormFeedback } from "reactstrap";

import { styleReactSelect, themeReactSelect } from '../../../../module/select-methods';
import { errorMessage } from "../../../../module/error-methods";

import MountComponent from "../../../../module/mount-components";
import DeleteModal from '../../../../module/delete-modal.component';

import { storeSubVariantAPI, updateSubVariantAPI, getSubVariantsAPI, deleteSubVariantAPI } from "../../../../api/sub-variant-api";
import { getVariantsAPI  } from "../../../../api/variant.api";

export default React.memo(({ userPermissions, tab }) => {

    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [subVariantsList, setSubVariantsList] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);

    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const optionStatus = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" }
    ]

    const getVariants = async () => {
        setLoading(true);
        await getVariantsAPI().then(({ data }) => {
            if (lodash.isArray(data)) { 
              const options = data.map(({ name, id }) => ({ label: name, value: id }));
              return setVariantOptions([...options]);
            }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    const getSubVariants = async () => {
        setLoading(true);
        await getSubVariantsAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setSubVariantsList([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @useFormiks ------------------------------------------------------------------------------------------------------------------------- */

      const subVariantInitialValues = {
          name: '',
          status: 'Active',
          variantsId: null,
      };

      const subVariantValidation = {
        variantsId: Yup.string().required("Please select Variant."),
        name: Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Only alphabets and spaces are allowed")
          .min(3, "Name must be at least 3 characters")
          .trim()
          .required("Name is required"),
      };

      const updateSubVariantFormik = useFormik({
          enableReinitialize: true,
          initialValues: { ...subVariantInitialValues },
          validationSchema: Yup.object({ ...subVariantValidation }),
          onSubmit: async (values, { resetForm }) => {
              try {
                  setLoading(true); const { variantsId, name, status, id } = values;
                
                  await updateSubVariantAPI(id, { variantsId, name, status });
                  await getSubVariants();

                  toggleNotificationState({ visible: true, message: 'Success! Sub Variant updated successfully.' });
                  resetForm();
              } catch (error) {
                  toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
              } finally { setLoading(false); toggleUpdateModal(false); setModuleState({}); }
          },
      });

      const subVariantFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...subVariantInitialValues },
        validationSchema: Yup.object({ ...subVariantValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                await storeSubVariantAPI({ name: values.name,variantsId: values.variantsId, status: "Active" });
                await getSubVariants();
            
                toggleNotificationState({ visible: true, message: 'Success! Sub Variant added successfully.' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); setModuleState({}); }
        },
      });

  /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteSubVariantAPI(moduleState.id);
            await getVariants();

            toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" deleted successfully !` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateSubVariantFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateSubVariantFormik.setValues({});
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */
      
      const dataTableHandler = () => {
          const columns = [
            {
              label: "Sr.No.",
              field: "sr",
              sort: "asc",
              width: 150,
            },
            {
              label: "Sub Variant",
              field: "subVariant",
              sort: "asc",
              width: 270,
            },
            {
              label: "Variant",
              field: "variant",
              sort: "asc",
              width: 270,
            },
            {
              label: "status",
              field: "status",
              sort: "asc",
              width: 270,
            },
          ];
          const rows = [
            ...subVariantsList.map((details, index) => ({
              sr: ++index,
              subVariant: details.name,
              variant: details.variants.name,
              status: details.status,
              action: (
                <div className="functionalBtn editDataShow">
                    <MountComponent condition={userPermissions.PRODUCT_MASTERS_UPDATE}>
                        <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                            <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                        </button>
                    </MountComponent>
                    <MountComponent condition={userPermissions.PRODUCT_MASTERS_DELETE}>
                        <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                            <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                        </button>
                    </MountComponent>
                </div>
              ),
            })),
          ];
        
          if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
              columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
          }

          return updateDataTable({ columns: [...columns], rows: [...rows] });
      };

    /** @useEffects -------------------------------------------------------------------------------------------------------------------------- */

      useEffect(() => { if (tab === "SUB_VARIANTS_TAB") { getSubVariants(); getVariants(); } }, [tab]);
      useEffect(() => {
          if (tab === "SUB_VARIANTS_TAB") { subVariantFormik.resetForm(); updateSubVariantFormik.resetForm(); }
      }, [tab]);

      useEffect(() => {
          if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
      }, [notificationState.visible]);

      useEffect(() => { dataTableHandler() }, [subVariantsList]);

  /** --------------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <div className="">
        <MountComponent condition={loading}>
            <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
            </div></div></div>
        </MountComponent>

        <Alert color={notificationState.color} isOpen={notificationState.visible}>
            {notificationState.message}
        </Alert>

        <MountComponent condition={userPermissions.PRODUCT_MASTERS_CREATE}>
            <form className="row mainText" onSubmit={subVariantFormik.handleSubmit}>
                <div className="mt-1 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 bg-transparent" style={{ height: "3em" }}>
                  <Select
                    options={variantOptions}
                    placeholder="Select Variant"
                    onChange={({ value }) => {
                      subVariantFormik.setFieldValue("variantsId", value);
                    }}
                    value={
                      variantOptions.find(
                        ({ value }) => value === subVariantFormik.values.variantsId
                      ) ?? null
                    }
                    theme={(theme) =>
                      themeReactSelect(theme, subVariantFormik.errors.variantsId)
                    }
                    styles={{
                      control: (baseStyles, state) =>
                        styleReactSelect(
                          baseStyles,
                          state,
                          subVariantFormik.errors.variantsId
                        ),
                    }}
                  ></Select>
                </div>
                <div
                  className="mt-1 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 bg-transparent"
                  style={{ height: "40px" }}
                >
                  <div className="mainFormSubmit">
                    <div className="error-input">
                      <Input
                        placeholder="Please enter Sub Variant name."
                        className="h-100"
                        name="name"
                        invalid={
                          subVariantFormik.errors.name &&
                          subVariantFormik.touched.name
                        }
                        value={subVariantFormik.values.name}
                        onChange={subVariantFormik.handleChange}
                      ></Input>
                      <FormFeedback
                        valid={
                          !subVariantFormik.errors.name &&
                          !subVariantFormik.touched.name
                        }
                      >
                        {subVariantFormik.errors.name}
                      </FormFeedback>
                    </div>
                    <Button className="btn-sm h-100 w-40">
                      <FaCheck />
                    </Button>
                  </div>
                </div>
            </form>
        </MountComponent>

        <div className="dataTable-hide-extra-props mt-3">
            <MDBDataTable responsive bordered data={dataTable} footer={false} />
        </div>

        <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
              <DeleteModal
                  toggleDeleteModal={toggleDeleteModal}
                  handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                  message={`Are you sure you want to delete ${moduleState.name} ?`}
              ></DeleteModal>
        </Modal>{/* Modal for delete sub variant */}

        <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Update Sub Variant</h5>
                <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
            </div>
            <Form className="needs-validation" onSubmit={updateSubVariantFormik.handleSubmit}>
                <div className="modal-body"><div className="row">
                    <div className="bg-transparent col-md-12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="sub-variant-name">Sub Variant name</Label>
                        <Input
                            name="name"
                            placeholder="Please enter sub variant name"
                            type="text"
                            className="form-control"
                            id="sub-variant-name"
                            onChange={updateSubVariantFormik.handleChange}
                            onBlur={updateSubVariantFormik.handleBlur}
                            value={updateSubVariantFormik.values.name || ""}
                            invalid={updateSubVariantFormik.touched.name && updateSubVariantFormik.errors.name ? true : false}
                        />
                        <MountComponent condition={updateSubVariantFormik.touched.name && updateSubVariantFormik.errors.name}>
                            <FormFeedback type="invalid"> {updateSubVariantFormik.errors.name} </FormFeedback>
                        </MountComponent>
                      </FormGroup>
                    </div>
                    <div className="bg-transparent col">
                        <FormGroup className="mb-3">
                            <Label htmlFor="variant-name">Variant Name</Label>
                            <Select
                                options={variantOptions}
                                id="variant-name"
                                placeholder="Select Status"
                                onChange={({ value }) => { updateSubVariantFormik.setFieldValue("variantsId", value); }}
                                value={variantOptions.find(({ value }) => value === updateSubVariantFormik.values.variantsId) ?? null}
                                theme={(theme) => themeReactSelect(theme, updateSubVariantFormik.touched.variantsId && updateSubVariantFormik.errors.variantsId)}
                                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateSubVariantFormik.touched.variantsId && updateSubVariantFormik.errors.variantsId) }}
                            />
                            <MountComponent condition={updateSubVariantFormik.touched.variantsId && updateSubVariantFormik.errors.variantsId}>
                                <small className="select-error">{updateSubVariantFormik.errors.variantsId}</small>
                            </MountComponent>
                        </FormGroup>
                    </div>
                    <div className="bg-transparent col">
                        <FormGroup className="mb-3">
                            <Label htmlFor="variant-name">Status</Label>
                            <Select
                                options={optionStatus}
                                id="variant-name"
                                placeholder="Select Status"
                                onChange={({ value }) => { updateSubVariantFormik.setFieldValue("status", value); }}
                                value={optionStatus.find(({ value }) => value === updateSubVariantFormik.values.status) ?? null}
                                theme={(theme) => themeReactSelect(theme, updateSubVariantFormik.touched.status && updateSubVariantFormik.errors.status)}
                                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateSubVariantFormik.touched.status && updateSubVariantFormik.errors.status) }}
                            />
                            <MountComponent condition={updateSubVariantFormik.touched.status && updateSubVariantFormik.errors.status}>
                                <small className="select-error">{updateSubVariantFormik.errors.status}</small>
                            </MountComponent>
                        </FormGroup>
                    </div>
                </div></div>
                <div className="modal-footer">
                    <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>Cancel</button>
                    <button type="submit" className="nextMasterModal btn">Update</button>
                </div>
            </Form>
        </Modal>{/* Modal for update sub variant */}
    </div>
  );
});
