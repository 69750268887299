import { server } from '../config/config'

export const storeBrandAPI = (payload = {}) => server({
    method  : 'post',
    url     : `brands`,
    data: { ...payload },
    headers : { "Content-type": "multipart/form-data" }
});

export const getBrandsAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `brands`,
    params  : { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getBrandAPI = (id) => server({
    method  : 'get',
    url     : `brands/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateBrandAPI = (id, payload = {}) => server({
    method: 'put',
    url: `brands/${id}`,
    data: { ...payload },
    headers: { "Content-type": "multipart/form-data" }
});

export const deleteBrandAPI = (id) => server({
    method: 'delete',
    url: `brands/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
