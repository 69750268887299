import React from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, } from "react-headless-accordion";
import { FormGroup, Label, Input, Alert } from "reactstrap";
import { MountCondition } from "../../../../module/mount-components";

export const PermissionNotification = React.memo(({ notificationState, toggleNotificationState }) => (
    <MountCondition condition={notificationState.color === "success"}>
        <MountCondition.True>
            <Alert color={notificationState.color} isOpen={notificationState.visible} toggle={() => toggleNotificationState({ ...notificationState, visible: false })} >
                {notificationState.message}
            </Alert>
        </MountCondition.True>
        <MountCondition.False>
            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>
        </MountCondition.False>
    </MountCondition>
));

export const PermissionCheckBox = React.memo(({ source, parentSource, handlePermission }) => (
    <FormGroup check inline className="permission-checkbox-container">
        <Label check className="permission-checkbox-label">{source.LABEL}</Label>
        <Input name={source.NAME} onChange={handlePermission} checked={source.CHECKED && parentSource.CHECKED} disabled={!parentSource.CHECKED} type="checkbox" className="permission-checkbox-input" />
    </FormGroup>
));

export const PermissionModule = React.memo(({ source, parentSource, children, handlePermission }) => (
    <AccordionItem>
        <div className="nested-accordion-header mt-3">
            <AccordionHeader type="button">
                <h3 className={`accordion-title`}>
                    <FormGroup check inline className="customized-form-group">
                        <Label check>{source.LABEL}</Label>
                        <Input type="checkbox" name={source.NAME} onChange={handlePermission} checked={source.CHECKED && parentSource.CHECKED} disabled={!parentSource.CHECKED} />
                    </FormGroup>
                </h3>
            </AccordionHeader>
        </div>
        <div className="nested-accordion-body">
            <AccordionBody>
                <div className="accordion-body"><div className="permission-module-container">
                    {children}
                </div></div>
            </AccordionBody>
        </div>
    </AccordionItem>
));

export const PermissionContainer = React.memo(({ source, children, handlePermission }) => (
    <Accordion>
        <AccordionItem>
            <div className="accordion-custom-header">
                <section className="d-flex">
                    <AccordionHeader>
                        <h3 className={`accordion-title`}>
                            <FormGroup check inline className="customized-form-group" >
                                <Label check>{source.LABEL}</Label>
                            </FormGroup>
                        </h3>
                    </AccordionHeader>
                    <section className="d-flex align-items-center pe-3">
                        <Input type="checkbox" name={source.NAME} onChange={handlePermission} checked={source.CHECKED} />
                    </section>
                </section>
            </div>
            <div className="custom-accordion-body">
                <AccordionBody>
                    <div className="accordion-body"> {children}</div>
                </AccordionBody>
            </div>
        </AccordionItem>
    </Accordion>
))
