import React, {useState, useEffect} from "react";
import lodash from "lodash";
import { Alert } from "reactstrap";
import Campaign, { SetupCampaign } from "./source.jsx";
import { MountCondition } from "../../../../module/mount-components.jsx";
import getCampaignListAPI from "../../../../api/getCampaignListAPI.js";
import MountComponent from "../../../../module/mount-components";

export default () => {

  const [campaignData, setCampaignData] = useState([])
  const [loading,setLoading] = useState(true);
  const notificationInitialState = { visible: false, message: '', color: '' }
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const campaignDetails = async () => {
    try {
        const { data : response } = await getCampaignListAPI();
        setCampaignData(lodash.isArray(response.data) ? response.data : []);
        
    } catch (error) { toggleNotificationState({ visible: true, color: "danger", message: "Something went wrong" })
    } finally { setLoading(false) }
};

useEffect(() => { campaignDetails(); }, []);

  return (
    <React.Fragment>
      <MountCondition condition={loading}>
        <MountCondition.True>
          <div className="fullscreen-loader"><div className="loader-container">
            <div className="loader"></div>
          </div></div>
        </MountCondition.True>
      </MountCondition>

      <MountComponent condition={notificationState.visible}>
        <Alert className="pt-2 pb-2" color={notificationState.color}>
          { notificationState.message }
        </Alert>
      </MountComponent>

      <div className="page-content"><div className="container-fluid"><div className="row">
        <div className="col-lg-12"><div className="zone"><div className="profile">
          <MountCondition condition="true">
            <MountCondition.True>
              <SetupCampaign></SetupCampaign>
            </MountCondition.True>
            <MountCondition.False>
              <Campaign campaignData={campaignData}></Campaign>
              </MountCondition.False>
          </MountCondition>
        </div></div></div>
        {/* <div className="col-lg-3"><RightSideProfile /></div> */}
      </div></div></div>
    </React.Fragment>
  );
};
