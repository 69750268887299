import * as Yup from 'yup';
import lodash from 'lodash'
import config from '../../../config/config';

export const validationSchema = Yup.object({
    title: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter Title"),
    productType: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Select Product Type"),

    description: Yup.string()
        .min(2, "Too Short!")
        .max(260, "Too Long!")
        .required("Please Enter description"),

    categoryId: Yup.string()
        .required("Please enter Product Category")
        .typeError("Please select Product Category"),

    subCategoryId: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please select product Sub Category"),

    typeId: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please select Type"),

    brandId: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please select Brand "),

    unitId: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please select Unit "),

    tags: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter tags"),

    mrp: Yup.number()
        .required('MRP Price is required'),

    regularPrice: Yup.number()
        .required('Regular Price is required'),

    distributorPrice: Yup.number().required('Distributor Price is required'),

    taxId: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Select Tax"),
});

export const initialValues = {
    title: '',
    productType: '',
    description: '',
    tags: '',
    mrp: 0,
    regularPrice: 0,
    distributorPrice: 0,
    variants : [],
    taxId:   null,
    typeId:  null,
    unitId:  null,
    brandId: null,
    categoryId : null,
    subCategoryId: null
};

export const initialVariantState = {
    imagePreview : null,
    options : [],
    unitId:null,
    mrp:'',
    regularPrice : '',
    distributorPrice:'',
    isPreviousVariantsDelete: false
}

export const initialVariantErrors = {
    options : { error : false, message : '' },
    unitId  : { error : false, message : '' },
    mrp : { error : false, message : '' },
    regularPrice : { error : false, message : '' },
    distributorPrice : { error : false, message : '' },
    image : { error : false, message : '' },
}

export const updateInitialValues = (apiResponse) => {
    try {

        /** separate product variant state --------------------------------------------------------------------------------------*/
        
            const { productVariants, ...responseData } = apiResponse;

            const primaryProductVariant = lodash.get(productVariants,'[0]',{});
            const updatedVariantState   = { ...initialVariantState }

            updatedVariantState.options = lodash.map(primaryProductVariant.productSubVariants,(details) => {
                return ({ value : details.variant?.id, label : details.variant?.name });
            }); 

        /** product details state ---------------------------------------------------------------------------------------------- */

            
            const pickProduct    = lodash.pick(lodash.cloneDeep(responseData), lodash.keys(initialValues));
            const updatedProduct = { ...initialValues, ...pickProduct }

            updatedProduct.imagePreview = `${config.api.base_url}public/products/${responseData.image}`
            updatedProduct.variants = lodash.map(lodash.cloneDeep(productVariants), (variationData) => {
                const response = lodash.pick(variationData, lodash.keys(initialVariantState))
                
                response.id = variationData.id
                response.imagePreview = `${config.api.base_url}public/products/${variationData.image}`
                response.options = lodash.map(variationData.productSubVariants,(details) => {
                    details.subVariant.label = details.subVariant.name; 
                    return details;
                });

                return response;
            });

        return { updatedProduct, updatedVariantState }

    } catch (error) { return { initialValues, initialVariantState }; }
};
