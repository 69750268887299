import React from 'react';

const Mount = (props) => {
    return props.condition ? props.children : <></>;
}

export const MountCondition = React.memo(({ condition, children }) => {
    const [isTrue, isFalse] = React.Children.toArray(children);
    return condition === true ? isTrue : isFalse;
});

MountCondition.True = ({ children }) => children;
MountCondition.False = ({ children }) => children;

export default React.memo(Mount)
