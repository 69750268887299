const ROUTES = {
    HOME: { PATH: '/' },
    AUTHENTICATION: {
        LOGIN: '/login',
        REGISTER: '/register',
        VERIFY_ACCOUNT: '/verify-account'
    },
    ERRORS: {
        UNAUTHORIZED: '/unauthorized'
    },
    ORGANIZATION_DETAILS: {
        PATH: '/organization',
        CHILDREN: {
            PROFILE: '/organization/profile',
            NETWORK_DETAILS: '/organization/masters',
            USER_RIGHTS: '/organization/user-rights',
            ADMIN_USERS: '/organization/users',
            ADMIN_USERS_CREATE: '/organization/users/create',
            ADMIN_USERS_UPDATE: '/organization/users/:id/update'
        }
    },
    NETWORK_MANAGER: {
        PATH: '/network-manager',
        CHILDREN: {
            GEOGRAPHY: '/network-manager/geography',
            LOCATION: '/network-manager/location',
            LOCATION_CREATE: '/network-manager/location/create',
            SALESMAN: '/network-manager/salesman',
            SALESMAN_CREATE: '/network-manager/salesman/create',
            CHANNEL_PARTNER: '/network-manager/channel-partner',
            CHANNEL_PARTNER_CREATE: '/network-manager/channel-partner/create',
            REPORTS: '/network-manager/reports'
        }
    },
    NETWORK_ENGAGE: {
        PATH: '/network-engage',
        CHILDREN: {
            TEMPLATE: '/network-engage/templates',
            TEMPLATE_CREATE: '/network-engage/templates/create',
            TEMPLATE_CLONE: '/network-engage/templates/create/:id',
            AUDIENCE: '/network-engage/audience',
            AUDIENCE_CREATE: '/network-engage/audience/create',
            AUDIENCE_UPDATE: '/network-engage/audience/:id/update',
            CAMPAIGN: '/network-engage/campaign',
            CAMPAIGN_CREATE: '/network-engage/campaign/create',
            CAMPAIGN_VIEW: '/network-engage/campaign/:id/view',
        }
    },
    CATALOG_MANAGER: {
        PATH: '/catalog-manager',
        CHILDREN: {
            PRODUCT_MASTERS: '/catalog-manager/masters',
            PRODUCTS: '/catalog-manager/products',
            CREATE_PRODUCT: '/catalog-manager/products/create',
            UPDATE_PRODUCT: '/catalog-manager/products/:id/update',
            CATALOGS: '/catalog-manager/products/catalogs',
            CATALOG_DETAILS: '/catalog-manager/products/catalogs/:id',
            BROCHURE: '/catalog-manager/brochure',
        }
    }
}

export default ROUTES;
