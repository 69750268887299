import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';

export const LevelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top:1em;
`;

export const LevelIndex = styled.div`
    background-color: ${({ color }) => { return color; }};
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    text-align:center;
    color:white;
    padding:1px;
    font-size:14px;
`;

export const LevelLink = styled(Link)`
    font-weight:600;
    text-decoration: none;
    color: #0022a0;
`;

export const LevelText = styled.p`
    font-weight:600;
    text-decoration: none;
    color: #495057;
    margin-bottom:0px;
`;

export const ProgressCircle = React.memo(({ count }) => (
    <div className="progressBar"><div className="container">
        <div className="row"><div className="progress" data-percentage={count}>
            <span className="progress-left">
                <span className="progress-bar"></span>
            </span>
            <span className="progress-right">
                <span className="progress-bar"></span>
            </span>
            <div className="progress-value">
                <div>{count}%</div>
            </div>
        </div></div>
    </div></div>
));
