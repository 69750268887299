import config,{server} from '../config/config'

export const deleteSalesHierarchy = async (id) => {
    
    return await server({
        method  : 'delete',
        url     : `sales-hierarchy/${id}`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default deleteSalesHierarchy