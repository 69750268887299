import React from "react";
import GoogleMap from "../../../../module/google-map-components";

export default React.memo((props) => {
    return (
        <React.Fragment>
            <div className="mapsHeading"><div className="row">
                <div className="col-lg-3 col-xl-3 col-md-4 col-sm-12 col-xs-12"><h4>Salesman</h4></div>
                <div className="d-flex justify-content-end col-lg-9 col-xl-9 col-md-8 col-sm-12 col-xs-12">
                    <button className="btn btn-sm btn-primary ps-3 pe-3" onClick={() => props.toggleMapPreview(false)}>Back</button>
                </div>
            </div></div>

            <div className="mt-3 mb-3"><div className="row"><div className="col-lg-12">
                <GoogleMap marks={[...props.googleMapMarks]} center={props.mapCenter}></GoogleMap>
            </div></div></div>
        </React.Fragment>
    )
});
