import React from "react";

function StepperHead({ data, hide }) {
  const ActiveURL = window.location.href;
  const routName = ActiveURL.split("/");

  const str = routName[3];
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  const found = data.findLast((element) => element);
  const changeActiveClass = (d) => {
    if ( d <= data.indexOf(hide ? "geography" : routName[3])) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="steperSection">
        {hide ? <h3>Define Your Geography</h3>: <h3>Setup Your {str2}</h3> }
        <div className="row" style={{ justifyContent: "center" }}>
          {data.map((x, i) => {
            return (
              <div className="col-md-2 mobRespo" key={i}>
                <div
                  className={`stepnum  ${
                    found === x
                      ? ""
                      : `line ${changeActiveClass(i) ? "activeLine" : ""}`
                  }`}
                >
                  <span className={` ${changeActiveClass(i) ? "active" : ""}`}>
                    {i + 1}
                  </span>
                  <p>{x}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default StepperHead;
