import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import store from "store";

import * as Yup from "yup";

import { useDropzone } from 'react-dropzone';
import { Alert } from "reactstrap";
import { useFormik } from "formik";

import MountComponent, { MountCondition } from "../../../../module/mount-components";
import { xlsxExport, xlsxImport } from "../../../../util/xlsx.util.js";
import { errorMessage, toastErrorMessage } from "../../../../module/error-methods";

import { checkPermissions } from "../../../../api/organization-users.api.js";

import { getDivisionsAPI } from "../../../../api/division.api.js";
import { getNetworkLocationsAPI } from "../../../../api/network-location.api.js";
import { getSalesHierarchiesAPI } from "../../../../api/sales-hierarchy.api.js";

import { getNetworkSalesmenImportInfoAPI, uploadMultipleNetworkSalesmenAPI } from "../../../../api/network-salesman.api.js";
import { getNetworkSalesmenDataAPI, updateNetworkSalesmenAPI, deleteNetworkSalesmenAPI } from "../../../../api/network-salesman.api.js";

import PERMISSIONS from '../../../../routes/permissions.js';

import Info from "./info";
import Table from "./table";
import XlSXImport from "./xlsx-import.jsx";
import GoogleMap from "./google-map.jsx";


export default React.memo(() => {
    const { CHILDREN: { SALESMEN } } = PERMISSIONS.NETWORK_MANAGER;

    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    /** @google @map @props ------------------------------------------------------------------------------------------- */

    const [mapPreview, toggleMapPreview] = useState(false);
    const [googleMapMarks, setGoogleMapMarks] = useState([]);
    const [mapCenter, setCenter] = useState({ lat: 24.5854, lng: 73.7125 });

    /** @modals ------------------------------------------------------------------------------------------------------- */

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);
    const [importModal, toggleImportModal] = useState(false);

    /** --------------------------------------------------------------------------------------------------------------- */


    const [salesmenDetails, setSalesmenDetails] = useState({});
    const [salesmenData, setSalesmenData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [apiStatus, setApiStatus] = useState('pending');
    const [filters, updateFilters] = useState({ divisionId: null, designationId: null, locationId: null });
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

    const [userPermissions, setUserPermissions] = useState({
        SALESMEN_CREATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        SALESMEN_VIEW: userDetails.userRole === "ORGANIZATION" ? true : false,
        SALESMEN_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        SALESMEN_DELETE: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    /** @options -------------------------------------------------------------------------------------------------------------------- */

    const [divisionOptions, setDivisionOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    /** @apis ----------------------------------------------------------------------------------------------------------------------- */

    const getDivisionOptions = async () => {
        await getDivisionsAPI().then(({ data }) => {
            if (lodash.isArray(data)) {
                const selectOptions = { label: '--Select Division--', value: null };
                const options = data.map(({ name, id }) => ({ label: name, value: id }));

                return setDivisionOptions(lodash.concat(selectOptions, options));
            }
        }).catch((error) => toastErrorMessage(error));
    };

    const getDesignationOptions = async () => {
        const divisionId = updateSalesmanFormik.values.divisionId ?? filters.divisionId
        if (!divisionId) { return setDesignationOptions([]); }

        setLoading(true); updateFilters({ ...filters, designationId: null });
        await getSalesHierarchiesAPI({ divisionId }).then(({ data }) => {
            if (lodash.isArray(data)) {
                const selectOptions = { label: '--Select Hierarchy--', value: null };
                const options = data.map(({ name, id }) => ({ label: name, value: id }));

                return setDesignationOptions(lodash.concat(selectOptions, options));
            }
        }).catch((error) => toastErrorMessage(error));
        setLoading(false);
    };

    const getLocationOptions = async () => {
        const divisionId = updateSalesmanFormik.values.divisionId ?? filters.divisionId
        if (!divisionId) { return setLocationOptions([]); }

        setLoading(true); updateFilters({ ...filters, locationId: null });
        await getNetworkLocationsAPI({ divisionId }).then(({ data }) => {
            if (lodash.isArray(data)) {
                const selectOptions = { label: '--Select Location--', value: null };
                const options = data.map(({ location, id }) => ({ label: location, value: id }));

                return setLocationOptions(lodash.concat(selectOptions, options));
            }
        }).catch((error) => toastErrorMessage(error));
        setLoading(false);
    };

    const getNetworkSalesmen = async () => {
        try {
            const { data } = await getNetworkSalesmenDataAPI({ ...filters });
            if (lodash.isArray(data)) { setSalesmenData([...data]) }
        } catch (error) {
            toastErrorMessage(error)
        } finally { setLoading(false); setApiStatus('COMPLETED'); }
    };

    const getPermissions = async () => {
        const modulePermissions = SALESMEN.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @handlers ------------------------------------------------------------------------------------------------------------------ */

    const handleXLSXExport = useCallback(() => {
        const response = lodash.map(salesmenData, (details, index) => ({
            "Sr. No.": ++index,
            "First Name": details.firstName,
            "Last Name": details.lastName,
            "Mobile": details.mobileNo,
            "Email": details.email,
            "SBU": details?.divisions?.name,
            "Designation": details?.salesHierarchy?.name,
            "Location": details?.networkLocation?.location,
        }));

        xlsxExport(response, "network-salesman.xlsx");
    });

    const handleGoogleMap = useCallback(() => {

        const primaryLocation = lodash.get(salesmenData, `[0]['networkLocation']`, {});
        const salesmenLocation = lodash.map(salesmenData, ({ networkLocation: location }, index) => ({
            key: ++index,
            title: location.location,
            position: { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) },
        }));

        if (lodash.isNaN(primaryLocation.latitude) && lodash.isNaN(primaryLocation.longitude)) {
            const latitude = parseFloat(primaryLocation.latitude);
            const longitude = parseFloat(primaryLocation.longitude);
            setCenter({ lat: latitude, lng: longitude });
        }

        setGoogleMapMarks([...salesmenLocation]);
        toggleMapPreview(true);
    });

    const handleDeleteCallback = useCallback((details = {}) => {
        setSalesmenDetails({ ...details });
        toggleDeleteModal(true);
    });

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteNetworkSalesmenAPI(salesmenDetails.id);
            await getNetworkSalesmen();

            toggleNotificationState({ visible: true, color: 'success', message: 'Your salesman has been deleted successfully!' });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setSalesmenDetails({}) }
    });

    const handleUpdateCallback = useCallback((data = {}) => {
        const updateData = lodash.pick(data, lodash.keys(updateSalesmanInitialValues));

        setSalesmenDetails({ ...data });
        toggleUpdateModal(true);
        return updateSalesmanFormik.setValues({ ...updateData });
    });

    const handleFilter = useCallback((option, properties) => {
        return updateFilters({ ...filters, [properties.name]: option.value });
    }, [filters]);

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @formik -------------------------------------------------------------------------------------------------------------------- */

    const updateSalesmanInitialValues = {
        firstName: null,
        lastName: null,
        mobileNo: null,
        email: null,
        designation: null,
        location: null,
        divisionId: null,
    }

    const updateSalesmanValidation = {
        firstName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter firstName"),
        lastName: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter lastName "),
        mobileNo: Yup
            .string()
            .required('Mobile number is required.')
            .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits.')
            .test('positive', 'Mobile number must be positive.', (val) => parseInt(val) > 0),
        email: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter email "),
        designation: Yup.string()
            .max(50, "Too Long!")
            .required("Please select designation "),
        location: Yup.string()
            .max(50, "Too Long!")
            .required("Please select location "),
        divisionId: Yup.string()
            .max(50, "Too Long!")
            .required("Please select division "),
    }

    const updateSalesmanFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...updateSalesmanInitialValues },
        validationSchema: Yup.object({ ...updateSalesmanValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {

                setLoading(true);

                await updateNetworkSalesmenAPI(salesmenDetails.id, { ...values })
                await getNetworkSalesmen();

                toggleNotificationState({ visible: true, color: 'success', message: 'Your salesman has been successfully updated!' });
                resetForm();

            } catch (error) {
                toastErrorMessage(error);
            } finally { setLoading(false); toggleUpdateModal(false); setSalesmenDetails({}); }
        },
    });

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);

    useEffect(() => { getDivisionOptions() }, []);

    useEffect(() => { getDesignationOptions() }, [updateSalesmanFormik.values.divisionId]);
    useEffect(() => { getLocationOptions() }, [updateSalesmanFormik.values.divisionId]);

    useEffect(() => { getDesignationOptions(); }, [filters.divisionId]);
    useEffect(() => { getLocationOptions(); }, [filters.divisionId]);
    useEffect(() => { setLoading(true); getNetworkSalesmen(); }, [filters.divisionId, filters.designationId, filters.locationId]);

    useEffect(() => {
        if (notificationState.visible) {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000);
        }
    }, [notificationState.visible]);

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /**  @import ------------------------------------------------------------------------------------------------------------------- */

    const [xlsxData, updateXLSXData] = useState([]);
    const [xlsxNotificationState, toggleXLSXNotificationState] = useState({ visible: false, color: "danger" });

    const xlsxDataDataTable = {
        columns: [
            { label: "Sr.No.", field: "srn", sort: "asc", width: 100, },
            { label: "First Name", field: "firstName", sort: "asc", width: 300, },
            { label: "Last Name", field: "lastName", sort: "asc", width: 300, },
            { label: "Mobile", field: "mobileNo", sort: "asc", width: 270, },
            { label: "Email", field: "email", sort: "asc", width: 150, },
            { label: "SBU", field: "divisionName", sort: "asc", width: 100, },
            { label: "Designation", field: "designationName", sort: "asc", width: 100, },
            { label: "Location", field: "locationName", sort: "asc", width: 150, },
            { label: "Action", field: "action", sort: "disabled", width: 100, },
        ],
        rows: [...xlsxData.map((data, index) => ({
            srn: index + 1,
            firstName: data.firstName,
            lastName: data.lastName,
            mobileNo: data.mobileNo,
            email: data.email,
            divisionName: data.divisionName,
            designationName: data.designationName,
            locationName: data.locationName,
            action: (
                <button className="btn btn-group" onClick={() => { handleUploadModalDelete(data.uuid); }}>
                    <i className="fas fa-trash-alt" style={{ color: "red" }}></i>
                </button>
            ),
        })),
        ],
    };

    const handleUploadModalDelete = useCallback((deleteUuid) => {
        const updatedData = lodash.filter(lodash.clone(xlsxData), ({ uuid }) => deleteUuid !== uuid);

        updateXLSXData(lodash.clone(updatedData))
    }, [xlsxData]);

    const handleUploadModal = useCallback(() => {
        updateXLSXData([]); toggleImportModal(false);
        toggleXLSXNotificationState({ visible: false, color: "danger", message: '' });
    });

    const handleUploadSubmit = useCallback(async () => {
        try {

            if (lodash.isEmpty(xlsxData)) {
                return toggleXLSXNotificationState({ visible: true, message: 'Please upload file.' });
            }

            const payload = lodash.map(xlsxData, (data) => lodash.omit(data, ['uuid', 'designationName', 'divisionName', 'locationName']));
            const payloadValidation = Yup.array().of(Yup.object({ ...updateSalesmanValidation }));

            // const locationsWithDetails    = lodash.filter(payload, data => lodash.every(data, value => value !== null));
            // const locationsWithoutDetails = lodash.filter(payload, data => lodash.some(data, lodash.isNull) );
            
            // console.clear();
            // console.log('payload with details:','\n\n',locationsWithDetails);
            // console.log('payload without details:','\n\n',locationsWithoutDetails);

            // return void 0;

            await payloadValidation.validate(payload);
            await uploadMultipleNetworkSalesmenAPI(payload);
            await getNetworkSalesmen();

            toggleXLSXNotificationState({ visible: true, color: 'success', message: 'Success! The network salesman\'s imported successfully!.' });
            toggleXLSXNotificationState({ visible: false, color: '', message: '' });
            toggleImportModal(false);

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Invalid Data!' });
            } else {
                toggleXLSXNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            }
        } finally { updateXLSXData([]); setLoading(false); }

    }, [xlsxData]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            return toggleXLSXNotificationState({ visible: true, message: 'Please select one .xlsx file.' });
        };

        const file = acceptedFiles[0];
        const reader = new FileReader();
        const requiredFields = ["First Name", "Last Name", "Email", "Mobile", "Sales Designation ID", "Location ID", "Division ID"];
        const preparePayload = (data) => lodash.map(data, (data, index) => ({
            uuid: `uuid-${index * 2}`,
            firstName: data["First Name"],
            lastName: data["Last Name"],
            email: data["Email"],
            mobileNo: String(data["Mobile"]),
            designation: data["Sales Designation ID"],
            location: data["Location ID"],
            divisionId: data["Division ID"]
        }));

        reader.onload = async (e) => {
            const { sheetData, headerRow } = xlsxImport(e.target.result);

            const missingFields = lodash.difference(requiredFields, headerRow);
            const extraFields = lodash.difference(headerRow, requiredFields);
            const unfilledRows = sheetData.filter((data) => requiredFields.some((field) => !data.hasOwnProperty(field)));

            if (lodash.size(missingFields) > 0) { toggleXLSXNotificationState({ visible: true, message: 'Wrong file uploaded.' }); }
            if (lodash.size(extraFields) > 0) { toggleXLSXNotificationState({ visible: true, message: 'Wrong file uploaded. Extra fields' }); }
            if (lodash.size(sheetData) === 0) { toggleXLSXNotificationState({ visible: true, message: 'Please fill the sheet before upload.' }); }
            if (lodash.size(unfilledRows) > 0) {
                return toggleXLSXNotificationState({ visible: true, message: 'Some rows have unfilled fields. Please check the data.' });
            }

            const duplicateRows = lodash(sheetData).groupBy((data) => JSON.stringify(data)).filter((group) => group.length > 1).value();

            if (lodash.size(duplicateRows) > 0) {
                return toggleXLSXNotificationState({ visible: true, message: 'Some rows have matching fields with other rows. Please check the data.' });
            }

            try {

                const payload = lodash.map(preparePayload(sheetData), (data) => lodash.omit(data, 'uuid'));
                const response = await getNetworkSalesmenImportInfoAPI(payload);

                if (lodash.size(response.data.result) === 0) {
                    return toggleXLSXNotificationState({ visible: true, message: 'Kindly check the data and reupload' });
                }
                else { return updateXLSXData(response.data.result); }

            } catch (error) { toggleXLSXNotificationState({ visible: true, message: error.message }); }
        };

        reader.readAsBinaryString(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} toggle={() => toggleNotificationState({ visible: false, color: '', message: '' })} >
                {notificationState.message}
            </Alert>

            <MountComponent condition={apiStatus === "COMPLETED"}>
                <MountCondition condition={lodash.isEmpty(salesmenData) && lodash.every(filters, prop => lodash.isNull(prop))}>
                    <MountCondition.True>
                        <Info userPermissions={userPermissions}></Info>
                    </MountCondition.True>
                    <MountCondition.False>

                        <MountCondition condition={mapPreview}>
                            <MountCondition.True>
                                <GoogleMap
                                    toggleMapPreview={toggleMapPreview}
                                    googleMapMarks={googleMapMarks}
                                    mapCenter={mapCenter}
                                ></GoogleMap>
                            </MountCondition.True>
                            <MountCondition.False>
                                <Table
                                    userPermissions={userPermissions}
                                    updateSalesmanFormik={updateSalesmanFormik}

                                    salesmenData={salesmenData}
                                    salesmenDetails={salesmenDetails}

                                    deleteModal={deleteModal}
                                    toggleDeleteModal={toggleDeleteModal}
                                    handleDeleteCallback={handleDeleteCallback}
                                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}

                                    updateModal={updateModal}
                                    toggleUpdateModal={toggleUpdateModal}
                                    handleUpdateCallback={handleUpdateCallback}

                                    toggleImportModal={toggleImportModal}

                                    handleXLSXExport={handleXLSXExport}
                                    handleGoogleMap={handleGoogleMap}

                                    divisionOptions={divisionOptions}
                                    designationOptions={designationOptions}
                                    locationOptions={locationOptions}

                                    filters={filters}
                                    handleFilter={handleFilter}
                                ></Table>
                            </MountCondition.False>
                        </MountCondition>

                        <XlSXImport
                            importModal={importModal}
                            handleUploadModal={handleUploadModal}
                            handleUploadSubmit={handleUploadSubmit}

                            xlsxData={xlsxData}
                            xlsxDataDataTable={xlsxDataDataTable}

                            xlsxNotificationState={xlsxNotificationState}
                            toggleXLSXNotificationState={toggleXLSXNotificationState}

                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                        ></XlSXImport>

                    </MountCondition.False>
                </MountCondition>
            </MountComponent>
        </React.Fragment>);
});