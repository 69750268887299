import React from "react";
import { Link } from "react-router-dom";

import image from "../../assets/images/network-engage-home.png";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

import { calculateLevelsCount, useApiData } from "./start-guide-methods";

export default React.memo(() => {
    const { NAME, CHILDREN: { TEMPLATE_MASTER, AUDIENCE_MASTER, CAMPAIGN_MASTER } } = PERMISSIONS.NETWORK_ENGAGE;
    const progressSteps = {
        0:  { label: 'Setup Template',target: TEMPLATE_MASTER.NAME, path: ROUTES.NETWORK_ENGAGE.CHILDREN.TEMPLATE, activeClass: '' },
        25: { label: 'Resume', target: AUDIENCE_MASTER.NAME, path: ROUTES.NETWORK_ENGAGE.CHILDREN.AUDIENCE, activeClass: '' },
        60: { label: 'Resume', target: CAMPAIGN_MASTER.NAME, path: ROUTES.NETWORK_ENGAGE.CHILDREN.CAMPAIGN, activeClass: '' },
        75: { label: 'Completed', target: NAME, path: ROUTES.NETWORK_ENGAGE.PATH, activeClass: '' },
    };

    const { array: apiData }  = useApiData({ module: NAME });
    const currentProgressStep = calculateLevelsCount(apiData, progressSteps);
    const currentStepDetails  = progressSteps[currentProgressStep] ?? null;

  return (
    <div className="row">
        <div className="col-md-4">
          <div className="netImage"><img src={image} /></div>
        </div>
        <div className="col-md-8">
            <div className="netContent">
                <h4 className="text-primary"></h4>
                <h2 className="text-primary">
                    <strong>
                        <span className="pt-2 head-network-engage">Easily talk to any or all of your Network</span>
                    </strong>
                </h2>
                <ul className="hide-bullet-catalog">
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>Single Panel for all communication needs - WhatsApp, Email, SMS</li>
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>Send messages to entire network at same time, anytime</li>
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>No limits on number of messages</li>
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>Run campaigns, schedule campaigns across multi communication channels in one click</li>
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>Ready to Use Templates</li>
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>Instant Information update to entire network</li>
                    <li className="mt-1 text-secondary mt-2"><strong><i aria-hidden="true" class="far fa-comment-dots text-warning font-weight-bold mx-2"></i></strong>Easy & Efficient way to connect</li>
                </ul>
                 
            </div>
            <Link to={currentStepDetails?.path} className="btn btn-primary networkBtn">
                {currentStepDetails?.label}
            </Link>
        </div>
    </div>
  );
});
