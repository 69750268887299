import { server } from '../config/config';

export const getChannelPartnersAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `network-channel-partner`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getChannelPartnerAPI = (id) => server({
    method  : 'get',
    url     : `network-channel-partner/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getChannelPartnerImportInfoAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-channel-partner/info`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
export const uploadMultipleChannelPartnerAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-channel-partner/multiple`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const storeChannelPartnerAPI = (payload = {}) => server({
    method  : 'post',
    url     : `network-channel-partner`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const updateChannelPartnerAPI = (id,payload = {}) => server({
    method  : 'put',
    url     : `network-channel-partner/${id}`,
    data    : { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const deleteChannelPartnerAPI = (id) => server({
    method  : 'delete',
    url     : `network-channel-partner/${id}`,
    headers : { "Content-type": "application/json; charset=UTF-8" }
});
