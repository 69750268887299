import React, { useCallback, useEffect, useState } from "react"
import lodash from "lodash";
import store from "store";
import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";
import { Link } from "react-router-dom";

import { PermissionCheckBox, PermissionContainer, PermissionModule, PermissionNotification } from "./components";

import getUserRolesAPI, { storeUserRolePermissionsAPI, getUserRolePermissionsAPI } from '../../../../api/userRoleAPI'
import { storeOrganizationModule } from "../../../../api/organizationModuleApi";

import MountComponent, { MountCondition } from "../../../../module/mount-components";
import { styleReactSelect, themeReactSelect } from "../../../../module/select-methods";
import { errorMessage } from "../../../../module/error-methods";

import ROUTES from "../../../../routes/routes";
import PERMISSIONS from "../../../../routes/permissions";
import { checkPermissions } from "../../../../api/organization-users.api";

export default React.memo(() => {
    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const { NAME, CHILDREN: { USER_RIGHTS } } = PERMISSIONS.QUICK_START_GUIDE;

    const [loading, setLoading] = useState(false);

    const notificationInitialState = { visible: false, message: '', color: '' }
    const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

    const [permissions, updatePermissions] = useState({ ...PERMISSIONS });
    const [userRoles, updateUserRoles] = useState([]);

    const [givenPermissions, setGivenPermissions] = useState({
        USER_RIGHTS_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    const getPermissions = async () => {
        const modulePermissions = PERMISSIONS.QUICK_START_GUIDE.CHILDREN.USER_RIGHTS.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setGivenPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);

    // @apis -----------------------------------------------------------------------------------------------------------------------------

    const getUserRoles = async () => {
        try {
            setLoading(true);
            const { data: response } = await getUserRolesAPI();

            if (lodash.isArray(response.data)) {
                updateUserRoles([...response.data.map(({ id, name }) => ({ label: name, value: id }))])
            }
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    };

    const getUserRolePermissions = async () => {

        if (!userPermissions.values.userRoleId) {
            userPermissions.setFieldValue('permissions', []);
            userPermissions.setErrors({});
            userPermissions.setTouched({});
            return updatePermissions(lodash.cloneDeep({ ...PERMISSIONS }));
        }

        try {

            const { data: response } = await getUserRolePermissionsAPI(userPermissions.values.userRoleId);

            if (lodash.isEmpty(response.data)) {
                userPermissions.setErrors({});
                userPermissions.setTouched({});
                userPermissions.setFieldValue('permissions', []);
                return updatePermissions(lodash.cloneDeep({ ...PERMISSIONS }));
            }

            const activePermissions = lodash.keyBy(response.data, 'name');

            const updatePermissionsRecursive = (permissions) => lodash.mapValues(permissions, (permission) => {
                if (lodash.has(activePermissions, permission.NAME)) { permission.CHECKED = Boolean(activePermissions[permission.NAME]['permission']) }
                if (lodash.isObject(permission.CHILDREN)) { permission.CHILDREN = updatePermissionsRecursive(permission.CHILDREN); }
                return permission;
            });

            const payload = (permissions) => lodash.map(permissions, (permission) => {
                const response = {}
                response.name = permission.name;
                response.id = permission.id;
                response.permission = Boolean(permission.permission);
                return response;
            });

            const updatedPermissions = updatePermissionsRecursive(lodash.cloneDeep(permissions));

            updatePermissions(lodash.cloneDeep(updatedPermissions)); // update the permissions hierarchy 
            userPermissions.setFieldValue('permissions', payload(response.data)); // update the payload 

        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            updatePermissions(lodash.cloneDeep({ ...PERMISSIONS })); // update the permissions hierarchy 
        } finally { setLoading(false); }
    }

    const storeUserRightPermissions = async (data, { resetForm }) => {
        try {

            if (!givenPermissions.USER_RIGHTS_UPDATE) {
                return toggleNotificationState({ visible: true, color: 'danger', message: "Warning! you are not authorized to update." });
            }

            setLoading(true);
            const { userRoleId, permissions } = data;
            await storeUserRolePermissionsAPI(userRoleId, { permissions });
            await storeOrganizationModule({ module: NAME, moduleLevel: USER_RIGHTS.NAME });

            updatePermissions(lodash.cloneDeep({ ...PERMISSIONS }));
            toggleNotificationState({ visible: true, color: 'success', message: "Permissions updated successfully. Changes have been applied." });
            resetForm();
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    }
    // -----------------------------------------------------------------------------------------------------------------------------------

    const userSchema = Yup.object({
        userRoleId: Yup.string().required('Please select user role.'),
        permissions: Yup.array().of(Yup.object({
            id: Yup.string(),
            name: Yup.string().required('Permission name is required'),
            permission: Yup.boolean().required('Permission value is required'),
        })).min(1, 'At least one permission is required').required('Permissions are required'),
    });
    const userPermissions = useFormik({
        initialValues: { userRoleId: null, permissions: [] },
        validationSchema: userSchema,
        onSubmit: storeUserRightPermissions,
    });

    const handlePermission = useCallback(({ target }) => {

        try {

            const updateChildrenPermissionsRecursive = (childPermissions, target) => lodash.mapValues(childPermissions, (childPermission) => {
                if (target.checked === false) { childPermission.CHECKED = target.checked; }
                if (target.checked === true) { childPermission.CHECKED = true; }
                if (lodash.isObject(childPermission.CHILDREN)) {
                    childPermission.CHILDREN = updateChildrenPermissionsRecursive(childPermission.CHILDREN, target);
                }
                return childPermission;
            });

            const updatePermissionsRecursively = (permissions, target) => lodash.mapValues(permissions, (permission) => {
                if (permission.NAME === target.name) {
                    permission.CHECKED = target.checked;
                    permission.CHILDREN = updateChildrenPermissionsRecursive(permission.CHILDREN, target);
                } else if (lodash.isObject(permission.CHILDREN)) {
                    permission.CHILDREN = updatePermissionsRecursively(permission.CHILDREN, target);
                }
                return permission;
            });

            const getPermissionsFromPermissionTree = (permissions, payload) => lodash.mapValues(permissions, (permission) => {

                if (lodash.has(permission, 'CHECKED')) { payload.push({ name: permission.NAME, permission: permission.CHECKED }); }

                if (lodash.isObject(permission.CHILDREN)) { permission.CHILDREN = getPermissionsFromPermissionTree(permission.CHILDREN, payload); }

                return permission;
            });

            const userPermissionsPayload = [];
            const response = updatePermissionsRecursively(lodash.cloneDeep(permissions), target);

            getPermissionsFromPermissionTree(response, userPermissionsPayload);
            updatePermissions(lodash.cloneDeep(response)); // update the permissions hierarchy 

            userPermissions.setFieldValue('permissions', lodash.cloneDeep(userPermissionsPayload)); // create a payload

        } catch (error) { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); }

        return true

    }, [permissions]);

    /** @useEffects ----------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { getUserRoles() }, []);
    useEffect(() => { getUserRolePermissions() }, [userPermissions.values.userRoleId]);
    useEffect(() => {
        if (notificationState.visible && notificationState.color === "success") {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000);
        }
        return () => null;
    }, [notificationState.visible]);

    useEffect(() => {
        const hasError = userPermissions.errors.permissions && userPermissions.touched.permissions;
        const error = userPermissions.errors.permissions;
        const errorState = { visible: false, color: '', message: '' }

        if (hasError) {
            errorState.visible = true;
            errorState.color = "danger";
            errorState.message = error;
        }

        toggleNotificationState({ ...errorState });
    }, [userPermissions.errors.permissions, userPermissions.touched.permissions]);

    /** ----------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountCondition condition={loading}>
                <MountCondition.True>
                    <div className="fullscreen-loader"><div className="loader-PermissionContainer">
                        <div className="loader"></div>
                    </div></div>
                </MountCondition.True>
            </MountCondition>
            <div className="user-right-form">
                {/* <pre>{JSON.stringify(userPermissions.values, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify(userPermissions.errors, null, 2)}</pre> */}

                <div className="justify-content-center row"><div className="col-md-5"><div className="mb-4">
                    <Select
                        value={lodash.find(userRoles, ({ value }) => value === userPermissions.values.userRoleId) ?? null}
                        onChange={({ value }) => userPermissions.setFieldValue('userRoleId', value)}
                        options={userRoles}
                        className="" placeholder="Select User Role"
                        theme={(theme) => themeReactSelect(theme, userPermissions.errors.userRoleId && userPermissions.touched.userRoleId)}
                        styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, userPermissions.errors.userRoleId && userPermissions.touched.userRoleId) }}
                    ></Select>
                    <MountComponent condition={userPermissions.errors.userRoleId && userPermissions.touched.userRoleId}>
                        <p style={{ color: "#f46a6a", fontSize: "10px" }}>{userPermissions.errors.userRoleId}</p>
                    </MountComponent>
                </div></div></div>
                <PermissionNotification
                    notificationState={notificationState}
                    toggleNotificationState={toggleNotificationState}
                ></PermissionNotification>


                <div className="accordion-user-form"><div className="row">
                    <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                        <PermissionContainer source={permissions.QUICK_START_GUIDE} handlePermission={handlePermission}>
                            <PermissionModule parentSource={permissions.QUICK_START_GUIDE} source={permissions.QUICK_START_GUIDE.CHILDREN.ORGANIZATION_DETAILS} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.ORGANIZATION_DETAILS} source={permissions.QUICK_START_GUIDE.CHILDREN.ORGANIZATION_DETAILS.CHILDREN.ORGANIZATION_DETAILS_UPDATE}></PermissionCheckBox>
                            </PermissionModule>

                            <PermissionModule parentSource={permissions.QUICK_START_GUIDE} source={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS} source={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN.NETWORK_DETAILS_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS} source={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN.NETWORK_DETAILS_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS} source={permissions.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN.NETWORK_DETAILS_DELETE}></PermissionCheckBox>
                            </PermissionModule>

                            <PermissionModule parentSource={permissions.QUICK_START_GUIDE} source={permissions.QUICK_START_GUIDE.CHILDREN.USER_RIGHTS} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.USER_RIGHTS} source={permissions.QUICK_START_GUIDE.CHILDREN.USER_RIGHTS.CHILDREN.USER_RIGHTS_UPDATE}></PermissionCheckBox>
                            </PermissionModule>

                            <PermissionModule parentSource={permissions.QUICK_START_GUIDE} source={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS} source={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS.CHILDREN.ADMIN_USERS_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS} source={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS.CHILDREN.ADMIN_USERS_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS} source={permissions.QUICK_START_GUIDE.CHILDREN.ADMIN_USERS.CHILDREN.ADMIN_USERS_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                        </PermissionContainer>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                        <PermissionContainer source={permissions.NETWORK_MANAGER} handlePermission={handlePermission}>
                            <PermissionModule parentSource={permissions.NETWORK_MANAGER} source={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} source={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY.CHILDREN.GEOGRAPHY_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} source={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY.CHILDREN.GEOGRAPHY_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} source={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY.CHILDREN.GEOGRAPHY_DELETE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY} source={permissions.NETWORK_MANAGER.CHILDREN.GEOGRAPHY.CHILDREN.GEOGRAPHY_MAP}></PermissionCheckBox>
                            </PermissionModule>

                            <PermissionModule parentSource={permissions.NETWORK_MANAGER} source={permissions.NETWORK_MANAGER.CHILDREN.LOCATION} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.LOCATION} source={permissions.NETWORK_MANAGER.CHILDREN.LOCATION.CHILDREN.LOCATION_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.LOCATION} source={permissions.NETWORK_MANAGER.CHILDREN.LOCATION.CHILDREN.LOCATION_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.LOCATION} source={permissions.NETWORK_MANAGER.CHILDREN.LOCATION.CHILDREN.LOCATION_DELETE}></PermissionCheckBox>
                            </PermissionModule>

                            <PermissionModule parentSource={permissions.NETWORK_MANAGER} source={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN} source={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN.CHILDREN.SALESMEN_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN} source={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN.CHILDREN.SALESMEN_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN} source={permissions.NETWORK_MANAGER.CHILDREN.SALESMEN.CHILDREN.SALESMEN_DELETE}></PermissionCheckBox>
                            </PermissionModule>

                            <PermissionModule parentSource={permissions.NETWORK_MANAGER} source={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER} source={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER.CHILDREN.CHANNEL_PARTNER_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER} source={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER.CHILDREN.CHANNEL_PARTNER_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER} source={permissions.NETWORK_MANAGER.CHILDREN.CHANNEL_PARTNER.CHILDREN.CHANNEL_PARTNER_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                        </PermissionContainer>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                        <PermissionContainer source={permissions.NETWORK_ENGAGE} handlePermission={handlePermission}>
                            <PermissionModule parentSource={permissions.NETWORK_ENGAGE} source={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER.CHILDREN.TEMPLATE_MASTER_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER.CHILDREN.TEMPLATE_MASTER_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.TEMPLATE_MASTER.CHILDREN.TEMPLATE_MASTER_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                            <PermissionModule parentSource={permissions.NETWORK_ENGAGE} source={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER.CHILDREN.AUDIENCE_MASTER_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER.CHILDREN.AUDIENCE_MASTER_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.AUDIENCE_MASTER.CHILDREN.AUDIENCE_MASTER_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                            <PermissionModule parentSource={permissions.NETWORK_ENGAGE} source={permissions.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER.CHILDREN.CAMPAIGN_MASTER_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER} source={permissions.NETWORK_ENGAGE.CHILDREN.CAMPAIGN_MASTER.CHILDREN.CAMPAIGN_MASTER_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                        </PermissionContainer>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                        <PermissionContainer source={permissions.CATALOG_MANAGER} handlePermission={handlePermission}>
                            <PermissionModule parentSource={permissions.CATALOG_MANAGER} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS.CHILDREN.PRODUCT_MASTERS_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS.CHILDREN.PRODUCT_MASTERS_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS.CHILDREN.PRODUCT_MASTERS_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                            <PermissionModule parentSource={permissions.CATALOG_MANAGER} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT.CHILDREN.PRODUCT_CREATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT.CHILDREN.PRODUCT_UPDATE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT} source={permissions.CATALOG_MANAGER.CHILDREN.PRODUCT.CHILDREN.PRODUCT_DELETE}></PermissionCheckBox>
                            </PermissionModule>
                            <PermissionModule parentSource={permissions.CATALOG_MANAGER} source={permissions.CATALOG_MANAGER.CHILDREN.BROCHURE_MASTER} handlePermission={handlePermission}>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.BROCHURE_MASTER} source={permissions.CATALOG_MANAGER.CHILDREN.BROCHURE_MASTER.CHILDREN.BROCHURE_MASTER_SHARE}></PermissionCheckBox>
                                <PermissionCheckBox handlePermission={handlePermission} parentSource={permissions.CATALOG_MANAGER.CHILDREN.BROCHURE_MASTER} source={permissions.CATALOG_MANAGER.CHILDREN.BROCHURE_MASTER.CHILDREN.BROCHURE_MASTER_DOWNLOAD}></PermissionCheckBox>
                            </PermissionModule>
                        </PermissionContainer>
                    </div>
                </div></div>

                <div className="saveBtn text-end mt-5 text-center">
                    <Link className="nextMaster btn btn-group" to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.NETWORK_DETAILS}>Previous</Link>
                    <MountComponent condition={givenPermissions.USER_RIGHTS_UPDATE}>
                        <button className="btn btn-group" type="button" onClick={() => { userPermissions.submitForm(); }}>Save</button>
                    </MountComponent>
                    <Link className="nextMaster btn btn-group" to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS}>Next</Link>
                </div>
            </div>
        </React.Fragment>
    )
})