import { server } from '../config/config'
 
export const storeDistributionHierarchyAPI = (payload = {}) => server({
    method  : 'post',
    url     : `distribution-hierarchy`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getDistributionHierarchiesAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `distribution-hierarchy`,
    params  : { ...queryParams },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getDistributionHierarchyAPI = (id) => server({
    method  : 'get',
    url     : `distribution-hierarchy/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateDistributionHierarchyAPI = (id, payload = {}) => server({
    method: 'put',
    url: `distribution-hierarchy/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteDistributionHierarchyAPI = (id) => server({
    method: 'delete',
    url: `distribution-hierarchy/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
