import React, { useState, useEffect, useRef } from "react";
import Editable from "react-bootstrap-editable";
import getGeographyLevelAPI from "../../api/getGeographyLevelAPI";
import updateGeographyLevelAPI from "../../api/updateGeographyLevelAPI";
import deleteGeographyLevelAPI from "../../api/deleteGeographyLevelAPI";
import getGeographyLevelSaveAPI from "../../api/getGeographyLevelSaveAPI";
import getDivisionsAPI from "../../api/getDivisionsAPI";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import {
    Col,
    Row,
    Alert,
    Modal,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
    } from "reactstrap";
import * as Yup from "yup";
import { useFormik,useFormikContext  } from "formik";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainNetwork from "./main-network";

    function Hierarchy({ tabId, setIsListEmpty }) {
    const [geographyId, setGeographyId] = useState(null);
    const [divisionId, setDivisionId] = useState(null);
    const [geographyName, setGeographyName] = useState("");
    const [value, setValue] = useState("");
    const [geographyLevels, setGeographyLevels] = useState([]);

    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
    const organizationId = authUserOrg?.data?.data?.id;
    const [divisions, setDivisions] = useState([]);
    const [editableValue, setEditableValue] = useState('');
    const [val, setVal] = useState('')

    const [modal_delete, setmodal_delete] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [selectedGroup, setselectedGroup] = useState(null);
    const [initialValue, setInitialValue] = useState('');
    const [resetKey, setResetKey] = useState(0);
    const [lastValue, setLastValue] = useState("")
    const [divisionAPICalls, setDivisionAPICalls] = useState(0);
    const [selectedSBU, setSelectedSBU] = useState(null);
    
    function handleSelectGroup(selectedValue) {
        setSelectedSBU(selectedValue);
    }

    const filteredGeography = selectedSBU
    ? geographyLevels.filter((geography) => geography?.division?.name === selectedSBU.label)
    : geographyLevels;


    
    const GeographyLevelsList = async () => {
        try {
        const response = await getGeographyLevelAPI();
        if (response) {
            if(response?.data?.length===0){
                setIsListEmpty(true);
            }
            setGeographyLevels(response.data);
        }
        } catch (error) {
        console.log("Erorr reg", error);
        }
    };
    useEffect(() => {
        GeographyLevelsList();
    }, []);

    const handleSubmit = async (newValue) => {

        const hasSpecialCharacters = /[^a-zA-Z0-9\s]/.test(newValue);

        if (hasSpecialCharacters) {
        // Display an error message or take appropriate action
        toast.error("Special characters not allowed");
        return;
        }
        try {
        const payload = {
            userId: authUser.userId,
            levelName: newValue,
            divisionId: selectedSBU ? selectedSBU.value: null,
            organizationId: organizationId,
        };

        if (selectedSBU && newValue.trim() !== "") {
            // Add new row
            const newRow = {
                sr: geographyLevels.length + 1,
                sbu: selectedSBU.label,
                name: newValue,
            };
            setGeographyLevels((prevRegions) => [...prevRegions, newRow]);
        }

        setResetKey((prevKey) => prevKey + 1);

        const { data } = await getGeographyLevelSaveAPI(payload);
        if(data){
        // setValue('')
        toast.success("Geography Hierarchy created successfully !")
        setselectedGroup(null)
        GeographyLevelsList();
        setGeographyName("");
        // newValue=""
        const isEmpty = geographyLevels.length === 0;
        // Call the callback function to inform the parent component
        // onApiSuccess(isEmpty);
        setIsListEmpty(false)
        }
        
        } catch (error) {
        
        if (error.response.status === 409) {
            toast.error("Level Already Exist");
        }
        if (error.response.status === 400) {
            if(selectedGroup === null){
            toast.error("SBU/Division Cannot be empty !");
            }else{
            toast.error("Level Cannot be empty !");
            }
            
        }
        console.log(error)
        //
        // toast.error(error.response.data.error);
        }
    };

    const handleEditClick = (geographyId, geographyName, divisionId) => {
        setGeographyId(geographyId);
        setDivisionId(divisionId);
        setGeographyName(geographyName);
        setValue(geographyName);
        // setselectedGroup(divisionId ? optionGroupEdit[0].options.find(option => option.value === divisionId) : null);
        const selectedSBUForEdit = divisionId ? optionGroupEdit[0].options.find(option => option.value === divisionId) : null;
        setselectedGroup(selectedSBUForEdit);
        setSelectedSBU(selectedSBUForEdit); 
    };

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_delete() {
        setmodal_delete(!modal_delete);
        removeBodyCss();
    }
    function tog_edit() {
        setmodal_edit(!modal_edit);
        removeBodyCss();
    }

    const handleSubmitRemove = async (geographyId) => {
        try {
        const { data } = await deleteGeographyLevelAPI(geographyId);
        setGeographyId(null);
        setmodal_delete(false);
        toast.success("Level Deleted Successfully");
        GeographyLevelsList();
        } catch (error) {
        console.log(error);

        //
        // toast.error(error.response.data.error);
        }
    };

    const [selectedEditGroup, setselectedEditGroup] = useState(
        divisionId ? divisionId : null
    );

    function handleSelectEditGroup(selectedValue) {
        setselectedEditGroup(selectedValue);
        setSelectedSBU(selectedValue);
    }

    const optionGroupEdit = [
        {
        options: divisions.map((DivisionName) => {
            return { label: DivisionName.name, value: DivisionName.id };
        }),
        },
    ];

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
        levelName: geographyName,
        userId: authUser.userId,
        organizationId: organizationId,
        divisionId: selectedGroup ? selectedGroup.value : "",
        },

        // enableReinitialize: true,
        // initialValues,

        validationSchema: Yup.object({
        levelName: Yup.string().required("Please Enter Geograpgy Level Name"),
        }),
        onSubmit: async (values) => {
            const hasSpecialCharacters = /[^a-zA-Z0-9\s]/.test(values.levelName);

            if (hasSpecialCharacters) {
            // Display an error message or take appropriate action
            toast.error("Special characters not allowed");
            return;
        }
        try {
            const payload = {
                userId: authUser.userId,
                levelName: values.levelName,
                divisionId: selectedSBU ? selectedSBU.value : null, // Use selectedSBU here
                organizationId: organizationId,
            };

            const { data } = await updateGeographyLevelAPI(payload, {
            id: geographyId,
            });
            setGeographyId(null);
            setGeographyName("");
            setmodal_edit(false);
            toast.success("Level Updated Successfully");
            GeographyLevelsList();
        } catch (error) {
            if(error.response.status == 409){
                setGeographyName(values.levelName);
                setmodal_edit(false);
                toast.error("Geography Level already exists")
            }
            setmodal_edit(false);
        }
        },
    });


    const divisionList = async () => {
        try {
        const response = await getDivisionsAPI();
        if (response) {
            setDivisions(response.data);
            console.log("setDivisions", divisions)
        }
        } catch (error) {
        console.log("Erorr reg", error);
        }
    };
    useEffect(() => {
        if (divisionAPICalls <= 1) {
            divisionList();
        }
        
    }, [tabId]);

    // useEffect(() => {
    //     // if (divisionAPICalls <= 1) {
    //         divisionList();
    //     },[]);

    const optionGroup = [
        {
        options: divisions.map((DivisionName) => {
            return { label: DivisionName.name, value: DivisionName.id };
        }),
        },
    ];

    useEffect(() => {
        // ...

        if (divisionId) {
        setselectedEditGroup(optionGroupEdit[0].options.find((option) => option.value === divisionId));
        }
    }, [divisionId, optionGroupEdit]);
    
    const data = {
        columns: [
        {
            label: "Sr.No.",
            field: "sr",
            sort: "asc",
            width: 150,
        },
        {
            label: "Heirarchy Name",
            field: "hraName",
            sort: "asc",
            width: 270,
        },
        {
            label: "SBU Name",
            field: "sbuName",
            sort: "asc",
            width: 270,
        },
        {
            label: "ID",
            field: "id",
            sort: "asc",
            width: 270,
        },
        {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 200,
        },
        ],
        rows: [
        ...filteredGeography.map((levels, i) => ({
            sr: ++i,
            hraName: levels.levelName,
            sbuName:levels.division.name,
            id: levels.id,
            action: (
            <div className="functionalBtn editDataShow">
                <button
                className="btn btn-group border"
                onClick={() => {
                    tog_edit();
                    handleEditClick(levels.id, levels.levelName, levels.divisionId);
                }}
                >
                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
                <button
                className="btn btn-group border mx-2"
                onClick={() => {
                    tog_delete();
                    handleEditClick(levels.id, levels.levelName);
                }}
                data-toggle="modal"
                >
                <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </div>
            ),
        })),
        ],
    };

    const rows = data.rows;

    useEffect(() => {
        if (divisionId) {
        setselectedEditGroup(optionGroupEdit[0].options.find((option) => option.value === divisionId));
        }
    }, [divisionId, optionGroupEdit]);


    useEffect(() => {
        setVal('')
    }, [geographyLevels])

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item dropped outside the list
        const { source, destination } = result;
        if (source.index === destination.index) return; // Item dropped at the same position
    
        // Reorder the geographyLevels array based on the drag and drop
        const reorderedLevels = Array.from(geographyLevels);
        const [removed] = reorderedLevels.splice(source.index, 1);
        reorderedLevels.splice(destination.index, 0, removed);
    
        // Update the state with the reordered levels
        setGeographyLevels(reorderedLevels);
    };

    return (
        <div className="division">     
        <Row className="justify-content-center">
            <Col lg={12}>
            <div className="row">
                <div className="col-md-6">
                <div className="mb-2">
                    <Select
                        value={selectedSBU}
                        onChange={(selectedValue) => handleSelectGroup(selectedValue)}
                        options={optionGroup}
                        classNamePrefix="select2-selectionsssss"
                        className="mainSelect"
                        placeholder="Select Division/SBU"
                    />
                </div>
                </div>
                <div className="col-md-6">
                <Editable
                    key={resetKey}
                    alwaysEditing={true}
                    disabled={false}
                    editText="superuser"
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    showText
                    className="mainText"
                    type="textfield"
                    value={initialValue}
                    onSubmit={handleSubmit}
                    onChange={(newValue) => setInitialValue(newValue)}
                />
                </div>
            </div>

            <div className="customeDatatable">
                <MDBDataTable responsive bordered data={data} footer={false} />
            </div>

            {/* <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="geography-levels">
                {(provided) => (
                    <ul  {...provided.droppableProps} ref={provided.innerRef} className="customeDatatable">
                    {geographyLevels.map((levels, index) => (
                        <Draggable key={levels.id} draggableId={levels.id.toString()} index={index}>
                        {(provided) => (
                            <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            >

                            <div className="customeDatatable">
                                <MDBDataTable responsive bordered data={data} footer={false} />
                            </div>
                            </li>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </ul>
                )}
                </Droppable>
            </DragDropContext> */}
            
        
            </Col>
        </Row>

        {/* Modal for Edit Division */}
        <Modal
            isOpen={modal_edit}
            toggle={() => {
            tog_edit();
            }}
            backdrop={"static"}
            scrollable={true}
            id="staticBackdrop"
            centered
        >
            <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
                Edit Geography
            </h5>
            <button
                type="button"
                className="btn-close"
                onClick={() => {
                setmodal_edit(false);
                }}
                aria-label="Close"
            ></button>
            </div>
            <Form
            className="needs-validation"
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            >
            <div className="modal-body">
                <Row>
                <Col md="12">
                    <div className="mb-2">
                    <Label htmlFor="validationCustom01">
                        Division Name
                    </Label>
                    <Select
                        value={selectedSBU}
                        // onChange={(selectedValue) =>
                        // handleSelectEditGroup(selectedValue)
                        // }
                        onChange={handleSelectEditGroup}
                        options={optionGroupEdit}
                        classNamePrefix="select2-selectionsssss"
                        className="mainSelect"
                        placeholder="Select Division/SBU"
                    />
                    </div>
                </Col>
                <Col md="12">
                    <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">
                        Geography Level name
                    </Label>
                    <Input
                        name="levelName"
                        placeholder="Geography Level name"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.levelName || ""}
                        invalid={
                        validation.touched.levelName &&
                        validation.errors.levelName
                            ? true
                            : false
                        }
                    />
                    {validation.touched.levelName &&
                    validation.errors.levelName ? (
                        <FormFeedback type="invalid">
                        {validation.errors.levelName}
                        </FormFeedback>
                    ) : null}
                    </FormGroup>
                </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <button
                type="button"
                className="cancelModal btn"
                onClick={() => {
                    setmodal_edit(false);
                }}
                >
                Cancel
                </button>
                <button type="submit" className="nextMasterModal btn">
                Update
                </button>
            </div>
            </Form>
        </Modal>

         {/* Modal for Delete Geography Level */}
        <Modal
            isOpen={modal_delete}
            toggle={() => {
                tog_delete();
            }}
            backdrop={"static"}
            scrollable={true}
            id="staticBackdrop"
            centered
            >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                Delete
            </h5>
            <button
                type="button"
                className="btn-close"
                onClick={() => {
                    setmodal_delete(false);
                }}
                aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <p>Are you sure you want to delete {validation.values.levelName} ?</p>
            </div>
            <div className="modal-footer">
                <button
                type="button"
                className="cancelModal btn"
                onClick={() => {
                    setmodal_delete(false);
                }}
                >
                Cancel
                </button>
                <button
                type="button"
                className="nextMasterModal btn"
                onClick={() => handleSubmitRemove(geographyId)}
                >
                Delete
                </button>
            </div>
        </Modal>
        </div>
    );
}

export default Hierarchy;
