import { server } from '../config/config'

export const storeSubCategoryAPI = (payload = {}) => server({
    method  : 'post',
    url     : `sub-categories`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getSubCategoriesAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `sub-categories`,
    params  : { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getSubCategoryAPI = (id) => server({
    method  : 'get',
    url     : `sub-categories/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateSubCategoryAPI = (id, payload = {}) => server({
    method: 'put',
    url: `sub-categories/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteSubCategoryAPI = (id) => server({
    method: 'delete',
    url: `sub-categories/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
