import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import store from "store";

import * as Yup from "yup";
import * as XLSX from 'xlsx';

import { useDropzone } from 'react-dropzone';
import { useFormik } from "formik";
import { Alert } from "reactstrap";

import TimelineGuide from "../../timeline-guide";
import RightSideProfile from "../../../Dashboard/right-side-profile";
import MountComponent, { MountCondition } from "../../../../module/mount-components";

import { errorMessage, toastErrorMessage } from "../../../../module/error-methods";

import { getNetworkLocationsAPI, updateNetworkLocationAPI, deleteNetworkLocationAPI, getNetworkLocationImportInfoAPI, uploadMultipleNetworkLocationsAPI } from "../../../../api/network-location.api.js";
import { checkPermissions } from "../../../../api/organization-users.api.js";
import { getStatesListAPI, getCitiesListAPI, getPinCodesListAPI } from "../../../../api/location.api.js";
import { getGeographyRegionsAPI } from "../../../../api/geography-regions.api.js";
import { getDivisionsAPI } from "../../../../api/division.api.js";

import PERMISSIONS from '../../../../routes/permissions.js';

import Info from "./info";
import Table from "./table";
import XlSXImport from "./xlsx-import.jsx";

export default React.memo(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Location | Giffy Network";
    const showGirl = false;
    const { CHILDREN: { LOCATION } } = PERMISSIONS.NETWORK_MANAGER;

    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);
    const [importModal, toggleImportModal] = useState(false);

    const [locationDetails, setLocationDetails] = useState({});
    const [locations, setLocations] = useState([]);

    const [apiStatus, setApiStatus] = useState('pending');
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

    const [userPermissions, setUserPermissions] = useState({
        LOCATION_CREATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        LOCATION_VIEW: userDetails.userRole === "ORGANIZATION" ? true : false,
        LOCATION_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        LOCATION_DELETE: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    /** @options -------------------------------------------------------------------------------------------------------------------- */

    const [divisionOptions, setDivisionOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [pinOptions, setPinOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);

    /** @apis ----------------------------------------------------------------------------------------------------------------------- */

    const getDivisionOptions = async () => {
        await getDivisionsAPI().then(({ data }) => {
            if (lodash.isArray(data)) {
                return setDivisionOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
            }
        }).catch((error) => toastErrorMessage(error));
    };

    const getRegionOptions = async () => {
        if (!updateLocationFormik.values.divisionId) { return true; }
        await getGeographyRegionsAPI({ divisionId: updateLocationFormik.values.divisionId }).then(({ data }) => {
            if (lodash.isArray(data)) {
                return setRegionOptions([...data.map(({ name, id }) => ({ label: name, value: id }))]);
            }
        }).catch((error) => toastErrorMessage(error));
    };

    const getLocations = async () => {
        try {
            const { data } = await getNetworkLocationsAPI();
            if (lodash.isArray(data)) { setLocations(data); }
        } catch (error) {
            toastErrorMessage(error)
        } finally { setLoading(false); setApiStatus('completed'); }
    }

    const getStateOptions = async () => {
        await getStatesListAPI().then(({ data: response }) => {
            if (lodash.isArray(response.data)) {
                return setStateOptions([...response.data.map(({ state }) => ({ label: state, value: state }))]);
            }
        }).catch((error) => toastErrorMessage(error));
    };
    const getCitiesOptions = async () => {
        const { state } = updateLocationFormik.values;
        if (!state) { return true; }
        await getCitiesListAPI({ states: [state] }).then(({ data: response }) => {
            if (lodash.isArray(response.data)) {
                return setCityOptions([...response.data.map(({ province }) => ({ label: province, value: province }))]);
            }
        }).catch((error) => toastErrorMessage(error));
    };
    const getPinCodeOptions = async () => {
        const { state, city } = updateLocationFormik.values;
        if (!state && !city) { return true; }
        await getPinCodesListAPI({ states: [state], cities: [city] }).then(({ data: response }) => {
            if (lodash.isArray(response.data)) {
                return setPinOptions([...response.data.map(({ zipcode, place }) => ({
                    value: zipcode, label: `${zipcode} (${place})`,
                })
                )]);
            }
        }).catch((error) => toastErrorMessage(error));
    };

    const getPermissions = async () => {
        const modulePermissions = LOCATION.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @handlers ------------------------------------------------------------------------------------------------------------------ */

    const handleDeleteCallback = useCallback((locationDetails = {}) => {
        setLocationDetails({ ...locationDetails });
        toggleDeleteModal(true);
    });

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteNetworkLocationAPI(locationDetails.id);
            await getLocations();

            toggleNotificationState({ visible: true, color: 'success', message: 'Your location has been deleted successfully!' });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setLocationDetails({}) }
    });

    const handleUpdateCallback = useCallback((data = {}) => {
        const updateData = lodash.pick(data, lodash.keys(updateLocationInitialValues));

        setLocationDetails({ ...data });
        toggleUpdateModal(true);
        return updateLocationFormik.setValues({ ...updateData });
    });

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @formik -------------------------------------------------------------------------------------------------------------------- */

    const updateLocationInitialValues = {
        location: null,
        regionId: null,
        city: null,
        state: null,
        pincode: null,
        divisionId: null,
    }

    const updateLocationValidation = {
        location: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter location"),
        pincode:
            Yup.string().required("Please enter your Pincode"),
        state: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please select state."),
        city: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please select city."),
        divisionId: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please select division."),
        regionId: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please select region."),
    }

    const updateLocationFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...updateLocationInitialValues },
        validationSchema: Yup.object({ ...updateLocationValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {

                setLoading(true);

                await updateNetworkLocationAPI(locationDetails.id, { ...values })
                await getLocations();

                toggleNotificationState({ visible: true, color: 'success', message: 'Your location has been successfully updated!' });
                resetForm();

            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); setLocationDetails({}); }
        },
    });

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);

    useEffect(() => { getLocations() }, []);
    useEffect(() => { getDivisionOptions(); getStateOptions(); }, []);

    useEffect(() => { getRegionOptions() }, [updateLocationFormik.values.divisionId]);
    useEffect(() => { getCitiesOptions() }, [updateLocationFormik.values.state]);
    useEffect(() => { getPinCodeOptions() }, [updateLocationFormik.values.city]);
    useEffect(() => {
        if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000); }
    }, [notificationState.visible]);

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /**  @import ------------------------------------------------------------------------------------------------------------------- */

    const [xlsxData, updateXLSXData] = useState([]);
    const [xlsxNotificationState, toggleXLSXNotificationState] = useState({ visible: false, color: "danger" });

    const xlsxDataDataTable = {
        columns: [
            {
                label: "Sr.No.",
                field: "index",
                sort: "asc",
                width: 150,
            },
            {
                label: "SBU",
                field: "divisionName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Location Name",
                field: "location",
                sort: "asc",
                width: 270,
            },
            {
                label: "Region",
                field: "regionName",
                sort: "asc",
                width: 200,
            },
            {
                label: "State",
                field: "state",
                sort: "asc",
                width: 100,
            },
            {
                label: "City",
                field: "city",
                sort: "asc",
                width: 150,
            },
            {
                label: "Pincode",
                field: "pincode",
                sort: "asc",
                width: 100,
            },
            {
                label: "Action",
                field: "action",
                sort: "disabled",
                width: 100,
            },
        ],
        rows: [...xlsxData.map((details, index) => ({
            index: ++index,
            divisionName: details?.divisionName,
            location: details?.location,
            regionName: details?.regionName,
            state: details.state,
            city: details.city,
            pincode: details.pincode,
            action: (
                <button className="btn btn-group" onClick={() => { handleUploadModalDelete(details.uuid); }}>
                    <i className="fas fa-trash-alt" style={{ color: "red" }}></i>
                </button>
            ),
        })),
        ],
    };

    const handleUploadModalDelete = useCallback((deleteUuid) => {
        const updatedData = lodash.filter(lodash.clone(xlsxData), ({ uuid }) => deleteUuid !== uuid);

        updateXLSXData(lodash.clone(updatedData))
    }, [xlsxData]);

    const handleUploadModal = useCallback(() => {
        updateXLSXData([]); toggleImportModal(false); 
        toggleXLSXNotificationState({ visible: false, color: "danger" });
    });

    const handleUploadSubmit = useCallback(async () => {
        try {

            if (lodash.isEmpty(xlsxData)) { return toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Please upload file.' }); }

            const payload = lodash.map(xlsxData, (data) => lodash.omit(data, ['uuid', 'divisionName', 'regionName']));
            const payloadValidation = Yup.array().of(Yup.object({ ...updateLocationValidation }));

            await payloadValidation.validate(payload);
            await uploadMultipleNetworkLocationsAPI(payload);
            await getLocations();

            toggleNotificationState({ visible: true, color: 'success', message: 'Success! The network location imported successfully!.' });
            toggleXLSXNotificationState({ visible: false, color: '', message: '' });
            toggleImportModal(false);

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Invalid Data!' });
            } else {
                toggleXLSXNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            }
        } finally { updateXLSXData([]); setLoading(false); }

    }, [xlsxData]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length !== 1) { return toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Please select .xlsx file.' }); };

        const file = acceptedFiles[0];
        const reader = new FileReader();
        const requiredFields = ["SBU", "Location Name", "Region", "State", "Select City District", "Pincode"];
        const preparePayload = (data) => lodash.map(data, (data, index) => ({
            uuid: `uuid-${index * 2}`,
            divisionId: data["SBU"],
            location: data["Location Name"],
            regionId: data["Region"],
            state: String(data["State"]),
            city: data["Select City District"],
            pincode: data["Pincode"],
        }));

        reader.onload = async (e) => {
            try {

                setLoading(true);

                const binaryData = e.target.result;
                const workbook = XLSX.read(binaryData, { type: 'binary' });

                const sheetName = workbook.SheetNames[0];
                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                const headerRow = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })[0];

                const missingFields = lodash.difference(requiredFields, headerRow);
                const extraFields = lodash.difference(headerRow, requiredFields);
                const unfilledRows = sheetData.filter((data) => requiredFields.some((field) => !data.hasOwnProperty(field)));

                if (lodash.size(missingFields) > 0) {
                    return toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Wrong file uploaded.' });
                }
                if (lodash.size(extraFields) > 0) {
                    return toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Wrong file uploaded. Extra fields' });
                }
                if (lodash.size(sheetData) === 0) {
                    return toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Please fill the sheet before upload.' });
                }

                if (lodash.size(unfilledRows) > 0) {
                    return toggleXLSXNotificationState({
                        visible: true, color: 'danger', message: 'Some rows have unfilled fields. Please check the data.'
                    });
                }

                const duplicateRows = lodash(sheetData).groupBy((data) => JSON.stringify(data)).filter((group) => group.length > 1).value();
                if (lodash.size(duplicateRows) > 0) {
                    return toggleXLSXNotificationState({
                        visible: true, color: 'danger', message: 'Some rows have matching fields with other rows. Please check the data.'
                    });
                }

                const payload = lodash.map(preparePayload(sheetData), (data) => lodash.omit(data, 'uuid'));
                const response = await getNetworkLocationImportInfoAPI(payload);

                if (lodash.size(response.data.result) === 0) { 
                    return toggleXLSXNotificationState({ visible: true, color: 'danger', message: 'Kindly check the data and reupload.' });
                }
                else { return updateXLSXData(response.data.result); }

            } catch (error) {
                toggleXLSXNotificationState({ visible: true, color: 'danger', message: errorMessage(error.message) });
            } finally { setLoading(false); }
        };

        reader.readAsBinaryString(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    /** completed :) -------------------------------------------------------------------------------------------------------------- */

    return (<React.Fragment>
        <MountCondition condition={loading}>
            <MountCondition.True>
                <div className="fullscreen-loader"><div className="loader-container">
                    <div className="loader"></div>
                </div></div>
            </MountCondition.True>
        </MountCondition>
        <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-lg-12"><div className="profile">
                <div className="actives">
                    <TimelineGuide heading="Setup Your Location" activePage={LOCATION}></TimelineGuide>
                </div>
                <Alert color={notificationState.color} isOpen={notificationState.visible} >
                    {notificationState.message}
                </Alert>
                <MountComponent condition={apiStatus === "completed"}>
                    <MountCondition condition={lodash.isEmpty(locations)}>
                        <MountCondition.True>
                            <Info userPermissions={userPermissions}></Info>
                        </MountCondition.True>
                        <MountCondition.False>
                            <Table
                                userPermissions={userPermissions}
                                updateLocationFormik={updateLocationFormik}

                                locations={locations}
                                locationState={locationDetails}

                                deleteModal={deleteModal}
                                toggleDeleteModal={toggleDeleteModal}
                                handleDeleteCallback={handleDeleteCallback}
                                handleConfirmDeleteCallback={handleConfirmDeleteCallback}

                                updateModal={updateModal}
                                handleUpdateCallback={handleUpdateCallback}
                                toggleUpdateModal={toggleUpdateModal}


                                divisionOptions={divisionOptions}
                                stateOptions={stateOptions}
                                cityOptions={cityOptions}
                                pinOptions={pinOptions}
                                regionOptions={regionOptions}

                                toggleImportModal={toggleImportModal}
                            ></Table>
                            <XlSXImport
                                importModal={importModal}
                                handleUploadModal={handleUploadModal}
                                handleUploadSubmit={handleUploadSubmit}

                                xlsxData={xlsxData}
                                xlsxDataDataTable={xlsxDataDataTable}

                                xlsxNotificationState={xlsxNotificationState}
                                toggleXLSXNotificationState={toggleXLSXNotificationState}

                                getRootProps={getRootProps}
                                getInputProps={getInputProps}
                            ></XlSXImport>
                        </MountCondition.False>
                    </MountCondition>
                </MountComponent>
            </div></div>
            <div className="col-lg-3">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
        </div></div></div>
    </React.Fragment>)
});