import lodash from 'lodash';

export const colors = {
    '#D2DAFF': '#FF96B2',
    '#FF96B2': '#F9C981',
    '#F9C981': '#89EFA0',
    '#89EFA0': '#D2DAFF'
};

export const selectOptions = (data = [], key) => {
    return lodash.map(data, (object) => { return { id: object.id ?? null, value: object[key], label: object[key], } })
}

export const selectPinCodesOptions = (data = [], key) => {
    // return lodash.map(lodash.uniqBy(data, key), (object) => ({
    //     id: object.id ?? null, value: object[key], label: `(${object[key]}) ${object['place']}`, place: object.place
    // }))
    return lodash.map(lodash.cloneDeep(data), (object) => ({
        id: object.id ?? null, value: object[key], label: `(${object[key]}) ${object['place']}`, place: object.place
    }))
}

export const treeNode = (data) => {

    return {
        node: String(data.name).replace(/\s/g, "-").toLowerCase(),
        nodes: lodash.map(data.nodes ?? [], (node) => treeNode(node)),
        color: colors[data.color] ?? '#D2DAFF',
        id: data.id ?? null,
        name: data.name,
        divisionId: data.divisionId,
        parent: data.parent ?? 0,
        states: ((data) => { return selectOptions(data, 'state') })(data.states ?? []),
        cities: ((data) => { return selectOptions(data, 'city') })(data.cities ?? []),
        pinCodes: ((data) => { return selectPinCodesOptions(data, 'pinCode') })(data.pinCodes ?? []),
        positions: [],
        optionStates: data.optionStates ?? [],
        optionCities: data.optionCities ?? [],
        optionPinCodes: data.optionPinCodes ?? [],
        optionPositions: data.optionPositions ?? [],
    }
}

export const searchNode = (nodeName, node) => {
    if (node.node === nodeName) { return node; }

    for (let i = 0; i < node.nodes.length; i++) {
        const search = searchNode(nodeName, node.nodes[i]);
        if (!lodash.isEmpty(search)) { return search; }
    }
}

export const generateRandomString = () => {
    const length = 5;
    const characters = '#ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '#';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
};

export const hexToRGBA = (hex, alpha) => {
    const r = parseInt(String(hex).replace('#', '').substring(0, 2), 16);
    const g = parseInt(String(hex).replace('#', '').substring(2, 4), 16);
    const b = parseInt(String(hex).replace('#', '').substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const geographyStructure = (data = []) => {
    const updateData = lodash.map(data, ({ id, levelName }) => ({ id, levelName }))
    const structure = lodash.transform(updateData, (result, value, index) => {
        if (index < updateData.length - 1) {
            lodash.set(result, value.id.toString(), updateData[index + 1]);
        }
    }, { 'initial-node': updateData[0] });

    return structure;
}

export const excludeStarOptions = lodash.curry((array) => {
    return lodash.filter(array, ({ value }) => value != "*")
});
