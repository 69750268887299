import React from "react";
import { Table, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import MountComponent from "../../../../module/mount-components";
import DeleteModal from "../../../../module/delete-modal.component";

import ROUTES from "../../../../routes/routes";

export default React.memo((props) => {

    const { organizationUsers, organizationUser, deleteModal } = props;
    const { toggleDeleteModal, handleDeleteCallback, handleConfirmDeleteCallback } = props;
    const { userPermissions } = props;
    const { dataTable } = props;

   

    return (
        <div className="userTable"><div>
            <MountComponent condition={userPermissions.ADMIN_USERS_CREATE}>
                <div className="resumeBtn text-end">
                    <Link to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS_CREATE} className="btn btn-group mx-3">Add New</Link>
                </div>
            </MountComponent>
            <div className="UserDataTable"><div className="col-12"><div className="userProfileTable">
                <MountComponent condition={false}>
                    <div className="table-responsive">
                        <Table className="table table-bordered mb-0 table">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Organization</th>
                                    <th>User Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {organizationUsers.map((data) => (
                                    <tr>
                                        <td>{data.user.firstName}</td>
                                        <td>{data.user.lastName}</td>
                                        <td>{data.user.phoneNumber}</td>
                                        <td>{data.user.email}</td>
                                        <td>{data.organization?.companyName}</td>
                                        <td>{data.userRole?.name}</td>
                                        <td>
                                            <MountComponent condition={userPermissions.ADMIN_USERS_DELETE}>
                                                <button className="btn btn-group border btn-sm ms-1" onClick={() => { handleDeleteCallback(data) }}>
                                                    <i className="fa fa-trash" aria-hidden="true" style={{ color: "red" }}></i>
                                                </button>
                                            </MountComponent>
                                            <MountComponent condition={userPermissions.ADMIN_USERS_UPDATE}>
                                                <Link className="btn btn-group border btn-sm ms-1" to={String(ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS_UPDATE).replace(':id', data.id)}>
                                                    <i className="fa fa-edit" aria-hidden="true" style={{ color: "#0022A0" }}></i>
                                                </Link>
                                            </MountComponent>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </MountComponent>
                <div className="dataTable-hide-extra-props mt-3">
                    <MDBDataTable responsive bordered data={dataTable} footer={false} />
                </div>
                <div className="resumeBtn mt-4 text-center">
                    <Link to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS} className="btn btn-group me-3">Previous</Link>
                    <Link to={ROUTES.HOME.PATH} className="btn btn-group">Dashboard</Link>
                </div>
            </div></div></div>

            <Modal isOpen={deleteModal} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${organizationUser?.user?.firstName} ?`}
                ></DeleteModal>
            </Modal>
        </div></div>
    )
})

