export const styleReactSelect = (baseStyles, state, error) => ({
    
    ...baseStyles,
    borderColor: error ? '#f46a6a' : '#ced4da',
    '&:hover': {
        boxShadow: error ? '0 0 0 1px #dc3545' : '0 0 0 1px #ced4da',
        outlineColor: error ? '#dc3545' : '#ced4da',
        borderColor: error ? '#f46a6a' : '#ced4da',
        borderWidth:'1px'
    },
});

export const themeReactSelect = (theme,error) => ({ 
    ...theme, 
    boxShadow: 'none',border: 'solid 0.1px', outline : 'none', borderWidth:'0.1px',
    colors: {  ...theme.colors, primary: error ? '#dc3545' : '#99a7d9', }, 
});