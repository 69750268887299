import React from "react";
import { Link } from "react-router-dom";

import image from "../../../../assets/images/add.png";
import MountComponent from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";

export default React.memo(({ userPermissions }) => (
    <div className=""><div className="text-center">
        <img src={image} />
        <div className="text-center mt-2 mb-5">
            <MountComponent condition={userPermissions.ADMIN_USERS_CREATE}>
                <Link to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS_CREATE} className="btn btn-primary mt-2 mb-2">Add New</Link><br />
                <strong>Add New User to your Account</strong>
            </MountComponent>
        </div>
    </div></div>
));
