import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import store from "store";
import classnames from "classnames";

import { Link } from "react-router-dom";
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import doubtImage from "../../../../assets/images/icon/doubts-button.png";
import { MountCondition } from '../../../../module/mount-components';

import DivisionComponent from "./division.component";
import GeographyHierarchy from "./geography-hierarchy.component";
import SalesHierarchy from "./sales-hierarchy.component";
import DistributionHierarchy from "./distribution-hierarchy.component";
import UserRole from "./user.role.component";

import PERMISSIONS from '../../../../routes/permissions.js';
import ROUTES from '../../../../routes/routes.js';
import { checkPermissions } from "../../../../api/organization-users.api";

export default React.memo(() => {
    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [tab, toggleTab] = useState('DIVISION_TAB');

    const tabsStructure = {
        'DIVISION_TAB': { next: 'GEOGRAPHY_HIERARCHY_TAB', previous: null },
        'GEOGRAPHY_HIERARCHY_TAB': { next: 'SALES_HIERARCHY_TAB', previous: 'DIVISION_TAB' },
        'SALES_HIERARCHY_TAB': { next: 'DISTRIBUTION_HIERARCHY_TAB', previous: 'GEOGRAPHY_HIERARCHY_TAB' },
        'DISTRIBUTION_HIERARCHY_TAB': { next: 'USER_ROLE_TAB', previous: 'SALES_HIERARCHY_TAB' },
        'USER_ROLE_TAB': { next: null, previous: 'DISTRIBUTION_HIERARCHY_TAB' }
    }

    const [userPermissions,setUserPermissions] = useState({
        NETWORK_DETAILS_CREATE: userDetails.userRole === "ORGANIZATION" ? true: false,
        NETWORK_DETAILS_UPDATE: userDetails.userRole === "ORGANIZATION" ? true: false,
        NETWORK_DETAILS_DELETE: userDetails.userRole === "ORGANIZATION" ? true: false,
    });

    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
    const organizationId = lodash.get(authUserOrg,'data.data.id','');

    const getPermissions = async () => {
        const modulePermissions = PERMISSIONS.QUICK_START_GUIDE.CHILDREN.NETWORK_DETAILS.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions,(data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
          const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
          return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => {});
    }

    useEffect(() => { if(userDetails.userRole !== "ORGANIZATION") { getPermissions(); } },[tab]);


    /** @handlers ------------------------------------------------------------------------------------------------------------------- */

        const switchTab = useCallback((tabId) => { return toggleTab(tabId) });
        const nextTabCallback = useCallback(() => {
            return toggleTab(tabsStructure[tab].next)
        });

        const previousTabCallback = useCallback(() => {
            return toggleTab(tabsStructure[tab].previous)
        });

    /** ----------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <div className="master"><div className=""><div className="card-body">
                <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                        <ul>
                            <NavItem className={classnames({ current: tab === "DIVISION_TAB" })}>
                                <NavLink className={classnames({ active: tab === "DIVISION_TAB" }, "mainInfo")} onClick={() => { switchTab("DIVISION_TAB"); }}>
                                    <div className="TabsName cursor-pointer">Division /SBU {" "}
                                        <span className="numb">
                                            <img src={doubtImage} />
                                            <div className="infoooo"><span>Division SBU</span></div>
                                        </span>
                                    </div>{" "}
                                </NavLink>
                            </NavItem>

                            <NavItem className={classnames({ current: tab === "GEOGRAPHY_HIERARCHY_TAB" })}>
                                <NavLink className={classnames({ active: tab === "GEOGRAPHY_HIERARCHY_TAB" }, "mainInfo")} onClick={() => { switchTab("GEOGRAPHY_HIERARCHY_TAB"); }}>
                                    <div className="TabsName cursor-pointer">Geography Hierarchy{" "}
                                        <span className="numb">
                                            <img src={doubtImage} />
                                            <div className="infoooo"><span>Geography Hierarchy</span></div>
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem className={classnames({ current: tab === "SALES_HIERARCHY_TAB" })}>
                                <NavLink className={classnames({ active: tab === "SALES_HIERARCHY_TAB" }, "mainInfo")} onClick={() => { switchTab("SALES_HIERARCHY_TAB"); }}>
                                    <div className="TabsName cursor-pointer">Sales Hierarchy{" "}
                                        <span className="numb">
                                            <img src={doubtImage} />
                                            <div className="infoooo"><span>Sales Hierarchy</span></div>
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem className={classnames({ current: tab === "DISTRIBUTION_HIERARCHY_TAB" })}>
                                <NavLink className={classnames({ active: tab === "DISTRIBUTION_HIERARCHY_TAB" }, "mainInfo")} onClick={() => { switchTab("DISTRIBUTION_HIERARCHY_TAB"); }}>
                                    <div className="TabsName cursor-pointer">Distribution Hierarchy{" "}
                                        <span className="numb">
                                            <img src={doubtImage} />
                                            <div className="infoooo"><span>Distribution Hierarchy</span></div>
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem className={classnames({ current: tab === "USER_ROLE_TAB" })} >
                                <NavLink onClick={() => { switchTab("USER_ROLE_TAB"); }}>
                                    <div className="TabsName cursor-pointer">User Role{" "}
                                        <span className="numb">
                                            <img src={doubtImage} />
                                            <div className="infoooo"><span>User Role</span></div>
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </ul>
                    </div>

                    <div className="content clearfix">
                        <TabContent activeTab={tab}>
                            <TabPane tabId={"DIVISION_TAB"}>
                                <DivisionComponent userPermissions={userPermissions} tab={tab} authUser={authUser} organizationId={organizationId}></DivisionComponent>
                            </TabPane>
                            <TabPane tabId={"GEOGRAPHY_HIERARCHY_TAB"}>
                                <GeographyHierarchy userPermissions={userPermissions} tab={tab} authUser={authUser} organizationId={organizationId}></GeographyHierarchy>
                            </TabPane>
                            <TabPane tabId={"SALES_HIERARCHY_TAB"}>
                                <SalesHierarchy userPermissions={userPermissions} tab={tab} authUser={authUser} organizationId={organizationId}></SalesHierarchy>
                            </TabPane>
                            <TabPane tabId={"DISTRIBUTION_HIERARCHY_TAB"}>
                                <DistributionHierarchy userPermissions={userPermissions} tab={tab} authUser={authUser} organizationId={organizationId}></DistributionHierarchy>
                            </TabPane>
                            <TabPane tabId={"USER_ROLE_TAB"}>
                                <UserRole userPermissions={userPermissions} tab={tab} authUser={authUser} organizationId={organizationId}></UserRole>
                            </TabPane>
                        </TabContent>
                    </div>

                    <div className="actions clearfix text-center my-4">
                        <MountCondition condition={lodash.isNull(tabsStructure[tab].previous)}>
                            <MountCondition.True>
                                <Link to={ROUTES.ORGANIZATION_DETAILS.PATH} className="nextMaster btn btn-group me-2">Previous</Link>
                            </MountCondition.True>
                            <MountCondition.False>
                                <button className="btn btn-primary me-2" onClick={previousTabCallback}>Previous</button>
                            </MountCondition.False>
                        </MountCondition>
                        <MountCondition condition={lodash.isNull(tabsStructure[tab].next)}>
                            <MountCondition.True>
                                <Link to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.USER_RIGHTS} className="nextMaster btn btn-group">Next</Link>
                            </MountCondition.True>
                            <MountCondition.False>
                                <button className="btn btn-primary" onClick={nextTabCallback}>Next</button>
                            </MountCondition.False>
                        </MountCondition>
                    </div>
                </div>
            </div></div></div>
        </React.Fragment>
    );
});
