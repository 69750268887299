import React from "react";
import StartGuide from "./start-guide.jsx";
import Content from "./content.jsx";
import MountComponent from "../../module/mount-components";
import RightSideProfile from "../Dashboard/right-side-profile";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Giffy Network";
    const showGirl = false;

    return (
      <React.Fragment>
      <div className="page-content"><div className="container-fluid"><div className="row">
            <div className="col-12 mt-1"><Content></Content></div>
            <div className="col-12 mt-3"><StartGuide></StartGuide></div>
            <div className="col-lg-3 col-md-12">
                <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
            </div>
      </div></div></div>
    </React.Fragment>
); };
