import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Alert, Modal, Button, Label, Input, FormFeedback, Form } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";

import { storeUserRoleAPI, getUserRolesAPI, updateUserRoleAPI, deleteUserRoleAPI } from "../../../../api/user-role.api";
import { storeOrganizationModule } from "../../../../api/organizationModuleApi";

import DeleteModal from "../../../../module/delete-modal.component";
import MountComponent from "../../../../module/mount-components";

import { errorMessage } from "../../../../module/error-methods"
import permissions from "../../../../routes/permissions";

export default React.memo(({ tab, authUser, organizationId, userPermissions }) => {
    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [userRoles, setUserRoles] = useState([]);
    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const userRoleList = async () => {
        setLoading(true);
        await getUserRolesAPI().then(({ data: response }) => {
            if (lodash.isArray(response?.data)) { return setUserRoles([...response?.data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteUserRoleAPI(moduleState.id);
            await userRoleList();
            toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" User Role Deleted Successfully` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateUserRoleFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateUserRoleFormik.setValues({});
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const userRoleInitialValues = { name: '', userId: authUser.userId };

    const userRoleValidation = {
        name: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters and spaces are allowed')
            .min(3, 'Name must be at least 3 characters').trim()
            .required('Name is required'),
    };

    const updateUserRoleFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...userRoleInitialValues },
        validationSchema: Yup.object({ ...userRoleValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                const { id: userRoleId, organizationId, name } = values;

                await updateUserRoleAPI(userRoleId, { organizationId, name });
                await userRoleList();

                toggleNotificationState({ visible: true, color: 'success', message: 'User Role updated Successfully !' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); }
        },
    });

    const userRoleFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...userRoleInitialValues },
        validationSchema: Yup.object({ ...userRoleValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);

                const { name, organizationId } = values;
                const { NAME, CHILDREN: { NETWORK_DETAILS } } = permissions.QUICK_START_GUIDE;

                await storeUserRoleAPI({ name, organizationId, userId: authUser.userId });
                await storeOrganizationModule({ module: NAME, moduleLevel: NETWORK_DETAILS.NAME })
                await userRoleList();

                toggleNotificationState({ visible: true, message: 'User Role Added Successfully !' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); }
        },
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const dataTableHandler = () => {
        const columns = [
            {
                label: "Sr.No.",
                field: "sr",
                sort: "asc",
                width: 50,
            }, {
                label: "User Role",
                field: "userRole",
                sort: "asc",
                width: 270,
            },
        ];
        const rows = [
            ...userRoles.map((details, index) => ({
                sr: ++index,
                userRole: details.name,
                id: details.id,
                action: (
                    <div className="editDataShow functionalBtn">
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_DELETE}>
                            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                    </div>
                ),
            })),
        ];

        if (userPermissions.NETWORK_DETAILS_UPDATE || userPermissions.NETWORK_DETAILS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
        }

        return updateDataTable({ columns: [...columns], rows: [...rows] });
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => {
        if (tab === "USER_ROLE_TAB") { userRoleList(); }
    }, [tab]);

    useEffect(() => {
        if (tab === "USER_ROLE_TAB") { userRoleFormik.resetForm(); updateUserRoleFormik.resetForm(); }
    }, [tab]);

    useEffect(() => {
        if (notificationState.visible) {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
        }
    }, [notificationState.visible]);

    useEffect(() => { dataTableHandler() }, [userRoles]);

    /** ---------------------------------------------------------------------------------------------------------------------------------- */

    return (
        <div className="">
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <MountComponent condition={userPermissions.NETWORK_DETAILS_CREATE}>
                <form className="row mainText" onSubmit={userRoleFormik.handleSubmit}>
                    <div className="col-md-6 bg-transparent pe-0 mb-2" style={{ height: '40px' }}><div className="mainFormSubmit">
                        <div className="error-input">
                            <Input className="h-100" name="name"
                                invalid={userRoleFormik.touched.name && userRoleFormik.errors.name}
                                value={userRoleFormik.values.name}
                                onChange={userRoleFormik.handleChange}
                                placeholder="Please enter user role name."
                            ></Input>
                            <FormFeedback valid={!userRoleFormik.touched.name && !userRoleFormik.errors.name}>
                                {userRoleFormik.errors.name}
                            </FormFeedback>
                        </div>
                        <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
                    </div></div>
                </form>
            </MountComponent>

            <div className="dataTable-hide-extra-props mt-3">
                <MDBDataTable responsive bordered data={dataTable} footer={false} />
            </div>

            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${moduleState.name} ?`}
                ></DeleteModal>
            </Modal> {/* Modal for Delete UserRole */}

            <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Update User Role</h5>
                    <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
                </div>
                <Form className="needs-validation" onSubmit={updateUserRoleFormik.handleSubmit}>
                    <div className="modal-body"><div className="form-group mb-3">
                        <Label htmlFor="validationCustom01">User Role</Label>
                        <Input name="name" placeholder="UserRole name" type="text" className="form-control" id="validationCustom01"
                            onChange={updateUserRoleFormik.handleChange}
                            onBlur={updateUserRoleFormik.handleBlur}
                            value={updateUserRoleFormik.values.name || ""}
                            invalid={updateUserRoleFormik.touched.name && updateUserRoleFormik.errors.name ? true : false}
                        />
                        <FormFeedback valid={!updateUserRoleFormik.touched.name && !updateUserRoleFormik.errors.name}>
                            {updateUserRoleFormik.errors.name}
                        </FormFeedback>
                    </div></div>

                    <div className="modal-footer">
                        <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>
                            Cancel
                        </button>
                        <button type="submit" className="nextMasterModal btn">
                            Update
                        </button>
                    </div>
                </Form>
            </Modal>{/* Modal for Edit User Role */}
        </div>
    );
});
