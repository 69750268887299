import React from "react";

import { LevelContainer, LevelIndex, LevelLink, ProgressCircle } from "../../module/start-guide-components";
import { getColor, useApiData, useGuideHook } from "../../module/start-guide-methods.js";
import MountComponent from "../../module/mount-components";

import ROUTES from "../../routes/routes";
import PERMISSIONS from "../../routes/permissions";

export default React.memo(() => {
    
    const { NAME, CHILDREN: { PRODUCT_MASTERS, PRODUCT } } = PERMISSIONS.CATALOG_MANAGER;
    const totalModules = {
        [PRODUCT_MASTERS.NAME]: { current: PRODUCT_MASTERS.NAME, previous: null },
        [PRODUCT.NAME]: { current: PRODUCT.NAME, previous: PRODUCT_MASTERS.NAME },
    };

    const { array: storedModules } = useApiData({ module: NAME });
    const { count, render } = useGuideHook(storedModules, totalModules);

    return (
        <MountComponent condition={render}>
            <div className="guide"><div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="guideContent">
                        <h4>Quick Start Guide</h4>
                    </div>
                    <LevelContainer source={PRODUCT_MASTERS.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[PRODUCT_MASTERS.NAME])}><span>1</span></LevelIndex>
                        <LevelLink to={ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCT_MASTERS}>Masters</LevelLink>
                    </LevelContainer>
                    <LevelContainer source={PRODUCT.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[PRODUCT.NAME])}>2</LevelIndex>
                        <LevelLink to={ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT}>Product</LevelLink>
                    </LevelContainer>
                    <LevelContainer source={PRODUCT.NAME}>
                        <LevelIndex color={getColor(storedModules, totalModules[PRODUCT.NAME])}>3</LevelIndex>
                        <LevelLink to={ROUTES.CATALOG_MANAGER.CHILDREN.PRODUCTS}>Product List</LevelLink>
                    </LevelContainer>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto">
                    <ProgressCircle count={count}></ProgressCircle>
                </div>
            </div></div>
        </MountComponent>
    );
});