import config, {server} from '../config/config'
import axios from 'axios'

export const getStateListAPI = async () => {
    
    return await server({
        method  : 'get',
        url     : `/location/states`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getStateListAPI