import { server } from '../config/config'

export const storeDivisionAPI = (payload = {}) => server({
    method  : 'post',
    url     : `divisions`,
    data: { ...payload },
    headers : { "Content-type": "application/json; charset=UTF-8" }
});

export const getDivisionsAPI = (queryParams = {}) => server({
    method  : 'get',
    url     : `divisions`,
    params  : { ...queryParams },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const getDivisionAPI = (id) => server({
    method  : 'get',
    url     : `divisions/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const updateDivisionAPI = (id, payload = {}) => server({
    method: 'put',
    url: `divisions/${id}`,
    data: { ...payload },
    headers: { "Content-type": "application/json; charset=UTF-8", }
});

export const deleteDivisionAPI = (id) => server({
    method: 'delete',
    url: `divisions/${id}`,
    headers: { "Content-type": "application/json; charset=UTF-8", }
});
