import React, { useState, useEffect } from "react";
import { Col, Row, Form, Label, Input, FormFeedback } from "reactstrap";
import "../../assets/scss/custom/pages/datatables.scss";
import getDivisionsAPI from "../../api/getDivisionsAPI";
import getRegionByDivisionAPI from "../../api/getRegionByDivisionAPI";
import getCityListAPI from "../../api/getCityListAPI";
import getStateListAPI from "../../api/getStateListAPI";
import getPinCodesListAPI from "../../api/getPinCodesListAPI";
import updateUserGuideAPI from "../../api/updateUserGuideAPI";
import getUsersDetailsAPI from "../../api/getUsersDetailsAPI";
import saveNetworkLocationAPI from "../../api/saveNetworkLocationAPI";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleApiWrapper, geocodeByAddress } from "google-maps-react";
import Select from "react-select";
import EditableForm from "./editableForm";
import { storeOrganizationModule } from "../../api/organizationModuleApi";

function Addlocation({ setHide }) {
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const authUserOrg = JSON.parse(localStorage.getItem("authUserOrg"));
  const organizationId = authUserOrg.data.data.id;
  const navigate = useNavigate();
  const [divisions, setDivisions] = useState([]);
  const [divisionId, setDivisionId] = useState([]);
  const [locationDetails, updateLocationDetails] = useState({});
  const [divisionName, setDivisionName] = useState([]);
  const [networkCount, setNetworkCount] = useState('');

  const [regions, setRegions] = useState([]);

  const [city, setCity] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityOptions, setCityOptions] = useState([])
  const [pinOptions, setPinOptions] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [pincode, setPincode] = useState([]);
  const [count, setCount] = useState(0);

  const locationCount = localStorage.getItem("networkcounter");

  useEffect(() => {
    setStateOptions(stateOptions)
  }, [stateOptions]);

  useEffect(() => {
    setCityOptions(cityOptions)
  }, [cityOptions]);

  useEffect(() => {
    setRegionOptions(regionOptions)
  }, [regionOptions]);

  const userDetails = async () => {
    try {
      const { data } = await getUsersDetailsAPI(userData.data.id);
      if (data) {
        setNetworkCount(data.data.networkStartGuide)
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  var cardValidation = /^[0-9a-zA-Z]+$/;
  const name = RegExp(/^[A-Za-z]+$/);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: locationDetails.location,
      regionId: locationDetails.regionId,
      city: locationDetails.city,
      state: locationDetails.state,
      pincode: locationDetails.pincode,
      divisionId: locationDetails.divisionId,
      userId: userData.userId,
      organizationId: organizationId,
      latitude: locationDetails.latitude,
      // longitude: locationDetails.longitude,
    },

    validationSchema: Yup.object({
      location: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter location"),

      pincode: Yup.string()
        .required("Please enter your Pincode")
        // .matches(/^\d{5,10}(?:[-\s]\d{4})?$/, "Pincode is invalid") // need to check again
        // .test(
        //   "length",
        //   "Pin code needs to be between 5 and 10 digits",
        //   (val) => val?.length >= 5 && val?.length <= 10
        // )
        .typeError("Please enter a valid zip code"),

      state: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter State "),
      city: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Please Enter City "),
    }),

    onSubmit: async (values) => {
      try {
        values.pincode = String(values.pincode);
        values.regionId = String(values.regionId);
        values.latitude = String(values.latitude);
        values.longitude = String(values.longitude);

        const { data } = await saveNetworkLocationAPI(values);
        await storeOrganizationModule({ module : 'NETWORK_MANAGER', moduleLevel : 'LOCATION' });
        toast.success("Location Added Successfully");
        if (data && networkCount < 40) {
          const guideResponse = await updateUserGuideAPI('networkStartGuide', userData.data.id);

          if(guideResponse.data.status === true) {
            const newCount = 40;
            localStorage.setItem("networkStartGuide", newCount.toString());
          }

        }

        // if(locationCount < 40){
        //   // const currentCount = parseInt(
        //   //   localStorage.getItem("networkcounter"),
        //   //   20
        //   // );
        //   const newCount = 40;
        //   localStorage.setItem("networkcounter", newCount.toString());
        //   setCount(newCount);
        // }
        

        // const counter = parseInt(localStorage.getItem("count") || "0", 20);

        setTimeout(() => {
          setHide(true);
        }, 1000);
      } catch (error) {
        toast.error(error.response.data.error);
        console.log("Error", error);
        // props.switchScreen({ screen : 'registration-verification', next : true ,data : values})
      }
    },
  });

  const getStateDetails = async () => {
    try {
      const { data } = await getStateListAPI();
      // console.log("DATA state", data)

      if (data) {
        // setState(data.data);
        const stateData = data.data
        // setRegions(response.data);
        const options = stateData.map((item) => ({
          value: item.state,
          label: item.state,
        }));
        setStateOptions(options);
      }
    } catch (error) {
      console.log("Error state", error);
    }
  };

  useEffect(() => {
    getStateDetails();
  }, []);

  const getCityDetails = async (state) => {

    try {
      const { data } = await getCityListAPI({state});
      if (data) {
        // setCity(data.data);
        const cityData = data.data;
        // setState(data.data);
        const options = cityData.map((state) => ({
          value: state.province,
          label: state.province,
        }));
        setCityOptions(options);
      }
    } catch (error) {
      console.log("Error city", error);
    }
  };

  useEffect(() => {
    getCityDetails(validation.values.state);
  }, [validation.values.state]);

  const getPinCodeDetails = async (states, cities) => {
    try {
      const { data } = await getPinCodesListAPI({ states, cities });

      if (data) {
        const pinData = data.data;
        // setState(data.data);
        const options = pinData.map((state) => ({
          value: state.zipcode,
          label: `${state.zipcode} (${state.place})`,
        }));
        setPinOptions(options);

      }
    } catch (error) {
      console.log("Error state", error);

    }
  };

  useEffect(() => {
    getPinCodeDetails(validation.values.state, validation.values.city);
  }, [validation.values.city]);

  const fetchGeolocation = async (pincode) => {

    validation.setFieldValue("latitude", 24.7944);
    validation.setFieldValue("longitude", 73.9733);

    try {
      // const response = await axios.get(
      //   `https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=AIzaSyDFbh8qmYfgZR77myC6eowbBEkhiR1YZPA`
      // );

      // const data = response.data;

      // if (data.status === "OK" && data.results.length > 0) {
      //   const lat = data.results[0].geometry.location.lat;
      //   const lng = data.results[0].geometry.location.lng;
        
      //   validation.setFieldValue("latitude", lat);
      //   validation.setFieldValue("longitude", lng);
      //   // setLatitude(lat);
      //   // setLongitude(lng);
      // } else {  
      //   console.log("No results found.");
      // }
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
    }
  };
  useEffect(() => {
    fetchGeolocation(validation.values.pincode);
  }, [validation.values.pincode]);

  const divisionList = async () => {
    try {
      const response = await getDivisionsAPI();
      if (response) {
        const divisionData = response.data;
        // setDivisions(response.data);
        const options = divisionData.map((state) => ({
          value: state.id,
          label: state.name,
        }));

        // const idDivision = divisionData.map((state) => ({
        //   value: state.id,
        //   label: state.id,
        // }));

        setDivisionName(options);
        // setDivisionId(idDivision);
        
      }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    divisionList();
  }, []);

  const regionList = async (divisionId) => {
    try {
      const response = await getRegionByDivisionAPI(
        divisionId
      );
      if (response) {
        const regionData = response.data
        // setRegions(response.data);
        const options = regionData.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setRegions(options);
      }
    } catch (error) {
      console.log("Erorr reg", error);
    }
  };
  useEffect(() => {
    regionList(validation.values.divisionId);
  }, [validation.values.divisionId]);

  return (
    <div>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <div className="submitForm">
            <Col lg={12}>
              <Row>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    <div className="mb-0">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        SBU
                      </Label>
                      <Select
                        className=""
                        name="divisionId"
                        isSearchable
                        // onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // value={validation.values.divisionId || ""}
                        onChange={(selectedOption) => {
                          validation.setFieldValue(
                            "divisionId",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        value={divisionName.find(
                          (option) =>
                            option.value === validation.values.divisionId
                        )}
                        options={divisionName}
                        invalid={
                          validation.touched.divisionId &&
                          validation.errors.divisionId
                            ? true
                            : false
                        }
                      >
                        <option>Select SBU/Division</option>
                        {/* {divisionName.map((object) => {
                          return (
                            <option value={object.id}>{object.name}</option>
                          );
                        })} */}
                        {divisionName.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {validation.touched.divisionId &&
                    validation.errors.divisionId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.divisionId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    <Form>
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Location Name
                        </Label>
                        <Input
                          type="text"
                          placeholder="Enter Location Name"
                          name="location"
                          className="form-control"
                          id="formrow-firstname-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.location || ""}
                          invalid={
                            validation.touched.location &&
                            validation.errors.location
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Form>
                    {validation.touched.location &&
                    validation.errors.location ? (
                      <FormFeedback type="invalid">
                        {validation.errors.location}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    {/* <Form> */}
                    <div className="mb-0">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        Region
                      </Label>
                      <Select
                        className=""
                        name="regionId"
                        isSearchable
                        // onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // value={validation.values.regionId || ""}
                        onChange={(selectedOption) => {
                          validation.setFieldValue(
                            "regionId",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        value={regions.find(
                          (option) =>
                            option.value === validation.values.regionId
                        )}
                        options={regions}
                        invalid={
                          validation.touched.regionId &&
                          validation.errors.regionId
                            ? true
                            : false
                        }
                      >
                        <option>Select Regions</option>
                        {/* {regions.map((object) => {
                          return (
                            <option value={object.id}>{object.name}</option>
                          );
                        })} */}
                        {regions.map((item) => {
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        })}
                      </Select>
                    </div>
                    {validation.touched.regionId &&
                    validation.errors.regionId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.regionId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    {/* <Form> */}
                    <div className="mb-0">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        State
                      </Label>
                      <Select
                        className=""
                        name="state"
                        isSearchable
                        // onChange={validation.handleChange}
                        // value={validation.values.state || ""}
                        onChange={(selectedOption) => {
                          validation.setFieldValue(
                            "state",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        value={stateOptions.find(
                          (option) =>
                            option.value === validation.values.designation
                        )}
                        onBlur={validation.handleBlur}
                        options={stateOptions}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      >
                        <option>Select State</option>
                        {stateOptions.map((item) => (
                          <option value={item.state}>{item.state}</option>
                        ))}
                      </Select>
                    </div>
                    {validation.touched.state && validation.errors.state ? (
                      <FormFeedback type="invalid">
                        {validation.errors.state}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    {/* <Form> */}
                    <div className="mb-0">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        Select City District
                      </Label>
                      <Select
                        className=""
                        name="city"
                        onChange={(selectedOption) => {
                          validation.setFieldValue("city", selectedOption ? selectedOption.value : "");
                        }}
                        value={cityOptions.find((option) => option.value === validation.values.city)}
                        onBlur={validation.handleBlur}
                        options={cityOptions}
                        invalid={
                          validation.touched.city && validation.errors.city
                            ? true
                            : false
                        }
                      >
                        <option>Select City</option>
                        {cityOptions.map((item) => (
                          <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                      </Select>
                    </div>
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="mt-4">
                    <Form>
                      <div className="mb-0">
                        <Label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Pincode
                        </Label>
                        <Select
                          className=""
                          name="pincode"
                          onChange={(selectedOption) => {
                            validation.setFieldValue("pincode", selectedOption ? selectedOption.value : "");
                          }}
                          value={pinOptions.find((option) => option.value === validation.values.pincode)}
                          onBlur={validation.handleBlur}
                          options={pinOptions}
                          invalid={
                            validation.touched.pincode &&
                            validation.errors.pincode
                              ? true
                              : false
                          }
                        >
                          <option>Select Pincode</option>
                          {pinOptions.map((item) => (
                            <option value={item.zipcode}>
                              {item.zipcode} ({item.place})
                            </option>
                          ))}
                        </Select>
                      </div>
                    </Form>
                    {validation.touched.pincode && validation.errors.pincode ? (
                      <FormFeedback type="invalid">
                        {validation.errors.pincode}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
            <hr style={{ color: "#ced4da", border: "1px solid" }} />
            <Col
              lg={12}
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <div className="saveBtn">
                <button className="btn btn-group" type="submit">
                  Save
                </button>
              </div>
              <div className="saveBtn mx-2">
                <button
                  className="btn btn-group"
                  onClick={() => {
                    setHide(true)
                  }}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </div>
        </Row>
      </Form>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCbhTP4LACuLGbjd68j9QlKRnTDakdY8ds",
})(Addlocation);
