import React from "react";
import Accounts from "./accounts";
import RightSideProfile from "../Dashboard/right-side-profile";

const Index = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <React.Fragment>
      <div className="page-content"><div className="container-fluid"><div className="row">
          <div className="col-lg-12 col-md-12"><Accounts></Accounts></div>
          {/* <div className="col-lg-3 col-md-12"><RightSideProfile></RightSideProfile></div> */}
      </div></div></div>
    </React.Fragment>
);}

export default Index;
