import config,{server} from '../config/config'

export const getProductListAPI = async (query = {}) => {
    
    return await server({
        method  : 'get',
        url     : 'product-details',
        params  : query,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default getProductListAPI