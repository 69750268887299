import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import RightSideProfile from "../../../Dashboard/right-side-profile.js";
import UpdateAudience from "./source.jsx";
import StepperHead from "../../../../components/Common/StepperHead.js";

export default(() => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [showHide, setShowHide] = useState(true);
  const [hide, setHide] = useState(true);

  const stepper = ["template", "Audience", "Campaign", "General Report"];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={9}>
              <div className="profile">
                {/* <div className="actives">
                  <StepperHead data={stepper} />
                </div> */}
                <UpdateAudience setHide={setHide} hide={hide} />
                {!showHide && hide !== "upload" && (
                  <div className="saveBtn"></div>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <RightSideProfile />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
});

