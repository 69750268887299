import React, { useState, useEffect, useCallback } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Alert, Modal, Button, Label, Input, FormFeedback, Form } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";

import { storeDivisionAPI, getDivisionsAPI, updateDivisionAPI, deleteDivisionAPI } from "../../../../api/division.api";

import MountComponent from "../../../../module/mount-components";
import { errorMessage } from "../../../../module/error-methods";

import DeleteModal from "../../../../module/delete-modal.component";

export default React.memo(({ tab, userPermissions }) => {
    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [divisions, setDivisions] = useState([]);
    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const divisionList = async () => {
        setLoading(true);
        await getDivisionsAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setDivisions([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteDivisionAPI(moduleState.id);
            await divisionList();
            toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" SBU deleted successfully` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateDivisionFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateDivisionFormik.setValues({});
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const divisionInitialValues = { name: '' };

    const divisionValidation = {
        name: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters and spaces are allowed')
            .min(3, 'Name must be at least 3 characters')
            .trim().required('Name is required'),
    };

    const updateDivisionFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...divisionInitialValues },
        validationSchema: Yup.object({ ...divisionValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);

                await updateDivisionAPI(values.id, { name: values.name });
                await divisionList();

                toggleNotificationState({ visible: true, message: 'Success! The SBU has been updated successfully.' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); }
        },
    });

    const divisionFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...divisionInitialValues },
        validationSchema: Yup.object({ ...divisionValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);

                await storeDivisionAPI({ name: values.name });
                await divisionList();

                toggleNotificationState({ visible: true, message: 'Success! The SBU has been created.' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); }
        },
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    const dataTableHandler = () => {
        const columns = [
            {
                label: "Sr.No.",
                field: "sr",
                sort: "asc",
                width: 150,
            }, {
                label: "SBU Name",
                field: "sbuName",
                sort: "asc",
                width: 270,
            },
        ];
        const rows = [
            ...divisions.map((details, index) => ({
                sr: ++index,
                sbuName: `${details.name} (${details.id})`,
                action: (
                    <div className="editDataShow functionalBtn">
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.NETWORK_DETAILS_DELETE}>
                            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                    </div>
                ),
            })),
        ];

        if (userPermissions.NETWORK_DETAILS_UPDATE || userPermissions.NETWORK_DETAILS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
        }

        return updateDataTable({ columns: [...columns], rows: [...rows] });
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { if (tab === "DIVISION_TAB") { divisionList(); } }, [tab]);
    useEffect(() => { if (tab === "DIVISION_TAB") { divisionFormik.resetForm(); updateDivisionFormik.resetForm(); } }, [tab]);
    useEffect(() => {
        if (notificationState.visible) {
            setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000);
        }
    }, [notificationState.visible]);
    
    useEffect(() => { dataTableHandler() }, [divisions]);

    /** ---------------------------------------------------------------------------------------------------------------------------------- */

    return (
        <div className="">
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <MountComponent condition={userPermissions.NETWORK_DETAILS_CREATE}>
                <form className="row mainText" onSubmit={divisionFormik.handleSubmit}>
                    <div className="col-md-6 bg-transparent pe-0 mb-2" style={{ height: '40px' }}><div className="mainFormSubmit">
                        <div className="error-input">
                            <Input placeholder="Please enter division/sub name." className="h-100" name="name"
                                invalid={divisionFormik.touched.name && divisionFormik.errors.name}
                                value={divisionFormik.values.name}
                                onChange={divisionFormik.handleChange}
                            ></Input>
                            <FormFeedback valid={!divisionFormik.touched.name && !divisionFormik.isValid}>
                                {divisionFormik.errors.name}
                            </FormFeedback>
                        </div>
                        <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
                    </div></div>
                </form>
            </MountComponent>

            <div className="dataTable-hide-extra-props mt-3">
                <MDBDataTable responsive bordered data={dataTable} footer={false} />
            </div>

            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${moduleState.name} ?`}
                ></DeleteModal>
            </Modal> {/* Modal for Delete Division */}

            <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Edit Division/SBU</h5>
                    <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
                </div>
                <Form className="needs-validation" onSubmit={updateDivisionFormik.handleSubmit}>
                    <div className="modal-body"><div className="form-group mb-3">
                        <Label htmlFor="validationCustom01">Division/SBU name</Label>
                        <Input name="name" placeholder="Division name" type="text" className="form-control" id="validationCustom01"
                            onChange={updateDivisionFormik.handleChange}
                            onBlur={updateDivisionFormik.handleBlur}
                            value={updateDivisionFormik.values.name || ""}
                            invalid={updateDivisionFormik.touched.name && updateDivisionFormik.errors.name ? true : false}
                        />
                        <FormFeedback type={!updateDivisionFormik.touched.name && !updateDivisionFormik.errors.name}>
                            {updateDivisionFormik.errors.name}
                        </FormFeedback>
                    </div></div>

                    <div className="modal-footer">
                        <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>
                            Cancel
                        </button>
                        <button type="submit" className="nextMasterModal btn">
                            Update
                        </button>
                    </div>
                </Form>
            </Modal>{/* Modal for Edit Division */}
        </div>
    );
});
