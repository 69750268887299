import React, { useState, useEffect } from "react";
import { Col, Row, Form, Modal, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import deleteAudienceAPI from "../../../../api/deleteAudienceAPI";
import "../../../../assets/scss/custom/pages/datatables.scss";
import imageSource from "../../../../assets/images/add.png";
import StepperHead from "../../../../components/Common/StepperHead";
import MountComponent from "../../../../module/mount-components";

const stepper = ["template", "Audience", "Campaign", "General Report"];

export default React.memo(({ audienceData: audienceDetails }) => {
  const [audienceData, setAudienceData] = useState(audienceDetails);
  const navigate = useNavigate();
  const [audienceId, setAudienceId] = useState(null);
  const [audienceName, setAudienceName] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);

  const notificationInitialState = { visible: false, message: '', color: '' }
  const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

  const handleEditClick = (audienceId, audienceName) => {
    setAudienceId(audienceId);
    setAudienceName(audienceName);
  };

  useEffect(() => {
    // Update audienceData when initialAudienceData changes
    setAudienceData(audienceDetails);
  }, [audienceDetails]);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_delete() {
    setModalDelete(!modalDelete);
    removeBodyCss();
  }

  const handleAudienceRemove = async (audienceId) => {
    try {
      await deleteAudienceAPI(audienceId);
      setAudienceData((prevAudienceData) =>
        prevAudienceData.filter((audience) => audience.id !== audienceId)
      );

      setAudienceId(null);
      setModalDelete(false);
      toggleNotificationState({ visible: true, color: "success", message: "Audience Deleted Successfully" })
      // audienceDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (originalDateString) => {
    const date = new Date(originalDateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const data = {
    columns: [
      {
        label: "Sr.No.",
        field: "sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Audience List Name",
        field: "audienceListName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Users",
        field: "users",
        sort: "asc",
        width: 270,
      },
      {
        label: "Total Users",
        field: "totalUsers",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date of Creation",
        field: "dateOfCreation",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: [
      ...audienceData.map((item, i) => ({
        sr: ++i,
        audienceListName: item.name,
        users: item.source == "SALES_HIERARCHY" ? "Salesmen" : "Distributors",
        totalUsers: item.totalUsers,
        dateOfCreation: formatDate(item.createdAt),
        action: (
          <div className="functionalBtn editDataShow">
            <Link to={`/network-engage/audience/${item.id}`} className="btn btn-group border">
              <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
            </Link>
            <button
              className="btn btn-group border mx-2"
              onClick={() => {
                tog_delete();
                handleEditClick(item.id, item.name);
              }}
            >
              <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
            </button>
          </div>
        ),
      })),
    ],
  };

  return (
    <>
      <React.Fragment>
      <MountComponent condition={notificationState.visible}>
        <Alert className="pt-2 pb-2" color={notificationState.color}>
          { notificationState.message }
        </Alert>
      </MountComponent>

        <Row>
          <Col lg={6} md={6}>
            <div className="mt-4">
              <div className="mb-0">
                <h5
                  // className="form-label"
                  className="mt-3 fw-bold mb-3"
                  htmlFor="formrow-firstname-input"
                >
                  Audiences List
                </h5>
              </div>
            </div>
          </Col>

          <div className="col-md-6 mt-3">
            <div className="locationBtn">
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate("/network-engage/audience/create");
                }}
              >
                Add New
              </button>
            </div>
          </div>

          <div className="dataTable mt-4">
            <MDBDataTable
              responsive
              bordered
              disableRetreatAfterSorting={true}
              data={data}
              footer={false}
            />
          </div>

          <Col lg={12} className="d-flex" style={{ justifyContent: "center" }}>
            <div className="saveBtn">
              {/* <Link to={hide ? "/add-campaign" : ""}> */}
              <button
                className="btn btn-group"
                onClick={() => {
                  navigate("/network-engage/campaign/create");
                }}
              >
                Next
              </button>
              {/* </Link> */}
            </div>
          </Col>
        </Row>

        {/* Modal for Delete */}
        <Modal
          isOpen={modalDelete}
          toggle={() => {
            tog_delete();
          }}
          
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Delete
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalDelete(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete {audienceName}?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="cancelModal btn"
              onClick={() => {
                setModalDelete(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="nextMasterModal btn"
              onClick={() => handleAudienceRemove(audienceId)}
            >
              Delete
            </button>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
});

export const SetupAudience = React.memo(() => {
  return (
    <React.Fragment>
      <div className="actives">
        <StepperHead data={stepper}></StepperHead>
      </div>
      <div className="addSection">
        <div className="adds">
          <img src={imageSource} />
          <div className="newbtn">
            <Link
              className="btn btn-group"
              to="/network-engage/audience/create"
            >
              Add New
            </Link>
            <div className="mt-1">
              <p>
                <span>You don't have any Audience </span>
                <br />
                <span>Add New Audience to your Account</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
