import React, { useState, useEffect } from "react";
import lodash from "lodash";
import Select from "react-select";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert, Col, Form, Label, Input, FormFeedback } from "reactstrap";

import { getOrganizationUserAPI, updateOrganizationUserAPI } from "../../../../api/organization-users.api";

import { storeOrganizationModule } from "../../../../api/organizationModuleApi";
import getUserRolesAPI from '../../../../api/userRoleAPI'

import MountComponent from "../../../../module/mount-components";
import { errorMessage } from "../../../../module/error-methods";
import { styleReactSelect, themeReactSelect } from "../../../../module/select-methods";

import ROUTES from "../../../../routes/routes";
import PERMISSIONS from "../../../../routes/permissions";

export default React.memo(() => {
    const { NAME, CHILDREN: { ADMIN_USERS } } = PERMISSIONS.QUICK_START_GUIDE;

    const [loading, setLoading] = useState(false);
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });
    const [userRoles, updateUserRoles] = useState([]);

    const navigate = useNavigate();
    const { id: organizationUserId } = useParams();

    // @apis ----------------------------------------------------------------------------------------------------------------------------------

    const getUserRoles = async () => {
        try {
            setLoading(true);
            const { data: response } = await getUserRolesAPI();

            if (lodash.isArray(response.data)) {
                updateUserRoles([...response.data.map(({ id, name }) => ({ label: name, value: id }))])
            }
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    };

    const storeOrganizationUser = async (data) => {
        try {
            setLoading(true);
            await updateOrganizationUserAPI(organizationUserId, data);
            await storeOrganizationModule({ module: NAME, moduleLevel: ADMIN_USERS.NAME });

            toggleNotificationState({ visible: true, color: 'success', message: "Success! Organization user updated." });
            setTimeout(() => {
                navigate(ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS)
            }, 3000);
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    }

    const getOrganizationUser = async () => {
        try {

            const { data: response } = await getOrganizationUserAPI(organizationUserId);
            const { userRoleId, user } = response.data

            if (lodash.isObject(response.data)) { validation.setValues({ userRoleId, ...user }); }

        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { setLoading(true); getOrganizationUser(); }, [organizationUserId]);
    useEffect(() => {
        if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); }
    }, [notificationState.visible]);

    /** ------------------------------------------------------------------------------------------------------------------------------------- */

    const validation = useFormik({
        enableReinitialize: true,
        onSubmit: storeOrganizationUser,
        initialValues: {
            userRoleId: null,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
        },
        validationSchema: Yup.object({
            userRoleId: Yup.string().min(1, "Too Short!").max(50, "Too Long!").required("Please Enter User Role "),
            firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Please Enter First Name"),
            lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Please Enter Last Name"),
            email: Yup.string().email().required("Please Enter Your Email"),
            phoneNumber: Yup.string().matches(RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), "Invalid phone number").required("Phone is required"),
        }),
    });

    useEffect(() => { getUserRoles() }, []);

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} >
                {notificationState.message}
            </Alert>

            <Form className="form-horizontal" onSubmit={validation.handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                        <Label className="form-label">First Name</Label>
                        <Input
                            className="form-control  formControlCustom"
                            name="firstName"
                            type="text"
                            placeholder="Enter First Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                                validation.touched.firstName &&
                                    validation.errors.firstName
                                    ? true
                                    : false
                            }
                        />
                        <MountComponent condition={validation.touched.firstName && validation.errors.firstName}>
                            <FormFeedback type="invalid">
                                {validation.errors.firstName}
                            </FormFeedback>
                        </MountComponent>
                    </div></div></div>

                    <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                        <Label className="form-label">Last Name</Label>
                        <Input
                            className="form-control  formControlCustom"
                            name="lastName"
                            type="text"
                            placeholder="Enter Last Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                                validation.touched.lastName &&
                                    validation.errors.lastName
                                    ? true
                                    : false
                            }
                        />
                        <MountComponent condition={validation.touched.lastName && validation.errors.lastName}>
                            <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                        </MountComponent>
                    </div></div></div>

                    <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                        <Label className="form-label" >Mobile</Label>
                        <Input
                            id="Number"
                            name="phoneNumber"
                            className="form-control formControlCustom"
                            placeholder="+91 "
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phoneNumber || ""}
                            invalid={
                                validation.touched.phoneNumber &&
                                    validation.errors.phoneNumber
                                    ? true
                                    : false
                            }
                        />
                        <MountComponent condition={validation.touched.phoneNumber && validation.errors.phoneNumber}>
                            <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                        </MountComponent>
                    </div></div></div>

                    <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                        <Label className="form-label">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            className="form-control  formControlCustom"
                            placeholder="Enter your Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                                validation.touched.email && validation.errors.email
                                    ? true
                                    : false
                            }
                        />
                        <MountComponent condition={validation.touched.email && validation.errors.email}>
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        </MountComponent>
                    </div></div></div>

                    <Col lg={6} md={6}>
                        <div className="mt-4"><div className="form-group"><div className="mb-0">
                            <Label className="form-label" htmlFor="userRoleId" >User Role</Label>
                            <Select
                                value={lodash.find(userRoles, ({ value }) => value === validation.values.userRoleId) ?? null}
                                onChange={({ value }) => validation.setFieldValue('userRoleId', value)}
                                options={userRoles}
                                className="mainSelect" placeholder="Select Role"
                                theme={(theme) => themeReactSelect(theme, validation.errors.userRoleId)}
                                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, validation.errors.userRoleId) }}
                            ></Select>
                            <MountComponent condition={validation.touched.userRoleId && validation.errors.userRoleId}>
                                <small className="select-error">{validation.errors.userRoleId}</small>
                            </MountComponent>
                        </div></div></div>
                    </Col>

                    <div className="resumeBtn mt-4 text-center">
                        <button type="submit" className="btn btn-group"> Submit </button>
                        <Link className="btn btn-group mx-2" to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS}>Cancel</Link>
                    </div>
                </div>
            </Form>
        </React.Fragment>
    );
})
