import React, { useState, useEffect } from "react";
import lodash from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import Select from "react-select";
import { Alert, Form, Label, Input, FormFeedback } from "reactstrap";

import { storeOrganizationUserAPI } from "../../../../api/organization-users.api";
import { storeOrganizationModule } from "../../../../api/organizationModuleApi";
import getUserRolesAPI from '../../../../api/userRoleAPI'

import { errorMessage } from "../../../../module/error-methods";
import { MountCondition } from "../../../../module/mount-components";
import { styleReactSelect, themeReactSelect } from "../../../../module/select-methods";

import ROUTES from "../../../../routes/routes";
import PERMISSIONS from "../../../../routes/permissions";

export default React.memo(() => {
    const { NAME, CHILDREN: { ADMIN_USERS } } = PERMISSIONS.QUICK_START_GUIDE;

    const [loading, setLoading] = useState(false);
    const [userRoles, updateUserRoles] = useState([]);

    const notificationInitialState = { visible: false, message: '', color: '' }
    const [notificationState, toggleNotificationState] = useState({ ...notificationInitialState });

    const navigate = useNavigate();

    // @apis ----------------------------------------------------------------------------------------------------------------------------------

    const getUserRoles = async () => {
        setLoading(true);
        await getUserRolesAPI().then(({ data: response }) => {
            if (lodash.isArray(response.data)) {
                updateUserRoles([...response.data.map(({ id, name }) => ({ label: name, value: id }))])
            }
        }).catch(() => { });
        setLoading(false);
    };

    const storeOrganizationUser = async (data) => {
        try {
            setLoading(true);
            await storeOrganizationUserAPI(data);
            await storeOrganizationModule({ module: NAME, moduleLevel: ADMIN_USERS.NAME });

            toggleNotificationState({ visible: true, color: 'success', message: 'User creation completed. The new user is now active.' });
            setTimeout(() => { navigate(ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS); }, 3000);
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); }
    }

    /** ------------------------------------------------------------------------------------------------------------------------------------- */

    const validation = useFormik({
        enableReinitialize: true,
        onSubmit: storeOrganizationUser,
        initialValues: {
            userRoleId: null,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
        },
        validationSchema: Yup.object({
            userRoleId: Yup.string().min(1, "Too Short!").max(50, "Too Long!").required("Please select user role "),
            firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Please enter first name"),
            lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Please enter last name"),
            email: Yup.string().email().required("Please Enter Your Email"),
            phoneNumber: Yup.string().matches(RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), "Invalid phone number").required("Phone is required"),
        }),
    });

    /** @useEffects ----------------------------------------------------------------------------------------------------------------- */

        useEffect(() => { getUserRoles() }, []);
        useEffect(() => {
            if(notificationState.visible && notificationState.color === "success") { 
                setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 3000); 
            }
            return () => null;
        },[notificationState.visible]); 
        
    /** ----------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountCondition condition={loading}>
                <MountCondition.True>
                    <div className="fullscreen-loader"><div className="loader-container">
                        <div className="loader"></div>
                    </div></div>
                </MountCondition.True>
            </MountCondition>
            <MountCondition condition={notificationState.color === "success"}>
                <MountCondition.True>
                    <Alert color={notificationState.color} isOpen={notificationState.visible} toggle={() => toggleNotificationState({ ...notificationState, visible: false })} >
                        {notificationState.message}
                    </Alert>
                </MountCondition.True>
                <MountCondition.False>
                    <Alert color={notificationState.color} isOpen={notificationState.visible} >
                        {notificationState.message}
                    </Alert>
                </MountCondition.False>
            </MountCondition>

            <Form className="form-horizontal row" onSubmit={validation.handleSubmit}>
                <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label">First Name</Label>
                    <Input
                        className="form-control  formControlCustom"
                        name="firstName"
                        type="text"
                        placeholder="Enter First Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                            validation.touched.firstName &&
                                validation.errors.firstName
                                ? true
                                : false
                        }
                    />
                    {validation.touched.firstName &&
                        validation.errors.firstName ? (
                        <FormFeedback type="invalid">
                            {validation.errors.firstName}
                        </FormFeedback>
                    ) : null}

                </div></div></div>

                <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label">Last Name</Label>
                    <Input
                        className="form-control  formControlCustom"
                        name="lastName"
                        type="text"
                        placeholder="Enter Last Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        invalid={
                            validation.touched.lastName &&
                                validation.errors.lastName
                                ? true
                                : false
                        }
                    />
                    {validation.touched.lastName &&
                        validation.errors.lastName ? (
                        <FormFeedback type="invalid">
                            {validation.errors.lastName}
                        </FormFeedback>
                    ) : null}
                </div></div></div>

                <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                    <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                    >
                        Mobile
                    </Label>
                    <Input
                        id="Number"
                        name="phoneNumber"
                        className="form-control formControlCustom"
                        placeholder="+91 "
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phoneNumber || ""}
                        invalid={
                            validation.touched.phoneNumber &&
                                validation.errors.phoneNumber
                                ? true
                                : false
                        }
                    />
                    {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                        <FormFeedback type="invalid">
                            {validation.errors.phoneNumber}
                        </FormFeedback>
                    ) : null}
                </div></div></div>

                <div className="col-md-6 col-sm-12"><div className="mt-4"><div className="mb-0">
                    <Label className="form-label">Email</Label>
                    <Input
                        id="email"
                        name="email"
                        className="form-control  formControlCustom"
                        placeholder="Enter your Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                            validation.touched.email && validation.errors.email
                                ? true
                                : false
                        }
                    />
                    {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                            {validation.errors.email}
                        </FormFeedback>
                    ) : null}
                </div></div></div>

                <div className="col-md-6 col-sm-12"><div className="mt-4">
                    <div className="form-group"><div className="mb-0">
                        <Label className="form-label" htmlFor="userRoleId" >User Role</Label>
                        <Select
                            value={lodash.find(userRoles, ({ value }) => value === validation.values.userRoleId) ?? null}
                            onChange={({ value }) => validation.setFieldValue('userRoleId', value)}
                            options={userRoles}
                            className="" placeholder="Select Role"
                            theme={(theme) => themeReactSelect(theme, validation.errors.userRoleId)}
                            styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, validation.errors.userRoleId) }}
                        ></Select>
                        {validation.touched.userRoleId && validation.errors.userRoleId ?
                            (<small className="text-danger mt-0" style={{ fontSize: '10px' }}>{validation.errors.userRoleId}</small>) : null
                        }
                    </div></div>
                </div>
                </div>

                <div className="resumeBtn mt-4 text-center">
                    <button type="submit" className="btn btn-group"> Submit </button>
                    <Link className="btn btn-group mx-2" to={ROUTES.ORGANIZATION_DETAILS.CHILDREN.ADMIN_USERS}>Cancel</Link>
                </div>
            </Form>
        </React.Fragment>
    );
})
