import React from "react";
import { Link } from "react-router-dom";

import image from "../../../../assets/images/add.png";
import { MountCondition } from "../../../../module/mount-components";

import ROUTES from "../../../../routes/routes";

export default React.memo(({ userPermissions }) => (
    <div className=""><div className="text-center">
        <MountCondition condition={userPermissions.PRODUCT_CREATE}>
            <MountCondition.True>
                <img src={image} />
                <div className="text-center mt-2 mb-5">
                    <Link to={ROUTES.CATALOG_MANAGER.CHILDREN.CREATE_PRODUCT} className="btn btn-primary mb-3">Add Product</Link><br />
                    <strong className="mt-5">Add New Product</strong>
                </div>
            </MountCondition.True>
            <MountCondition.False>
                <strong className="mt-5 mb-5">No Products</strong>
            </MountCondition.False>
            </MountCondition>
    </div></div>
));
