import { Link, useNavigate } from "react-router-dom";
import four from "../../../assets/images/403.png";
import ROUTES from '../../../routes/routes';

export default () => {
  const navigate = useNavigate();

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="zone">
              <div className="profile">
                <div className="fourzeros-section">
                  <img src={four} />
                  <div className="four-content">
                    <h2>You are not authorized.</h2>
                    <p>
                      The page you're trying to access has restricted access.
                    </p>
                    <p>Please refer to your system administrator</p>
                    <div className="zero-btn">
                      {/* <button className="btn btn-primary" onClick={() => navigate(-1)}>Go Back</button> */}
                      <Link to={ROUTES.HOME.PATH} className="btn btn-primary mx-3">Back to Dashboard</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
