import config,{server} from '../config/config'

export const updateDistributionHierarchyAPI = async (params,id) => {
    
    return await server({
        method  : 'put',
        url     : `distribution-hierarchy/${id.id}`,
        data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
}

export default updateDistributionHierarchyAPI