import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import store from "store";

import * as Yup from "yup";
import * as XLSX from 'xlsx';

import { useDropzone } from 'react-dropzone';
import { useFormik } from "formik";
import { Alert } from "reactstrap";

import MountComponent, { MountCondition } from "../../../../module/mount-components.jsx";
import { errorMessage, toastErrorMessage } from "../../../../module/error-methods.js";

import { checkPermissions } from "../../../../api/organization-users.api.js";

import { getDivisionsAPI } from "../../../../api/division.api.js";
import { getNetworkLocationsAPI } from "../../../../api/network-location.api.js";
import { getDistributionHierarchiesAPI } from "../../../../api/distribution-hierarchy.api.js";

import { getChannelPartnersAPI, updateChannelPartnerAPI, deleteChannelPartnerAPI } from "../../../../api/channel-partner.api.js";
import { getChannelPartnerImportInfoAPI, uploadMultipleChannelPartnerAPI } from "../../../../api/channel-partner.api.js";

import PERMISSIONS from '../../../../routes/permissions.js';

import Info from "./info.jsx";
import Table from "./table.jsx";
import XlSXImport from "./xlsx-import.jsx";
import GoogleMap from "./google-map.jsx";

import { xlsxExport } from "../../../../util/xlsx.util.js";
import VectorMap from "./vector-map.jsx";

export default React.memo(() => {
    const { CHILDREN: { CHANNEL_PARTNER } } = PERMISSIONS.NETWORK_MANAGER;

    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [loading, setLoading] = useState(false);
    const [filters, updateFilters] = useState({ divisionId: null, designationId: null, locationId: null });

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);
    const [importModal, toggleImportModal] = useState(false);

    /** @vector @map @props ------------------------------------------------------------------------------------------- */

    const [vectorMap, toggleVectorMap] = useState(false);
    const [vectorMapProps, setVectorMapProps] = useState({ props: [] });

    /** --------------------------------------------------------------------------------------------------------------- */

    /** @google @map @props ------------------------------------------------------------------------------------------- */

    const [mapPreview, toggleMapPreview] = useState(false);
    const [googleMapMarks, setGoogleMapMarks] = useState([]);
    const [mapCenter, setCenter] = useState({ lat: 24.5854, lng: 73.7125 });

    /** -------------------------------------------------------------------------------------------------------------- */

    const [channelPartnerState, setChannelPartnerState] = useState({});
    const [channelPartners, setChannelPartners] = useState([]);

    const [apiStatus, setApiStatus] = useState('pending');
    const [notificationState, toggleNotificationState] = useState({ visible: false, color: '', message: '' });

    const [userPermissions, setUserPermissions] = useState({
        CHANNEL_PARTNER_CREATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        CHANNEL_PARTNER_VIEW: userDetails.userRole === "ORGANIZATION" ? true : false,
        CHANNEL_PARTNER_UPDATE: userDetails.userRole === "ORGANIZATION" ? true : false,
        CHANNEL_PARTNER_DELETE: userDetails.userRole === "ORGANIZATION" ? true : false,
    });

    /** @options -------------------------------------------------------------------------------------------------------------------- */

    const [divisionOptions, setDivisionOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    /** @apis ----------------------------------------------------------------------------------------------------------------------- */

    const getDivisionOptions = async () => {
        await getDivisionsAPI().then(({ data }) => {
            if (lodash.isArray(data)) {
                const selectOptions = { label: '--Select Division--', value: null };
                const options = data.map(({ name, id }) => ({ label: name, value: id }));

                return setDivisionOptions(lodash.concat(selectOptions, options));
            }
        }).catch((error) => toastErrorMessage(error));
    };

    const getDesignationOptions = async () => {
        const divisionId = updateChannelPartnerFormik.values.divisionId ?? filters.divisionId
        if (!divisionId) { return setDesignationOptions([]); }

        setLoading(true); updateFilters({ ...filters, designationId: null });
        await getDistributionHierarchiesAPI({ divisionId }).then(({ data }) => {
            if (lodash.isArray(data)) {
                const selectOption = { label: '--Select Hierarchy--', value: null };
                const options = data.map(({ name, id }) => ({ label: name, value: id }));

                return setDesignationOptions(lodash.concat(selectOption, options));
            }
        }).catch((error) => toastErrorMessage(error));
        setLoading(false);
    };

    const getLocationOptions = async () => {
        const divisionId = updateChannelPartnerFormik.values.divisionId ?? filters.divisionId
        if (!divisionId) { return setLocationOptions([]); }

        setLoading(true); updateFilters({ ...filters, locationId: null });
        await getNetworkLocationsAPI({ divisionId }).then(({ data }) => {
            if (lodash.isArray(data)) {
                const selectOptions = { label: '--Select Location--', value: null };
                const options = data.map(({ location, id }) => ({ label: location, value: id }));

                return setLocationOptions(lodash.concat(selectOptions, options));
            }
        }).catch((error) => toastErrorMessage(error));
        setLoading(false);
    };


    const getChannelPartners = async () => {
        try {
            const { data } = await getChannelPartnersAPI({ ...filters });
            if (lodash.isArray(data)) { setChannelPartners(data); }
        } catch (error) {
            toastErrorMessage(error)
        } finally { setLoading(false); setApiStatus('COMPLETED'); }
    }

    const getPermissions = async () => {
        const modulePermissions = CHANNEL_PARTNER.CHILDREN;
        const searchPermissions = lodash.map(modulePermissions, (data) => data.NAME);

        await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
            const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
            return setUserPermissions({ ...userPermissions, ...response });
        }).catch(() => { });
    }

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @handlers ------------------------------------------------------------------------------------------------------------------ */

    const handleXLSXExport = useCallback(() => {
        const response = lodash.map(channelPartners, (details, index) => ({
            "Sr.No": ++index,
            "Channel Partner Name": `${details.firstName} ${details.lastName}`,
            "Mobile": details.mobileNo,
            "Email": details.email,
            "SBU/Division": details.divisions.name,
            "Designation": details.distributionHierarchy.name,
            "Location": details.networkLocation.location
        }));

        xlsxExport(response, "network-channel-partners.xlsx");
    });

    const handleGoogleMap = useCallback(() => {

        const primaryLocation = lodash.get(channelPartners, `[0]['networkLocation']`, {});
        const channelPartnersLocation = lodash.map(channelPartners, ({ networkLocation: location }, index) => ({
            key: ++index,
            title: location.location,
            position: { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) },
        }));

        if (lodash.isNaN(primaryLocation.latitude) && lodash.isNaN(primaryLocation.longitude)) {
            const latitude = parseFloat(primaryLocation.latitude);
            const longitude = parseFloat(primaryLocation.longitude);
            setCenter({ lat: latitude, lng: longitude });
        }

        setGoogleMapMarks([...channelPartnersLocation]);
        toggleMapPreview(true);
    });

    const handleDeleteCallback = useCallback((data = {}) => {
        setChannelPartnerState({ ...data });
        toggleDeleteModal(true);
    });

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteChannelPartnerAPI(channelPartnerState.id);
            await getChannelPartners();

            toggleNotificationState({ visible: true, color: 'success', message: 'Your channel partner has been deleted successfully!' });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setChannelPartnerState({}) }
    });

    const handleUpdateCallback = useCallback((data = {}) => {
        const updateData = lodash.pick(data, lodash.keys(updateChannelPartnerInitialValues));

        setChannelPartnerState({ ...data });
        toggleUpdateModal(true);
        return updateChannelPartnerFormik.setValues({ ...updateData });
    });

    const handleFilter = useCallback((option, properties) => {
        return updateFilters({ ...filters, [properties.name]: option.value });
    }, [filters]);

    const handleVectorMap = useCallback(() => {
        const colors = ["#D2DAFF", "#FF96B2", "#F9C981", "#89EFA0", "#F2D2BD", "#BEE3F8", "#FFD1DC", "#FFE7B2", "#C1F3C1", "#F9E3D0"];
        const props = []; const colorGroups = [100];

        const uniqueLocations = lodash.uniqBy(channelPartners, (data) => data.networkLocation.state);
        const groupedByRegion = lodash.groupBy(uniqueLocations, 'networkLocation.regionId');

        lodash.map(groupedByRegion, (group, index) => {
            const states = lodash.map(group, ({ networkLocation: { state } }) => state);
            const region = lodash.get(group, `[0].networkLocation.regions`, {});

            colorGroups.push(index);
            props.push({ label: region.name, states, region, color: colors[(index % colors.length)] });
        });

        setVectorMapProps({ props: props, colorGroups: colorGroups })

        return toggleVectorMap(true);
    }, [channelPartners]);

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @formik -------------------------------------------------------------------------------------------------------------------- */

    const updateChannelPartnerInitialValues = {
        firstName: null,
        lastName: null,
        mobileNo: null,
        email: null,
        location: null,
        designation: null,
        divisionId: null,
    }

    const updateChannelPartnerValidation = {
        firstName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter firstName"),
        lastName: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter lastName"),
        mobileNo: Yup
            .string()
            .required('Mobile number is required.')
            .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits.')
            .test('positive',
                'Mobile number must be positive.',
                (val) => parseInt(val) > 0
            ),
        email: Yup.string()
            .min(1, "Too Short!")
            .max(50, "Too Long!")
            .required("Please Enter email "),
        divisionId: Yup.string().required("Please select division"),
        designation: Yup.string().required("Please select designation"),
        location: Yup.string().required("Please select location"),
    }

    const updateChannelPartnerFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...updateChannelPartnerInitialValues },
        validationSchema: Yup.object({ ...updateChannelPartnerValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {

                setLoading(true);

                await updateChannelPartnerAPI(channelPartnerState.id, { ...values })
                await getChannelPartners();

                toggleNotificationState({ visible: true, color: 'success', message: 'Your channel partner has been successfully updated!' });
                resetForm();

            } catch (error) {
                toastErrorMessage(error);
            } finally { setLoading(false); toggleUpdateModal(false); setChannelPartnerState({}); }
        },
    });

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /** @useEffects ---------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { if (userDetails.userRole !== "ORGANIZATION") { getPermissions(); } }, []);

    useEffect(() => { getDivisionOptions(); }, []);

    useEffect(() => { getDesignationOptions() }, [updateChannelPartnerFormik.values.divisionId]);
    useEffect(() => { getLocationOptions() }, [updateChannelPartnerFormik.values.divisionId]);

    useEffect(() => { getDesignationOptions(); }, [filters.divisionId]);
    useEffect(() => { getLocationOptions(); }, [filters.divisionId]);
    useEffect(() => { setLoading(true); getChannelPartners(); }, [filters.divisionId, filters.designationId, filters.locationId]);
    useEffect(() => {
        if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 2000); }
    }, [notificationState.visible]);

    /** ---------------------------------------------------------------------------------------------------------------------------- */

    /**  @import ------------------------------------------------------------------------------------------------------------------- */

    const [xlsxData, updateXLSXData] = useState([]);
    const [xlsxNotificationState, toggleXLSXNotificationState] = useState({ visible: false, color: "danger" });

    const xlsxDataDataTable = {
        columns: [
            { label: "Sr.No.", field: "srn", sort: "asc", width: 100 },
            { label: "Name", field: "partnerName", sort: "asc", width: 300 },
            { label: "Mobile", field: "mobileNo", sort: "asc", width: 270 },
            { label: "Email", field: "email", sort: "asc", width: 150 },
            { label: "SBU/Division", field: "divisionId", sort: "asc", width: 100 },
            { label: "Designation", field: "designation", sort: "asc", width: 100 },
            { label: "Location", field: "location", sort: "asc", width: 150 },
            { label: "Action", field: "action", sort: "disabled", width: 100 },
        ],
        rows: [...xlsxData.map((data, index) => ({
            srn: index + 1,
            partnerName: `${data.firstName} ${data.lastName}`,
            mobileNo: data.mobileNo,
            email: data.email,
            divisionId: data.divisionName,
            designation: data.designationName,
            location: data.locationName,
            action: (
                <button className="btn btn-group" onClick={() => { handleUploadModalDelete(data.uuid); }}>
                    <i className="fas fa-trash-alt" style={{ color: "red" }}></i>
                </button>
            ),
        })),
        ],
    };

    const handleUploadModalDelete = useCallback((deleteUuid) => {
        const updatedData = lodash.filter(lodash.clone(xlsxData), ({ uuid }) => deleteUuid !== uuid);
        updateXLSXData(lodash.clone(updatedData));
    }, [xlsxData]);

    const handleUploadModal = useCallback(() => {
        updateXLSXData([]); toggleImportModal(false);
    });

    const handleUploadSubmit = useCallback(async () => {
        try {
            if (lodash.isEmpty(xlsxData)) {
                return toggleNotificationState({ visible: true, color: 'danger', message: "Please upload file." });
            }

            const excludeProperties = ["uuid", "designationName", "divisionName", "locationName"];
            const payload = lodash.map(xlsxData, (data) => lodash.omit(data, [...excludeProperties]));

            // const locationsWithDetails    = lodash.filter(payload, data => lodash.every(data, value => value !== null));
            // const locationsWithoutDetails = lodash.filter(payload, data => lodash.some(data, lodash.isNull) );

            // console.clear();
            // console.log('payload with details:','\n\n',locationsWithDetails);
            // console.log('payload without details:','\n\n',locationsWithoutDetails);

            // return void 0;

            await uploadMultipleChannelPartnerAPI(payload);
            await getChannelPartners();

            toggleNotificationState({ visible: true, color: 'success', message: "Data imported successfully!" });

        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: error.message });
        } finally { updateXLSXData([]); toggleImportModal(false); setLoading(false); }

    }, [xlsxData]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            return toggleXLSXNotificationState({ visible: true, message: "Please select one .xlsx file." });
        }

        const file = acceptedFiles[0];
        const reader = new FileReader();
        const requiredFields = [
            "First Name",
            "Last Name",
            "Email",
            "Mobile",
            "Distribution Designation ID",
            "Location ID",
            "Division ID",
        ];
        const preparePayload = (data) =>
            lodash.map(data, (data, index) => ({
                uuid: `uuid-${index * 2}`,
                firstName: data["First Name"],
                lastName: data["Last Name"],
                email: data["Email"],
                mobileNo: String(data["Mobile"]),
                designation: data["Distribution Designation ID"],
                location: data["Location ID"],
                divisionId: data["Division ID"],
            }));

        reader.onload = async (e) => {
            try {

                setLoading(true);

                const binaryData = e.target.result;
                const workbook = XLSX.read(binaryData, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                const headerRow = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })[0];

                const missingFields = lodash.difference(requiredFields, headerRow);
                const extraFields = lodash.difference(headerRow, requiredFields);
                const unfilledRows = sheetData.filter((data) =>
                    requiredFields.some((field) => !data.hasOwnProperty(field))
                );

                if (lodash.size(missingFields) > 0) {
                    return toggleXLSXNotificationState({ visible: true, message: "Wrong file uploaded." });
                }
                if (lodash.size(extraFields) > 0) {
                    return toggleXLSXNotificationState({ visible: true, message: "Wrong file uploaded. Extra fields" });
                }
                if (lodash.size(sheetData) === 0) {
                    return toggleXLSXNotificationState({ visible: true, message: "Please fill the sheet before upload." });
                }
                // if (lodash.size(unfilledRows) > 0) {
                //     return toggleXLSXNotificationState({
                //         visible: true, message: "Some rows have unfilled fields. Please check the data."
                //     });
                // }

                // const duplicateRows = lodash(sheetData)
                //     .groupBy((data) => JSON.stringify(data))
                //     .filter((group) => group.length > 1)
                //     .value();

                // if (lodash.size(duplicateRows) > 0) {
                //     return toggleXLSXNotificationState({
                //         visible: true,
                //         message: "Some rows have matching fields with other rows. Please check the data."
                //     });
                // }

                const payload = lodash.map(preparePayload(sheetData), (data) => lodash.omit(data, "uuid"));
                const response = await getChannelPartnerImportInfoAPI(payload);

                if (lodash.size(response.data.result) === 0) {
                    return toggleXLSXNotificationState({ visible: true, message: "Kindly check the data and reupload" });
                } else {
                    return updateXLSXData(response.data.result);
                }

            } catch (error) {
                toggleXLSXNotificationState({ visible: true, color: 'danger', message: errorMessage(error.message) });
            } finally { setLoading(false); }
        };

        reader.readAsBinaryString(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible} toggle={() => toggleNotificationState({ visible: false, color: '', message: '' })} >
                {notificationState.message}
            </Alert>

            <MountComponent condition={apiStatus === "COMPLETED"}>
                <MountCondition condition={lodash.isEmpty(channelPartners) && lodash.every(filters, prop => lodash.isNull(prop))}>
                    <MountCondition.True>
                        <Info userPermissions={userPermissions}></Info>
                    </MountCondition.True>
                    <MountCondition.False>
                        <MountComponent condition={mapPreview}>
                            <GoogleMap
                                toggleMapPreview={toggleMapPreview}
                                googleMapMarks={googleMapMarks}
                                mapCenter={mapCenter}
                            ></GoogleMap>
                        </MountComponent>
                        <MountCondition condition={vectorMap}>
                            <MountCondition.True>
                                <VectorMap
                                    toggleVectorMap={toggleVectorMap}
                                    vectorMapProps={vectorMapProps}
                                ></VectorMap>
                            </MountCondition.True>
                            <MountCondition.False>
                                <Table
                                    userPermissions={userPermissions}
                                    updateChannelPartnerFormik={updateChannelPartnerFormik}

                                    channelPartners={channelPartners}
                                    channelPartnerState={channelPartnerState}

                                    deleteModal={deleteModal}
                                    toggleDeleteModal={toggleDeleteModal}
                                    handleDeleteCallback={handleDeleteCallback}
                                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}

                                    updateModal={updateModal}
                                    handleUpdateCallback={handleUpdateCallback}

                                    toggleUpdateModal={toggleUpdateModal}
                                    handleXLSXExport={handleXLSXExport}

                                    divisionOptions={divisionOptions}
                                    designationOptions={designationOptions}
                                    locationOptions={locationOptions}

                                    toggleImportModal={toggleImportModal}
                                    handleGoogleMap={handleGoogleMap}
                                    handleVectorMap={handleVectorMap}

                                    filters={filters}
                                    handleFilter={handleFilter}
                                ></Table>
                            </MountCondition.False>
                        </MountCondition>
                        <XlSXImport
                            importModal={importModal}
                            handleUploadModal={handleUploadModal}
                            handleUploadSubmit={handleUploadSubmit}

                            xlsxData={xlsxData}
                            xlsxDataDataTable={xlsxDataDataTable}

                            xlsxNotificationState={xlsxNotificationState}
                            toggleXLSXNotificationState={toggleXLSXNotificationState}

                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                        ></XlSXImport>
                    </MountCondition.False>
                </MountCondition>
            </MountComponent>
        </React.Fragment>)
});