import { useEffect, useState } from "react";
import lodash from "lodash";

import getOrganizationModules from "../api/organizationModuleApi"

export const useHttpRequest = (params) => {
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const httpRequest = async () => {
        try {
            const { data: response } = await getOrganizationModules(params);
            const levels = lodash.isArray(response.data) ? response.data : [];
            return setArray(lodash.map(levels, 'moduleLevel'));
        } catch (error) { setArray([]); setError(error.message) } finally { setLoading(false); }
    };

    useEffect(() => { httpRequest(); return () => null }, []);

    return { array, error, loading };
};

export const useNetworkHook = (storedModules, callback) => {
    const [state, updateState] = useState({ path: '', label: 'Loading...' });

    useEffect(() => {
        updateState(callback(storedModules));
    }, [storedModules]);

    return { step: state }
}
