import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash";
import store from "store";
import { Link } from "react-router-dom";

import TimelineGuide from "../../timeline-guide";
import RightSideProfile from "../../../Dashboard/right-side-profile";

import MountComponent, { MountCondition } from "../../../../module/mount-components";
import { errorMessage } from "../../../../module/error-methods";

import { checkPermissions } from "../../../../api/organization-users.api";
import getProductListAPI from "../../../../api/getProductListAPI.js";
import deleteProductAPI from "../../../../api/deleteProductAPI";

import Info from "./info";
import Table from "./table";

import PERMISSIONS from '../../../../routes/permissions.js';
import config from "../../../../config/config.js";
import ROUTES from "../../../../routes/routes.js";

export default () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Product | Giffy Network";

    const showGirl = false;
    const { CHILDREN: { PRODUCT } } = PERMISSIONS.CATALOG_MANAGER;

    const userSession = store.get('session');
    const userDetails = lodash.get(userSession, 'data', {});

    const [apiStatus, setApiStatus] = useState('pending');
    const [loading, setLoading] = useState(true);
    const [notificationState, toggleNotificationState] = useState({ visible: false });
    
    const [deleteModal, toggleDeleteModal] = useState(false);

    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });
    const [filters, updateFilters] = useState({});

    const [productDetails, setProductDetails] = useState({});
    const [productList, updateProductList] = useState([]);

    const [userPermissions,setUserPermissions] = useState({
        PRODUCT_CREATE: userDetails.userRole === "ORGANIZATION" ? true: false,
        PRODUCT_VIEW:   userDetails.userRole === "ORGANIZATION" ? true: false,
        PRODUCT_UPDATE: userDetails.userRole === "ORGANIZATION" ? true: false,
        PRODUCT_DELETE: userDetails.userRole === "ORGANIZATION" ? true: false,
    });

    const statusOptions = [
        { label: "All", value: "All" },
        { label: "Publish", value: "Publish" },
        { label: "Draft", value: "Draft" },
    ];

    /** @apis ----------------------------------------------------------------------------------------------------------------------- */

        const getProducts = async () => {
            try {
                const { data: response } = await getProductListAPI(filters);
                updateProductList(response.data);
            } catch (error) {
            } finally { setLoading(false); setApiStatus('completed') }
        };

        const getPermissions = async () => {
            const modulePermissions = PRODUCT.CHILDREN;
            const searchPermissions = lodash.map(modulePermissions,(data) => data.NAME);
    
            await checkPermissions({ searchKeywords: [...searchPermissions] }).then(({ data }) => {
              const response = lodash.mapValues(lodash.mapKeys(data.data, 'name'), 'permission');
              return setUserPermissions({ ...userPermissions, ...response });
            }).catch(() => {});
        }
    
    /** @handlers ------------------------------------------------------------------------------------------------------------------ */
    
        const handleDeleteModalCallback = useCallback((details = {}) => {
            setProductDetails({ ...details });
            toggleDeleteModal(true);
        });
        
        const handleConfirmDeleteCallback = useCallback(async () => {
            try {
                await deleteProductAPI(productDetails.id);
                await getProducts();
                toggleNotificationState({ visible: true, color: 'success', message: `"${productDetails.name}" deleted successfully !` });
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleDeleteModal(false); setProductDetails({}) }
        });

    /** ---------------------------------------------------------------------------------------------------------------------------------- */
        const dataTableHandler = () => {
            const columns = [{
                label: "Sr.No.",
                field: "sno",
                sort: "asc",
                width: 100,
            },{
                label: "Image",
                field: "image",
                sort: "asc",
                width: 270,
            },{
                label: "Product",
                field: "product",
                sort: "asc",
                width: 200,
            },{
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },{
                label: "Type",
                field: "type",
                sort: "asc",
                width: 150,
            },{
                label: "Brand",
                field: "brand",
                sort: "asc",
                width: 100,
            },{
                label: "Action",
                field: "action",
                sort: "asc",
                width: 100,
            }];
        
            const rows = [...productList.map((details, index) => ({
                sno: ++index,
                image: (<><img style={{ height: "50px", width: "50px" }} src={`${config.api.base_url}public/products/${details.image}`}/></>),
                product: details.title,
                status: "Active",
                type: details.types?.name,
                brand: details.brands?.name,
                action: (
                <div className="functionalBtn editDataShow">
                    <MountComponent condition={userPermissions.PRODUCT_UPDATE}>
                        <Link className="btn btn-group border" to={ROUTES.CATALOG_MANAGER.CHILDREN.UPDATE_PRODUCT.replace(':id',details.id)}>
                            <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                        </Link>
                    </MountComponent>
                    <MountComponent condition={userPermissions.PRODUCT_DELETE}>
                        <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                            <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                        </button>
                    </MountComponent>
                </div>
                ),
            }))];

            if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
                columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
            }
    
            return updateDataTable({ columns: [...columns], rows: [...rows] });
        }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */
        
        useEffect(() => { if(userDetails.userRole !== "ORGANIZATION") { getPermissions(); } },[]);
        
        useEffect(() => { setLoading(true); getProducts() }, [filters]);
        useEffect(() => { dataTableHandler() }, [productList]);

        useEffect(() => {
            if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
        }, [notificationState.visible]);

    /** ---------------------------------------------------------------------------------------------------------------------------------- */

    return (
        <React.Fragment>
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <div className="page-content"><div className="container-fluid"><div className="row">
                <div className="col-lg-12"><div className="profile">
                    <div className="actives">
                        <TimelineGuide heading="Product List" activePage={PRODUCT}></TimelineGuide>
                    </div>
                    <MountComponent condition={apiStatus === "completed"}>
                        <MountCondition condition={lodash.isEmpty(productList) && lodash.isEmpty(filters)}>
                            <MountCondition.True>
                                <Info userPermissions={userPermissions}></Info>
                            </MountCondition.True>
                            <MountCondition.False>
                                <Table
                                    statusOptions={statusOptions}
                                    dataTable={dataTable}
                                    moduleState={productDetails}
                                    notificationState={notificationState}

                                    filters={filters}
                                    updateFilters={updateFilters}

                                    deleteModal={deleteModal}
                                    toggleDeleteModal={toggleDeleteModal}
                                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                                ></Table>
                            </MountCondition.False>
                        </MountCondition>
                    </MountComponent>
                </div></div>
                <div className="col-lg-3">
                    <MountComponent condition={showGirl}><RightSideProfile /></MountComponent>
                </div>
            </div></div></div>
        </React.Fragment>
    );
};
