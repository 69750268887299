import React, { useCallback, useEffect, useState } from "react";
import lodash from "lodash"
import { MountCondition } from '../../module/mount-components'

import getProductDetailAPI from "../../api/getProductDetailsAPI";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";
import { useParams } from "react-router-dom"
import config from "../../config/config";

export default () => {

    const params = useParams();
    
    const [loading,setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState("variant-0");
    const [productDetails, setProductDetails] = useState({});
    const [productVariantDetails, setProductVariantDetails] = useState({});

    const getProductDescription = async () => {
        try {

            setLoading(true);
            const { data : response } = await getProductDetailAPI(params.id);

            setProductDetails(response);
            setProductVariantDetails(lodash.get(response,'productVariants[0]', {}))
            setLoading(false);
        } catch (error) { console.log('Product Detail Error : ', error); setLoading(false); }
    }

    useEffect(() => { getProductDescription() }, []);

    const handleProductVariant = useCallback((variant,index) => {
        setActiveTab(`variant-${index}`)
        setProductVariantDetails({ ...variant })
    },[productVariantDetails])

  return (
    <React.Fragment>
        <MountCondition condition={loading}>
            <MountCondition.True>
                <div className="fullscreen-loader"><div className="loader-container">
                    <div className="loader"></div>
                </div></div>
            </MountCondition.True>
        </MountCondition>
        <div className="profile" style={{ background: "#F5F6F8 !important" }}>
            <div className="catalog-header-box">
                <h5 style={{ fontWeight: 'bold' }}>Product Details</h5>
            </div>

            <div className="row">
                {/* <div className="col-12"><pre>{JSON.stringify(productVariantDetails,null,2)}</pre></div> */}
                <div className="col-xl-5 col-lg-5 col-md-5 bg-transparent"><div className="row mt-3">
                      <div className="bg-transparent col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <Nav className="flex-column" pills>
                              { (productDetails.productVariants ?? []).map((variant,index) => (
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === "1", })} onClick={() => { handleProductVariant(variant,index); }} >
                                            <img src={`${config.api.base_url}public/products/${variant.image}`} alt="" className="img-fluid mx-auto d-block tab-img rounded pointer" />
                                        </NavLink>
                                    </NavItem>
                              )) }
                          </Nav>
                      </div>

                      <div className="bg-transparent col-9">
                          <TabContent activeTab={activeTab} className="position-relative">
                              { (productDetails.productVariants ?? []).map((variant,index) => ( 
                                  <TabPane tabId={`variant-${index}`}>
                                      <div className="h-75">
                                          <img src={`${config.api.base_url}public/products/${variant.image}`} alt="" id="expandedImg1" className="img-fluid mx-auto d-block tab-img rounded" />
                                      </div>
                                  </TabPane>
                              )) }
                          </TabContent>
                      </div>
                </div></div>

                <div className="col-xl-7 col-lg-7 col-md-7"><div className="mt-4 mt-xl-3 ps-xl-4">
                      <h4 className="font-size-20">{productDetails.title}</h4>
                      <h5 className="pt-2 mb-3">
                          <del className="text-muted me-2">${ productVariantDetails.mrp }</del>${productVariantDetails.regularPrice}
                      </h5>

                      <div><div className="row">
                          <h5 className="font-size-14 mt-3 col-12 bg-transparent">Specification :</h5>
                          <div className="col-lg-12"><div className="mt-2">
                              <ul className="list-unstyled product-desc-list text-muted new-list-decor">
                                  <li>
                                      <span><strong>Category</strong></span>
                                      <span>{ productDetails.subCategories?.name }</span>
                                  </li>
                                  <li>
                                      <span><strong>Brand</strong></span>
                                      <span>{ productDetails.brands?.name }</span>
                                  </li>
                                  <li>
                                      <span><strong>Type</strong></span>
                                      <span>{ productDetails.types?.name }</span>
                                  </li>
                                  <li>
                                      <span><strong>Tax</strong></span>
                                      <span>{ productDetails.tax?.name }</span>
                                  </li><br/><br/>

                                  { (productVariantDetails.productSubVariants ?? []).map((data) => (
                                      <li>
                                          <span><strong>{ data.variant.name }</strong></span>
                                          <span>{ data.subVariant.name }</span>
                                      </li>
                                  )) }
                              </ul>
                          </div></div>
                      </div></div>

                      <div className="descriptionContent">
                          <div className="row"><div className="col-lg-12 col-md-12 col-sm-12">
                              <h5>Description:</h5>
                              <p style={{ textAlign: "justify" }} className="text-muted">{productDetails.description}</p>
                          </div></div>
                      </div>
                </div></div>

            </div>
        </div>
    </React.Fragment>
  );
}
