import React, { useState, useEffect, useCallback } from 'react';
import lodash from "lodash";
import * as Yup from "yup";

import { MDBDataTable } from "mdbreact";
import { FaCheck } from "react-icons/fa";
import { useFormik } from "formik";

import Select from "react-select";
import { Modal, FormGroup, Label, Input, FormFeedback, Form, Alert, Button } from "reactstrap";

import { storeTaxAPI, getTaxesAPI, updateTaxAPI, deleteTaxAPI } from '../../../../api/tax.api';

import MountComponent from "../../../../module/mount-components";

import { errorMessage } from "../../../../module/error-methods";
import { styleReactSelect, themeReactSelect } from '../../../../module/select-methods';

import DeleteModal from '../../../../module/delete-modal.component';

export default React.memo(({ userPermissions, tab }) => {
    const [loading, setLoading] = useState(false);

    const [deleteModal, toggleDeleteModal] = useState(false);
    const [updateModal, toggleUpdateModal] = useState(false);

    const [moduleState, setModuleState] = useState({});
    const [taxesList, setTaxesList] = useState([]);

    const [notificationState, toggleNotificationState] = useState({ visible: false });

    const [dataTable, updateDataTable] = useState({ columns: [], rows: [] });

    const optionStatus = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" }
    ]

    const taxList = async () => {
        setLoading(true);
        await getTaxesAPI().then(({ data }) => {
            if (lodash.isArray(data)) { return setTaxesList([...data]); }
        }).catch((error) => { toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) }); });
        setLoading(false);
    };

    /** @useFormiks ------------------------------------------------------------------------------------------------------------------------- */

    const taxInitialValues = {
        name: '',
        organizationId: null,
        status: null,
    };

    const taxValidation = {
        name: Yup.string().trim().required("Please Enter Tax"),
    };

    const updateTaxFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...taxInitialValues },
        validationSchema: Yup.object({ ...taxValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {

                setLoading(true);

                await updateTaxAPI(values.id, { name: values.name, status: values.status, organizationId: values.organizationId });
                await taxList();

                toggleNotificationState({ visible: true, message: 'Success! tax updated successfully.' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); toggleUpdateModal(false); setModuleState({}); }
        },
    });

    const taxFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...taxInitialValues },
        validationSchema: Yup.object({ ...taxValidation }),
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true); const { name, organizationId } = values;

                await storeTaxAPI({ name, organizationId: 1, status: "Active" });
                await taxList();

                toggleNotificationState({ visible: true, message: 'Success! tax added successfully.' });
                resetForm();
            } catch (error) {
                toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
            } finally { setLoading(false); setModuleState({}); }
        },
    });

    /** @handlers ------------------------------------------------------------------------------------------------------------------------- */

    const handleConfirmDeleteCallback = useCallback(async () => {
        try {
            await deleteTaxAPI(moduleState.id);
            await taxList();
            toggleNotificationState({ visible: true, color: 'success', message: `"${moduleState.name}" deleted successfully !` });
        } catch (error) {
            toggleNotificationState({ visible: true, color: 'danger', message: errorMessage(error) });
        } finally { setLoading(false); toggleDeleteModal(false); setModuleState({}); }
    });

    const handleDeleteModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state })
        toggleDeleteModal(true);
    });

    const handleUpdateModalCallback = useCallback((state = {}) => {
        setModuleState({ ...state });
        toggleUpdateModal(true);
        return updateTaxFormik.setValues({ ...state });
    });

    const handleCancelUpdateModalCallback = useCallback(() => {
        setModuleState({});
        toggleUpdateModal(false);
        return updateTaxFormik.setValues({});
    });

    /** ----------------------------------------------------------------------------------------------------------------------------------- */
    const dataTableHandler = () => {
        const columns = [
            {
                label: "Sr.No.",
                field: "sr",
                sort: "asc",
                width: 150,
            },
            {
                label: "Tax Name",
                field: "taxName",
                sort: "asc",
                width: 270,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 270,
            },
        ];
        const rows = [
            ...taxesList.map((details, index) => ({
                sr: ++index,
                taxName: details?.name,
                status: details?.status,
                action: (
                    <div className="functionalBtn editDataShow">
                        <MountComponent condition={userPermissions.PRODUCT_MASTERS_UPDATE}>
                            <button className="btn btn-group border" onClick={() => { handleUpdateModalCallback(details); }}>
                                <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                        <MountComponent condition={userPermissions.PRODUCT_MASTERS_DELETE}>
                            <button className="btn btn-group border mx-2" onClick={() => { handleDeleteModalCallback(details); }} data-toggle="modal">
                                <i class="fa fa-trash" style={{ color: '#e11515' }} aria-hidden="true"></i>
                            </button>
                        </MountComponent>
                    </div>
                ),
            })),
        ];

        if (userPermissions.PRODUCT_MASTERS_UPDATE || userPermissions.PRODUCT_MASTERS_DELETE) {
            columns.push({ label: "Action", field: "action", sort: "disabled", width: 200 });
        }

        return updateDataTable({ columns: [...columns], rows: [...rows] });
    }

    /** @useEffects ---------------------------------------------------------------------------------------------------------------------- */

    useEffect(() => { if (tab === "TAXES_TAB") { taxList(); } }, [tab]);
    useEffect(() => {
        if (tab === "TAXES_TAB") { taxFormik.resetForm(); updateTaxFormik.resetForm(); }
    }, [tab]);

    useEffect(() => {
        if (notificationState.visible) { setTimeout(() => { toggleNotificationState({ visible: false, color: '', message: '' }); }, 5000); }
    }, [notificationState.visible]);

    useEffect(() => { dataTableHandler() }, [taxesList]);
    /** ----------------------------------------------------------------------------------------------------------------------------------- */

    return (
        <div className="">
            <MountComponent condition={loading}>
                <div className="fullscreen-loader"><div className="loader-container"><div className="loader">
                </div></div></div>
            </MountComponent>

            <Alert color={notificationState.color} isOpen={notificationState.visible}>
                {notificationState.message}
            </Alert>

            <MountComponent condition={userPermissions.PRODUCT_MASTERS_CREATE}>
                <form className="row mainText" onSubmit={taxFormik.handleSubmit}>
                    <div className="col-12 bg-transparent pe-0 mb-2" style={{ height: '40px' }}><div className="mainFormSubmit">
                        <div className="error-input">
                            <Input placeholder="Please enter Tax" className="h-100" name="name"
                                invalid={taxFormik.touched.name && taxFormik.errors.name}
                                value={taxFormik.values.name}
                                onChange={taxFormik.handleChange}
                            ></Input>
                            <MountComponent condition={taxFormik.touched.name && taxFormik.errors.name}>
                                <FormFeedback type="invalid">{taxFormik.errors.name}</FormFeedback>
                            </MountComponent>
                        </div>
                        <Button className="btn-sm h-100 w-40"><FaCheck /></Button>
                    </div></div>
                </form>
            </MountComponent>

            <div className="dataTable-hide-extra-props mt-3">
                <MDBDataTable responsive bordered data={dataTable} footer={false} />
            </div>

            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(false); }} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <DeleteModal
                    toggleDeleteModal={toggleDeleteModal}
                    handleConfirmDeleteCallback={handleConfirmDeleteCallback}
                    message={`Are you sure you want to delete ${moduleState.name} ?`}
                ></DeleteModal>
            </Modal>{/* Modal for delete tax */}

            <Modal isOpen={updateModal} toggle={handleCancelUpdateModalCallback} backdrop={"static"} scrollable={true} id="staticBackdrop" centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Edit Taxes</h5>
                    <button type="button" className="btn-close" onClick={handleCancelUpdateModalCallback} aria-label="Close"></button>
                </div>
                <Form className="needs-validation" onSubmit={updateTaxFormik.handleSubmit}>
                    <div className="modal-body">
                        <FormGroup className="mb-3">
                            <Label htmlFor="tax-name">Tax</Label>
                            <Input name="name" placeholder="Tax" type="text" className="form-control" id="tax-name"
                                onChange={updateTaxFormik.handleChange}
                                onBlur={updateTaxFormik.handleBlur}
                                value={updateTaxFormik.values.name || ""}
                                invalid={updateTaxFormik.touched.name && updateTaxFormik.errors.name ? true : false}
                            />
                            <MountComponent condition={updateTaxFormik.touched.name && updateTaxFormik.errors.name}>
                                <FormFeedback type="invalid"> {updateTaxFormik.errors.name} </FormFeedback>
                            </MountComponent>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label htmlFor="tax-status">Status</Label>
                            <Select
                                options={optionStatus}
                                placeholder="Select Status"
                                onChange={({ value }) => { updateTaxFormik.setFieldValue("status", value); }}
                                value={optionStatus.find(({ value }) => value === updateTaxFormik.values.status) ?? null}
                                theme={(theme) => themeReactSelect(theme, updateTaxFormik.touched.status && updateTaxFormik.errors.status)}
                                styles={{ control: (baseStyles, state) => styleReactSelect(baseStyles, state, updateTaxFormik.touched.status && updateTaxFormik.errors.status) }}
                            />
                            <MountComponent condition={updateTaxFormik.touched.status && updateTaxFormik.errors.status}>
                                <small className="select-error">{updateTaxFormik.errors.status}</small>
                            </MountComponent>
                        </FormGroup>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="cancelModal btn" onClick={handleCancelUpdateModalCallback}>Cancel</button>
                        <button type="submit" className="nextMasterModal btn">Update</button>
                    </div>
                </Form>
            </Modal>{/* Modal for Edit Division */}
        </div>
    );
});
