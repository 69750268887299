import { server } from '../config/config';

export const requestSignInOTP = (requestData = {}) => server({
    method: 'post',
    url: `auth/sign-in`,
    data: { ...requestData },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const signInVerification = async (requestData = {}) => server({
    method: 'post',
    url: `auth/sign-in-verification`,
    data: { ...requestData },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const requestVerificationOTP = (requestData = {}) => server({
    method: 'post',
    url: `auth/request-verification`,
    data: { ...requestData },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const requestSignUp = (requestData = {}) => server({
    method: 'post',
    url: `auth/sign-up`,
    data: { ...requestData },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});

export const signUpVerification = (requestData = {}) => server({
    method: 'post',
    url: `auth/sign-up-verification`,
    data: { ...requestData },
    headers: { "Content-type": "application/json; charset=UTF-8" }
});