import React,{ Fragment,cloneElement } from "react";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import styled from "styled-components";

const googleMapKey = "AIzaSyDFbh8qmYfgZR77myC6eowbBEkhiR1YZPA"

const MapContainer = styled.div`
    height: 300px;
    background: #f8f9fa;
    border-radius: 3px;
`
const List = ({ children, array }) => (
    <Fragment>
        { array.map((data, index) => (<Fragment key={index}>{cloneElement(children, { ...data })}</Fragment>))}
    </Fragment>
);

export default React.memo(({ marks, center }) => (
    <MapContainer>
        <LoadScript googleMapsApiKey={googleMapKey}>
            <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={center} zoom={5}>
                <List array={marks}><Marker /></List>
            </GoogleMap>
        </LoadScript>
    </MapContainer>
));
